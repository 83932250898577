import React from 'react';
import { number } from '../../../config';
import { CalendarCell } from '@progress/kendo-react-dateinputs';
import { addDays, addMonths, addWeeks, addYears, compareAsc, differenceInDays, getDate, getDay, getMonth, isLastDayOfMonth, startOfDay } from 'date-fns';
import "./recurringTask.scss";
import { endByCountValueSatSun } from './recurringTaskHelper';
import { isDateValid } from '../../../shared/validators/validator';

/**
 * custom changes for the kendo calander cell
 * @author Muskan Thakur  
 */
const RecurrenceCustomCalendarCell = (props) => {
    let style = {};
    const { recur, duration, endBydateValue, realProps, endByCountValue, skipDays, calenderValue, disablePastDueDate, recurrencePattern, skipCountValue } = props;
    const { value } = realProps;
    const todaysDate = startOfDay(new Date());
    const skipWeekends = skipDays.filter(function (e) { return e !== true })

    const setStyle = () => {
        return { cursor: "pointer", opacity: 0.6 };
    };

    /**
     * sets styles for the weekend (saturday/sunday)
     */
    const weekendStyle = () => {
        const selectedDateWeekDay = getDay(new Date(value));
        const skipPastDueDates = skipWeekends[number.ZERO]
        if (
            (skipPastDueDates === number.ONE && selectedDateWeekDay === number.ZERO) ||
            (skipPastDueDates === number.SEVEN && selectedDateWeekDay === number.SIX) ||
            (skipWeekends?.length === number.TWO && (selectedDateWeekDay === number.SIX || selectedDateWeekDay === number.ZERO))
        ) {
            style = setStyle();;
        }
    };

    const pastDatesDisabled = () => {
        const dailyEndlessConditions = differenceInDays(value, todaysDate) < number.ZERO;
        if (dailyEndlessConditions && disablePastDueDate) {
            style = setStyle();
        }
    }

    const getSecondComparisonDate = () => {
        const diff = differenceInDays(new Date(calenderValue), new Date(todaysDate));
        return diff > number.ZERO ? calenderValue : todaysDate;
    }

    const comparisonDate = disablePastDueDate ? getSecondComparisonDate() : calenderValue

    /**
     * sets calander dates for daily recurrence based on the type of duration 
     */
    const getDailyRecur = () => {
        weekendStyle()
        pastDatesDisabled()
        const selectedDateWeekDay = getDay(new Date(value));
        const dailyEndlessCondition = differenceInDays(value, comparisonDate) >= number.ZERO;
        const dailyEndByDateConditon = dailyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const endByCountSkipDay = endByCountValueSatSun(endByCountValue, skipWeekends, calenderValue, false)
        const dailyEndByCountCondition = dailyEndlessCondition && compareAsc(addDays(calenderValue, (endByCountSkipDay + endByCountValue)), value) !== -number.ONE;

        let completionBasedSelection = compareAsc(addDays(calenderValue, skipCountValue - number.ONE), value) == -number.ONE && compareAsc(addDays(calenderValue, skipCountValue), value) !== -number.ONE;
        const dailyCompletionBased = dailyEndlessCondition && completionBasedSelection
        const dailyEndbyDateCompletionBased = dailyEndByDateConditon && completionBasedSelection

        const setStyle = () => {
            const skipPastDueDates = skipWeekends[number.ZERO]
            if ((skipWeekends?.length === number.ZERO) || (skipWeekends?.length === number.TWO && (selectedDateWeekDay !== number.ZERO && selectedDateWeekDay !== number.SIX)) || (skipWeekends?.length === number.ONE && ((selectedDateWeekDay !== number.SIX && skipPastDueDates === number.SEVEN) || (selectedDateWeekDay !== number.ZERO && skipPastDueDates === number.ONE)))) {
                style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
            }
        }
        const checkRecurrencePatternOne = (duration) => (
            (duration?.value === number.ONE && dailyEndlessCondition) ||
            (duration?.value === number.TWO && dailyEndByDateConditon) ||
            (duration?.value === number.THREE && dailyEndByCountCondition)
        ) && recurrencePattern === number.ONE;
        
        const checkRecurrencePatternTwo = (duration) => recurrencePattern === number.TWO && (
            ((duration?.value === number.ONE || duration?.value === number.THREE) && dailyCompletionBased) ||
            (duration?.value === number.TWO && dailyEndbyDateCompletionBased)
        );
        
        const dailyRecurrenceCondition = () => checkRecurrencePatternOne(duration) || checkRecurrencePatternTwo(duration);

        if (dailyRecurrenceCondition()) {
            setStyle()
        }
    }

    /**
     * sets calander dates for weekly recurrence based on the type of duration 
     */
    const getWeeklyRecur = () => {
        weekendStyle()
        pastDatesDisabled()
        const selectedDateWeekDay = getDay(new Date(calenderValue));
        const currentCellWeekDay = getDay(new Date(value));
        const weeklyEndlessCondition = selectedDateWeekDay === currentCellWeekDay && differenceInDays(value, comparisonDate) > number.ZERO;
        const weeklyEndByDateCondition = weeklyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const weeklyEndByCountCondition = weeklyEndlessCondition && compareAsc(addWeeks(calenderValue, endByCountValue), value) !== -number.ONE;

        let completionBasedSelection = compareAsc(addWeeks(calenderValue, skipCountValue - number.ONE), value) === -number.ONE && compareAsc(addWeeks(calenderValue, skipCountValue), value) !== -number.ONE;
        const weeklyCompletionBased = weeklyEndlessCondition && completionBasedSelection
        const weeklyEndByDateCompletionBased = weeklyEndByDateCondition && completionBasedSelection

        const checkWeeklyRecurrencePatternOne = (duration) => (
            (duration?.value === number.ONE && weeklyEndlessCondition) ||
            (duration?.value === number.TWO && weeklyEndByDateCondition) ||
            (duration?.value === number.THREE && weeklyEndByCountCondition)
        ) && recurrencePattern === number.ONE;
        
        const checkWeeklyRecurrencePatternTwo = (duration) => recurrencePattern === number.TWO && (
            ((duration?.value === number.ONE || duration?.value === number.THREE) && weeklyCompletionBased) ||
            (duration?.value === number.TWO && weeklyEndByDateCompletionBased)
        );
        
        const weeklyRecurrenceCondition = () => checkWeeklyRecurrencePatternOne(duration) || checkWeeklyRecurrencePatternTwo(duration);

        if (weeklyRecurrenceCondition()) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    }

    /**
     * sets calander dates for monthly recurrence based on the type of duration 
     */
    const getMonthlyRecur = () => {
        weekendStyle()
        pastDatesDisabled()
        const selectedDayOfMonth = getDate(new Date(calenderValue));
        const currentCellDay = getDate(new Date(value));
        const monthlyEndlessCondition = selectedDayOfMonth === number.THIRTY_ONE ? isLastDayOfMonth(new Date(value)) : (currentCellDay === selectedDayOfMonth) && differenceInDays(value, comparisonDate) > number.ZERO;
        const monthlyEndByDateCondition = monthlyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const monthlyEndByCount = monthlyEndlessCondition && compareAsc(addMonths(calenderValue, endByCountValue), value) !== -number.ONE;

        let completionBasedSelection = compareAsc(addMonths(calenderValue, skipCountValue - number.ONE), value) == -number.ONE && compareAsc(addMonths(calenderValue, skipCountValue), value) !== -number.ONE;
        const monthlyCompletionBased = monthlyEndlessCondition && completionBasedSelection
        const monthlyEndByDateCompletionBased = monthlyEndByDateCondition && completionBasedSelection

        const checkMonthlyRecurrencePatternOne = (duration) => (
            (duration?.value === number.ONE && monthlyEndlessCondition) ||
            (duration?.value === number.TWO && monthlyEndByDateCondition) ||
            (duration?.value === number.THREE && monthlyEndByCount)
        ) && recurrencePattern === number.ONE;
        
        const checkMonthlyRecurrencePatternTwo = (duration) => recurrencePattern === number.TWO && (
            ((duration?.value === number.ONE || duration?.value === number.THREE) && monthlyCompletionBased) ||
            (duration?.value === number.TWO && monthlyEndByDateCompletionBased)
        );
        
        const monthlyRecurrenceCondition = () => checkMonthlyRecurrencePatternOne(duration) || checkMonthlyRecurrencePatternTwo(duration);

        if (monthlyRecurrenceCondition()) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    }

    /**
     * sets calander dates for yearly recurrence based on the type of duration 
     */
    const getYearlyRecur = () => {
        weekendStyle()
        pastDatesDisabled()
        const selectedDayOfYear = getDate(new Date(calenderValue));
        const selectedMonthOfYear = getMonth(new Date(calenderValue));
        const currentCellDay = getDate(new Date(value));
        const currentCellMonth = getMonth(new Date(value));

        const yearlyEndlessCondition = ((selectedDayOfYear === currentCellDay) && (selectedMonthOfYear === currentCellMonth) && differenceInDays(value, comparisonDate) > number.ZERO);
        const yearlyEndByDateCondition = yearlyEndlessCondition && compareAsc(endBydateValue, value) !== -number.ONE;
        const yearlyEndByCount = yearlyEndlessCondition && compareAsc(addYears(calenderValue, endByCountValue), value) !== -number.ONE;

        const checkYearlyConditions = (duration) => (
            (duration?.value === number.ONE && yearlyEndlessCondition) ||
            (duration?.value === number.TWO && yearlyEndByDateCondition) ||
            (duration?.value === number.THREE && yearlyEndByCount)
        );
        
        const yearlyRecurrenceCondition = () => checkYearlyConditions(duration);

        if (yearlyRecurrenceCondition()) {
            style = { backgroundColor: "#0d6efd", color: "white", border: "#0c65e9" };
        }
    }

    /**
     * handles the recurring actions based on the recur value and validates dates before execution
     * @author Muskan Thakur
     */
    const handleRecurAction = () => {
        if (isDateValid(calenderValue) && isDateValid(value)) {
            switch (recur?.value) {
                case number.ONE:
                    getDailyRecur();
                    break;
                case number.TWO:
                    getWeeklyRecur();
                    break;
                case number.THREE:
                    getMonthlyRecur();
                    break;
                case number.FOUR:
                    getYearlyRecur();
                    break;
                default:
                    weekendStyle();
                    pastDatesDisabled();
                    break;
            }
        }
    };

    handleRecurAction();
    return <CalendarCell {...realProps} style={style} />;
};

export default RecurrenceCustomCalendarCell;