import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { uploadEditorS3IFrame } from '../../../shared/services/common.service';
import { appendFieldsFromObject, createFormData } from '../../../helper/formDataHelper';
import { EditorUtils } from '@progress/kendo-react-editor';
import { generateUniqueId, useInterval } from './groupedMedia-utils';
import { useSelector } from 'react-redux';
import { useEditorContext } from '../../editor.Context';
import './PDFDialog.scss';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { button, icon, label, number } from '../../../config';

/**
 * PDFDialog Component
 *
 * @param {Object} props - Component properties.
 * @param {Function} setDialog - Function to set the visibility of the dialog.
 * @param {Function} updateNode - Function to update the node in the editor.
 * @param {Object} iframeProp - Properties of the iframe being edited.
 * @param {Function} updateIframe - Function to update the iframe in the editor.
 * @returns {JSX.Element} The PDFDialog component.
 * @author Shivam
 */
const PDFDialog = ({ props, setDialog, updateNode, iframeProp, updateIframe ,removeIframe}) => {
  const { view } = props;
  const { user } = useSelector((state) => state.auth);
  const { value, start, stop } = useInterval(number.ZERO, number.ONE_HUNDRED_FIFTY);
  const [file, setFile] = useState(null);
  const [width, setWidth] = useState(number.SIXTY);
  const [height, setHeight] = useState(number.SIXTY);
  const [isValidWidth, setIsValidWidth] = useState(true);
  const [isValidHeight, setIsValidHeight] = useState(true);
  const [isValidFile, setIsValidFile] = useState(true);
  const [fileSizeError, setFileSizeError] = useState(false);
  const fileInputRef = useRef(null);

  const editorState = useEditorContext();
  const { setEditFrameId, setVisibleDialog } = editorState;

  const MAX_FILE_SIZE_MB = number.HUNDRED;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * number.ONE_THOUSAND_TWENTY_FOUR * number.ONE_THOUSAND_TWENTY_FOUR;

/**
 * Validates the width and height whenever either of these values changes.
 */
useEffect(() => {
  validateWidth(width);
  validateHeight(height);
}, [width, height]);

/**
 * Updates the height and width state values based on the `iframeProp` 
 */
useEffect(() => {
  if (updateNode) {
    const extractValue = (value) => parseInt(value.replace(/\D/g, ''), number.TEN);
    setHeight(extractValue(iframeProp.height));
    setWidth(extractValue(iframeProp.width));
  }
}, [updateNode]);

const setDefault = () => {
  setHeight(number.SIXTY) ; 
  setWidth(number.SIXTY);
}


  /**
   * Closes the dialog and resets the editor context state.
   */
  const onClose = () => {
    setVisibleDialog(false);
    setEditFrameId(null);
    setDialog && setDialog(false);
  };

  /**
   * Validates the width value.
   * @param {number} value - The width value to validate.
   */
  const validateWidth = (value) => {
    setIsValidWidth(value > number.ZERO && value <= number.HUNDRED);
  };

  /**
   * Validates the height value.
   * @param {number} value - The height value to validate.
   */
  const validateHeight = (value) => {
    setIsValidHeight(value > number.ZERO && value <= number.HUNDRED);
  };

  /**
   * Handles the file selection and validates the file size.
   * @param {Event} e - The file input change event.
   */
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[number.ZERO];
    if (selectedFile) {
      if (selectedFile.size <= MAX_FILE_SIZE_BYTES) {
        setFile(selectedFile);
        setIsValidFile(true);
        setFileSizeError(false);
      } else {
        setFileSizeError(true);
        setIsValidFile(false);
      }
    } else {
      setIsValidFile(false);
      setFileSizeError(false);
    }
  };

  /**
   * Inserts an iframe node into the editor.
   * @param {string} result - The URL of the uploaded file.
   */
  const insertIframe = (result) => {
    const schema = view.state.schema;
    const nodeType = schema.nodes.iframe;
    const id = generateUniqueId(user.id, "media", file?.name);

    const node = nodeType.createAndFill({
      src: result,
      style: `width: ${width}vw; height: ${height}vh;`,
      id: id
    });

    if (node) {
      let transaction = view.state.tr;
      transaction.insert(view.state.selection.head, node);
      const paragraphNode1 = schema.nodes.paragraph.create();
      transaction.insert(view.state.selection.head + 1, paragraphNode1);
      view.dispatch(transaction);
      view.focus();
    }
    onClose();
  };

  /**
   * Handles the save action, including uploading the file and updating or inserting the iframe.
   */
  const handleSave = async () => {
    let result;

    if (file) {
      const payload = { files: file };
      const formData = createFormData();
      appendFieldsFromObject(formData, payload);
      start();
      result = await uploadEditorS3IFrame(formData);
      stop();
    }

    if (updateNode) {
      const location = result?.Location || iframeProp.src;
      updateIframe({ src: location, width: width, height: height, id: iframeProp.id });
    } else {
      insertIframe(result.Location);
    }

    onClose();
  };

  /**
   * Handles the cancel action to close the dialog without saving changes.
   */
  const handleCancel = () => {
    onClose();
  };

  /**
   * Triggers the file input click event.
   */
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /**
  * handle Height Change.
  */
  const handleHeightChange = (event) => {
    const newHeight = Number(event.target.value);
    setHeight(newHeight);
  };

  /**
  * handle Width Change.
  */
  const handleWidthChange = (event) => {
    const newWidth = Number(event.target.value);
    setWidth(newWidth);
  };

  return (
    <Dialog title="Edit PDF" onClose={onClose} className='dialog-index'>
      <div className='form-group'>
        <label>{label.FILE_NAME}</label>
        <div className='position-relative'>
          <div className='d-flex align-items-center'>
            <div className='custom-upload-file-container w-100'>
              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileChange}
                id="upload_statement"
                className='file-border'
                ref={fileInputRef} 
              />
              <span className='link-text small ml-2 position-absolute add-pdf-btn' onClick={triggerFileInput}>{button.ADD}</span>
              <span className="upload-btn form-control text-truncate" id="upload-btn"> {!file && updateNode ? iframeProp.name : file ? file.name :  label.SELECT_FILE}</span>
            </div>

          </div>          
        </div>
        <div className="text-center">
          {value ? <ProgressBar value={value} /> : ""}
        </div>
        {!isValidFile && !fileSizeError && <div className='mt-2 file-size-error'>{label.VALID_FILE}</div>}
        {fileSizeError && <div className='mt-2 file-size-error'>{label.VALID_SIZE_SIZE} {MAX_FILE_SIZE_MB} MB.</div>}
      </div>
      <div className='form-row'>
        <div className='form-group col-md-6'>
          <label>{label.WIDTH_}</label>
          <div>
            <div className='d-flex align-items-center'>
              <input
                className='form-control w-50'
                value={width}
                onChange={handleWidthChange}
                id = "pdf-dialog-width"
              />
              <span className='small ml-2'>(%)</span>
            </div>
            {!isValidWidth && <div className='mt-2' style={{ color: 'red', fontSize: '12px' }}>{label.VALID_WIDTH}</div>}
          </div>
        </div>        
        <div className='form-group col-md-6'>
          <label>{label.HEIGHT_}</label>
          <div>
            <div className='d-flex align-items-center'>
              <input
                className='form-control w-50'
                value={height}
                onChange={handleHeightChange}
                id = "pdf-dialog-height"
              />
              <span className='small ml-2'>(%)</span>
            </div>
            {!isValidHeight && <div className='mt-2 file-size-error'>{label.VALID_HEIGHT}</div>}
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <div className='d-flex justify-content-between align-items-center'>
          <span className='link-text small cursor-pointer' onClick = {setDefault}>{button.DEFAULT}</span>
          <div>
          <Button className='btn btn-secondary mr-2' type="button" onClick={()=>removeIframe(iframeProp.id)} id = "url-dialog-cancel-btn">{icon.TRASH}</Button>
            <Button className='btn btn-secondary mr-2' type="button" onClick={handleCancel}>{button.CANCEL}</Button>
            <Button 
              className='btn-width btn btn-primary'
              type="button" 
              onClick={handleSave} 
              disabled={!isValidWidth || !isValidHeight || !isValidFile}
              id = "pdf-dialog-insert-pdf-btn"
            >
              {button.SAVE}
            </Button>
          </div>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};

export default PDFDialog;
