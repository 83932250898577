import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import TaskAssignee from './TaskAssignee';
import TaskAssignmentId from './TaskAssignmentId';
import TaskAssignmentType from './TaskAssignmentType';
import TaskSubmit from './TaskSubmit';
import WorkflowAssignmentType from './WorkflowAssignmentType';
import { setExpanded, setFlowElements, setShowAttachments, setShowComments, setShowDescription, setShowLinkedTasksSubTasks, setShowSimplfiedDetail , setShowTaskDescPopup, toggleDescPopup} from "../../../../../actions/taskSidebar";
import { lowercaseKeys } from "../../../../../helper/common";

/**
 * Container component for assignment dropdowns
 * Parent component => TaskDetail
 * @returns JSX
 */
const TaskAssignment = () => {
  const { workflowElements, workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails)
  const { isNewTask,expanded } = useSelector(state => state.taskSidebar);
  const dispatch = useDispatch()
  const {showTaskDetailPanel} = useSelector((state) => state.sidebarContent);
  const { run } = useSelector((state) => state.initializeTour);
  const { isCloneTask } = useSelector(state => state.cloneTaskDetails);
  /**
   * add flow elements and panel visibility of workflows while creating tasks
   * @author {Prachi Jain}
    */
  useEffect(() => {
    if (isNewTask) {
      let flowElementsData = []
      if (workflowElements?.length) flowElementsData = workflowElements.map((workflowElement) => lowercaseKeys(workflowElement));
      dispatch(setFlowElements(flowElementsData))
    }
    if (isNewTask && workflowBasicDetails) {
      const { ShowAttachments, ShowDescription, ShowChildSubTasks, ShowComments, SimplifiedDetail , ShowTaskDescPopup } = workflowBasicDetails
      dispatch(setShowAttachments(isCloneTask ? isCloneTask : ShowAttachments))
      dispatch(setShowDescription(isCloneTask ? isCloneTask : ShowDescription))
      dispatch(setShowLinkedTasksSubTasks(isCloneTask ? isCloneTask : ShowChildSubTasks))
      dispatch(setShowComments(isCloneTask ? isCloneTask : ShowComments))
      dispatch(setShowSimplfiedDetail(isCloneTask ? isCloneTask : SimplifiedDetail))
      dispatch(toggleDescPopup({ status: !!ShowTaskDescPopup, type: 'description' }));
      !run && !!ShowTaskDescPopup && dispatch(setExpanded(expanded.filter((item) => item !== "task-description")));
    }
  }, [workflowBasicDetails, workflowElements])

  return (
    <React.Fragment>
        <TaskAssignmentType/>
        <TaskAssignmentId/>
        <WorkflowAssignmentType/>
        <TaskAssignee/>
        {showTaskDetailPanel && !isNewTask ?  "": <TaskSubmit />}
  </React.Fragment>

  )
}

export default React.memo(TaskAssignment);
