import React from 'react';
import { icon, label } from '../../config';
import { formatDate } from '../editor.helper';


/**
* Non Editable Editor 
* PC KendoEditor
* @author Shivam Mishra
*/
const TaskDetails = ({ taskInfo }) => {   
    const { Name, ProjectTaskId, Priority, Assignee, DueDate, IsTaskComplete } = taskInfo;
    return (
        <div className='row'>
            <div className='col-md-12'>
                <div className='container pt-2 pb-2 details-popup-minwidth'>
                    <div className='d-flex d-flex justify-content-between justify-content-center align-items-center mt-3 mb-3'>
                        <div>{ProjectTaskId}</div>
                        <div className='ml-2 mr-2'>{Name}</div>
                        <div className='d-flex justify-content-center'>{IsTaskComplete ? icon.GREEN_CHECK : icon.CHECK}</div>
                    </div>
                    <div className='d-flex d-flex justify-content-between justify-content-center align-items-center'>
                        <div>{Assignee ?? label.UNASSIGNED}</div>
                        <div className='ml-2 mr-2'>{icon[Priority]}</div>
                        <div>{formatDate(DueDate)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(TaskDetails);