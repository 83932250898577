import { endOfDay, format } from "date-fns";
import { addComment, handleNewComments } from "../../../../actions/comments";
import { label, number } from "../../../../config";
import store from "../../../../store"
import { NEW_COMMENT_CREATION_TIME } from "../../sidebar.constants";
import { splitString } from "../../../../utils";
import { stripHtmlTags } from "../../../Comments/comment.helper";
import { appendFieldsFromObject, createFormData } from "../../../../helper/formDataHelper";
import { deleteComment, deleteReply } from "./comments.service";

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * delete comments for new task
 * @param {Integer} commentId
 * @returns {Void}
 * @author Himanshu Negi
 */
export const deleteNewTaskComment = (commentId) => {
  const state = store.getState();
  const { allComments } = state.comments;
  const newComments = allComments.filter((comment) => comment?.Id !== commentId);
  store.dispatch(handleNewComments(newComments));
}



/**
 * updates reactions on comments for new task
 * @param {String} emoji
 * @param {Integer} commentId
 * @returns {Void}
 * @author Himanshu Negi
 */
export const newTaskCommentReaction = (emoji, commentId) => {
  const state = store.getState();
  const { allComments } = state.comments;
  const { user } = state.auth;
  const emojiReaction = { emoji, count: number.ONE, isMine: number.ONE, users: user?.Name }
  const selectedComment = allComments?.find((comment) => comment?.Id === commentId);
  if (selectedComment.hasOwnProperty('emojis')) {
    const { emojis } = selectedComment;
    const emojiIndex = emojis.findIndex((e) => e?.emoji === emoji);
    const newEmojis = emojiIndex != number.MINUS_ONE ? emojis.filter((e, i) => i !== emojiIndex) : [...emojis, emojiReaction];
    selectedComment.emojis = newEmojis;
  } else {
    selectedComment.emojis = [emojiReaction]
  }
  const comments = allComments.map((comment) => comment?.Id === selectedComment?.Id ? selectedComment : comment);
  store.dispatch(handleNewComments(comments));
}

/**
 * here object for mention in comments
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getAllConfig = () => {
  return {
    Email: label.NOTIFY_ALL,
    label: label.ALL,
    myProjectId: null,
    value: number.ZERO
  }
}

export const commentImagesTypes = {
  COMMENT_IMAGES: "COMMENT_IMAGES",
  REPLY_IMAGES: "REPLY_IMAGES",
}

/**
 * Returns deleteFile Payload
 * @param {Integer} entityTypeId
 * @param {Integer} entityId
 * @param {String} file
 * @returns {Object}
 */
export const getDeleteFilePayload = (entityTypeId, entityId, file) => {
  return { entityTypeId, entityId, file }
};

export const allowedCommentFileTypes = ["image/png", "image/jpeg"];

/**
 * Retrieves the tagged users for a task.
 * @param {string[]} userIds - An array of user IDs.
 * @returns {string[]} An array of unique user IDs including both provided user IDs and additional users.
 * @author Himanshu
 */
export const getTaggedUser = (userIds) => {
  const state = store.getState();
  const { isNewTask, task, newTaskData } = state.taskSidebar;
  let mentionUsers = [...userIds];
  let allUsers = [];

  if (userIds?.find((id) => Number(id) === number.ZERO)) {
    mentionUsers = mentionUsers.filter((id) => Number(id) !== number.ZERO)
    allUsers = splitString(isNewTask ? newTaskData?.followers : task?.followerIds, ',');
    if (!allUsers?.includes(task?.Assignee)) allUsers.push(task?.Assignee.toString());
  }
  mentionUsers = mentionUsers.concat(allUsers);
  return [...new Set([...mentionUsers])];
}

/**
 * Retrieves the tagged users for notifications based on existing users and provided user IDs.
 * @param {string} existingUsers - A comma-separated string of existing user IDs.
 * @param {string[]} userIds - An array of user IDs.
 * @param {boolean} isAddFollowerUser - Indicates whether to include users based on followers.
 * @returns {string[]} An array of unique user IDs for notification tagging.
 * @author Himanshu
 */
export const getNotificationTaggedUsers = (existingUsers, userIds, isAddFollowerUser) => {
  const state = store.getState();
  const { isNewTask, task, newTaskData } = state.taskSidebar;
  const userId = state.auth?.user?.id;
  let existingMentionUsers = existingUsers ? splitString(existingUsers, ",") : [];
  let allUsers = [];
  allUsers = splitString(isNewTask ? newTaskData?.followers : task?.followerIds, ',');
  if (!allUsers?.includes(task?.Assignee)) allUsers.push(task?.Assignee?.toString());
  let notificationUsers = userIds?.filter((id) => !existingMentionUsers?.includes(id))
  notificationUsers = notificationUsers?.filter((id) => isAddFollowerUser ? !allUsers?.includes(id) : allUsers?.includes(id));
  notificationUsers = notificationUsers?.filter(Id => +(Id) !== +(userId));
  return [...new Set([...notificationUsers])];
}

/**
 * Checks for deletion of a comment if there is only one image in the comment
 * @param {*} item 
 * @param {*} textProperty 
 * @param {*} imageProperty 
 * @returns {boolean}
 */
export const needsDeletion = (item, textProperty, imageProperty) => stripHtmlTags(item?.[textProperty]).trim() === "" && (item?.[imageProperty] && splitString(item?.[imageProperty], ",").length === number.ONE);


/**
 * handles deletion of comments or reply 
 * @param {*} payload 
 * @param {*} commentId 
 */
export const deleteCommentOrReply = async (payload, commentId) => {
  const formData = createFormData();
  appendFieldsFromObject(formData, payload);
  commentId ? await store.dispatch(deleteReply(formData, commentId)) : await store.dispatch(deleteComment(formData));
}

/**
 * generates a random string
 * @param {*} length 
 * @returns String
 * @author Sarthak Arora
 */
export const getRandomString = (length = 5) => {
  return Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
}

/**
 * converts base64 to File object
 * @param {*} base64String 
 * @param {*} fileName 
 */
export const base64ToFile = async (base64String, fileName) => {
  const response = await fetch(base64String);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
}


/**
 * checks if the text in the given html is empty or not 
 *  @author Sarthak Arora
 */
export const isEmptyHtml = (htmlString) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  const textContent = tempElement.textContent || tempElement.innerText || "";
  return textContent.trim().length === number.ZERO;
};
