import { indent, outdent } from '@progress/kendo-editor-common';
import { ProseMirror } from "@progress/kendo-react-editor";
import axios from "axios";
import { updatesIsCollabCentralOpened } from "../actions/common";
import { createWorkflowTask } from "../components/TaskSidebar/sidebar.helper";
import { getTaskInfoForDescription, openSidebar } from "../components/Tasks/tasks.service";
import { number, quote } from "../config";
import { splitString } from "../utils";
import { SMART_CHIP_REGEX , TASK_CHIP_REGEX } from "./editor.helper";
import {
  commonAttributes,
  getAttributes,
  getAttrs,
  hasAttrs,
  hole,
  tagMark,
} from './editor.marks';

const { inputRules, wrappingInputRule, InputRule } = ProseMirror;

/**
 * This is the node configuration for non editable node 
 * @author Shivam Mishra
*/
export const nonEditable = {
  name: 'nonEditable',
  inline: true,
  group: 'inline',
  content: 'inline+',
  marks: '',
  attrs: {
    contenteditable: { default: null },
    class: { default: '' },  // Allow dynamic classes
    style: { default: null },
    id: { default: null },
  },
  atom: true,
  parseDOM: [
    {
      tag: 'span.uneditable',
      priority: 51,
      getAttrs: (dom) => ({
        id: dom.getAttribute('id'),
        class: dom.getAttribute('class'),  // Get the dynamic class from the DOM
      }),
    },
  ],
  // The styles can be added via the class as well
  toDOM: (node) => [
    'span',
    {
      contenteditable: false,
      class: 'uneditable d-inline-block cursor-pointer mb-1 mr-1 ' + node.attrs.class,  // Use the dynamic class
      id: node.attrs.id,
    },
    0,
  ],
};

export const iframe = {
  attrs: {
    src: {
      default: null,
    },
    style: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  group: 'block',
  selectable: false,
  parseDOM: [
    {
      tag: 'iframe',
      getAttrs: (dom) => ({
        src: dom.getAttribute('src'),
        style: dom.getAttribute('style'),
        id: dom.getAttribute('id'),
      }),
    },
  ],
  toDOM: (node) => {
    const attrs = {
      src: node.attrs.src,
      style: node.attrs.style,
      id: node.attrs.id,
      frameborder: '0',
      allowfullscreen: 'true',
    };
    return ['iframe', attrs];
  },
};

/**
 * Function to handle image insertion in the editor's view.
 * @param {Object} args - The arguments object.
 * @param {File[]} args.files - Array of image files to insert.
 * @param {EditorView} args.view - The editor's view.
 * @param {Event} args.event - The event triggering the image insertion.
 * @returns {boolean} - Returns true if files were inserted, false otherwise.
 * @author Shivam Mishra
 */
export const onImageInsert = (args) => {
  const { files, view, event } = args;
  const nodeType = view.state.schema.nodes.image;

  const position =
    event.type === "drop"
      ? view.posAtCoords({ left: event.clientX, top: event.clientY })
      : null;

  insertImageFiles({ view, files, nodeType, position });

  return files.length > 0;
};

/**
 * Function to create input rules for formatting lists.
 * @param {Object} nodes - The nodes object containing list node types.
 * @param {NodeType} nodes.ordered_list - The NodeType for ordered lists.
 * @param {NodeType} nodes.bullet_list - The NodeType for bullet lists.
 * @returns {InputRule} - Returns input rules for formatting lists.
 * @author Shivam Mishra
 */
export const inputRule = nodes => {
  const {
    ordered_list,
    bullet_list,
  } = nodes;
  return inputRules({
    rules: [
      // Converts '- ' or '+ ' to a bullet list.
      wrappingInputRule(/^\s*([-+*])\s$/, bullet_list),
      // Converts '1. ' to an ordered list.
      wrappingInputRule(/^(\d+)\.\s$/, ordered_list, match => ({
        order: Number(match[1])
      }), (match, node) => node.childCount + node.attrs.order === match[1]),
      // Converts an URL to a link.
      new InputRule(
        // https://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\s$/,
        (state, match, start, end) => {
          const link = state.schema.marks.link.create({
            href: match[1],
          });
          return state.tr.addMark(start, end, link).insertText(" ");
        }
      )

    ]
  });
};

/**
 * Function to create keymap for handling keyboard shortcuts.
 * @param {ProseMirror} ProseMirror - The ProseMirror instance.
 * @param {Function} indent - The function to handle indentation.
 * @param {Function} outdent - The function to handle outdentation.
 * @returns {Keymap} - Returns keymap for handling keyboard shortcuts.
 * @author Shivam Mishra
 */
export const keymap = ProseMirror.keymap({
  Tab: (state, dispatch, view) => {
    indent(state, dispatch, view);
    return true;
  },
  'Shift-Tab': (state, dispatch, view) => {
    outdent(state, dispatch, view);
    return true;
  },
});


export const generateUniqueId = (userID) => {
  const timestamp = Date.now();
  const uniqueId = `ss_${userID}_${timestamp}`;
  return uniqueId;
};

/**
 * Function to handle keydown events in the editor's view.
 * @param {EditorView} view - The editor's view.
 * @param {KeyboardEvent} domEvent - The DOM event.
 * @param {Function} setOffset - Function to set offset for input popup.
 * @param {Function} setInputPopup - Function to set visibility of input popup.
 * @returns {boolean} - Returns true if keydown event is handled, false otherwise.
 * @author Shivam Mishra
 */
export const handleKeydown = async (domEvent, setInputPopup, insertNonEditable, userId, setChipTarget, prevKey, smartChipTarget, setTaskSearchPopup, overallSearchRef , setTempChipId) => {
  if ((prevKey.current === ' ' || prevKey.current === 'Enter' || !prevKey.current)) {
    if (domEvent.key === '@') {
      domEvent.preventDefault();
      const id = generateUniqueId(userId)
      setTempChipId(id) ; 
      await insertNonEditable('@', id)
      const chipTarget = document.getElementById(id);
      setChipTarget(chipTarget);
      setInputPopup(true);
      return true;
    }
    if (domEvent.key === '#') {
      domEvent.preventDefault();
      const id = generateUniqueId(userId)
      await insertNonEditable('#', id)
      const chipTarget = document.getElementById(id);
      overallSearchRef.current = chipTarget;
      setTaskSearchPopup(true)
      return true;
    }
  }
  if (domEvent.key === 'Tab' || domEvent.key === ' ') {
    setInputPopup(false);
  }
  if (!['Shift', 'Backspace'].includes(domEvent.key)) prevKey.current = domEvent.key;
  const isTargetChipExist = document.getElementById(smartChipTarget);
  if (!isTargetChipExist) { setInputPopup(false); }
  return false;
};

/**
 * Function to handle clicking on a smart chip.
 * @param {Event} event - The click event.
 * @param {React.MutableRefObject<boolean>} editableRef - Ref for editable state.
 * @param {Function} setShowEditChip - Function to set the visibility of edit chip.
 * @param {Function} setSmartChipId - Function to set the ID of the smart chip.
 * @param {Function} setEditChip - Function to set the edit chip target.
 * @returns {void}
 * @author Shivam
 */
export const handleSmartChipClick = (event, editableRef, setShowEditChip, setSmartChipId, setEditChip) => {
  const { target } = event;
  const id = target.id;
  if (target.localName === 'span' && id && id.match(SMART_CHIP_REGEX) && editableRef.current) {
    setShowEditChip(true);
    setSmartChipId(id);
    setEditChip(target);
  }
  if (target.localName === 'span' && id && id.match(SMART_CHIP_REGEX) && !editableRef.current) {
    const match = id.match(TASK_CHIP_REGEX);
    
    if (match && match[number.ONE]) {
        const taskId = match[number.ONE];
        openSidebar({ id: taskId});
      }
  }
};

/**
 * Function to handle clicking on an anchor tag.
 * @param {Event} event - The click event.
 * @param {React.MutableRefObject<boolean>} editableRef - Ref for editable state.
 * @param {Function} dispatch - Function to dispatch actions.
 * @param {Object} history - Object representing the history.
 * @returns {void}
 * @author Shivam
 */
export const handleAnchorClick = (event, editableRef, dispatch, history) => {
  const { target } = event;
  const currentHostname = window.location.hostname;
  if (target.localName === 'a') {
    event.preventDefault();
    // might used later
    // if (editableRef.current === false) {
    //   const urlPath = target.getAttribute('href');
    //   const url = new URL(urlPath);
    //   const params = new URLSearchParams(url.search);
    //   const createTask = params.get("createTask");
    //   const fId = params.get("fId");
    //   const pId = params.get("pId");
    //   const tId = params.get("tId");
    //   dispatch(updatesIsCollabCentralOpened(false));

    //   if (currentHostname === url.hostname) {
    //         event.preventDefault();
        
    //     if (fId && pId && createTask) {
    //       createWorkflowTask(fId, pId);
    //     } else if (tId) {
    //       openSidebar({ id: tId });
    //     } else {
    //       history.push(url.pathname + url.search)
    //     }
    //   }
    // } else {
    //   event.preventDefault();
    // }
  }
};

/**
 * Function to handle clicking on an image.
 * @param {Event} event - The click event.
 * @param {Object} _ - Placeholder for lodash functions.
 * @param {Function} setOpenImage - Function to set the visibility of the image popup.
 * @param {Function} setImg - Function to set the image source.
 * @param {boolean} openImage - Current state of the image popup.
 * @returns {void}
 * @author Shivam
 */

export const handleImageClick = (event, _, setOpenImage, setImg, openImage) => {
  const { target } = event;
  const posAtCoords = _.posAtCoords({
    left: event.pageX,
    top: event.pageY,
  });
  

  if ((posAtCoords && _.nodeDOM(posAtCoords?.pos)?.tagName === "IMG") || target.nodeName === 'IMG') {
    const imgSrc = _.nodeDOM(posAtCoords?.pos)?.src || target.src;
    if (imgSrc) {
      setImg(imgSrc);
      setOpenImage(!openImage);
    }
  }
};

/**
 * Extracts the task ID from a string.
 * @param {string} str - The input string containing the task ID.
 * @returns {?string} The extracted task ID, or null if not found.
 * @author Shivam
 */
function extractTaskId(str) {
  const parts = splitString(str, '_');
  const taskIdIndex = parts?.indexOf('task');
  if (taskIdIndex !== -1 && taskIdIndex < parts?.length - 1) {
    return parts[taskIdIndex + 1];
  } else {
    return null;
  }
}


/**
 * Function to handle hovering over a link.
 * @param {Element} target - The target element.
 * @param {React.MutableRefObject<CancelToken>} cancelTokenRef - Ref for cancel token.
 * @param {Function} setSelectedLink - Function to set the selected link.
 * @param {Function} setUrl - Function to set the URL.
 * @param {Function} setVisible - Function to set the visibility.
 * @param {React.MutableRefObject<boolean>} editableRef - Ref for editable state.
 * @param {Function} setTaskInfo - Function to set task information.
 * @param {Function} getTaskInfoForDescription - Function to get task info.
 * @returns {void}
 * @author Shivam
 */

export const handleLinkHover = async (target, cancelTokenRef, setSelectedLink, setUrl, setVisible, editableRef, setTaskInfo , setEditFrame , setEditFrameId) => {
  if (cancelTokenRef.current) {
    cancelTokenRef.current.cancel(quote.OPERATION_CANCEL_MSG);
  }
  const hovered = document.getElementById('editor-update-popup') ; 
  if (hovered){ setVisible(false);setEditFrameId(null);}
  cancelTokenRef.current = axios.CancelToken.source();
  if (target.localName === 'a') {
    const url = new URL(target.href);
    const tId = url.searchParams.get("tId");
    setSelectedLink(target);
    setUrl(target.href);
    setVisible(false);
    if (tId) {
      getTaskInfoForDescription(tId, cancelTokenRef.current)
        .then(taskDetail => {
          setTaskInfo(taskDetail);
          setVisible(taskDetail ? true : false);
        })
    } else {
      setTaskInfo(null)
      setVisible(true)
    }
  }
  if (target.localName === 'span' && extractTaskId(target.id)) {
    setSelectedLink(target);
    setUrl(target.href);
    setVisible(false);
    getTaskInfoForDescription(extractTaskId(target.id), cancelTokenRef.current)
      .then(taskDetail => {
        setTaskInfo(taskDetail);
        setVisible(taskDetail ? true : false);
      })
  }
  if(target.localName === 'iframe'){
    setEditFrameId(target.id); 
  }
};

/**
 * Object defining various marks for the editor.
 * Each mark specifies its attributes, parsing logic, and rendering logic.
 * @type {Object}
 * @author Shivam Mishra
 */
export const editorMarks = {
  link: {
    attrs: {
      ...commonAttributes(),
      href: { default: null },
      target: { default: '_blank' },
      title: { default: null },
    },
    inclusive: false,
    parseDOM: [{ tag: 'a', getAttrs: getAttributes }],
    toDOM(node) {
      let oldHref = node.attrs.href;
      let newHref =
        oldHref?.indexOf('https://') >= 0 || oldHref?.indexOf('http://') >= 0  || oldHref?.match(/mailto:/)
          ? oldHref
          : 'https://' + oldHref;
      return ['a', getAttrs({ ...node.attrs, href: newHref }, false), hole];
    },
  },

  ...tagMark('strong'),
  ...tagMark('b'),
  ...tagMark('em'),
  ...tagMark('i'),
  ...tagMark('u'),
  ...tagMark('del'),
  ...tagMark('sub'),
  ...tagMark('sup'),
  ...tagMark('code'),

  style: {
    attrs: {
      ...commonAttributes(),
    },
    parseDOM: [
      {
        tag: 'span',
        getAttrs: getAttributes,
      },
    ],
    toDOM: (node) =>
      hasAttrs(node.attrs, false)
        ? ['span', getAttrs(node.attrs, false), hole]
        : ['span', hole],
  },
};
