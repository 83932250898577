import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { useSelector } from 'react-redux';


import { Tooltip } from '@progress/kendo-react-tooltip';
import { icon, label, number } from '../../../../config';
import { filterData } from '../../../../utils/kendo';
import { getRelatedAssignmentList, getSelectedWorkflowDetails } from '../Details/taskDetail.helper';
import { useTaskOwnerContext } from './taskOwner.Context';

const TooltipContentTemplate = (props) => {
  return (
    <p>{props.title}</p>
  );
};

/**
 * OwnerAssignee dropdown component
 * Parent component => TaskAssignment
 * @returns JSX
 * @author Shivam
 */
const OwnerAssignee = () => {
  const assignmentState = useTaskOwnerContext()
  const { assignmentType , assignedId, relatedAssignmentId, setRelatedAssignmentId, relatedAssignmentList, setRelatedAssignmentList, isDisabled, workflowAssignmentType } = assignmentState;

  const { task, isNewTask } = useSelector(state => state.taskSidebar);
  const { defaultDetails } = useSelector(state => state.tasks);
  const taskWorkflowDetails = useSelector((state) => state.taskWorkflowDetails);
  const { workflowBasicDetails } = taskWorkflowDetails;




  /**
   * handles project assignees change, fetches workflow details if task is new and assigned to a workflow
   * @param {Object} e
   * @returns {Void}
   */
  const handleChange = async (e) => {
    setRelatedAssignmentId(e.target.value);
    (workflowAssignmentType?.value == number.FIVE && isNewTask) && getSelectedWorkflowDetails(null, null, e.target.value.value)
  }

  /**
   * Returns OR related assignee list based on related assignment type
   * @param {Object} e
   * @returns {Void}
   */
  const getAndFilterAssignmentList = async (event) => {
    const payload = { workflowAssignmentType, assignedId, assignmentType, defaultDetails }
    let relatedAssignedIdList = await getRelatedAssignmentList(payload);
    relatedAssignedIdList = event ? filterData(event.filter, relatedAssignedIdList) : relatedAssignedIdList
    setRelatedAssignmentList(relatedAssignedIdList);
  }

  /**
  * custom dropdown item render for workflow list
  * @returns JSX
  */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span key={itemProps?.index} className='privacy-dropdown-values d-flex align-content-center' title={itemProps?.dataItem?.label}>
        <span className='text-truncate'>{itemProps?.dataItem?.label}</span>
      </span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  /**
   * custom dropdown value render for workflow list
   * @returns JSX
   */
  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [
      <span key={value?.value} className='d-flex align-items-center justify-content-center' title={value?.label}>
        <span className='text-truncate'>{value?.label}</span>
      </span>
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <React.Fragment>
      {workflowAssignmentType?.value && <div className="form-group col-lg-4 col-md-4 col-sm-4">
        <div className="d-flex align-items-center assignee-field-wrapper w-100">
          <div className="assignee-field-inner d-flex">
            {<div className="w-100 assignee-field-box">
              <Tooltip
                content={(props) => <TooltipContentTemplate title={props.title} />}
                position='bottom'
                anchorElement="target"
                parentTitle={true}
                tooltipClassName="workflow-desc-tooltip"
              >
                <label className='d-flex align-items-center' htmlFor="">{workflowAssignmentType.value === number.TWO ? label.ASSIGNEE : label.NAME} {workflowAssignmentType.value === number.FIVE && <span aria-label='workflow-info' className='info-icon cursor-pointer' title={workflowBasicDetails?.Description}>{icon.INFO_ICON}</span>}
                </label>
              </Tooltip>
              {<div>
                <Tooltip position='bottom' anchorElement="target" parentTitle={true}>
                  <DropDownList
                    data={relatedAssignmentList}
                    value={relatedAssignmentId ? relatedAssignmentId : null}
                    onChange={handleChange}
                    textField="label"
                    key="value"
                    id= "ownership-dd2"
                    filterable={true}
                    onFilterChange={getAndFilterAssignmentList}
                    disabled={isDisabled}
                    itemRender={itemRender}
                    valueRender={valueRender}
                  />
                </Tooltip>
              </div>}
            </div>}
          </div>
        </div>
      </div>}
    </React.Fragment>
  )
}

export default OwnerAssignee ;