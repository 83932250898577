import React from "react";
import { useSelector } from "react-redux";
import { icon, label } from "../../../../config";

/**
 * Parent Component: WorkflowPanel
 * It consist of workflow default assignee
 */
const WorkflowDefaultAssignee = (props) => {
  const assigneeList = useSelector((state) => state.tasks.defaultDetails.assigneeList);
  const userInfo = assigneeList.find((user) => user.value === props.dataItem.DefaultAssignee);
  return (
    <React.Fragment>
      <span id='wf-default-assignee-profile' title={userInfo ? userInfo?.label : label.UNASSIGNED}>
        {userInfo && userInfo?.PhotoLink ? (
          <span className='project-quick-setting-img rounded-circle d-block'>
            <img className='object-fit-cover h-100 w-100 rounded-circle' src={userInfo ? userInfo?.PhotoLink : icon.NO_ASSIGNEE} alt='user-image' />
          </span>
        ) : (
          <span className='d-flex align-items-center project-setting-profile-img'>
            {userInfo ? <span>{icon.ACCOUNT_CIRCLE}</span> : <span className='profile-no-assignee'>{icon.NO_ASSIGNEE}</span>}
          </span>
        )}
      </span>
    </React.Fragment>
  );
};

export default WorkflowDefaultAssignee;
