import {
  SET_CURR_USER,
  UPDATEPASSWORD_SUCCESS,
  UPDATE_CURR_USER
} from "../actions/action-types";
import isEmpty from "lodash/isEmpty";

const initialState = {
  isAuthenticated: false,
  passwordUpdateSuccess: false,
  user: {},
  isGoogleUser: false,
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURR_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.user),
        user: action.user,
      };
    case UPDATEPASSWORD_SUCCESS:
      return {
        ...state,
        passwordUpdateSuccess: true,
      };
    case UPDATE_CURR_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      }
    default:
      return state;
  }
};

export default auth;
