//constant file
import "../components/Tasks/tasks.scss";
import { route } from "../config/routes";

//Number Constants
export const number = {
  MINUS_ONE_FIFTY: -150,
  MINUS_FIFTY: -50,
  MINUS_FIFTEEN: -15,
  MINUS_FIVE: -5,
  MINUS_TEN: -10,
  MINUS_TWO: -2,
  MINUS_ONE: -1,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  NINETEEN: 19,
  TWENTY: 20,
  TWENTY_TWO: 22,
  TWENTY_THREE: 23,
  TWENTY_FOUR: 24,
  TWENTY_FIVE: 25,
  TWENTY_NINE: 29,
  THIRTY: 30,
  THIRTY_ONE: 31,
  THIRTY_THREE: 33,
  THIRTY_FOUR: 34,
  THIRTY_FIVE: 35,
  THIRTY_SIX: 36,
  FORTY: 40,
  FORTY_FIVE: 45,
  FIFTY: 50,
  FIFTY_TWO: 52,
  SIXTY: 60,
  SIXTY_FIVE: 65,
  SEVENTY: 70,
  NINTY: 90,
  NINTY_NINE: 99,
  HUNDRED: 100,
  ONE_HUNDRED_THIRTY: 130,
  ONE_HUNDRED_FIFTY: 150,
  TWO_HUNDRED: 200,
  TWO_HUNDRED_THIRTY: 230,
  TWO_HUNDRED_THIRTY_FIVE: 235,
  TWO_HUNDRED_FIFTY: 250,
  TWO_HUNDRED_SEVENTY: 270,
  THREE_HUNDRED: 300,
  THREE_HUNDRED_SIXTY_FIVE: 365,
  FOUR_HUNDRED: 400,
  FIVE_HUNDRED: 500,
  SIX_HUNDRED: 600,
  SIX_HUNDRED_FIFTY: 650,
  FIVE_HUNDRED_EIGHTY: 580,
  SEVEN_HUNDRED: 700,
  ONE_THOUSAND: 1000,
  ONE_THOUSAND_TWENTY_FOUR: 1024,
  ONE_THOUSAND_FIVE_HUNDRED: 1500,
  FIVE_THOUSAND_ONE: 5001,
  SIX_THOUSAND: 6000,
  SEVEN_THOUSAND: 7000,
  TEN_THOUSAND: 10000,
  ONELAKH_EIGHTY_THOUSAND: 180000,
};

export const limit = {
  WORKFLOW_NAME_MAX_LENGTH: 35,
  WORKFLOW_DESCRIPTION_MAX_LENGTH: 120,
  DASHBOARD_LIMIT: 1073741824,
  TASK_LIMIT: 524288000,
  MAX_FILENAME_LENGTH: 50,
};

// Special Characters

export const specialCharacter = {
  STAR: "*",
  DASH: "-",
  SPACE: " ",
};

//API Request Methods
export const requestMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

// API Request Status
export const requestStatus = {
  OK: 200,
  FORBIDDEN: 403,
};

// Type of file sizes
export const fileSizeType = {
  BYTES: "Bytes",
  KILOBYTE: "KB",
  MEGABYTE: "MB",
  GEGABYTE: "GB",
  TERABYTE: "TB",
};

// Labels
export const label = {
  RECURRENCE_PATTERN_AND_MODE: "Recurrence Pattern and Mode",
  INCLUDE_CLEARED_TASK: "Include Cleared Notifications",
  EXPECTED_EFFORT: "Expected Effort",
  MAX_LIMIT_EFFORT: "Max Limit is 99",
  MIN_LIMIT_EFFORT: "Minimum Limit is 1",
  SHOW_ALL_NOTIFICATIONS: "Show all",
  RECURRENCE_PATTERN: "Recurrence Pattern",
  TaG_SETTINGS: "Tag Settings",
  TAG_NAME: "Tag Name",
  BACKGROUND_COLOUR: "Background Color",
  TEXT_COLOR: "Text Color",
  TAG_PREVIEW: "Tag Preview",
  TASK_RECURRENCE: "Task Recurrence",
  SKIP: "Skip",
  TASK_ASSIGNEE: "Task Assignee",
  SKIP_PAST_DATES: "Skip Past dates",
  ADD_TAG: "Add Tag",
  DISABLE_RECURRING: "Disable the recurring for the future Tasks",
  FREQUENCY: "Frequency",
  REPEAT_TIMES: "Repeat Times",
  REMINDERS: "Reminders",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
  GOOGLE_SSO_SIGN_IN: "Signed in with Google SSO",
  PINNED: "Pinned",
  REMOVE_PIN: "Remove Pin",
  PIN_TASK: "Pin Task",
  TASK_ID: "Task Id",
  TASK_NAME: "Task Name",
  TASK_ICONS: "Task Icons",
  CONVERT_TASK: "Convert to Task",
  DUE_DATE: "Due Date",
  CHOOSE_PRIORITY: "Priority",
  PRIORITY: "Priority",
  PRIORITIES: "Priorities",
  CURRENT_USER: "Assignee",
  STAGE: "Stage",
  TAG: "Tag",
  TAGS: "Tags",
  EMAIL: "Email",
  PASSWORD: "Password",
  CURRENT_PASSWORD: "Current Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  FULLNAME: "Full Name",
  COMPANY: "Company Name",
  NEW_WIDGET: "Create New Widget",
  BREADCRUMB_TASKS: "Tasks",
  ARCHIVED_TASKS: "Archived Tasks",
  ARCHIVE_TASK: "Archive Task",
  ARCHIVED: "Archived",
  MY_PROJECTS: "My Projects",
  MANAGER_NAME: "Manager",
  JOB_TITLE: "Job Title",
  INBOX: "Inbox",
  WELCOME: "Welcome,",
  HOME: "Home",
  PROJECTS: "Projects",
  QUEUES: "Workspaces",
  ACTIVITY_DIGEST: "Activity Digest",
  CALENDAR: "Calendar",
  HELP_SUPPORT: "Help",
  PROJECT_CODE: "Project Code",
  TEAM_FILTER_PLACEHOLDER: "All Teams",
  STAGE_FILTER_PLACEHOLDER: "All Stages",
  EXTERNAL_TASKS_PROJECT: "External Tasks",
  TEAMS: "Teams",
  DEFAULT_VIEW: "Default View",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  OWNERS: "Project Owners",
  OWNER: "owner",
  FOLLOWERS: "Followers",
  INVALID_EMAILS: "Invalid Email(s)",
  ERR_MESSAGE: "Some error occured, please try again",
  POSITION: "top-end",
  ERROR_ICON: "error",
  SUCCESS_ICON: "success",
  WARNING_ICON: "warning",
  SHOW_CONFIRM: false,
  SIGNUP_ALERT_FOOTER: '<a href = "http://localhost:3000">Login Instead?</a>',
  PASSWORD_MISMATCH: "The passwords didn’t match. Try again.",
  INVALID_USERNAME: "Username is not valid",
  INVALID_TASK_CODE: "Task code is not valid",
  INVALID_MANAGER_NAME: "Manager name is not valid",
  INVALID_JOB_TITLE: "Invalid job title",
  PASSWORD_LENGTH_MISMATCH: "Password must be between 8 to 16 characters",
  INVALID_EMAIL: "Please enter a valid email address",
  ALERT: "Alert!",
  UNLOCK_TASK: "Unlock Task",
  TIME_ERROR: "Start Time should be less than End Time",
  COMPANY_ERROR: "Please enter a valid Company Name",
  OWNER_NAME: "Owner Name",
  STATUS: "Status",
  COMPLETION: "Completion",
  MILESTONES: "Milestones",
  COLLABORATORS: "Collaborators",
  USER: "User",
  PREFERENCES: "Preferences",
  REMOVE_PHOTO: "Remove photo",
  REMOVE_LOGO: "Remove Logo",
  UPLOAD_LOGO: "Upload Logo",
  UPLOAD_AN_IMAGE: "Upload an Image",
  CHANGE_PASSWORD: "Change Password",
  GOOGLE_USER: "isGoogleUser",
  USERS: "Users",
  EXPORT_CSV: "Export CSV",
  USER_TYPE: "Roles",
  USER_STATUS: "User Status",
  ALL: "All",
  ADMIN: "Admin",
  BILLING_OWNER: "Billing Owner",
  EMPLOYEE: "Employee",
  ACCOUNT_STATUS: "Account Status",
  SHOW_MORE: "Show more",
  ROLE: "Roles",
  LAST_ACTIVITY: "Last Activity",
  ACTIONS: "Actions",
  PROFILE_SETTINGS: "Edit Profile Settings",
  ACTIVATE: "Activate",
  DEACTIVATE: "Deactivate",
  REACTIVATE: "Reactivate",
  REVOKE: "Revoke",
  APPROVE: "Approve",
  APPROVALS: "Approvals",
  APPROVAL: "Approval",
  PENDING_APPROVALS: "Pending Approvals",
  ALL_APPROVALS: "Approvals",
  REJECT: "Reject",
  RETRACT: "Retract",
  INVITE_USERS: "Invite Users",
  NAME_EMAIL_SEARCH: "Search by name or email",
  ORGANIZATION_NAME: "Organization Name",
  DISPLAYED_INTERNALLY: "(Displayed Internally)",
  REGISTERED_NAME: "Registered Name",
  TOTAL_SHEATS: "Total Licenses",
  TIME_ZONE: "Time Zone",
  THE: "the",
  USER_SETTINGS: "User Profile Setting",
  USERS_ADDED: "Users Added",
  DEFAULT_PASSWORD: "default",
  DELETE_ORGRANIZATION: "Delete Organization",
  DELETE_STAGE_HEADING: "Delete Stage",
  USER_PROFILE_SETTINGS: "User Profile Settings",
  DELETE_ORGRANIZATION_DESC: "Deleting an Organization is an Irreversible Action. Do you want to Proceed?",
  QUEUE_NAME: "Workspace Name",
  QUEUE_DESCRIPTION: "Description",
  QUEUE_EMAIL: "Incoming Email",
  QUEUE_PROJECTS: "Default Project",
  QUEUE_OWNERS: "Owners",
  INTERNAL_ERROR: "Internal Server Error",
  ADD_PROJECT_USERS: "Type name, email to add",
  REMOVE_USER_HEADER: "Remove User",
  DELETE_TASK: "Delete Task",
  REMOVE_PROJECT_USER: "Are you sure you want to remove user from this ",
  NUMBER_OF_USERS: "5+ in",
  IN_PROJECT: "in project",
  SELECT_QUEUE: "Select a Workspace",
  CREATED_BY: "By :",
  DEFAULT_PROJECT: "Default Team",
  SEARCH_PROJECTS: "Search Projects",
  ALL_ROLES: "All Roles",
  INVALID_FILE_FORMAT: "Invalid file format. Try again.",
  ACTIVITY_LOG: "Activity Log",
  TIME: "Time",
  ACTION: "Action",
  LOG_VIEW: "Log View",
  USER_NAME: "Username",
  DETAILS: "Details",
  DETAILS_SEARCH: "Search Details",
  SHOW: "Show",
  PROJECT_NAME: "Project Name",
  ACTIVE: "Active",
  INACTIVE: "In Active",
  QUEUE: "Workspace",
  DEFAULT: "Filter Columns",
  SEARCH_QUEUES: "Search Workspace",
  ADD_TASK: "Add Task",
  ADD_TEAM: "Add Team",
  ADD_SPAM: "Add Spam",
  ADD_SPAM_EMAIL: "Add Spam Email",
  FROM: "From",
  TO: "To",
  SENDER: "Sender",
  WORKFLOW_EMAIL: "Workflow Email",
  RECEIVER: "Receiver",
  TASKID: "TaskId",
  EMAIL_SUBJECT: "Email Subject",
  SUBJECT: "Subject",
  EMAIL_DETAILS: "Email Details",
  NOT_SPAM: "Not Spam",
  SPAM_LIST: "Spam List",
  ENTER: "Enter",
  EMAIL_ID: "Email Id",
  DATE_ADDED: "Date Added",
  WORKFLOW_SETTINGS: "Workflow Settings",
  ADD_SUBTASK: "Add Subtask",
  LINK_TASK: "Link Task",
  PROJECT: "Project",
  CURRENT_PROJECT: "Assignee",
  CURRENT_QUEUE: "Assignee",
  MARK_COMPLETE: "Mark As Complete",
  COMPLETED: "Completed",
  INCOMPLETE: "Incomplete",
  COMPLETE_FORWARD: "Complete & Forward",
  COMPLETED_RETURNED: "Completed & Returned",
  ATTACHMENTS: "Attachments",
  ATTACHMENT: "Attachment",
  SUB_TASKS: "Sub Tasks",
  LINKED_TAKS: "Linked Tasks",
  COMMENTS: "Comments",
  DEFAULT_USER: "Default User",
  CURRENT_STAGE: "Current Stage",
  CREATOR: "Owner",
  ASSIGN_TO: "Assign To",
  ASSIGNED: "Assigned",
  ASSIGNED_BY: "Assigned By",
  ASSIGNEE: "Assignee",
  ASSIGNEES: "Assignees",
  ASSIGNEE_FILTER_PLACEHOLDER: "All Assignee",
  FOLLOW: "Follow",
  UNFOLLOW: "Unfollow",
  TASK_DESCRIPTION: "Description",
  ASSIGN_TO_ME: "Assign To Me",
  DEFAULT_ASSIGNEE: "Default Assignee",
  DEFAULT_WORKFLOW: "Default Workflow",
  REMOVE_ASSIGNEE: "Remove Assignee",
  COMING_SOON: "COMING SOON",
  MAKE_PUBLIC: "Make Public",
  EDITED: "Edited",
  NEW_REPLY: "new",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  OLDER: "Older",
  PROFILE_PICTURE: "Profile Picture",
  NOTES: "Notes",
  DELETE: "Delete ",
  NO: "No",
  YES: "Yes",
  STAGES: "Stages",
  CONFIRM: "Please confirm",
  LOADING: "Loading...",
  REMOVE: "Remove",
  ALTERNATE_TEXT: "Alternate text",
  TITLE: "Title",
  WIDTH: "Width (px)",
  HEIGHT: "Height (px)",
  IMAGE: "Image",
  WEB_ADDRESS: "Web address",
  CANCEL: "Cancel",
  INSERT: "Insert",
  STRING: "string",
  USER_IN_PROJECT: "User is Already in Team",
  REQUESTED_TO_ADMIN: "Request sent to admin",
  ADDED_BY: "Added By",
  USER_HAS_INVITE: "User has pending invite",
  EXIST_IN_DRUTAS: "Email Already Exist in Drutas",
  DATE: "Date",
  ADD_REM: "Add Reminder",
  CLOSE_POPUP: "Close",
  POP_OUT: "Pop out",
  DEL_REM: "Delete Reminder",
  DEL_REC: "Disable the recurring for the future tasks",
  EDIT_REC: "Edit the current recurring",
  EDIT_REM: "Edit Reminder",
  CONFIRM_TEXT: "Confirm",
  UNDO: "Undo",
  ERROR_OCCURED: "An error occurred, please try again!",
  TASK_PRIORITY: "Task Priority",
  TASK_DUE_DATE: "Set task due date",
  TASK_ARCHIVE_DATE: "Archive done tasks",
  DEFAULT_TAGS: "Default tags",
  DESCRIPTION: "Description",
  DESCRIPTION_TASK: "Task Description",
  INFORMATION: "Quick Info",
  ICON: "Icon",
  PRIVACY: "Privacy",
  EMAIL_FOR_TASK_CREATION: "Email for task Creation",
  STREAM: "Stream",
  ADD_STREAM: "Add Stream",
  EDIT_STREAM: "Edit Stream",
  PARENT_TASKS: "Parent tasks",
  CHILD_TASKS: "Child tasks",
  RELATED_TASKS: "Related tasks",
  SUBTASKS: "Subtasks",
  DAYS: "Days",
  DAY: "Day",
  BOOKMARK_TASKS: "Bookmark Tasks",
  BOOKMARK_PROJECT: "Bookmark Projects",
  BOOKMARK_ICON: "bookmark",
  DELINK_TASK: "Delink Task",
  TASK_CREATED: "task was created",
  APPROVAL_FROM: "Approval From",
  DESCRIPTION_KEY: "description",
  NO_DUE_DATE: "No due date",
  REMOVE_DUE_DATE: "Remove due date",
  SHOW_TASKS_WIHTOUT_DUEDATE: "All Tasks without Due Date",
  VIEW_SETTINGS: "View Settings",
  RENAME_ATTACHMENT: "Rename Attachment...",
  ADD_DUE_DATE: "Add Duedate",
  ADD_NOTE: "Add note...",
  DELETE_POPUP_REF_STREAM: "delete Popup Ref",
  STREAM_POPUP_REF: "stream Popup Ref",
  LOCKED_DESC: "Locked Description",
  VALID_EMAIL: " Email(s) sent successfully",
  DEPENDENCY: "Dependency",
  REMAINING: "remaining",
  ORGANIZATION: "organization",
  SHARE: "Share",
  UPDATE: "Update",
  DEFAULT_STREAM_ALIAS: "Team Drutas",
  MARK_AS_READ: "Mark As Read",
  UNREAD: "Unread",
  CHILD_TASK: "Childtask",
  ADD_DELAY: "Add Delay",
  ACTIVE_INACTIVE: "Active/Inactive",
  DAYS_AFTER_COMPLETION: "Days after completion",
  TASK_SETUP: "Task Setup",
  TEAM: "Team",
  WORKFLOW: "Workflow",
  SET_DUE_DATE: "Set Due Date",
  AUTO_SELECT_STAGE: "Auto Select (First Stage)",
  INCOMING_STAGE: "Incoming Stage",
  DAYS_BEFORE_DUE_DATE: "Days before due date",
  WORKFLOW_DESIGNER: "Workflow Designer",
  BASIC_ELEMENTS: "Basic Elements",
  SIMPLIFIED_DETAILS: "Simplified Task Detail",
  FLOW_ELEMENTS: "Flow Elements",
  EXIT_FLOW: "Exit Flow from Orignating Team",
  RETURN_ELEMENTS: "Return Elements",
  DELEGATED_TASK: "Task will be Delegated and return upon completion",
  PASSED_ON_TASK: "Task will be Passed On to the next Team /assignee",
  TASK_RETURN: "The task will return to the",
  ON_FINAL_COMPLETION: "on final completion",
  NOTE: "Note",
  SELECT_WORKFLOW: "Select Workflow",
  PANEL_VISIBILTY: "Panel Visibility",
  TASK_VIEW: "Task View",
  VIEW_SELECTION: "View Selection",
  DEFAULT_STREAM_ALIAS: "Workspace Drutas",
  SUBTASK: "Subtask",
  INACTIVE_WORKFLOW: "Inactive",
  SHOW_ALL_NOTIFICATIONS: "Show all",
  SETTINGS: " Settings",
  SHARE_LINK: "Share Link",
  CLOSE: "Close",
  TASK_TRANSFER_MODE: "Task transfer mode",
  DEFAULT_TEMPLATE: "Default Template",
  MAKE_DEFAULT: "Make Default",
  PROJECT_PRIVACY_CLOSED: "The privacy setting of the project will be changed to closed",
  PROJECT_PRIVACY_SHARED: "The privacy setting of the project will be changed to Shared",
  UNASSIGNED: "Unassigned",
  REMINDER_EXISTS: "Reminder already exists",
  WORKFLOW_DESCRIPTION: "Workflow Description",
  FILTER_NAME_REQUIRED_ERROR: "Filter name is Required",
  FILTER_NAME_DUPLICATE_ERROR: "Filter name is duplicate",
  DELEGATED: "Delegated",
  OPERATIONAL_TEAM: "Operational Team Name",
  NO_FILTER: "No Filter",
  SELECT_ALL: "Select All",
  OPEN_LINK_IN_NEWTAB: " Open link in new tab",
  ONLY: "Only",
  ITEM: "Item",
  ITEMS: "Items",
  QUEUE_ENTITY: "Queue",
  SORT_ID: "SortId",
  FILTER_NAME_PlACEHOLDER: "Filter Name",
  SELECTED: "Selected",
  INDUSTRY: "Industry",
  CONTACT_NAME: "Contact Name",
  CONATACT_NUMBER: "Contact Number",
  REG_NAME_ERROR: "Registered Name Taken",
  REG_NAME_LENGTH_ERROR: "Registered Name must be at least 3 characters long",
  INDUSTRY_NAME_LENGTH_ERROR: "Industry Name must be at least 3 characters long",
  REG_NAME_SPECIAL_CHARACTER_ERROR: "This alphanumeric character is not allowed.",
  OPERATIONAL_TEAM_ERROR: "Should be between 3 and 14 characters only",
  CONTACT_NUMBER_ERROR: "Enter a valid contact number",
  COUNTRY_CODE_ERROR: "Enter a valid country code",
  COMPANY_MAIL_VERIFIED: "Email Verified Sucessfully",
  SHOW_ALL_NOTIFICATIONS: "Show All Notifications",
  ADD: "Add",
  MY: "My ",
  CODE: " Code",
  NAME: " Name",
  DISPLAY_NAME: "Display Name",
  UPLOAD_IMAGE: " Upload an image",
  YOUR_JOB_TITLE: "Your job title",
  YOUR_TASK_CODE: "Your task code (prefix text)",
  YOUR_MANAGER_NAME: "Your manager's name",
  SEARCH: "Search ",
  SMALL_S: "s",
  IN: " in",
  RETURN_MODE: "Return Mode",
  MODE: "mode",
  DEFINE: "Define ",
  EDIT: "Edit",
  CONFIGURE: "Configure",
  SHOW_DESCRIPTION: "Show",
  HIDE: "Hide",
  COMING_SOON: "Coming Soon",
  DEFAULT: "Default ",
  TASK_DURATION: "Task Duration",
  ALREADY_EXISTS: " already exists",
  REOPEN_TASK: "Reopen Task",
  APPROVAL_CHECK: "Only one approval is allowed per task",
  TASK_ROUTE_AMENDED: "Task Route Amended",
  TASK_AMENDED: "Task Amended",
  SELECT_USER: "Select User",
  SELECT_WORKSPACE: "Select Workspace",
  DASHBOARD: "Dashboard",
  RECENTLY_VIEWED_TASKS: "Recently Viewed tasks",
  RECENTLY_VIEWED: "Recently Viewed",
  NOTIFY_ALL: "Notify all the followers in this task",
  ALL: "all",
  SHOW_MORE: "Show more",
  COMPLETED_ARCHIVED: "Task completed & Archived",
  COMPANY: "Company",
  COLLAB_CENTRAL: "Collab Central",
  COMPLETED_DATE: "Completed Date",
  TASK_COMPLETED: "Task Completed",
  MORE: "...more",
  WORKFLOWS: "Workflows",
  QUICK_LINKS: "Quick Links",
  FOLLOWER_MAIL_INTERVAL: "Follower Mail Interval",
  DELETE_TAG: "Delete tag",
  OWNERSHIP: "Ownership",
  INCLUDE_TASKS: "Include Task(s)",
  TASK_DUE_OPTIONS: "Task Due Options",
  CLEAR: "Clear",
  WORKSPACE_FILTER: "Workspace Filter",
  TEAM_FILTER: "Team Filter",
  MY_FILTER: "My Filter",
  CLEAR_FILTER: "Clear Filter",
  VISIBLE_ONLY_TO_ME: "Visible only for me",
  INITIATED: "Initiated",
  INCLUDE_OVERDUE: "Include Overdue",
  NEW_NOTIFICATION: "You've got a new notification!",
  DENIED_REQUEST: "You denied for the notification",
  ACTIVE_PLAN: "Active Plan",
  LICENSE_COUNT: "500 Licenses, Unlimited time",
  LOGIN_TYPE: "Login Type(s)",
  USAGE_SUMMARY: "Usage Summary",
  DOWNLOAD_FAILED: "Download failed, Please try again",
  MOVE: "Move",
  BOARD: "Board",
  TASK_LOCKED: "Task Locked",
  TASK_LOCKED_BY: "Task has been locked by",
  LOCK_TASK: "Lock Task",
  YOU: "you",
  SHOW_ON_BOARD: "Show on board",
  MINIMIZED_VIEW: "Minimized View",
  ACTIVATION: "Activation",
  DEACTIVATION: "Deactivation",
  LOGIN_TYPES: "Login Type(s)",
  TASK_CODE: "Task Code",
  PREFIX: "(prefix)",
  TASK_CODE_PLACEHOLDER: "Task code",
  RESEND_INVITE: "Resend Invite",
  RESET_PASSWORD: "Reset Password",
  USER_PROFILE: "User Profile",
  USER_ENGAGEMENT: "User Engagement",
  MY_BOARD: "My Board",
  USAGE_COUNT: "Usage Count",
  USER_BOARD: "'s Board",
  COMMENT: "Comment",
  PAST_DUE_DATES: "Past due dates",
  TOMORROW: "Tomorrow",
  DAYS_FROM_TODAY: "Day(s) from today",
  ON_DUE_DATE: "On due date",
  DAYS_BEFORE_DUE_DATE: "Day(s) before due date",
  SET_REMINDER: "Set Reminder",
  CUSTOM: "Custom",
  SET: "Set",
  PEOPLE: "People",
  SMART_CHIPS: "Smart Chips",
  REMINDER_TIME: "Reminder Time",
  MODE: "Mode",
  PLAN_MY_DAY: "Plan My Day",
  USE_LIVE_LOCATION: "Use Live Location",
  VIEW_PAST_REMINDERS: "View Past Reminders",
  DYNAMIC_REMINDER: "Dynamic Reminder",
  VIEW_ALL_NOTIFICATIONS: "View all notifications",
  VIEW_ALL_RECENTLY_VIEWED: "View all recently viewed",
  SHOW_ONLY: "Show Only",
  REMOVE_TEAM: "Remove Team",
  UPLOAD_ORG_LOGO: "Upload your organization logo",
  CREATE_TASK_BY_EMAIL: "Create Task by Email",
  ACKNOWLEDGEMENT: "Acknowledgement",
  CHECK_FOR_SPAM: "Check for spam",
  EMAIL_COPIED: "Email Copied",
  CREATE_PASSWORD: "Create Password",
  SEARCH_USER: "Search User...",
  CREATED_USING_EMAIL: "Created using email",
  NOTIFICATION_CONTROL: "Notification Control",
  ESC_PRESS: "Press esc to close",
  SEARCH_TASK: "SEARCH TASK",
  POPULAR: "popular",
  RECENTS: "recents",
  VIEW_POPULAR: "View Popular",
  VIEW_RECENT: "View Recent",
  CLASSIC_VIEW: "Classic View",
  MERGE_INTO: "Merge Into",
  POPULAR: "Popular",
  RECENT: "Recent",
  CLASSIC: "Classic",
  CLASSIC_VIEW: "Classic View",
  CLOUD_STORAGE: "Cloud Storage",
  REMEMBER_ME: "Remember Me",
  UNREAD: "Unread",
  TAGGED: "Tagged",
  USER_PREFERENCE: "userPreference",
  DOCUMENTS: "Documents",
  VIDEO_PHOTOS: "Videos And Photos",
  ATTACHMENT_RENAMED: "Attachment renamed",
  ATTACHMENT_DELETED: "Attachment deleted",
  DELETE_ATTACHMENT: "Delete Attachment",
  TAKE_TOUR: "Take Quick Tour",
  STEP: "Step",
  AUTOMATION_TASK: "Automation Task",
  BOARD: "Board",
  SIMPLIFIED: "Simplified",
  DESC_POPOUT: "Description Pop-out",
  ACKNOWLEDGEMENT_EMAIL: "Acknowledgement Email",
  SCROLL_UP: "Scroll Up [Pg Up]",
  SCROLL_DOWN: "Scroll Down [Pg Dn]",
  TEAM_BREAKDOWN: "Team Breakdown",
  GRAPH: "Graph",
  SELECT_DATE: "Select Date",
  SELECT_TIME: "Select Time",
  MY_TASK_CODE: "My Task Code",
  MANAGER: "Manager's Name",
  BOARD_NAME: "Personal Board Name",
  DEFAULT_REMINDER_TIME: "Default Reminder Time",
  DEFAULT_LOCATION: "Indian/Antananarivo",
  CUSTOMIZE_VIEW: "Customize View",
  PIN_POSITION: "Pin Position",
  DATE_RANGE: "Date Range",
  ANYTIME: "Anytime",
  DUE_IN: "Due in",
  TASKS_DUE_IN: "Tasks Due",
  LAST: "Last",
  WIHOUT_DUE_DATE: "Without due date",
 FOR:"for",
 FILE_NAME: "File Name" ,
  VALID_FILE : "Please select a valid PDF file." ,
  VALID_SIZE_SIZE : "File size must be less than" ,
  VALID_HEIGHT : "Height must be between 1 and 100%." ,
  VALID_WIDTH : "Width must be between 1 and 100%." ,
  WIDTH_ : "Width" ,
  HEIGHT_ : "Height",
  URL : "URL" ,
  SELECT_FILE : "Please select a file..." ,
  VISIBILITY_OFF: "Visible only to me",
  VISIBILITY_ON: "Visible to everyone",
  ONE_REQUIRED: "Please select at least one",
  DEFINE_TEAM_STAGES: "Define Team Stages",
  TEAM_MEMBERS: "Team Members",
  SELECT_ALL_USERS: "Select All Users",
  OTHERS: "Others",
  EXTERNAL_TO_BOARD: "External to Board",
  FILTER_PIN_POSITION: "Filter pinned to position",
  MAIN_DESCRIPTION: "Main Description",
  APPROVED_DESCRIPTION: "Approved Description",
  EXTERNAL: "External",
  UNBOOKMARK: "UnBookmark",
  EXPORT_GRID: "Export Grid",
  EXPORT_AS: "Export As",
  EXPORT: "Export"
};

// Quotes and Messages
export const quote = {
  IMG_HEADER: "“Collaboration is the best way to work. It’s only way to work, really. Everyone’s there because they have a set of skills to offer across the board.”",
  FORM_HEADER: "Work Smart, Deliver Fast!",
  FORM_ADMIN_USER: "Set up your organization profile",
  FORM_USER_PROFILE: "Set up your user profile",
  FORM_USER_WELCOME: "Enter your information to create your profile",
  FORM_QUOTE: " We load you with power to come out of the odds and go agile with ease.",
  NEW_ACC: "New on our platform?",
  OPTION: " or",
  CREATE_ACC: "Create an account",
  FORGOTPASS_LINK: "Forgot Password?",
  LOGIN_FORM_FOOTER1: "“The future of mobile is the future of everything”",
  LOGIN_FORM_FOOTER2: "Now download the latest Drutas Management tool on your smart phones",
  COPYRIGHT: `Copyright © ${new Date().getFullYear()} All Rights Reserved with`,
  COMPANY: "Paxcom",
  COUNTRY: "India",
  SIGNUP_HEADER: "Sign up",
  TAG_ALREADY_EXITS: "Tag Already Exists",
  NO_ACCESS: "You do not have access to the task",
  TAG_DELETED: "Tag deleted successfully",
  SIGNUP_WELCOME: "Signup here to create your own dashboard.",
  EXISTING_ACC: "Already have an account? ",
  SIGNIN_INSTEAD: "Sign in instead",
  FORGOTPASSWORD_HEADER: "Forgot your password?",
  LOGIN_HEADER: "Log in to account",
  LOGIN_WELCOME: "Enter your email & password to login",
  FORGOTPASSWORD_WELCOME: "Enter your email address below and we'll send you a password reset link.",
  FORGOTPASSWORD_FOOTER: " Make sure to check your spam filter for an email from info@paxcom.net",
  BACKTOLOGIN: "Back to login",
  PASSWORD_CHARACTER_SET: "Password must be between 8 to 16 characters",
  HEADER: "New Project",
  SUBHEADER: "This contains basic information about your project",
  ADD_QUEUE_HEADER: "Add New Workspace",
  EXISTING_QUEUE: "Workspace Details",
  MOVE_EXISTING_PROJECT: "Projects",
  ADD_PROJECT: "Add Project",
  EDIT_PROJECT: "Edit Project",
  TEAM: "Members",
  MOVE_TO: "Move To",
  REQUIRED_FIELD: "This is a Required Field",
  EMAIL_NOT_MATCHED: "This Email Is Not Provisioned For Access!",
  GOOGLE_SSO_EMAIL_NOT_MATCHED: "This email is not authorized for access",
  REQUIRED_OWNERS: "Please Select Atleast One Owner",
  RETURNABLE_TASK: "Delegate",
  NON_RETURNABLE_TASK: "Pass On",
  ENTER_TASK_NAME_PROJECT: "Please enter a task name and project!",
  EXTERNAL_TASK: "The task will move to the personal Team of the user",
  PROJECT_MOVE_FROM_PRIMARY: "Move Team To Secondary List",
  PROJECT_MOVE_FROM_SECONDARY: "Move Team To Primary List",
  QUEUE_MOVE_FROM_PRIMARY: "Move Workspace To Secondary List",
  QUEUE_MOVE_FROM_SECONDARY: "Move Workspace To Primary List",
  UNDER_CONSTRUTION: "Our home page is under construction",
  DELEGATE_TASK: "Return to original project, On completion",
  PASS_ON_TASK: "Move to assigned project (Non returnable task)",
  ON_TOOLTIP_DELEGATE_TASK: "Task delegated to",
  ON_TOOLTIP_PASS_ON_TASK: "Task has been passed on from",
  TOOLTIP_PASS_ON_TASK: "Task is passed on from",
  TOOLTIP_DELEGATE_TASK: "and delegated to",
  ON_TOOLTIP_RETURNABLE_TASK: "Task will return to ",
  ON_COMPLETION: ", On completion",
  VERSION: "V ",
  REPLYING_TO: "replying to",
  DELETED_COMMENT: "This Comment Was Deleted",
  PROJECT_ACCESS_DENIED: "You donot have access to this project!",
  WORKFLOW_ACCESS_DENIED: "You donot have access to this workflow!",
  TASK_ACCESS_DENIED: "You donot have access to this task!",
  WORKSPACE_ACCESS_DENIED: "You donot have access to this workspace!",
  MANAGE_WORKFLOW: "Manage Project Workflow",
  EMPTY_NOTES: "Click + icon to create new Note",
  NEW_NOTE: "Add New Note!",
  LAST_SAVE: "Last saved at",
  STAGE_NAME_ERROR: "Stage name is required",
  NAME_EXIST: "Stage name already exists",
  MIN_STAGE_ERROR: "A minimum of two stages required!",
  CANNOT_DELETE_STAGE: "This stage cannot be deleted!",
  DELETE_NOTE: "Are you sure you want to delete this note?",
  DELETE_STAGE: "The stage you want to delete has open tasks. Are you sure you want to delete this stage? \n If yes, please select the stage where you want to move the open tasks*",
  SELECT_STAGE: "Please select a stage",
  ADD_BOOKMARK: "Add Bookmark",
  BOOKMARKED: "Bookmarked",
  BOOKMARK: "Bookmark",
  PROJECT_BOOKMARK_ALERT: "Team bookmark limit reached",
  TASK_BOOKMARK_ALERT: "Task bookmark limit reached",
  QUEUE_BOOKMARK_ALERT: "Workspace Bookmark Limit Reached",
  COPIED_TO_CLIPBOARD: "Task link copied to Clipboard!",
  WORKFLOW_COPIED_TO_CLIPBOARD: "Workflow link copied to Clipboard!",
  SMART_LINK_COPIED_TO_CLIPBOARD: "Smart link copied to Clipboard",
  HYPER_LINK_COPIED_TO_CLIPBOARD: "Hyper link copied to Clipboard",
  NUDGE_SENT: "Nudge sent!",
  NUDGE_ACKNOWLEDGED: "Nudge acknowledged!",
  PROFILE_PREFERENCES: "Profile Preferences",
  MY_PROJECT: "My Project",
  RULES_WORKFLOWS: "Rules & Workflow",
  NO_NOTES_FOUND: "No Notes Found",
  ADD_STAGE_BEFORE_SAVING: "Please Add The Stage  Before Saving",
  BOOKMARK_MODAL_MESSAGE: "Are you sure you want to delete bookmark?",
  NEW_COMMENTS: "New Comments",
  VIEW_COMMENTS: "View Comments",
  ADD_COMMENT: "Add Comment",
  NOTIFICATIONS: "Notifications",
  PROJECT_NAME: "Project Name",
  NO_NEW_NOTIFICATIONS: "No new notifications",
  COMMENT_TEXT_ERROR: "Something wrong with comment text! please check",
  EXISTING_REMINDERS: "Existing Reminders",
  NEW_REMINDER: "New Reminder",
  DELETE_TASK_TEXT: "Are you sure you want to delete this task?",
  ADD_NEW_NOTE: "Add new note",
  CREATE_REMINDER: "New Reminder Title",
  REMINDER_TIME_HINT: "Hint: Select your Reminder time ",
  AT_LEAST_TWO_CHAR: "Type at least two characters",
  NO_RESULT_FOUND: "No results found",
  CANNOT_DELEGATE_TASK: "Task cannot be further delegated if it is marked complete or is in the final stage!",
  PROJECT_NAME_LENGTH_ERROR: "Team name must contain atleast 4 characters",
  SELECT_LINK_TYPE: "Please select link type!",
  SHOW_ALL_TASKS: "All Tasks with Due Date",
  ENTER_VALID_DUEDAYS: "Please Enter Valid DueDays",
  COMMENTS_ADD_FOLLOWERS: "Do you want to add the under mentioned user(s) as followers to this task?",
  SHIFT_ENTER_MESSAGE: "Shift + Enter to add a new line",
  PROJECT_FILE_SIZE_EXCEEDED: "File size must under 1 GB",
  TASK_FILE_SIZE_EXCEEDED: "File size must under 500 MB",
  PROJECT_SETTING: "Project Settings",
  HELP: "Help",
  AUTO_SAVE_LOADING: "Saving...",
  AUTO_SAVED: "Saved",
  PROCESS_APPROVAL: "Request Approval",
  APPROVAL: "Approval",
  REQUEST_FOR_APPROVAL: "Request for approval being processed, task is now locked",
  ALREADY_ON_HOLD: "Task approval is already on hold",
  SELECT_ACTION: "Please select action",
  SELECT_NEXT_ASSIGNEE: "Please select next approver",
  NOTE: "Note:",
  STREAMS_FOR_USERS: "This streams will be shared with active users in the system.",
  STREAMS_FOR_ORGANISATION: "This stream will be shared with all active organisations",
  STREAMS_FOR_DASHBOARD: "This stream will be shared with all active users in this project",
  MAC_RECURRENCE: "Task has been recreated as per the recurrence pattern",
  STREAMS_FOR_TEAM_USERS: "The stream will be sent to all users included in the project of this Workspace",
  STREAMS_FOR_TEAM_PROJECTS: "The stream will be shared with to all users who are part of the selected Teams",
  ONE_FILTER: "Please Select Atleast One Filter",
  SELECT_DATES: "Please select a start and end date to apply the recurrence",
  SELECT_VALID_END_DATE: "End by date should be after the first recurrence",
  SELECT_VALID_COUNT: "Please select a valid count to apply the recurrence",
  ENTER_ALL_FIELDS: "Please enter all the required fields",
  MAKE_WORKFLOW_SHARED: "Change at least one workflow's privacy to open",
  PRIVACY_CHANGE: "The privacy of the project will be changed to",
  PROJECT_PRIVACY_RESTRICTED: "The project's privacy  will be updated to Restricted",
  DEFAULT_WORKFLOW_SHARED: "The default workflow of the Team has been set as shared",
  RESERVED_STAGE_NAME: "Reserved Stage Name",
  MAKE_SHARED_WORKFLOWS_CLOSED: "All shared workflows will be closed",
  WORKFLOW_DELETED: "The workflow has been deleted",
  CHOOSE_NEW_REPLACE_DEFAULT: "Choose a new workflow to replace the default one",
  TASK_RETURN: "Task will return to ",
  TASK_DELEGATED: "Task delegated to ",
  TASK_PASSED_ON: "Task passed on to ",
  WORKFLOW_NAME_REQUIRED: "Workflow name is required",
  SENT_VERIFICATION_MAIL: "Email verification request has been sent to your registered email",
  VERIFY_COMPANY_MAIL: "Verify Email to proceed further",
  COMPANY_UPDATED: "Organization Updated  Sucessfully",
  ORGANIZATION_UPDATED: "Organization set up complete",
  PROFILE_SETUP: "Profile set up complete",
  ADD_ALL_COMPANY_FIELDS: "Please add all fields before proceeding",
  ADD_COMPANY_FIELDS: "All fields must be completed",
  LIMIT_EXCEEDED: "Limit Exceeded",
  MAIL_VERIFIED: "Verified",
  ADD_WORKFLOW: "Add Workflow",
  WORKFLOW_PRIVACY_RULE: "One workflow of the personal project should always be shared",
  WORKFLOW_ADDED: "Workflow Added",
  INVALID_REMINDER: "Please enter a valid reminder",
  APP_VERSION: "Application Version",
  TASK_CREATION_REQUIRED: "Task creation is required",
  CAN_NOT_COEXIST: "Two identical flow elements cannot coexist",
  UNACCESSED_WORKFLOW: "You do not have access to this Workflow",
  DELETION_NOT_ALLOWED: "Delete the upcoming element first to prevent the coexistence of two similar elements",
  FLOW_ELEMENT_ADDED: "Flow element added",
  FLOW_ELEMENT_DELETED: "Flow element deleted",
  SUBTASK_DELETED: "Subtask deleted",
  SUBTASK_ADDED: "Subtask added",
  CHILD_TASK_ADDED: "Child task added",
  CHILD_TASK_REMOVED: "Child task deleted",
  MAXIMUM_FILTER_LIMIT_REACHED: "Maximum Filter Name Limit Reached",
  TEAM_ADDED: "Team Added",
  SMART_LINK: "Smart Link",
  URL: "URL",
  FILTER_NAME_EXCEEDS: "Filter name exceeds the maximum allowed limit. Please choose a different name",
  LOCK_CONFIRMATION: "Locking the task will restrict editing of the select fields & attachments. Do you want to proceed?",
  UNLOCK_CONFIRMATION: "Are you sure you want to unlock this task?",
  WORKFLOW_USAGE: "Workflow Usage:",
  SAME_PAYLOAD_ERROR: "Change any field to enable Save Button",
  REQURED_FIELDS_ERROR: "Fill Required Fields to enable Save button",
  SAVE_BUTTON_ENABLED: "Save Button Enabled",
  EMAIL_IN_USE: "Email in use",
  TASKCODE_IN_CODE: "Task code in use",
  LOGIN_TYPES_DISABLE_ERROR: "Both login Types can't be disabled!",
  NOT_ALLOWED_TO_REMOVE_ADMIN: "At least one Admin should be present!",
  NOT_ALLOWED_TO_REMOVE_BILLING_OWNER: "At least one Billing Owner should be present!",
  REMINDER_INVALID_DATE: " Reminder date cannot be past the current date",
  UPPER_LIMIT_REACHED: " You can add only upto 5 reminders for a workflow",
  REMINDER_TITLE_EMPTY : "Reminder Title cannot be empty",
  OPERATION_CANCEL_MSG: "Operations cancelled due to new request",
  WORKFLOW_DELETE_CONFIRMATION: "Are you sure you want to delete this workflow?",
  PLAN_MY_DAY_MODE: "Oops!! please close the task to exit plan my day mode.",
  PLAN_COMPLETE_TASKS: "Task Completed",
  PLAN_DO_LATER: "Today's due dates and tasks pinned to your personal board.",
  ALL_TASK_PLANNED: "Thank you, all tasks have been planned for today.",
  TASK_SKIPPED: "Task Skipped",
  PLAN_DO_TODAY: "The task has been pinned and the due date has been adjusted to today’s date.",
  PLAN_DO_TOMORROW: "The due date is set for tomorrow’s date.",
  PLAN_REMOVE_DUE_DATE: "Due date removed for the task",
  PLAN_DUE_DATE_CUSTOM: "Due date set for",
  PLAN_SET_REMINDER_TOMORROW: "Reminder set for tomorrow",
  PLAN_SET_REMINDER_CUSTOM: "Reminder Set",
  CHOSEN_NOTIFICATION_TYPE: "Select the notifications that matter to you",
  AGREE_TO_RECEIVE_EMAIL: "I consent to receiving email communications.",
  EMAIL_VERIFICATION: "Email sent to your account",
  USE_MY_EMAIL: "Use My Email: ",
  INSERT_HYPERLINK_NOTIF: "Press ctrl+k to hyperlink selected text",
  WORKFLOW_DELETE_CONFIRMATION: "Are you sure you want to delete this workflow?",
  PRIVACY_POLICY: "Privacy Policy",
  HELP: "Help",
  USE_MY_EMAIL: "Use My Email: ",
  ACCOUNT_VERIFIED: "Email verified, Your account details have been sent to the Admin, please wait until your account is approved.",
  EMAIL_EXIST: "Email already exists , login please",
  EXPIRED_INVITE: "Expired invite, please request a new one",
  SIGNIN_OPTION: "Sign in options",
  DESC_MODE_UPDATE: "only mode creator can update the mode",
  WHAT_DO_YOU_NEED: "What do you need help with",
  CHOOSE_AN_ORGANIZATION: "Choose an Organization",
  CHOOSE_YOUR_ORGANIZATION_TO_LOG_IN: "Choose your organization to log in",
  BACK: "Back",
  SELECT_TAG: "Select a tag",
  ALL_COMMENTS_MARK_AS_READ: "All comments are marked as read successfully",
  ATTACHMENT_DELETED_SURE: " Are you sure you want to delete this attachment ?",
  UP_TO_DATE: "Yay! You are up to date!",
  EMAIL_MARKED_AS_SPAM: "Email marked as spam ",
  NO_DESCRIPTION: "No description available",
  DOMAIN_EMAIL_ID_NOT_REGISTERED: "User email not registered in",
  SOMETHING_WENT_WRONG: "Oops! Something went wrong",
  TRY_AGAIN_LATER: "An unexpected error has occurred. Please try again later.",
  USER_DENIED_LOCATION: "User denied the request for Geolocation",
  INFORMATION_UNAVAILABLE: "Location information is unavailable",
  LOCATION_TIME_OUT: "The request to get user location timed out",
  UNKNOWN_ERROR: "An unknown error occurred",
  SHOW_MORE_LOGS: "Show More Logs",
  NO_FILTER: "No Filter",
  UNABLE_TO_CONVERT_TASK: "Unable to convert subtask to task",
  EXPORT_SUCCESSFULLY: "Exported Successfully",
  CUSTOM_FILTER_NOT_FOUND : "Custom Filters Not Found",
  CREATE_FILTER : "Start by Creating a Filter"
};

export const action = {
  CONFIRM: "confirm",
  HIDE: "hide",
  ACTIVE: "active",
  PUSH: "push",
  EMAIL: "email",
  REMOVE: "remove",
};


export const button = {
  LOGIN: "Log In",
  SIGNUP: "Get Started Now",
  FORGOT_PASSWORD: " Send reset link",
  SIGNUP_WITH_GOOGLE: "Sign Up with Google",
  LOGIN_WITH_GOOGLE: "Log in with Google",
  CHANGE_PASSWORD: "Change Password",
  ADMIN_CONSOLE: "Admin Console",
  LOGOUT: "Log Out",
  USER_PROFILE: "User Profile",
  RESET_PASSWORD: "Reset Password",
  APPROVE_REQUEST: "Approve Request",
  CREATE: "Create Project",
  CANCEL: "Cancel",
  ADD_PROJECT: "Add Project",
  SAVE: "Save",
  SAVE_AS: "Save As",
  DELETE_ORGRANIZATION: "Remove Organization",
  RESEND_INVITE: "Resend Invite",
  ADD_QUEUE: "Add Workspace",
  DELETE_QUEUE: "Delete Workspace",
  MOVE_PROJECTS: "Move",
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  EDIT_WORKFLOW: "Edit Workflow",
  CLONE_PROJECT: "Clone Project",
  DELETE_PROJECT: "Delete Project",
  DONE: "Apply",
  CLEAR: "Reset",
  DELETE_TASK: "Delete",
  ARCHIVE_TASK: "Archive Task",
  UNARCHIVE_TASK: "Unarchive Task",
  COMMENT_REPLY: "Reply",
  COMMENT_EDIT: "Edit",
  COMMENT_DELETE: "Delete",
  COMMENT_MAKE_PUBLIC: "Make Public",
  LOAD_MORE_COMMENTS: "Load more",
  NO: "No",
  YES: "Yes",
  SEE_ALL_NOTIFICATIONS: "See all notifications",
  CLEAR_ALL: "Clear All",
  SUPER_ADMIN_CONSOLE: "Superadmin Console",
  STOP: "Stop",
  SAVE_REMINDER: "Save Reminder",
  CLONE_TASK: "Clone",
  ADD_CHILD_TASK: "Child Task",
  ADD_FLOW_ELEMENT: "Flow Element",
  ADD_WORKFLOW: "Add Workflow",
  STAGE: "Stage",
  ADD_NEW_STAGE: "New Stage",
  SUBMIT: "Submit",
  DEFAULT: "Default",
  VERIFY_EMAIL: "Verify Email",
  CREATE: "Create",
  ADD_NEW_STAGE: "Add New Stage",
  ADD: "Add",
  MOVE_TEAM: "Move Team",
  CANCEL_EDIT: "Cancel Edit",
  LOCK_TASK: "Lock",
  UNLOCK_TASK: "Unlock Task",
  LOCKED: "Locked",
  BOOKMARK_TASK: "Bookmark",
  BOOKMARKED_TASK: "Bookmarked",
  NEXT: "Next",
  BACK: "Back",
  DONE_SETUP: "Done",
  MERGE_TAGS: "Merge Tags",
  TRY_AGAIN: "Try Again",
  VALID_URL: "Please enter a valid URL.",
};

export const icon = {
  ORDER_APPROVE: <i className='outlined'>order_approve</i>,
  TOPIC: <i className='outlined'>topic</i>,
  SETTING: <i className='material-symbols-outlined'>settings_applications</i>,
  OTHER_ADMISSION: <i className='outlined'>other_admission</i>,
  GROUP_WORK: <i className='outlined'>group_work</i>,
  HELP: <i className='material-symbols-outlined'>help</i>,
  IMAGE: <i className='outlined'>image</i>,
  PRIORITY: <i className='material-symbols-outlined'>priority_high</i>,
  FRAME_RELOAD: <i className='material-symbols-outlined'>frame_reload</i>,
  APP_REGISTRATION: <i className='material-symbols-outlined'>app_registration</i>,
  TASK_ALT: <i className='material-symbols-outlined'>task_alt</i>,
  WORKSPACES: <i className='material-symbols-outlined'>workspaces</i>,
  DRAFT_ORDERS: <i className='material-symbols-outlined'>draft_orders</i>,
  USER_STORY: <i className='material-symbols-outlined'>feed</i>,
  NEW: <i className='material-symbols-outlined'>fiber_new</i>,
  EYBOARD_COMMAND_KEY: <i className='material-symbols-outlined'>keyboard_command_key</i>,
  BOOK: <i className='material-symbols-outlined'>book_5</i>,
  ASSIGNMENT: <i className='material-symbols-outlined'>assignment</i>,
  ALARM: <i className='material-symbols-outlined'>alarm</i>,
  TOOLTIP: <i className='material-symbols-outlined'>tooltip</i>,
  CHECK_CIRCLE: <i className='material-symbols-outlined'>check_circle</i>,
  BADGE: <i className='material-symbols-outlined'>badge</i>,
  CAMPAIGNS: <i className='material-symbols-outlined'>campaign</i>,
  LIST: <i className='material-symbols-outlined'>list_alt</i>,
  FILTER: <i className='material-symbols-outlined'>filter</i>,
  DND: <i className='material-symbols-outlined cursor-pointer mr-2'>do_not_disturb_on</i>,
  FOLLOWED: <i className='material-symbols-outlined'> visibility</i>,
  GLOBAL_NOTIFICATION: <i className='material-symbols-outlined cursor-pointer mr-2'>notifications</i>,
  EMAIL_NOTIFICATION: <i className='material-symbols-outlined cursor-pointer mr-2'>mail</i>,
  PROJECTS: <i className='material-symbols-outlined material-symbols-fill '> group </i>,
  TASKS: <i className='material-symbols-outlined'>format_list_bulleted</i>,
  QUEUES: <i className='material-symbols-outlined cursor-pointer '>groups</i>,
  WORKFLOW: <i className='material-symbols-outlined cursor-pointer'>account_tree</i>,
  ACTIVITY_DIGEST: <i className='material-symbols-outlined cursor-pointer campaign-icon'>campaign</i>,
  INBOX: <i className='material-symbols-outlined cursor-pointer'>forum</i>,
  CALENDAR: <i className='material-symbols-outlined cursor-pointer'>event</i>,
  HELP_SUPPORT: <i className='material-symbols-outlined cursor-pointer'>support</i>,
  ADD_HEADER: <i className='material-symbols-outlined cursor-pointer mr-2 add-icon'>add</i>,
  BULLET_LIST: <i className='material-symbols-outlined cursor-pointer'>format_list_bulleted</i>,
  DVR: <i className='material-symbols-outlined cursor-pointer  material-symbols-fill'> group </i>,
  FORUM: <i className='material-symbols-outlined cursor-pointer'>forum</i>,
  SEARCH: <i className='material-symbols-outlined cursor-pointer align-middle'>search</i>,
  PERSON_SEARCH: (
    <i className='material-symbols-outlined cursor-pointer align-middle' data-toggle='tooltip' data-placement='bottom' title='Search'>
      person_search
    </i>
  ),
  TUNE: <i className='material-symbols-outlined cursor-pointer'>tune</i>,
  ADD_BOX: <i className='material-symbols-outlined cursor-pointer'>add_box</i>,
  ACCOUNT_CIRCLE: <i className='material-symbols-outlined account-circle-icon material-symbols-fill'>account_circle</i>,
  UNASSIGNED_USER: <i className='material-symbols-outlined black'>no_accounts</i>,
  TEAM_ACCOUNT_CIRCLE: <i className='material-symbols-outlined cursor-pointer team-account material-symbols-fill'> account_circle</i>,
  HOME: <i className='material-symbols-outlined cursor-pointer'>home</i>,
  DASHBOARD: <i className='material-symbols-outlined cursor-pointer material-symbols-fill'>house</i>,
  BUSINESS: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title={route.ADMIN_ROUTE.MANAGE_ORGANIZATION.PROPS.HEADER}>
      business
    </i>
  ),
  LOCK: <i className='material-symbols-outlined cursor-pointer red material-symbols-fill'>lock</i>,
  TASK_LOCK: <i className='material-symbols-outlined cursor-pointer  maroon material-symbols-fill'>group</i>,
  TASK_LOCKED_OUTLINED: <i className='material-symbols-outlined black material-symbols-fill '> group </i>,
  TASK_LOCKED_OUTLINED_DARK_MODE: <i className='material-symbols-outlined white material-symbols-fill'> group </i>,
  ADMIN_SETTINGS: <i className='material-symbols-outlined cursor-pointer material-symbols-fill'>admin_panel_settings</i>,
  LOGOUT: <i className='material-symbols-outlined'>logout</i>,
  SIDE_COLLAPESE: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Collapse'>
      logout
    </i>
  ),
  MENU: <i className='material-symbols-outlined cursor-pointer'>menu</i>,
  GROUPS: <i className='material-symbols-outlined cursor-pointer material-symbols-fill'>groups</i>,
  ACCOUNT_TREE: <i className='material-symbols-outlined cursor-pointer'>account_tree</i>,
  CAMPAIGN: <i className='material-symbols-outlined cursor-pointer  campaign-icon'>campaign</i>,
  RATE_REVIEW: <i className='material-symbols-outlined cursor-pointer'>rate_review</i>,
  VISIBILITY: <i className='material-symbols-outlined cursor-pointer'>visibility</i>,
  VISIBILITY_OFF: <i className='material-symbols-outlined cursor-pointer'>visibility_off</i>,
  PERSON_TOGGLE: (
    <i className='material-symbols-outlined cursor-pointer material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title={"Search Users"}>
      person{" "}
    </i>
  ),
  PROJECTS_TOGGLE: (
    <i className='material-symbols-outlined material-symbols-fill ' data-toggle='tooltip' data-placement='bottom' title={"Search Projects"}>
      {" "}
      group{" "}
    </i>
  ),
  PERSON: (
    <i className='material-symbols-outlined cursor-pointer material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title={route.ADMIN_ROUTE.MANAGE_USERS.PROPS.HEADER}>
      person{" "}
    </i>
  ),
  HISTORY: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title={route.ADMIN_ROUTE.ACTIVITY_LOG.PROPS.HEADER}>
      history{" "}
    </i>
  ),
  SUMMARIZE: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title={route.ADMIN_ROUTE.USAGE_SUMMARY.PROPS.HEADER}>
      summarize{" "}
    </i>
  ),
  MANAGE_STORAGE: (
    <i className='material-symbols-outlined ' data-toggle='tooltip' data-placement='bottom' title={route.ADMIN_ROUTE.MANAGE_UTILIZATION.PROPS.HEADER}>
      package_2
    </i>
  ),
  RESET: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Reset'>
      restart_alt{" "}
    </i>
  ),
  ELLIPSIS_HORIZ: <i className='material-symbols-outlined cursor-pointer'> more_horiz </i>,
  PERSON_BLUE: <i className='material-symbols-outlined cursor-pointer material-symbols-fill blue'>person </i>,
  PERSON_RED: <i className='material-symbols-outlined cursor-pointer material-symbols-fill red'>person </i>,
  PERSON_GREEN: <i className='material-symbols-outlined cursor-pointer material-symbols-fill green'>person </i>,
  PERSON_PURPLE: <i className='material-symbols-outlined cursor-pointer material-symbols-fill purple'>person </i>,
  PERSON_ORANGE: <i className='material-symbols-outlined cursor-pointer material-symbols-fill orange'>person </i>,
  PERSON_GREY: <i className='material-symbols-outlined cursor-pointer material-symbols-fill grey'>person </i>,
  PERSON_YELLOW: <i className='material-symbols-outlined cursor-pointer material-symbols-fill  yellow'>person </i>,
  PERSON_PINK: <i className='material-symbols-outlined cursor-pointer material-symbols-fill pink'>person </i>,
  PERSON_MAROON: <i className='material-symbols-outlined cursor-pointer material-symbols-fill maroon'>person </i>,
  IMPORT_FILE: <i className='material-symbols-outlined  cursor-pointer'>download</i>,
  SETTINGS: (
    <i className='material-symbols-outlined cursor-pointer material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title='Settings'>
      settings
    </i>
  ),
  HELP_OUTLINE: <i className='material-symbols-outlined cursor-pointer'>help_outline</i>,
  PRIVACY_PUBLIC: <i className='material-symbols-outlined cursor-pointer'>public</i>,
  PRIVACY_TEAM: <i className='material-symbols-outlined cursor-pointer material-symbols-fill'>group</i>,
  PROJECT_ICON: <i className='material-symbols-outlined cursor-pointer material-symbols-fill material-symbols-fill'>group</i>,
  EDIT_TASK: (
    <i className='material-symbols-outlined cursor-pointer opacity-7' title='Edit Task'>
      edit
    </i>
  ),
  PIN_TASK: <i className='material-symbols-outlined material-symbols-fill blue-color-pin'>keep</i>,
  UNPIN_FILTER: (
    <i className='material-symbols-outlined material-symbols-fill blue-color-pin fa-rotate-minus-45' data-toggle='tooltip' data-placement='bottom'>
      keep
    </i>
  ),
  PIN_FILTER: (
    <i className='material-symbols-outlined material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title='Pin To Board'>
      keep
    </i>
  ),
  UNPIN_TASK: <i className='material-symbols-outlined  opacity-7'>keep</i>,
  PIN_TASK_GRID: (
    <span className='wrapper'>
      Pinned{" "}
      <span className='pin-icon box'>
        <i className='material-symbols-outlined material-symbols-fill'>keep</i>
      </span>
    </span>
  ),
  EDIT_PROJECT: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Edit Project'>
      edit
    </i>
  ),
  DONE: <i className='material-symbols-outlined cursor-pointer'>done</i>,
  CORRECT: <i className='material-symbols-outlined'>done</i>,
  CLEAR: <i className='material-symbols-outlined cursor-pointer'>clear</i>,
  CLOSE_COMMENT: <i className='material-symbols-outlined cursor-pointer'>close</i>,
  CLOSE: (
    <i className='material-symbols-outlined cursor-pointer' title='Close'>
      close
    </i>
  ),
  CLOSE_TASK_DUE_DATE: <i className='material-symbols-outlined cursor-pointer'>close</i>,
  CANCEL_FILE: <i className='material-symbols-outlined remove-attachment'>cancel</i>,
  ADD_USERS: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Add Users'>
      person_add
    </i>
  ),
  DELETE: <i className='material-symbols-outlined cursor-pointer'>cancel</i>,
  SORT: <i className='material-symbols-outlined cursor-pointer'>filter_list</i>,
  EXPORT_CSV: (
    <i className='material-symbols-outlined  cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Export CSV'>
      download
    </i>
  ),
  INVITE_USERS: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Invite Users'>
      person_add
    </i>
  ),
  ASCENDING: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Ascending'>
      south
    </i>
  ),
  DESCENDING: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Descending'>
      north
    </i>
  ),
  LEFT: <i className='material-symbols-outlined cursor-pointer'>chevron_left</i>,
  RIGHT: <i className='material-symbols-outlined cursor-pointer'>chevron_right</i>,
  GRID: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Custom View'>
      {" "}
      grid_view{" "}
    </i>
  ),
  DRAG_INDICATOR: <i className='material-symbols-outlined'>drag_indicator</i>,
  NOTIFICATION: (
    <i className='material-symbols-outlined cursor-pointer material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title='Notifications'>
      campaign
    </i>
  ),
  DARK_MODE: <i className='material-symbols-outlined'>dark_mode</i>,
  LIGHT_MODE: <i className='material-symbols-outlined'>light_mode</i>,
  HEADSET: (
    <i className='material-symbols-outlined cursor-pointer material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title='Help & Support'>
      headset_mic
    </i>
  ),
  EDIT_NOTES: (
    <i className='notes-button material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Notes'>
      edit_note
    </i>
  ),
  RECENT: (
    <i className='material-symbols-outlined cursor-pointer' data-toggle='tooltip' data-placement='bottom'>
      pending_actions
    </i>
  ),
  CLONE_WORKFLOW: <i className='material-symbols-outlined '>content_copy</i>,
  WORKFLOW_ICON: (
    <span className='workflow-icon'>
      <i className='material-symbols-outlined fa-rotate-90 d-inline-block low-priority-icon'>low_priority</i>
    </span>
  ),
  DEFAULT_PROJECT: (
    <i className='material-symbols-outlined float-right' data-toggle='tooltip' data-placement='bottom' title='Default Workflow'>
      {" "}
      military_tech{" "}
    </i>
  ),
  FORWARD_ARROW: <i className='material-symbols-outlined icon-mirror cursor-pointer'>reply</i>,
  EXPAND: <i className='material-symbols-outlined cursor-pointer'>expand_more</i>,
  EXPAND_CIRCLE_DOWN: <i className='material-symbols-outlined'>expand_circle_down</i>,
  EXPAND_CIRCLE_DOWN_FILLED: <i className='material-symbols-outlined'>expand_circle_down</i>,
  ARROW_UP: <i className='material-symbols-outlined '>arrow_drop_up</i>,
  ARROW_DOWN: <i className='material-symbols-outlined '>arrow_drop_down</i>,
  INFO: <i className='material-symbols-outlined cursor-pointer'>info</i>,
  DESCRIPTION: <i className='material-symbols-outlined cursor-pointer'>description</i>,
  ATTACHMENT: <i className='material-symbols-outlined cursor-pointer'>attachment</i>,
  TREE: <i className='material-symbols-outlined cursor-pointer'>account_tree</i>,
  MEDIATION: <i className='material-symbols-outlined cursor-pointer'>mediation</i>,
  COMMENT: <i className='material-symbols-outlined cursor-pointer'>insert_comment</i>,
  ACTIVITY_HISTORY: <i className='material-symbols-outlined cursor-pointer'>history</i>,
  OPEN_IN_FULL: <i className='material-symbols-outlined'>open_in_full</i>,
  ADD_CIRCLE: <i className='material-symbols-outlined cursor-pointer task-align'>add_circle</i>,
  NORTH_EAST: <i className='material-symbols-outlined cursor-pointer'>north_east</i>,
  SOUTH_WEST: <i className='material-symbols-outlined cursor-pointer'>south_west</i>,
  CHECKBOX: (
    <i className='material-symbols-outlined' data-toggle='tooltip' data-placement='bottom'>
      done
    </i>
  ),
  SAVE_MARK: (
    <i className='material-symbols-outlined cursor-pointer style' data-toggle='tooltip' data-placement='bottom' title='Save'>
      done
    </i>
  ),
  CHECK: (
    <i className='material-symbols-outlined cursor-pointer opacity-7' data-toggle='tooltip' data-placement='bottom' title='Mark As Complete'>
      verified
    </i>
  ),
  GREEN_OUTLINED_CHECK: (
    <i className='material-symbols-outlined cursor-pointer opacity-7 green' data-toggle='tooltip' data-placement='bottom' title='Completed by team'>
      verified
    </i>
  ),
  GREEN_CHECK: (
    <i className='material-symbols-outlined green cursor-pointer material-symbols-fill' data-toggle='tooltip' data-placement='bottom' title='Task Completed'>
      verified
    </i>
  ),
  GREEN_CHECK_WORKFLOW: (
    <i className='material-symbols-outlined green cursor-pointer' data-toggle='tooltip' data-placement='bottom' title='Final Stage'>
      verified
    </i>
  ),
  OPEN_LOCK: <i className='material-symbols-outlined cursor-pointer blue material-symbols-fill'>group</i>,
  PRIORITY_LOW: <i className='bi bi-chevron-down' title='Low'></i>,
  PRIORITY_MEDIUM: <i className='bi bi-pause fa-rotate-90 d-inline-block' title='Medium'></i>,
  PRIORITY_HIGH: <i className='bi bi-chevron-up' title='High'></i>,
  PRIORITY_HIGHEST: <i className='bi bi-chevron-double-up' title='Highest'></i>,
  PDF: <i className='material-symbols-outlined picture-pdf'> picture_as_pdf</i>,
  ALL_TASKS_ICON: <i className='material-symbols-outlined'> playlist_add_check </i>,
  SENT_BY_DELEGATION: <i className='material-symbols-outlined icon-equal-dimension'>open_in_new</i>,
  RECIEVED_BY_DELEGATION: <i className='material-symbols-outlined fa-rotate-90 d-inline-block icon-equal-dimension'>open_in_new</i>,
  SENT_BY_PASS_ON: <i className='material-symbols-outlined icon-equal-dimension'>open_in_browser</i>,
  RECIEVED_BY_PASS_ON: <i className='material-symbols-outlined fa-rotate-180 d-inline-block red icon-equal-dimension'>open_in_browser</i>,
  TASK_PERSON: <i className='material-symbols-outlined cursor-pointer material-symbols-fill'>person </i>,
  TRASH: <i className='material-symbols-outlined cursor-pointer red'> delete </i>,
  PROJECT_QUEUE_MOVE: <i className='material-symbols-outlined cursor-pointer'>sync_alt</i>,
  USER_ICON: <i className='material-symbols-outlined material-symbols-fill'>person</i>,
  TRIPLE_DOT: <i className='material-symbols-outlined cursor-pointer'>more_horiz</i>,
  ADD_REACTION: <i className='material-symbols-outlined cursor-pointer'>add_reaction</i>,
  SEND_BUTTON: (
    <i className='material-symbols-outlined' data-atr='send button'>
      send
    </i>
  ),
  CANCEL: <i className='material-symbols-outlined'>cancel</i>,
  DATE_RANGE: <i className='material-symbols-outlined maroon-text'>date_range</i>,
  ARROW_RIGHT_WORKFLOW: (
    <i className='material-symbols-outlined cursor-pointer subdirectory-arrow-right' title='Incoming Stage'>
      subdirectory_arrow_right
    </i>
  ),
  EDIT_ICON: <i className='material-symbols-outlined'>edit</i>,
  DELETE_ICON: <i className='material-symbols-outlined'>delete</i>,
  DELETE_FOREVER_ICON: <i className='material-symbols-outlined'>delete_forever</i>,
  UPDATE_ICON: <i className='material-symbols-outlined'>update</i>,
  CHECK_CIRCLE_ICON: <i className='material-symbols-outlined green'>check_circle</i>,
  CANCEL_ICON: <i className='material-symbols-outlined'>cancel</i>,
  REMOVE_ICON: <i className='material-symbols-outlined'>do_not_disturb_on</i>,
  SMS_ICON: <i className='material-symbols-outlined'>sms</i>,
  CHECK_LIST_ICON: <i className='material-symbols-outlined'>checklist</i>,
  ADD_CIRCLE_ICON: <i className='material-symbols-outlined'>add_circle</i>,
  HIGHLIGHT_OFF_ICON: <i className='material-symbols-outlined'>cancel</i>,
  ADD_NOTE: <i className='material-symbols-outlined'>add</i>,
  CLOSE_EDITOR: (
    <i className='material-symbols-outlined' title='Close'>
      close
    </i>
  ),
  SELECT_COLOR: (
    <i className='material-symbols-outlined' title='color-p-btn'>
      color_lens
    </i>
  ),
  ADD_BOOKMARK: <i className='material-symbols-outlined'>bookmark_add</i>,
  BOOKMARK_ADDED: <i className='material-symbols-outlined'>bookmark_added</i>,
  SAVE_NOTE: <i className='material-symbols-outlined'>save</i>,
  BACK_HAND_OUTLINED: <i className='material-symbols-outlined  opacity-7'>back_hand</i>,
  BACK_HAND: <i className='material-symbols-outlined material-symbols-fill'>back_hand</i>,
  WAVING_HAND: <i className='material-symbols-outlined'>waving_hand</i>,
  BACKWARD_ARROW: <i className='material-symbols-outlined cursor-pointer'>reply</i>,
  LINK: (
    <i className='material-symbols-outlined blue' data-toggle='tooltip' data-placement='bottom' title='Share Link'>
      link
    </i>
  ),
  SHARE: (
    <i className='material-symbols-outlined' data-toggle='tooltip' data-placement='bottom'>
      share
    </i>
  ),
  BOOKMARK_STAGE: <i className='material-symbols-outlined'>track_changes</i>,
  BOOKMARK_NAME: <i className='material-symbols-outlined'>check</i>,
  BOOKMARK_TEST: <i className='fas fa-bookmark'></i>,
  CHECK_NOTIFICATIONS: <i className='material-symbols-outlined cursor-pointer'>check_circle</i>,
  BELL_ICON: <i className='material-symbols-outlined material-symbols-fill'>notifications_active</i>,
  RECENT_SEARCH: <i className='material-symbols-outlined'>youtube_searched_for</i>,
  TAG: <i className='material-symbols-outlined'>sell</i>,
  PANEL: <i className='material-symbols-outlined'>window</i>,
  DEFAULT_SETTINGS: <i className='material-symbols-outlined'>tune</i>,
  STAGES: <i className='material-symbols-outlined'>storage</i>,
  RECUR: <i className='material-symbols-outlined '>repeat</i>,
  LOCK_ICON: <i className='material-symbols-outlined material-symbols-fill'>lock</i>,
  LOCK_TASK_ICON: (
    <i className='material-symbols-outlined material-symbols-fill' title='Task Locked'>
      lock
    </i>
  ),
  ARCHIVE: <i className='material-symbols-outlined'>archive</i>,
  EDIT_DESCRIPTION: (
    <i className='material-symbols-outlined cursor-pointer opacity-7' title='Edit Description'>
      edit
    </i>
  ),
  DELINK_TASK: <i className='material-symbols-outlined cursor-pointer'>link_off</i>,
  LINK_ATTACHMENT: <i className='material-symbols-outlined cursor-pointer'>link</i>,
  REMOVE: <i className='material-symbols-outlined'>remove</i>,
  LIST_VIEW: <i className='material-symbols-outlined'>ballot</i>,
  BOARD_VIEW: <i className='bi bi-kanban'></i>,
  DASHBOARD_VIEW: <i className='material-symbols-outlined'>space_dashboard</i>,
  TASK_FOLLOWERS: <i className='material-symbols-outlined cursor-pointer material-symbols-fill '>group</i>,
  DESCRIPTION_POPUP_ICON: <i className='material-symbols-outlined'>open_in_new</i>,
  AUTO_SAVE: <i className='material-symbols-outlined'>autorenew</i>,
  APPORVAL_APPROVED: <i className='material-symbols-outlined approval-approved'>verified_user</i>,
  APPROVAL_ICON: <i className='material-symbols-outlined approval-initiated'>verified_user</i>,
  APPROVAL_RECOMMENDED: <i className='material-symbols-outlined'>arrow_circle_up</i>,
  APPROVAL_ONHOLD: <i className='material-symbols-outlined'>hourglass_empty</i>,
  APPROVAL_REJECTED: <i className='material-symbols-outlined approval-rejected'>gpp_bad</i>,
  APPROVAL_REASSIGN: <i className='material-symbols-outlined'> east </i>,
  OPEN_IN_BROWSER: <i className='material-symbols-outlined'>open_in_browser</i>,
  DELETE_APPROVAL_LOGS: <i className='material-symbols-outlined'>delete</i>,
  APPROVAL_INFO: <i className='material-symbols-outlined'>info</i>,
  APPROVAL_REVOKE: <i className='material-symbols-outlined'>unpublished</i>,
  STREAM_ICON: <i className='material-symbols-outlined'>post_add</i>,
  FOLLOWER_ICON: <i className='material-symbols-outlined cursor-pointer'>control_point_duplicate</i>,
  FILTER: <i className='material-symbols-outlined'>filter_alt</i>,
  DO_NOT_DISTURB: <i className='material-symbols-outlined'>do_not_disturb_on</i>,
  DEFAULT_ICON: <i className='material-symbols-outlined'>task</i>,
  FINANCE_TEAM: <i className='material-symbols-outlined'>request_quote</i>,
  EDIT_WORKSPACE: (
    <i className='material-symbols-outlined cursor-pointer opacity-7' title='Edit Workspace'>
      edit
    </i>
  ),
  TASK: <i className='material-symbols-outlined'>task</i>,
  IMAGE: <i className='material-symbols-outlined'>image</i>,
  NO_ASSIGNEE: <i className='material-symbols-outlined'>person_off</i>,
  EXPAND_ICON: <i className='material-symbols-outlined cursor-pointer'>expand</i>,
  CLEAR_ALL: <i className='material-symbols-outlined'>clear_all</i>,
  ADD: <i className='material-symbols-outlined'>add</i>,
  DONE_OUTLINED: <i className='material-symbols-outlined green'>done_outline</i>,
  INFO_ICON: <i className='material-symbols-outlined'>info</i>,
  STAR: <i className='material-symbols-outlined'>star</i>,
  RADIO_BUTTON_CHECKED: <i className='material-symbols-outlined'>radio_button_checked</i>,
  FLAG: <i className='material-symbols-outlined'>flag</i>,
  ARROW_LEFT: <i className='material-symbols-outlined'>west</i>,
  ARROW_RIGHT: <i className='material-symbols-outlined'>east</i>,
  ARROW_FORWARD: <i className='material-symbols-outlined'>arrow_forward</i>,
  ARROW_BACK_IOS: <i className='material-symbols-outlined'>arrow_back_ios_new</i>,
  ARROW_UP: <i className='material-symbols-outlined'> keyboard_arrow_up </i>,
  ARROW_DOWN: <i className='material-symbols-outlined'> keyboard_arrow_down </i>,
  ADD_TASK: <i className='material-symbols-outlined'>add_task</i>,
  FILE_UPLOAD: <i className='material-symbols-outlined'>upload</i>,
  UNFOLD_MORE: <i className='material-symbols-outlined cursor-pointer'>unfold_more</i>,
  DOWNLOAD_FILE: <i className='material-symbols-outlined'>cloud_download</i>,
  UNLOCK: <i className='material-symbols-outlined material-symbols-outlined'>lock_open_right</i>,
  APPROVAL_LOCK: <i className='material-symbols-outlined cursor-pointer material-symbols-fill'>lock</i>,
  MINIMIZE: <i className='material-symbols-outlined'>minimize</i>,
  OPEN_IN_NEW: (
    <i className='material-symbols-outlined' style={{ height: "24px", width: "24px" }}>
      open_in_new
    </i>
  ),
  DEACTIVATE_USER: <i className='material-symbols-outlined'>person_off</i>,
  REACTIVATE_USER: <i className='material-symbols-outlined material-symbols-fill'>person</i>,
  ADMIN_ACTIVITY_LOG: <i className='material-symbols-outlined'>history </i>,
  BOLT: <i className='material-symbols-outlined'>offline_bolt</i>,
  QUICK_FILTER_ONE: <i className='material-symbols-outlined'>flare</i>,
  QUICK_FILTER_TWO: <i className='material-symbols-outlined'>receipt_long</i>,
  QUICK_FILTER_THREE: <i className='material-symbols-outlined'>apps</i>,
  QUICK_FILTER_FOUR: <i className='material-symbols-outlined'>web_stories</i>,
  ADMIN_ACTIVITY_LOG: <i className='material-symbols-outlined'>history </i>,
  COPY_CONTENT: <i className='material-symbols-outlined '>content_copy</i>,
  ADMIN_ACTIVITY_LOG: <i className='material-symbols-outlined'>history </i>,
  COPY_CONTENT: <i className='material-symbols-outlined '>content_copy</i>,
  PLAN_DO_LATER: <i className='material-symbols-outlined'>free_cancellation</i>,
  PLAN_SKIP_TASKS: <i className='material-symbols-outlined'>event_busy</i>,
  PLAN_DO_TODAY: <i className='material-symbols-outlined'>event_available</i>,
  PLAN_DO_TOMORROW: <i className='material-symbols-outlined'>calendar_month</i>,
  PLAN_CUSTOM_DUE_DATE: <i className='material-symbols-outlined'>add_card</i>,
  PLAN_REMIND_TOMORROW: <i className='material-symbols-outlined'>notifications</i>,
  PLAN_CUSTOM_REMIND: <i className='material-symbols-outlined'>notification_add</i>,
  LOCATION: <i className='material-symbols-outlined live-location-icon'>my_location</i>,
  PHOTO_CAMERA: <i className='material-symbols-outlined'>photo_camera </i>,
  ROLE: <i className='material-symbols-outlined material-symbols-fill'>person</i>,
  ADD_MODERATOR: <i className='material-symbols-outlined '>add_moderator</i>,
  KEYBOARD_HIDE: <i className='material-symbols-outlined'>keyboard_hide</i>,
  KEYBOARD_LOCK: <span className='material-symbols-outlined'>keyboard_lock</span>,
  KEYBOARD_LOCK_OFF: <span className='material-symbols-outlined'>keyboard_lock_off</span>,
  WARNING: <i className='material-symbols-outlined'>warning</i>,
  QUICK_TOUR: <i className='material-symbols-outlined'>smart_display</i>,
  WARNING: <i className='material-symbols-outlined'>warning</i>,
  ARROW_DROP_DOWN: <i className='material-symbols-outlined '>arrow_drop_down</i>,
  LIST: <i className='material-symbols-outlined'>lists</i>,
  CHART_WIDGET_OPEN: <i className='material-symbols-outlined'>top_panel_open</i>,
  CHART_WIDGET_DELEGATED: <i className='material-symbols-outlined'>forward_to_inbox</i>,
  CHART_WIDGET_OVEDUE: <i className='material-symbols-outlined'>running_with_errors</i>,
  CHART_WIDGET_COMPLETED: <i className='material-symbols-outlined'>assignment_turned_in</i>,
  WORKFLOW_1: <i className='material-symbols-outlined'>assignment_turned_in</i>,
  WORKFLOW_2: <i className='material-symbols-outlined'>patient_list</i>,
  WORKFLOW_3: <i className='material-symbols-outlined'>lists</i>,
  WORKFLOW_4: <i className='material-symbols-outlined'>assignment</i>,
  WORKFLOW_5: <i className='material-symbols-outlined'>add_task</i>,
  WORKFLOW_6: <i className='material-symbols-outlined'>currency_rupee</i>,
  WORKFLOW_7: <i className='material-symbols-outlined'>edit_note</i>,
  WORKFLOW_8: <i className='material-symbols-outlined'>reviews</i>,
  WORKFLOW_9: <i className='material-symbols-outlined'>circle_notifications</i>,
  WORKFLOW_10: <i className='material-symbols-outlined '>deployed_code</i>,
  WORKFLOW_11: <i className='material-symbols-outlined'>flowsheet</i>,
  WORKFLOW_12: <i className='material-symbols-outlined'>emoji_events</i>,
  WORKFLOW_13: <i className='material-symbols-outlined'>priority_high</i>,
  WORKFLOW_14: <i className='material-symbols-outlined'>redeem</i>,
  WORKFLOW_15: <i className='material-symbols-outlined'>home_health</i>,
  WORKFLOW_16: <i className='material-symbols-outlined'>event_available</i>,
  WORKFLOW_17: <i className='material-symbols-outlined'>free_cancellation</i>,
  WORKFLOW_18: <i className='material-symbols-outlined'>diversity_3</i>,
  WORKFLOW_19: <i className='material-symbols-outlined'>fact_check</i>,
  WORKFLOW_20: <i className='material-symbols-outlined'>help</i>,
  WORKFLOW_21: <i className='material-symbols-outlined '>inbox</i>,
  WORKFLOW_22: <i className='material-symbols-outlined '>beenhere</i>,
  WORKFLOW_23: <i className='material-symbols-outlined'>build</i>,
  WORKFLOW_24: <i className='material-symbols-outlined'>recommend</i>,
  WORKFLOW_25: <i className='material-symbols-outlined'>edit_square</i>,
  WORKFLOW_26: <i className='material-symbols-outlined '>auto_stories</i>,
  WORKFLOW_27: <i className='material-symbols-outlined'>deployed_code_history</i>,
  WORKFLOW_28: <i className='material-symbols-outlined'>add_notes</i>,
  WORKFLOW_29: <i className='material-symbols-outlined'>settings_applications</i>,
  WORKFLOW_30: <i className='material-symbols-outlined'>rocket_launch</i>,
  WORKFLOW_31: <i className='material-symbols-outlined'>shopping_cart</i>,
  WORKFLOW_32: <i className='material-symbols-outlined'>phonelink_lock</i>,
  WORKFLOW_33: <i className='material-symbols-outlined'>table_chart_view</i>,
  WORKFLOW_34: <i className='material-symbols-outlined'>book_5</i>,
  WORKFLOW_35: <i className='material-symbols-outlined'>payments</i>,
  WORKFLOW_36: <i className='material-symbols-outlined'>empty_dashboard</i>,
  WORKFLOW_37: <i className='material-symbols-outlined'>credit_score</i>,
  WORKFLOW_38: <i className='material-symbols-outlined'>system_update_alt</i>,
  WORKFLOW_39: <i className='material-symbols-outlined'>fiber_new</i>,
  WORKFLOW_40: <i className='material-symbols-outlined'>alarm</i>,
  WORKFLOW_41: <i className='material-symbols-outlined'>library_add_check</i>,
  WORKFLOW_42: <i className='material-symbols-outlined'>bar_chart_4_bars</i>,
  WORKFLOW_43: <i className='material-symbols-outlined '>picture_in_picture</i>,
  WORKFLOW_44: <i className='material-symbols-outlined'>captive_portal</i>,
  WORKFLOW_45: <i className='material-symbols-outlined'>tooltip</i>,
  WORKFLOW_46: <i className='material-symbols-outlined'>folder_copy</i>,
  WORKFLOW_47: <i className='material-symbols-outlined'>support_agent</i>,
  WORKFLOW_48: <i className='material-symbols-outlined '>more</i>,
  WORKFLOW_49: <i className='material-symbols-outlined'>date_range</i>,
  WORKFLOW_50: <i className='material-symbols-outlined'>user_attributes</i>,
  WORKFLOW_51: <i className='material-symbols-outlined'>adb</i>,
  WORKFLOW_52: <i className='material-symbols-outlined '>sticky_note_2</i>,
  WORKFLOW_53: <i className='material-symbols-outlined'>flight</i>,
  WORKFLOW_54: <i className='material-symbols-outlined'>cycle</i>,
  WORKFLOW_55: <i className='material-symbols-outlined'>quick_reference_all</i>,
  WORKFLOW_56: <i className='material-symbols-outlined'>monetization_on</i>,
  WORKFLOW_57: <i className='material-symbols-outlined'>badge</i>,
  WORKFLOW_58: <i className='material-symbols-outlined '>add_moderator</i>,
  WORKFLOW_59: <i className='material-symbols-outlined'>contact_mail</i>,
  WORKFLOW_60: <i className='material-symbols-outlined'>laptop_mac</i>,
  WORKFLOW_61: <i className='material-symbols-outlined'>stack_star</i>,
  WORKFLOW_62: <i className='material-symbols-outlined'>shopping_bag</i>,
  WORKFLOW_63: <i className='material-symbols-outlined'>webhook</i>,
  WORKFLOW_64: <i className='material-symbols-outlined'>image</i>,
  WORKFLOW_65: <i className='material-symbols-outlined'>calendar_add_on</i>,
  IFRAME: <i className="material-symbols-outlined">iframe</i> ,
  PICTURE_AS_PDF: <i className="material-symbols-outlined">picture_as_pdf</i> ,
  INSERT_IMAGE_EDITOR : <i className="material-symbols-outlined">add_photo_alternate</i>,
  APPROVAL_SKIP: <i className="material-symbols-outlined">remove_moderator</i>

};

export const directory = {
  ORGANIZATION: "Organization",
  USER_PROFILE: "User_Profile",
  ATTACHMENT: "Task_Attachment",
};

export const link = {
  HELP_SUPPORT: "https://drutas.com/help/",
  TASK_CODE_HELP: "https://drutas.com/help/tasks/how-to-create-a-task/what-is-a-task-code/",
  QUEUE_HELP_BOOKMARK: "https://drutas.com/help/workspaces/",
  PROJECT_HELP_BOOKMARK: "https://drutas.com/help/teams/",
  TASK_HELP_BOOKMARK: "https://drutas.com/help/tasks/",
  ARCHIVE_HELP_BOOKMARK: "https://drutas.com/help/teams/",
  UNDER_CONSTRUTION_IMG: "https://www.processmaker.com/wp-content/uploads/2021/08/business-process-automation-use-cases-768x512.jpg",
  IMAGE_SAVE_URL: "https://demos.telerik.com/kendo-ui/service-v4/upload/save",
  IMAGE_REMOVE_URL: "https://demos.telerik.com/kendo-ui/service-v4/upload/remove",
  VERSION_URL: "https://drutas.com/updates/",
  WORKFLOW_HELP_SUPPORT: "https://drutas.com/help/automation-using-workflows/",
  PRIVACY_POLICY: "https://drutas.com/privacy-policy/",
  HELP: "https://drutas.com/help/login-and-setup/",
  ADD_TASK: "https://drutas.com/help/tasks/",
  TEAM_NAME: "https://drutas.com/help/tasks/",
  COMMENT: "https://drutas.com/help/tasks/comments-in-the-task-sidebar/",
};

export const tooltip = {
  TIME: "Time",
  DETAILS: "Details",
  USER_NAME: "User Name",
  ACTION: "Action",
  CHOOSE_PROJECT_COLOR: "Choose Team Color",
  PROJECT_NAME: "Project Name",
  PROJECT_CODE: "Project Code",
  TEAM: "Workspace",
  END_DATE: "End Date",
  MEMBERS: "Members",
  TASKS: "Tasks",
  LAST_ACTIVITY: "Last Activity",
  ADD_QUEUE_ADMIN_ACCESS: "For adding a Workspace, Admin access is required",
  PROJECTS: "Projects",
  OWNERS: "Owners",
  HELP: "Help",
  USER: "User",
  PROJECT: "Projects",
  INSERT_IMG: "Insert Image",
  UPLOAD: "Upload",
  EDIT: "Edit",
  ACKNOWLEDGE_NUDGE: "Acknowledge Nudge",
  ACK: "Ack",
  ACK_SEND_AHEAD: "Ack & Send Ahead",
  USER_NUDGED: "User Nudged",
  NUDGE_NEXT_ASSIGNEE: "Nudge the Next Assignee",
  TASK_ID: "Task Id",
  DUE_DATE: "Due Date",
  DUE: "Due",
  PRIORITY: "Priority",
  ASSIGNEE_FILTER_PLACEHOLDER: "All Assignee",
  ASSIGNEE: "Assignee",
  STAGE: "Stage",
  TAGS: "Tags",
  TASK: "Task",
  PROJECT_RULES: "Project Rules",
  BASIC_DETAILS: "Basic Details",
  DISCARD: "Discard",
  CANCEL: "Cancel",
  ADD: "Add",
  UPDATE: "Update",
  DELETE: "Delete",
  DELETE_FILTER: "Delete Filter",
  CLEAR_ASSIGNEE: "Clear Assignee",
  NAME: "Name",
  STAGE_NAME: "Stage Name",
  ADD_NEW: "Add new",
  NOTES: "Notes",
  NOTIFICATIONS: "Notifications",
  REMINDER: "Reminder",
  FONT_COLOR: "Font color",
  TEXT_BACKGROUND_COLOR: "Text background color",
  SEND_COMMENT: "Send Now Press Return",
  DELETE_APPROVAL: "Delete approval",
  ADD_NOTE: "Add note",
  VIEW_NOTE: "View note",
  REVOKE_APPROVAL: "Revoke approval",
  ADD_TASK_CTRLD: "Add Task... (Ctrl + D)",
  DEFAULT_ASSIGNEE: "Default Assignee",
  DEFAULT_WORKFLOW: "Default Workflow",
  PRIVACY: "Privacy",
  CURRENT_STAGE_SKIP: "Skipping not allowed on task current stage",
  ADD_FILTER: "Add Filter",
  DELETE_FILTER: "Delete",
  EDIT_FILTER: "Edit Filter",
  EXIT: "Exit",
  SKIP_STAGE: "Skip stage",
  STAGE_SKIPPED: "Stage is skipped",
  TAGS_OR_DESC: "OR",
  TAGS_AND_DESC: "AND",
  SELECT_TEAM: "Select Team(s)",
  MOVE_TEAM: "Move Team(s)",
  MINIMIZE: "Minimize",
  CLONE: "Clone",
  WORKFLOW_DELETE_ACCESS: "you don't have access to delete this workflow",
  WORKFLOW_DEFAULT: "A default worfklow can't be deleted",
  WORKFLOW_USAGE: "The Workflow is currently in use , Click for Details",
  UNLOCK_TASK: "Unlock Task",
  LOCK_TASK: "Lock Task",
  CLICK_TO_ASSIGN: "Click To Assign",
  ADD_REACTION: "Add Reaction",
  REMOVE_FOLLOWER: "Remove Follower",
  FLOW: "Flow",
  MOVE: "Move",
  NEXT: "Next",
  PREVIOUS: "Previous",
  OPEN_DESCRIPTION: "Open Description",
  OPEN_COLLAB_CENTRAL: "Open Collab Central",
  REMOVE_ATTACHMENT: "Remove attachment",
  EDIT_ATTACHMENT: "Edit attachment",
  DOWNLOAD_ATTACHMENT: "Download attachment",
  DELINK_TASK: "Delink task",
  ACTIONS: "Actions",
  UPLOAD_AN_IMAGE: "Upload an image",
  APPROVAL: "Approval",
  COMMENTS: "Comments",
  COPY_WORKFLOW_LINK: "Copy workflow link",
  DELETE_WORKFLOW: "Delete Workflow",
  PLAN_COMPLETE: "Completed",
  PLAN_TODAY: "Do today",
  PLAN_DO_LATER: "Do later",
  PLAN_REMINDER: "Remind me",
  PLAN_SKIP: "Skip",
  PLAN_CLOSE: "Close plan my day",
  TASK_CODE: "A unique code for easy tracking of your tickets.",
  COPY_EMAIL: "Copy Email",
  ADD_NEW_EMAIL: "Add new Email Id",
  SUPPORT_CENTER: "Support Center",
  SYSTEM_STREAMS: "System Streams",
  VIEW_BY_POPULARITY: "View by popularity",
  VIEW_RECENT_WORKFLOWS: "View recent workflows",
  VIEW_CLASSIC_COLLAB_CENTRAL: "View Classic Collab Central",
  MAIL: "Mail",
  SPAM: "Spam",
  FILE_NAME: "File Name",
  UPLOADED_DATE: "Uploaded Date",
  UPLOADED_BY: "Uploaded By",
  SIZE: "Size",
  ACTION: "Action",
  MANAGE_PROJECT: "Project",
  DELETE_EMAIL: "Delete Email",
  MARK_AS_SPAM: "Mark as spam",
  MARKED_AS_SPAM: "Marked as spam",
  CREATED_AT: "Data for the past 7 days",
  TOTAL_WORKFLOWS: "Workflows",
  TOTAL_PROJECTS: "Teams",
  APPROVAL_INITIATED: "Initiated",
  APPROVAL_GRANTED: "Granted",
  SAVE_FILTER: "Save Filter",
  CREATED: "Created",
  COMPLETED: "Completed"
};

export const dateFormat = {
  DATE_FORMAT: "h:mmaaa, dd MMM yy",
  LAST_ACTIVITY: "HH:mm, dd MMM yy",
  DEFAULT_DATE_FORMAT: "dd-MMM-yyyy",
  COMMON_DATE_FORMAT: "dd MMM yy",
  DASHBOARD_FORMAT: "HH:mm, dd/MM/yyyy",
  DATE_FORMAT_WITHOUT_YEAR: "dd MMM",
  REMINDER_TIME_FORMAT: 'HH:mm',
  REMINDER_DATE_FORMAT: 'yyyy-MM-dd',
};

export const replyBubbleConfig = {
  NONE: "none",
};

export const defaultColor = "#F2c9fb";

export const editorColors = ["#Dfedd6", "#C9f1fd", "#F2c9fb", "#Fff1d7", "#Fefac0"];

export const addOpenCase = {
  FROM_CLOSE_BUTTON: "fromCloseButton",
  FROM_OTHER_BUTTONS: "fromOtherButtons",
};

export const richEditor = {
  MODULES: {
    toolbar: [
      [{ size: [] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      [
        {
          color: [
            "#000000", // Black
            "#e60000", // Red
            "#ff9900", // orange
            "#ffff00", // yellow
            "#008a00", // green
            "#0066cc", // Navy Blue
            "#9933ff", // Blue Violet
            "#ffffff", // White
            "#facccc", // light pink
            "#ffebcc", // Blanched Almond
            "#ffffcc", // Cream
            "#cce8cc", // Granny Apple
            "#cce0f5", //  Pattens Blue
            "#ebd6ff", // Blue Chalk
            "#bbbbbb", // Silver
            "#f06666", // Bittersweet
            "#ffc266", // Grandis
            "#ffff66", // Laser Lemon
            "#66b966", // Fern
            "#66a3e0", // Picton Blue
            "#c285ff", // Heliotrope
            "#888888", // Grey
            "#a10000", // Dark Red
            "#b26b00", // Dark Goldenrod
            "#b2b200", // La Rioja
            "#006100", // Dark Green
            "#0047b2", // Cobalt
            "#6b24b2", // Purple Heart
            "#444444", // Charcoal
            "#5c0000", // Maroon
            "#663d00", // Brown
            "#666600", // Olive
            "#003700", // Myrtle
            "#002966", // Sapphire
            "#3d1466", // Christalle
          ],
        },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ indent: "-1" }, { indent: "+1" }],
    ],
  },

  FORMATS: ["size", "align", "indent", "bold", "italic", "underline", "strike", "list", "bullet", "link", "image", "color"],
};

export const RESPONSIVE_WIDTH = 992;

export const placeholder = {
  ENTER_TASK_NAME: "Enter task name",
  SEARCH_NOTES: "Search Notes",
  WRITE_SOMETHING: "Write Something...",
  ENTER_COMPANY_NAME: "Enter your company name",
  SEARCH_BY_ORGANIZATION: "Search by organisation...",
  SEARCH: "Search...",
  SEARCH_USER: "Search username...",
  SEARCH_PROJECT: "Search project...",
  SEARCH_POPULAR_WORKFLOWS: "Search in popular workflows...",
  SEARCH_RECENT_WORKFLOWS: "Search in recent workflows...",
  REMINDER_TITLE: "Reminder Title",
  SAVE_TASK: "Save task. (Ctrl + S)",
};

export const kendo = {
  INPUT_MIN_LENGTH: 0,
  INPUT_MAX_LENGTH: 35,
  TASK_INPUT_LENGTH: 80,
  INPUT_SECONDARY_LENGTH: 27,
};

export const notifyIcon = {
  ERROR_ICON: "error",
  SUCCESS_ICON: "success",
  WARNING_ICON: "warning",
};

/**
 * color palette settings.
 */
export const paletteSettings = {
  palette: "metro",
  tileSize: 16,
  palette: "basic",
};

/**
 * language settings.
 */
export const languages = {
  ENGLISH: "en",
};

/** no access object if user doesn't have access of queue */
export const noAccessObject = {
  access: number.ZERO,
};

export const errorCode = {
  CANCEL_REQUEST: "ERR_CANCELED",
};

export const REMINDER_DUE_DATE_OPTIONS = {
  day: "2-digit",
  month: "short",
  year: "numeric",
};

export const text = {
  REGISTERED: "registered",
  OPERATIONAL_TEAM_DROPDOWN: "operationalTeamDropDown",
  INDUSTRY_NAME: "industryName",
  OPERATIONAL_TEAM: "operationalTeam",
  TIMEZONE: "Timezone",
  INDUSTRY: "Industry",
  ORGANIZATION: "organization",
  USER_ID: "User Id",
  SSO_LOGIN: "SSO Login",
  CSV: "csv",
  PDF: "pdf",
  EXCEL: "excel"
};

export const regex = {
  REGISTER_NAME_REGEX: /^[a-zA-Z0-9-.]*$/,
};
export const quickFilters = {
  ALL_TASK: "All Task",
  OWNERSHIP: "Ownership",
  DELEGATED: "Delegated",
  ARCHIVED: "Archived",
  ASSIGNED: "Assigned",
  PENDING_APPROVALS: "Pending Approvals",
};

export const linkedTaskTypes = {
  CHILD_TASKS: "CHILD_TASKS",
  PARENT_TASKS: "PARENT_TASKS",
  RELATED_TASKS: "RELATED_TASKS",
};
