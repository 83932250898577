import { Popup } from '@progress/kendo-react-popup';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTaskOwnerShip } from "../../../../actions/taskSidebar";
import { label, number } from "../../../../config";
import { usePopup } from "../../../../helper/commonHooks";
import OwnerShipContainer from "./OwnerShipContainer";
import { TaskOwnerContextProvider } from "./taskOwner.Context";
import "./taskOwnership.scss";

/**
* TaskOwnerShipPopup
* Parent component => HeaderSubtitle
* @author Shivam Mishra
*/
const TaskOwnerShipPopup = ({ anchor }) => {
    const dispatch = useDispatch()
    const { task } = useSelector((state) => state.taskSidebar);
    const {ThroughEmail} = task;
    const { tasks } = useSelector((state) => state);
    const { contentRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup()
    const creatorName = tasks?.defaultDetails?.assigneeList.find(user => user.value === task.CreatedBy);
    const [showDetailedOwnerShip, setshowDetailedOwnerShip] = useState(false);


    const hideOnBlur = () => {
        setshowDetailedOwnerShip(!showDetailedOwnerShip);
        dispatch(toggleTaskOwnerShip(false))
    };

    return (
        <>

            <Popup
                onOpen={() => onOpen(contentRef)}
                popupClass={'ownership-popup'}
                anchor={anchor.current}
                animate={false}
                show={true}
            >
                <div
                    ref={contentRef}
                    title="task-ownership"
                    tabIndex={number.ZERO}
                    onFocus={() => onFocus(blurTimeoutRef)}
                    onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
                >
                    <div className="p-2">
                      {ThroughEmail ? label.AUTOMATION_TASK : !!creatorName?.label ? label.CREATOR + ": " + creatorName?.label : label.CREATED_USING_EMAIL}
                        <div className="d-none">
                            <TaskOwnerContextProvider>
                                <OwnerShipContainer />
                            </TaskOwnerContextProvider>
                        </div>
                    </div>
                </div>
            </Popup >

        </>
    );
};

export default React.memo(TaskOwnerShipPopup);
