import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setShowTaskSidebar, setTaskOnBlur, toggleTaskOwnerShip } from "../../../actions/taskSidebar";
import { icon, label, number, quote, route, tooltip } from "../../../config";
import config from "../../../env.config";
import { getTaskUrl, popupAlign } from "../../../helper/common";
import { usePopup } from "../../../helper/commonHooks";
import Bookmark from "../../../shared/components/Bookmark/Bookmark";
import { copyTextToClipboard, copyTextWithLink, isTrueBit, sanitizeUrl } from "../../../utils/common";
import { onBlur, onFocus, onOpen } from "../../../utils/kendo";
import { updateBookmark } from "../../Bookmarks/bookmarks.service";
import { getTasks, handleTaskKeyUpdate } from "../../Tasks/tasks.service";
import TaskOwnerShipPopup from "../TaskSidebarContent/TaskOwnerShip/TaskOwnerShipPopup";
import { getCurrentActiveProject } from "../sidebar.common";
import { isUserPartOfProject, isUserPartOfTaskFlow } from "../sidebar.helper";
import { getDefaultProject, taskAction } from "./TaskSidebarHeader.helper";

/**
 * HeaderSubtitle component handles assignee logic and task related action logic
 * @props {task, defaultDetails, isNewTask, auth}
 */
const HeaderSubtitle = ({ defaultDetails, auth, currentFlow, breadcrumbs }) => {
  const { isNewTask, task, expanded, showTaskOwnerShip } = useSelector((state) => state.taskSidebar);
  const { ThroughEmail } = task;
  const { ProjectTaskId, Name } = task;
  const [, setTaskProjectId] = useState();
  const [pageParams, setPageParams] = useState();
  const [isDisabled, setIsDisabled] = useState(task ? isTrueBit(task, "IsLocked") : false);
  const [isApiInProgress, setIsApiInProgress] = useState(false);
  const [isCompleted, setIsCompleted] = useState(isTrueBit(task, "IsCompleted"));
  const [showConfirmReopen, setShowConfirmReopen] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [taskUrl, setTaskUrl] = useState("");
  const [isFlowLocked, setIsFlowLocked] = useState(false);
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);

  const { assigneeList } = useSelector((state) => state.tasks.defaultDetails);
  const assignedBy = useMemo(() => {
    return task?.Assignee === task?.AssignedBy ? null : assigneeList?.find((user) => user.value === task?.AssignedBy);
  }, [task?.Assignee, task?.AssignedBy, assigneeList]);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const deleteRef = React.useRef(null);
  const blurTimeoutRef = React.useRef(null);
  const taskOwnerRef = React.useRef(null);
  const reopenAnchor = React.useRef();
  const shareIconAnchor = React.useRef();
  const showPopupRef = React.useRef(null);
  const blurShowPopupRef = React.useRef(null);
  const deleteReopenRef = React.useRef(null);
  const blurReopenTimeoutRef = React.useRef(null);
  const wrapper = React.useRef(null);
  const { show: dependencyPopup, setShow: setDependencyPopup, anchor } = usePopup();
  const { flowElements } = useSelector((state) => state.taskSidebar);
  const { user } = useSelector((state) => state.auth);
  const anchorAlign = { horizontal: "right", vertical: "bottom" };
  const [loading, setLoading] = useState(false),
    bookmarks = useSelector((state) => state.bookmarks),
    isTaskBookmarked = bookmarks?.taskBookmarks?.find((taskElement) => taskElement.Id === task?.taskId);
  const loggedInUser = useSelector((state) => state.auth.user);
  const { isPlanMyDayActive } = useSelector((state) => state.planMyDay);
  const { tenantAppUrl } = useSelector((state) => state.app);
  useEffect(() => {
    if (matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH })) {
      let { params } = matchPath(pathname, {
        path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH,
      });
      setPageParams(params);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isNewTask && task.taskId) {
      setTaskUrl(sanitizeUrl(getTaskUrl(task.taskId)));
    }
  }, [isNewTask, task.taskId]);

  useEffect(() => {
    const defaultProject = getDefaultProject(defaultDetails);
    setTaskProjectId(pageParams?.projectId ? pageParams?.projectId.toString() : defaultProject);
  }, [pageParams, defaultDetails]);

  useEffect(() => {
    setIsDisabled(task ? isTrueBit(task, "IsLocked") : false);
    setIsCompleted(task ? isTrueBit(task, "IsCompleted") : false);
  }, [task?.taskId, task?.IsLocked, task?.IsCompleted]);

  useEffect(() => {
    setIsFlowLocked(currentFlow?.isFlowLocked ? true : false);
  }, [currentFlow?.isFlowLocked]);

  /**
   * used to call retract,reject and complete apis
   * @param {*} type
   */
  const handleTaskAction = async (type) => {
    setIsApiInProgress(true);
    let response = await taskAction(type, auth, isFlowLocked);
    if (response) {
      // need this later
      // if (type == "accept") {
      //   openSidebar({ id: task.taskId, userId: auth.user.id, entityProjectId: task.EntityProjectId }, null, true)
      //   getTasks(null, true)
      // }
      getTasks(null, true);
      if (!isPlanMyDayActive) {
        dispatch(setShowTaskSidebar({ showTaskSidebar: false }));
      }
      setIsApiInProgress(false);
    }
  };

  /**
   * Handles the complete action based on dependency toggle
   */
  const handleComplete = () => {
    if (task?.IsDependent && !isCompleted && task.inCompleteSubtaskCount + task.inCompleteChildtaskCount) {
      setDependencyPopup(true);
      return;
    }
    if (!isCompleted) {
      setIsCompleted(true);
      handleTaskAction("complete");
    }
  };

  /**
   * handles the mark as complete button text
   */
  const getMarkAsCompleteText = () => {
    if (task?.IsDependent && dependencyPopup && !isCompleted) return label.DEPENDENCY;

    const { currentProject, currentProjectIndex } = getCurrentActiveProject(breadcrumbs);
    if (task.IsTaskComplete) return label.COMPLETED;
    else if ((currentProject?.assignmentProjectId === task?.EntityProjectId || currentProject?.isApproval) && currentProjectIndex === breadcrumbs?.length - number.ONE)
      return !isTrueBit(task, "IsCompleted") && label.MARK_COMPLETE;

    if (currentProject?.assignmentProjectId === task?.EntityProjectId || currentProject?.isApproval) return label.COMPLETE_FORWARD;
    return null;
  };

  /**
   * used to get if the reopen button should be visible or not
   * @returns {Boolean} show reopen button
   * @author {Prachi Jain}
   */
  const getReopenCondition = () => {
    if (isUserPartOfTaskFlow(flowElements, defaultDetails) && expanded?.length !== number.ZERO && !task.isFollowed) return true;
    else return false;
  };

  const hideOnBlur = () => {
    setDependencyPopup(false);
  };

  // handles complete button conditions
  const getCompleteCondition = () => {
    const { currentProjectIndex } = getCurrentActiveProject(breadcrumbs);
    if (
      ((!isDisabled && breadcrumbs[currentProjectIndex]?.id === currentFlow?.id && flowElements?.length && !task.isFollowed && task.hasAccess) || task.IsTaskComplete) &&
      !isTrueBit(task, "InApproval") &&
      expanded?.length !== number.ZERO &&
      !isNewTask
    )
      return true;

    return false;
  };

  // handles retract button conditions
  const getRetractCondition = () => {
    const { currentProjectIndex } = getCurrentActiveProject(breadcrumbs);
    const previousFlow = breadcrumbs[currentProjectIndex - 1];
    if (
      isUserPartOfProject(defaultDetails, { value: previousFlow?.assignmentProjectId }) &&
      !breadcrumbs[currentProjectIndex]?.completedBy &&
      currentProjectIndex !== number.ZERO &&
      !isTrueBit(task, "InApproval") &&
      !task.hasAccess &&
      expanded?.length !== number.ZERO &&
      !task.isFollowed
    )
      return true;

    return false;
  };

  // handles reject button conditions
  const getRejectCondition = () => {
    const { currentProjectIndex } = getCurrentActiveProject(breadcrumbs);
    if (
      !isTrueBit(task, "IsLocked") &&
      currentProjectIndex !== number.ZERO &&
      breadcrumbs[currentProjectIndex]?.id === currentFlow?.id &&
      !isTrueBit(task, "InApproval") &&
      !isTrueBit(task, "IsCompleted") &&
      task.hasAccess &&
      !task.isFollowed &&
      expanded?.length !== number.ZERO
    )
      return true;

    return false;
  };

  const handleHover = () => {
    dispatch(toggleTaskOwnerShip(true));
  };

  /**
   * handles reopen of task
   */
  const handleTaskReopen = () => {
    closeConfirmReopen();
    setIsCompleted(false);
    handleTaskAction("reopen");
  };

  /**
   * closes confirm reopen button
   */
  const closeConfirmReopen = () => {
    setShowConfirmReopen(false);
  };

  /**
   * @author Shivam Mishra
   */
  const handleLeave = () => {
    dispatch(toggleTaskOwnerShip(false));
  };

  /**
   * copies task name and hyperlinked task code
   * @author Muskan Thakur
   */
  const handlesCopyTaskNameAndCode = () => {
    const textToCopied = `: ${Name}`;
    const linkedText = ProjectTaskId;
    const linkURL = (tenantAppUrl ? tenantAppUrl : config.BASE_URL.APP_URL) + taskUrl;
    copyTextWithLink(textToCopied, linkedText, linkURL);
  };

  /**
   * function used for handling click on share icon
   * @author Pragun Gandotra
   */
  const handleClickShareIcon = () => {
    setShowSharePopup(!showSharePopup);
  };

  /**
   * function used for closing sharePopup when clicked outside
   * @author Pragun Gandotra
   */
  const closeCopyLinkPopup = () => {
    setShowSharePopup(false);
  };

  /**
   * function used for copying taskUrl
   * @author Pragun Gandotra
   */
  const copyTaskUrl = () => {
    const fullTaskUrl = (tenantAppUrl ? tenantAppUrl : config.BASE_URL.APP_URL) + taskUrl;
    copyTextToClipboard(fullTaskUrl, quote.COPIED_TO_CLIPBOARD);
  };

  /**
   * The username of the person who has locked the task.
   * @author Himanshu Negi
   */
  const taskLockedBy = useMemo(() => {
    const lockedOwner = defaultDetails?.assigneeList?.find((assignee) => assignee?.value === task?.LockedBy);
    if (lockedOwner?.value === user?.id) return label.YOU;
    return lockedOwner?.label;
  }, [task?.LockedBy]);

  /**
   * Task lock confirmation logic
   * @param {void}
   * @returns {void}
   * @author Himanshu Negi
   */
  const handleLockTask = useCallback(async () => {
    await handleTaskKeyUpdate(isNewTask, "isTaskLocked", number.ZERO);
    dispatch(setTaskOnBlur({ key: "LockedBy", value: null }));
  }, []);

  /**
   * handles complete Action
   * @author Prachi Jain
   */
  const handleCompleteAction = useCallback(() => {
    setIsCompleted(true);
    handleTaskAction("complete");
    setDependencyPopup(false);
  }, []);

  /**
   * show controlled tooltip
   * @author Himanshu Negi
   */
  const showTooltip = useCallback(async () => {
    setOpen(true);
    await setTargetElement(wrapper.current);
  }, [wrapper?.current]);

  /**
   * hide controlled tooltip
   * @author Himanshu Negi
   */
  const hideTooltip = useCallback(async () => {
    setOpen(false);
    await setTargetElement(null);
  }, [wrapper?.current]);

  /**
   * handle flow locked state
   * @author Himanshu Negi
   */
  const handleToggleLock = useCallback(async () => {
    await hideTooltip();
    setIsFlowLocked(!isFlowLocked);
    showTooltip();
  }, [isFlowLocked]);

  /**
   * function to remove bookmark - udpates bookmark value
   *
   * @param {boolean} isTaskBookmarked - Whether the task is bookmarked or not
   * @author Dimple Sahota
   */

  const confirmBookmarking = useCallback(async () => {
    // Logic to remove bookmark
    setLoading(true);
    if (isTaskBookmarked) {
      const payload = { userId: loggedInUser.id, entityType: "Tasks", entityId: task.taskId };
      await dispatch(updateBookmark(payload));
    }
    setLoading(false);
  }, [isTaskBookmarked]);

  return (
    <>
      <Tooltip anchorElement='target' parentTitle={true} position='bottom'>
        {!isNewTask && (
          <div className='task-share-container'>
            <span
              className='subtitle-assignee header-subtitle-task-code'
              id='header-subtitle-task-code-click'
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              ref={taskOwnerRef}
              onClick={handlesCopyTaskNameAndCode}>
              {ProjectTaskId}
            </span>
            <button
              id='task-share-icon-element'
              className='position-relative ml-2 task-share-icon rotate-minus-50 border-0'
              ref={shareIconAnchor}
              onClick={handleClickShareIcon}
              title={label.SHARE_LINK}>
              {icon.LINK_ATTACHMENT}
            </button>
            {!!task?.IsTaskLocked && (
              <>
                <button
                  id='task-locked'
                  title={`${label.TASK_LOCKED_BY} ${taskLockedBy}`}
                  className='task-locked-icon bg-transparent d-flex align-items-center justify-content-center opacity-75'
                  onClick={handleLockTask}>
                  {icon.LOCK}
                </button>
              </>
            )}
            <div className='bookmark-icon ml-1'>{isTaskBookmarked && <Bookmark loading={loading} bookmarkBoolean={isTaskBookmarked} handleBookmarkFunction={confirmBookmarking} />}</div>
            {ThroughEmail ? (
              <span className='assignedby-container text-truncate font-weight-normal ml-4'>{label.AUTOMATION_TASK}</span>
            ) : (
              assignedBy && (
                <span className='assignedby-container text-truncate font-weight-normal ml-3'>
                  {label.ASSIGNED_BY} : {assignedBy?.label}
                </span>
              )
            )}
          </div>
        )}
      </Tooltip>

      <div className='mr-2'>{showTaskOwnerShip && <TaskOwnerShipPopup anchor={taskOwnerRef} />}</div>

      <div className='subtitle-actions d-flex'>
        {!!(currentFlow?.isFlowLocked === number.ONE && !task?.IsTaskLocked && !isTrueBit(task, "InApproval") && !task?.IsTaskComplete) && (
          <Tooltip position='bottom' anchorElement='target' parentTitle={true} open={open} targetElement={targetElement}>
            <button
              id='header-subtitle-task-lock-btn'
              ref={wrapper}
              className={`lock-action ${isFlowLocked ? "text-blue" : "task-black"}`}
              onClick={handleToggleLock}
              title={isFlowLocked ? tooltip.UNLOCK_TASK : tooltip.LOCK_TASK}
              onMouseOver={showTooltip}
              onMouseLeave={hideTooltip}>
              {isFlowLocked ? icon.APPROVAL_LOCK : icon.UNLOCK}
            </button>
          </Tooltip>
        )}
        {getRetractCondition() && (
          <button id='task-sidebar-retract' className='btn btn-warning btn-sm  ml-2 mr-2 text-nowrap' onClick={() => handleTaskAction("retract")} disabled={isApiInProgress}>
            {label.RETRACT}
          </button>
        )}
        {getRejectCondition() && (
          <button id='task-sidebar-reject' className='btn btn-danger btn-sm ml-2 mr-2 text-nowrap' onClick={() => handleTaskAction("reject")} disabled={isApiInProgress}>
            {label.REJECT}
          </button>
        )}

        {!!(task?.IsTaskComplete && !isTrueBit(task, "InApproval")) && getReopenCondition() && (
          <button id='task-sidebar-reopen' className={"btn btn-sm text-nowrap button-warning"} onClick={() => setShowConfirmReopen(!showConfirmReopen)} ref={reopenAnchor}>
            {label.REOPEN_TASK}
          </button>
        )}

        {getCompleteCondition() && (
          <button
            id='task-sidebar-complete'
            className={"btn btn-sm text-nowrap " + (isCompleted ? "btn-success pointer-events-none" : "btn-outline-success cursor-pointer")}
            onClick={handleComplete}
            isDisabled={isApiInProgress}
            ref={anchor}>
            {getMarkAsCompleteText()}
          </button>
        )}

        {task?.IsDependent !== number.ZERO && dependencyPopup && (
          <Popup show={true} anchor={anchor.current} onOpen={() => onOpen(deleteRef)} className='dependency-popup'>
            <div
              className='dependency-popup-inner d-flex align-items-center justify-content-between'
              tabIndex={number.ZERO}
              ref={deleteRef}
              id='dependency-popup'
              onFocus={() => onFocus(blurTimeoutRef)}
              onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}>
              <span className='cursor-pointer red d-flex align-items-center' onClick={hideOnBlur}>
                {icon.CLOSE}
              </span>
              <span id='dependency-popup-tick-icon' className='cursor-pointer green d-flex align-items-center' onClick={handleCompleteAction}>
                {icon.CHECKBOX}
              </span>
            </div>
          </Popup>
        )}

        {showConfirmReopen && (
          <Popup show={true} anchor={reopenAnchor.current} onOpen={() => onOpen(deleteReopenRef)} className='dependency-popup' anchorAlign={anchorAlign} popupAlign={popupAlign("right", "top")}>
            <div
              id='reopen-confirm-popup'
              className='action-popup'
              tabIndex={number.ZERO}
              ref={deleteReopenRef}
              onFocus={() => onFocus(blurReopenTimeoutRef)}
              onBlur={() => onBlur(blurReopenTimeoutRef, closeConfirmReopen)}>
              <button id='reopen-confirm-tick-icon' onClick={handleTaskReopen} className='edit-profile-btn text-left border-0 bg-transparent w-100'>
                {icon.DONE}
              </button>
            </div>
          </Popup>
        )}

        {showSharePopup && (
          <Popup
            show={true}
            anchor={shareIconAnchor.current}
            onOpen={() => onOpen(showPopupRef)}
            popupClass='share-task-links-popup position-relative'
            anchorAlign={anchorAlign}
            popupAlign={popupAlign("center", "top")}>
            <div
              id='share-popup'
              className='popover-item'
              tabIndex={number.ZERO}
              ref={showPopupRef}
              onFocus={() => onFocus(blurShowPopupRef)}
              onBlur={() => onBlur(blurShowPopupRef, closeCopyLinkPopup)}
              onClick={closeCopyLinkPopup}>
              <button id='task-smart-link-option' onClick={handlesCopyTaskNameAndCode}>
                {quote.SMART_LINK}
              </button>
              <button id='task-url-option' onClick={copyTaskUrl}>
                {quote.URL}
              </button>
            </div>
          </Popup>
        )}
      </div>
    </>
  );
};

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
  return {
    auth: state.auth,
    defaultDetails: state.tasks.defaultDetails,
    allTasks: state.tasks.allTasks,
  };
}

export default connect(mapStateToProps, null)(HeaderSubtitle);
