import React, { useState } from "react";
import { useTaskOwnerContext } from "./taskOwner.Context";
import OwnerAssignmentType from "./OwnerAssignmentType";
import OwnerAssignmentId from "./OwnerAssignmentId";
import WorkflowAssignmentType from "./WorkflowAssignmentType";
import OwnerAssignee from "./OwnerAssigee";
import { assignedTypes } from "../../../Tasks/tasks.constants";
import { icon, number } from "../../../../config";

/**
* OwnerShipContainer
* Parent component => TaskOwnerShipPopup
* @author Shivam Mishra
*/
const OwnerShipContainer = () => {
    const { setOwnerAssignmentType} = useTaskOwnerContext()
    const [showDetailedOwnerShip, setshowDetailedOwnerShip] = useState(false);



    const handleDetailedOwnerShip = () => {
        setshowDetailedOwnerShip(!showDetailedOwnerShip);
        setOwnerAssignmentType(assignedTypes.find((i) => i.key === number.TWO));
    };


    return (
        <>
            <div> 
                {/* to be implemented later */}
                {/* <span className="cursor-pointer" onClick={handleDetailedOwnerShip}>
                    {icon.EDIT_WORKSPACE}
                </span> */}
                <br />
                <div >
                    {showDetailedOwnerShip && (
                        <div className="form-row">
                                <OwnerAssignmentType />
                                <OwnerAssignmentId />
                                <WorkflowAssignmentType />
                                <OwnerAssignee />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default React.memo(OwnerShipContainer);
