import React from 'react';
import { useSelector } from 'react-redux';
import { icon, label, number, route } from "../../config";
import { formatDate, getMaxSortId } from "../../helper/common";
import store from '../../store';
import { isTrueBit } from '../../utils';
import { appendString, getFutureDate, splitString } from "../../utils/common";
import { getRenderCondition } from '../TaskSidebar/sidebar.common';

export const dateDiff = (time) => {
    const difference = new Date(time) - new Date()
    const differenceInDays = Math.round((difference) / (number.TWENTY_FOUR * number.SIXTY * number.SIXTY * number.ONE_THOUSAND))
    return differenceInDays < number.ZERO;
};

/**
 * Views for task grids for Tab switch on Tasks page
 */
export const views = [
    {
        label: 'List View',
        selected: true,
        component: 'TaskList'
    },
    {
        text: "Board View",
        selected: true,
        component: "TaskBoard"
    },
    {
        label: 'DashBoard View',
        selected: true,
        component: 'DashBoard'
    }
]


export const viewList = () => {
    const state = store.getState()
    const { selectedView } = state.tasks

    const kendoViewList = [

        {
            text: "List View",
            selected: selectedView === "List View",
            id: 0,
            icon: icon.LIST_VIEW,
            class: "list-view",
        },
        {
            text: "Dashboard View",
            selected: selectedView === "Dashboard View",
            id: 2,
            icon: icon.DASHBOARD_VIEW,
            class: "dashboard-view"
        }
    ];
    return kendoViewList;
};

export const taskHeaders = (projectId) => {
    const state = store.getState();
    const { operationalTeamName } = state.auth.user;
    return [
        projectId ? { label: "Stage", code: "Stage", css: "" } : { label: appendString(operationalTeamName, label.SMALL_S), code: "Projects", css: "" },
        { label: "Task Id", code: "Task Id", css: "" },
        { label: "Task Icons", code: "Task Icons", css: "" },
        { label: "Due Date", code: "Due Date", css: "" },
        { label: "Priority", code: "Priority", css: "" },
        { label: "Assigned By", code: "Assigned By", css: "" },
        projectId ? { label: "Assignee", code: "Assignee", css: "" } : { label: "Stage", code: "Stage", css: "" },
        { label: "Tags", code: "Tags", css: "" }
    ];
}

export const tableHeaders = ["Stage", "Projects", "Task Id", "Task Icons", "Due Date", "Assigned By", "Priority", "Assignee"]

export const taskGridFields = ["ProjectTaskId", "StageName", "date", "PriorityName", "AssigneeName", "TagLabels", "AssignedByName", "Name", "Priority", "StageId", "Projects", "currentStage"]

export const radioOptions = [{
  label: "Excel",
  value: "excel",
},
{
  label: "PDF",
  value: "pdf",
},
{
  label: "CSV",
  value: "csv",
}]

/**
 * returns projectId for renderCondition
 * @param {void}
 * @returns {Number} projectId
 * @author Himanshu Negi
 */
const getProjectId = () => {
    const state = store.getState();
    const { task, isNewTask, newTaskData } = state?.taskSidebar;
    const projectId = isNewTask ? newTaskData?.project : task?.ProjectId;
    return projectId
}

/**
 * Task Sidebar navbar options
 */
export const navbarOptions = [
    {
        label: 'Approval Logs',
        icon: icon.INFO,
        id: 'task-approval',
        section: 'approval',
        render: 'ShowApprovals',
        jumpId: '.0',
        renderCondition: () => {
            const state = store.getState();
            const { task, isNewTask, approvalLogs } = state?.taskSidebar;
            return (!isNewTask && (isTrueBit(task, 'InApproval') || isTrueBit(task, 'IsApproved') || approvalLogs?.length)) ? true : false;
        },
        expandCondition: () => true,
        disableCondition: () => false
    },
    {
        label: () => {
            const state = store.getState();
            const { task } = state?.taskSidebar;
            return isTrueBit(task, "InApproval") && !isTrueBit(task, "IsApproved")  ? label.ACTION : label.DETAILS;

        },
        icon: icon.INFO,
        id: 'task-details',
        section: 'details',
        render: '',
        jumpId: '.1',
        renderCondition: () => true,
        expandCondition: () => true,
        disableCondition: () => false
    },
    {
        label: 'Parent Task Details',
        icon: icon.INFO,
        id: 'parent-task-details',
        section: 'ParentDetails',
        render: 'ParentTaskDetailPanel',
        jumpId: '.2',
        renderCondition: () => {
            const state = store.getState();
            const { task, newTaskData, showLinkedTasksSubTasks } = state?.taskSidebar;
            return showLinkedTasksSubTasks && (task?.parentLinkTaskCount || newTaskData?.parentLinkTaskCount)
        },
        expandCondition: () => {
            const state = store.getState();
            const { task, newTaskData } = state?.taskSidebar;
            return task?.parentLinkTaskCount || newTaskData?.parentLinkTaskCount
        },
        disableCondition: () => false
    },
    {
        label: () => {
            const state = store.getState();
            const { task, isNewTask } = state?.taskSidebar;
            if(isNewTask) return ""
            if(task?.IsTaskLocked && (isTrueBit(task, "IsApproved") && task?.ShowLockedDescription)) return label.APPROVED_DESCRIPTION;
            if(task?.IsTaskLocked) return label.LOCKED_DESC
            if(isTrueBit(task, "IsApproved")) return label.APPROVED_DESCRIPTION;
            return label.MAIN_DESCRIPTION
        },
        icon: icon.DESCRIPTION,
        id: 'task-approval-description',
        section: 'description',
        render: 'ShowApprovalDescription',
        jumpId: '.4',
        renderCondition: () => {
            const state = store.getState();
            const { task, showDescription } = state?.taskSidebar
            return (isTrueBit(task, "IsApproved") || task?.ShowLockedDescription) && showDescription ? true : false;
        },
        expandCondition: () => {
            const state = store.getState();
            const { task } = state?.taskSidebar
            return isTrueBit(task, "IsApproved") || task?.ShowLockedDescription ? true : false;
        },
        disableCondition: () => false
    },
    {
        label: label.DESCRIPTION,
        icon: icon.DESCRIPTION,
        id: 'task-description',
        section: 'description',
        render: 'ShowDescription',
        jumpId: '.3',
        renderCondition: () => {
            const state = store.getState();
            const { showDescription } = state?.taskSidebar;
            return showDescription;
        },
        expandCondition: () => {
            const state = store.getState();
            const { showDescPopup } = state?.taskSidebar;
            return (!showDescPopup?.status) ? true : false
        },
        disableCondition: () => {
            const state = store.getState();
            const { showDescription } = state?.taskSidebar;
            return showDescription ? false : true
        }
    },
    {
        label: 'Attachments',
        icon: icon.ATTACHMENT,
        id: 'task-attachments',
        section: 'attachments',
        render: 'ShowAttachments',
        jumpId: '.5',
        renderCondition: () => {
            const state = store.getState();
            const { showAttachments } = state?.taskSidebar;
            return showAttachments
        },
        expandCondition: () => {
            const state = store.getState();
            const { task, newTaskData } = state?.taskSidebar;
            return task?.attachmentCount || newTaskData?.attachmentCount;
        },
        disableCondition: () => {
            const state = store.getState();
            const { showAttachments } = state?.taskSidebar;
            return showAttachments ? false : true
        }
    },
    {
        label: 'Linked Tasks',
        icon: icon.TREE,
        id: 'task-linked-tasks',
        section: 'linkedTasks',
        render: 'ShowLinkedTasks',
        jumpId: '.6',
        renderCondition: () => {
            const state = store.getState();
            const { showLinkedTasksSubTasks } = state?.taskSidebar;
            return showLinkedTasksSubTasks;
        },
        expandCondition: () => {
            const state = store.getState();
            const { task, newTaskData } = state?.taskSidebar;
            return (task?.linkedTaskCount || task?.subTaskCount || newTaskData?.childOrSubtaskCount || newTaskData?.parentLinkTaskCount) ? true : false;
        },
        disableCondition: () => {
            const state = store.getState();
            const { showLinkedTasksSubTasks } = state?.taskSidebar;
            return showLinkedTasksSubTasks ? false : true
        }
    },
    {
        label: 'Comments',
        icon: icon.COMMENT,
        id: 'task-comments',
        section: 'comments',
        render: 'ShowComments',
        jumpId: '.7',
        renderCondition: () => {
            const state = store.getState();
            const { showComments } = state?.taskSidebar;
            return showComments;
        },
        expandCondition: () => {
            const state = store.getState();
            const { currentFocus } = state?.taskSidebar;
            return (currentFocus === "comments") ? true : false;
        },
        disableCondition: () => {
            const state = store.getState();
            const { showComments } = state?.taskSidebar;
            return showComments ? false : true
        }
    },
    {
        label: 'Work Flow Elements',
        icon: icon.WORKFLOW_ICON,
        id: 'task-workflow',
        section: 'workflow',
        render: 'ShowWorkflow',
        jumpId: '.8',
        renderCondition: (renderKey) => {
            return getRenderCondition(renderKey);
        },
        expandCondition: () => false,
        disableCondition: () => false
    },
    {
        label: 'Activity Log',
        icon: icon.HISTORY,
        id: 'task-activity-log',
        section: 'activityLog',
        render: 'ShowActivity',
        jumpId: '.9',
        renderCondition: (renderKey) => {
            return getRenderCondition(renderKey);
        },
        expandCondition: () => false,
        disableCondition: () => false
    }
]

/**
 * Default/initial task details for new Task
 */
export const taskDetails = {
    assignedId: null,
    assignedType: 2,
    assignee: null,
    creator: null,
    dueDate: getFutureDate(number.THREE),
    isFollowed: false,
    isReturnable: true,
    name: '',
    parentTaskId: null,
    priority: 2,
    project: null,
    stageId: null,
    tags: '',
}

/**
 * Fields Enabled for changed on Locked status
 */
export const enabledFields = ["name", "priority", "dueDate", "tags"]

/**
 * Assignee Types for Tasks
 */
export const assignedTypes = [

    {
        label: 'Project',
        key: 3,
        icon: icon.DVR
    },
    {
        label: 'User',
        key: 2,
        icon: icon.TASK_PERSON,
    },
    // {
    //     label: 'Team',
    //     key: 7,
    //     icon: icon.GROUPS
    // }
]

/**
 * Get text width in pixel unit.
 * @param {String} text
 * @returns text width in pixel
 */
const getTextWidth = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    return context.measureText(text).width;
}

/**
 * gives labels and colors of tags for the given ids
 * @param {String} tagIds
 * @returns {Object}
 * @author {Prachi Jain}
 */
export const getTagLabelAndColor = (tagIds) => {
    let state = store.getState();
    let defaultDetails = state.tasks.defaultDetails
    const tags = splitString(tagIds, ',')
    const tagInfo = tags?.map(id => {
        const tag = defaultDetails?.tagList?.find(t => t.value == id);
        const tagsLabel = tag?.label?.length > number.TWENTY_TWO ? tag?.label?.substring(number.ZERO, number.TWENTY_TWO) + "..." : tag?.label
        return { label: tagsLabel, color: tag?.color };
    });

    const labels = tagInfo?.map(info => info.label) || [];
    const colors = tagInfo?.map(info => info.color) || [];
    return { labels, colors };
}

/**
 * Creates JSX using tag label and tag color for task grid
 * @param {String} value
 * @param {Object} task
 * @returns tag JSX list
 */
export const formatTags = (value, task, cellWidth) => {
    let { labels, colors } = getTagLabelAndColor(value),
        valueLength = getTextWidth(labels);
    let length;
    for (let i = labels?.length; i > number.ZERO; i--) {
        valueLength = getTextWidth(labels?.slice(0, i).toString());
        if (valueLength <= cellWidth) {
            length = i
            break;
        }
    }
    if (valueLength < cellWidth) {
        return labels?.slice(number.ZERO, length)?.map((v, i) => getTagElement([v], colors[i], labels?.toString(), i, cellWidth))
    }
    else {
        return labels?.slice(number.ZERO, number.THREE)?.map((v, i) => getTagElement([v], colors[i], labels?.toString(), i))
    }
}




/**
 * JSX element span for tag
 * @param {String} name
 * @param {String} color
 * @returns JSX
 */
const getTagElement = (name, color, value, index, cellWidth) => {
    let valueLength = getTextWidth(value);
    if (valueLength < cellWidth) {
        return (<span className="badge tags-badge font-weight-bold mr-1" style={{ border: `2px solid ${color}`, color: color }} id={index} key={index}>{name}</span>);
    } else {
        return (<span className="badge tags-badge font-weight-bold mr-1" style={{ border: `2px solid ${color}`, color: color }} id={index} key={index}
            data-toggle="tooltip" data-placement="bottom" title={value} >{name}</span>);
    }
}


/**
 * Task key for filtering tasks in grid
 */
export const tableKey = 'StageId'



/**
 * Table grid config for Projects grid
 */
export const bodyConfig = [
    {
        label: <b>To Do</b>,
        icon: icon.CHECK,
        tableView: true,
        key: number.ONE
    },
    {
        label: <b>In Progress</b>,
        icon: icon.CHECK,
        tableView: true,
        key: number.TWO
    },
    {
        label: <b>Completed</b>,
        icon: icon.CHECK,
        tableView: true,
        key: number.THREE
    },
    {
        label: <b>Backlog</b>,
        icon: icon.CHECK,
        tableView: false,
        key: ''
    },
    {
        label: <b>Working</b>,
        icon: icon.CHECK,
        tableView: false,
        key: ''
    }
]

export const reminderPayload = {
    title: "",
    start: new Date(),
    end: getFutureDate(number.ONE_THOUSAND),
}


export const commentEmojiLimit = number.TEN

export const CellRender = (props) => {
    let groups = props.lastGroup && splitString(props.lastGroup, ",")
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    let children;
    let mainField = props?.originalProps?.dataItem?.field;
    if (props?.originalProps?.rowType == 'groupHeader' && props?.originalProps?.field == "value") {
        let arrow = props?.td?.props?.children?.props?.children[number.ZERO];
        let header = props?.td?.props?.children?.props?.children[number.ONE]
        children = (
            !props.archiveView && <span>
                {arrow}
                {mainField == "StageId" && header && `Stage ${header}`}
                {mainField === "Stages" && getMaxSortId(props?.defaultDetails?.stagesConfig, "SortId") + 1 == header ? label.DELEGATED : (mainField === "Stages" && props?.defaultDetails?.stagesConfig?.find(s => s.SortId == header && s.tableView)?.label)}
                {mainField === "Priority" && props?.defaultDetails?.priorityList?.find(s => s.value == header)?.label}
                {mainField == "date" && header === "" ? label.NO_DUE_DATE : null}
                {mainField == "date" && header && formatDate(header)}
                {mainField == "AssigneeName" && header === null ? label.UNASSIGNED : null}
                {(mainField == "AssigneeName" && header) && header}
                {mainField == "AssignedByName" && header === null ? label.UNASSIGNED : null}
                {(mainField == "AssignedByName" && header) && header}
                {(mainField == "Projects" || mainField == "ProjectTaskId" || mainField == "Name" || mainField == "tagLabels") && header}
                {/* {props.projectId && groups && groups[0] == "Stages" && mainField == "Stages" && !props.archiveView && <AddTask {...props} stageId={props?.defaultDetails?.stagesConfig?.find(s => s.SortId == header && s.tableView)?.value} />} */}
            </span>
        );
        return React.cloneElement(props.td, props.td.props, children);
    }
    const additionalProps =
    {
        onClick: () => { props.enterEdit(dataItem, cellField); },
    };
    const clonedProps = { ...props.td.props, ...additionalProps };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

export const RowRender = (props) => {
    const { task } = useSelector((state) => state.taskSidebar)
    const rowProjectTaskId = props?.tr?.props?.children[0]?.props?.dataItem?.Id;
    let selectedRow;
    if (props?.originalProps?.from === label?.REMINDERS) selectedRow = props?.tr?.props?.children[0]?.props?.dataItem?.Id === props?.originalProps?.navigatedReminder;
    else selectedRow = task?.taskId && rowProjectTaskId === task?.taskId;
    const { dataItem, rowType } = props.originalProps;
    if (rowType !== "groupHeader") {
        const trProps = {
            ...props?.tr?.props,
            onBlur: () => props.exitEdit(),
            onMouseEnter: () => props.setHover({ id: dataItem.Id }),
            onMouseLeave: () => props.setHover({ id: number.ZERO }),
            className: selectedRow ? "active-bg" : {}
        };
        return React.cloneElement(props.tr, { ...trProps }, props?.tr?.props?.children);
    }
    else {
        const trProps = {
            ...props?.tr?.props,
            onMouseEnter: () => props.setHover({ id: number.ZERO }),
            onMouseLeave: () => props.setHover({ id: number.ZERO }),
        };
        return React.cloneElement(props.tr, { ...trProps }, props?.tr?.props?.children[0])
    }
};

/**
 * handles font color of editor
 * @params (string , style)
 * return void
*/
export const toggleThemeFont = (currentTheme, themeStyle) => {
    if (currentTheme == 'light') { themeStyle.innerHTML = ''; }
    else if (currentTheme == 'dark') { themeStyle.innerHTML = 'div.k-content { color: white; } table  {color: white}' }
}


export const taskCardFilters = {
    ALL_TASKS_FILTER: "allTasks",
    ASSIGNED_FILTER: "assigned",
    DELEGATED_FILTER: "delegated",
    FOLLOWED_FILTER: "followed",
    CREATED_FILTER: "created",
    ARCHIVED_FILTER: "archived",
    APPROVAL_FILTER: "approvals",
}

export const TASK_PAGE_REGEX = /\/tasks\/(\d+)\/(.+)/g;
