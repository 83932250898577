import React, { useCallback, useEffect, useMemo } from 'react';
import { editorIcon, toolbarList } from './editor.helper';
import {
  Editor,
  EditorUtils,
  ProseMirror
} from '@progress/kendo-react-editor';
import { editorMarks, handleAnchorClick, handleKeydown, handleLinkHover, handleSmartChipClick, iframe, inputRule, keymap, nonEditable } from './util';
import "./editor.scss";
import { number } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultTaskValues } from '../components/Tasks/tasks.service';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';
import { EditorStateProvider, useEditorContext } from './editor.Context';
import EditorPopup from './editorPopup/EditorPopup';
import { insertImagePlugin } from './upload-image/insertImagePlugin' ; 
import { insertImageFiles } from './upload-image/upload-image-util';
import { Tooltip } from '@progress/kendo-react-tooltip';

const KendoEditor = (props) => {
  return (
    <EditorStateProvider>
      <KendoEditorContent {...props} />
    </EditorStateProvider>
  );
};


const { EditorState, EditorView, Schema } = ProseMirror;
const { imageResizing, tableResizing , pasteCleanup ,  sanitize, replaceImageSourcesFromRtf } = EditorUtils;

// Settings for pasting
const pasteSettings = {
  stripTags: 'span',
};


const KendoEditorContent = ({
  desc, setDesc, viewRef, toggleEditable, autoSaveKey, setUpdateAutoSaveKey, toolbar,
  editableRef, editorRef, editorEditIcon, hidePreview , acknowledgeMailEditor, showIconAtTop }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { mode } = useSelector((state) => state.taskSidebar.task);
  const { user } = useSelector((state) => state.auth);
  const editorState = useEditorContext();
  const { visible, setVisible, setUrl, setSelectedLink,
    taskInfo, setTaskInfo, cancelTokenRef, setInputPopup,
    smartChipTarget, setChipTarget, prevKeyRef, modeRef,
    setTaskSearchPopup, setEditChip, setShowEditChip,
    setSmartChipId, openImage, setOpenImage, overallSearchRef ,  
    setTempChipId , tempChipID , setEditFrame , setEditFrameId 
  } = editorState;
  const editIcon = useMemo(() => {
    return editorIcon.find(i => i.id === editorEditIcon);
  }, [editorIcon, editorEditIcon]);
  const showEditIframe = modeRef.current === number.ONE || modeRef.current === number.THREE || !editableRef.current ;


  /**
 * Hook to dispatch an action to get default task values if not added already.
 * @author Shivam Mishra
 */
  useEffect(() => {
    if (!defaultDetails.isAdded) {
      dispatch(getDefaultTaskValues(user.companyId, user.id));
    }
  }, [user.companyId, user.id]);

  /**
* Hook to update editor mode.
* @author Shivam Mishra
*/
  useEffect(() => { modeRef.current = mode }, [mode])

  /**
 * Hook to update the editor's content editable state.
 * Sets 'contenteditable' attribute of the editor's content element.
 * @author Shivam Mishra
 */
  useEffect(() => {
    editorRef?.current?._contentElement?.setAttribute('contenteditable', editableRef.current)
  }, [editableRef.current])


  /**
 * Callback function to handle keydown events.
 * @param {EditorView} view - The editor view.
 * @param {KeyboardEvent} domEvent - The DOM event.
 * @author Shivam Mishra
 */
  const handleKeydownCallback = useCallback(
    (domEvent) => {
      // stops propagation of up and down arrow key to stop navigation . 
      if (domEvent.key === 'ArrowUp' ||  domEvent.key === 'ArrowDown')  domEvent.stopPropagation();
      // prevent default behaviour when editor is read only or update chip only . 
      if (modeRef.current === number.ONE || modeRef.current === number.THREE || !editableRef.current) {
        domEvent.preventDefault()
        return
      }
      handleKeydown(domEvent, setInputPopup, insertNonEditable, user.id, setChipTarget, prevKeyRef, smartChipTarget, setTaskSearchPopup, overallSearchRef , setTempChipId)
    },
    [setInputPopup, prevKeyRef.current]
  );


  /**
 * Callback function to handle image insertion.
 * @param {Object} args - The arguments object.
 * @author Shivam Mishra
 */

  /**
 * Callback function to create input rules for formatting nodes.
 * @param {Object} nodes - The nodes object.
 * @author Shivam Mishra
 */
  const inputRuleCallback = useCallback(
    (nodes) => inputRule(nodes),
    []
  );

  /**
  * handles open and closing of image modal
  * @author Shivam Mishra
  */
  const toggleDialogs = () => {
    setOpenImage(!openImage)
  }

  /**
  * useCallback function to handle click event.
  * @param {MouseEvent} event - The click event.
  * @author Shivam Mishra
  */
  const handleClickCallback = useCallback((_, event) => {
    !editableRef.current && handleAnchorClick(event, editableRef, dispatch, history);
    if (modeRef.current === number.ONE || !editableRef.current) {
      event.preventDefault();
      return
    }
    handleSmartChipClick(event, editableRef, setShowEditChip, setSmartChipId, setEditChip);
  }, []);


  /**
* useCallback function to handle mouseover event on anchor elements.
* This function checks if the target is an anchor element, extracts the "tId" parameter from its href attribute,
* fetches task details using the "tId" if available, and updates the component state accordingly.
* @param {Event} _ - The event object (not used in the function logic).
* @param {MouseEvent} domEvent - The mouseover event object.
* @author Shivam Mishra
*/
  const onMouseOver = useCallback(async (_, domEvent) => {
    const { target } = domEvent;
    handleLinkHover(target, cancelTokenRef, setSelectedLink, setUrl, setVisible, editableRef, setTaskInfo ,setEditFrame , setEditFrameId );
  }, [visible, taskInfo]);

  const onImageInsert = args => {
    const {
      files,
      view,
      event
    } = args;
    const nodeType = view.state.schema.nodes.image;
    const position = event.type === 'drop' ? view.posAtCoords({
      left: event.clientX,
      top: event.clientY
    }) : null;
    insertImageFiles({
      view,
      files,
      nodeType,
      position
    });
    return files.length > 0;
  };


  /**
 * Function to mount the editor view.
 * This function initializes the editor view with the provided state and plugins,
 * and attaches event listeners for keyboard events and picture opening.
 * @param {Object} event - The event object containing view properties.
 * @param {EditorState} event.viewProps.state - The state of the editor view.
 * @returns {EditorView} - Returns the mounted editor view.
 * @author Shivam Mishra
 */
  const onMount = (event) => {
    const state = event.viewProps.state;

    let { doc, schema } = state;
    const paragraph = {
      ...schema.spec.nodes.get('paragraph'),
    };
    paragraph.attrs = paragraph.attrs || {};
    paragraph.attrs['dir'] = {
      default: null,
    };
    let nodes = schema.spec.nodes.update('paragraph', paragraph);
    nodes = schema.spec.nodes.addToEnd('nonEditable', nonEditable).addToEnd('iframe', iframe);
    const mySchema = new Schema({ nodes: nodes, marks: editorMarks });
    doc = EditorUtils.createDocument(mySchema, desc);

    const plugins = [...state.plugins,
    imageResizing(),
    inputRuleCallback(mySchema.nodes),
    insertImagePlugin(onImageInsert) , 
      keymap
    ];

    viewRef.current = new EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        handleDOMEvents: {
          ...(event.viewProps.handleDOMEvents || {}),
          keydown: (_, domEvent) => handleKeydownCallback(domEvent),
          click: (view, domEvent) => handleClickCallback(view, domEvent),
          mouseover: (view, domEvent) => onMouseOver(view, domEvent),
        },
        state: EditorState.create({
          doc,
          plugins: [
            ...tableResizing(),
            ...plugins],
        }),
      }
    );
    return viewRef.current;
  };

    /**
   * function to update a non-editable node with specified text and ID into the editor.
   * @param {string} text - The text content of the non-editable node.
   * @param {string} id - The ID of the non-editable node.
   * @author Shivam Mishra
   */
  const updateNonEditable = (text , newNodeId , chipClass = '') => {
    if (editorRef.current) {
      const { view } = editorRef.current.state;
      const { state, dispatch } = view;
      const { schema, doc, tr } = state;

      // Traverse through all the nodes in the document
      doc.descendants((node, pos) => {
        if (node.type.name === 'nonEditable' && node.attrs.id === tempChipID) {
          // Create a new node with updated attributes or content
          const updatedNode = schema.nodes.nonEditable.create(
            {
              ...node.attrs,
              id: newNodeId , 
              class: chipClass, 
            },
            schema.text(text) 
          );

          // Replace the old node with the new node
          const transaction = tr.replaceWith(
            pos,
            pos + node.nodeSize,
            updatedNode
          );
          dispatch(transaction);
        }
      });

      view.focus();
      setInputPopup(false);
    }
  };

  /**
   * useCallback function to insert a non-editable node with specified text and ID into the editor.
   * @param {string} text - The text content of the non-editable node.
   * @param {string} id - The ID of the non-editable node.
   * @author Shivam Mishra
   */
  const insertNonEditable = useCallback((text, id ,  chipClass = 'shortcode') => {
    const { view } = editorRef.current.state;
    const schema = view.state.schema;

    // Get the new node from the schema
    const nodeType = schema.nodes.nonEditable;

    // Create a new node with the selected text
    const node = nodeType.createAndFill(
      { class: chipClass, id: id },
      schema.text(text)
    );

    // Insert the new node
    EditorUtils.insertNode(view, node);
    view.focus();
    setInputPopup(false);

    return true ; 
  }, []);

  /**
   * Debounced update task api call
   * @param {Callback}  
   * @returns {Void}
   * @authpr Shivam Mishra
   */
  const debouncedSave = useCallback(_.debounce(() => {
    setUpdateAutoSaveKey(prevKey => prevKey + number.ONE);
  }, number.FIVE_HUNDRED), [autoSaveKey]);

  /**
   * useCallback function to handle change event.
   * @param {Object} event - The change event object.
   * @authpr Shivam Mishra
   */
  const handleChange = useCallback((event) => {
    const nextValue = event.html;
    setDesc(nextValue);
    autoSaveKey && debouncedSave();
  }, []);

  const handleWrapperClick = (ev) => {
    const tr = editorRef.current.state.view.state.tr;

    const textBeforeCursor = tr.doc.textBetween(
      tr.selection.from - 1,
      tr.selection.from
    );

    prevKeyRef.current = textBeforeCursor;

  };

  /**
* urlify text on enter key press
* Params {*} event
* @author Shivam Mishra 
*/
  const onKeyDown = useCallback((ev) => {
    if (ev.key === 'Enter') {
      if (document.getElementById("k-editor-link-url") && document.getElementById("k-editor-link-text")) {
        ev.preventDefault();
        const insertButton = document.querySelector('.k-button-solid-primary');
        if (insertButton) {
          insertButton.click();
        }
      }
    }

    if (ev.key === 'Backspace'){
      handleWrapperClick() ; 
    }
  }, []);

  /**
 * Handle paste HTML event to cleanup and sanitize HTML content.
 * @param {object} event - The event object containing pasted HTML content.
 * @returns {string} - Sanitized HTML content.
 * @author Shivam Mishra
 */
  const handlePasteHtml = React.useCallback((event) => {
    let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
  
    if (event.nativeEvent.clipboardData) {
      html = replaceImageSourcesFromRtf(
        html,
        event.nativeEvent.clipboardData
      );
    }
    return html;
  }, []);

  return (
    <div className="dt-kendo-editor position-relative overflow-auto">
      {
      <Tooltip anchorElement="target" parentTitle={true} position="bottom">
        <div onClick={handleWrapperClick} onKeyDown={onKeyDown}>
          <Editor
            tools={editableRef.current ? toolbarList(toolbar) : []}
            onChange={handleChange}
            defaultEditMode="div"
            value={desc ?? ""}
            onMount={onMount}
            ref={editorRef}
            onPasteHtml={(event) => handlePasteHtml(event)}
            className={(!editableRef.current || (mode && (mode !== number.TWO))) ? "disable-toolbar" : ""}
          />
        </div>
         </Tooltip>}
      {!hidePreview &&
        <EditorPopup
          insertNonEditable={insertNonEditable}
          updateNonEditable={updateNonEditable}
          editorRef={editorRef}
          toggleDialogs={toggleDialogs}
          editableRef={editableRef}
          acknowledgeMailEditor  = {acknowledgeMailEditor}
          showEditIframe = {showEditIframe}
        />
      }
      {<div className={`${showIconAtTop ? 'button-align-top':''} position-absolute button-align`}>
        <button className={`${editIcon?.class} description-button mb-2 mr-2 p-0 btn rounded-circle`} onClick={toggleEditable}>{editIcon?.icon}</button>
      </div>}
    </div>
  );
};

export default React.memo(KendoEditor);