import { EditorTools } from '@progress/kendo-react-editor';
import { icon, paletteSettings } from '../config/constants';
import { addMinutes, format, isValid, parse } from 'date-fns';
import { editorToolType } from './editor.Constant';
import EditorScriptTool from './editorTools/EditorScriptTool';
import GroupedTools from './editorTools/GroupedTools';
import EditorPrintPdfTool from './editorTools/EditorPrintPdfTool';
import EditorTableTools from './editorTools/EditorTableTools';
import EditorAlignmentTool from './editorTools/EditorAlignmentTool';
import EditorHyperLinkTool from './editorTools/EditorHyperLinkTool';
import GroupedInsertMedia from './editorTools/GroupedInsertMedia';
import { InsertImage } from './upload-image/insertImageTool';

export const HREF_REGEX = /href\s*=\s*(['"])(https?:\/\/.+?)\1/g;

export const SMART_CHIP_REGEX = /^ss_\d+_\d+_\w+_\w+$/;

export const TASK_CHIP_REGEX = /task_(\d+)/;

export const ANCHOR_TAG_REGEX = /<a\s+[^>]*href\s*=\s*(['"])(https?:\/\/[^\/]+)\/?(.*?)\1[^>]*>(.*?)<\/a>/g;

export const NON_EDITABLE_REGEX = /^ss[\w-]*$/;

export const TARGET_BLANK_REGEX = /target\s*=\s*(['"])_blank\1/;

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  NumberedList,
  BulletedList,
  Undo,
  Redo,
  Link,
  Unlink,
  TableProperties, TableCellProperties,
  MergeCells,
  SplitCell,
  ForeColor,
  BackColor,
  CleanFormatting
} = EditorTools;

const CustomForeColor = props => (
  <ForeColor {...props} colorPickerProps={{  title: 'ForeColorTitle', view: 'palette', icon: 'foreground-color', paletteSettings: paletteSettings, defaultValue: "green" }} />
);
const CustomBackColor = props => (
  <BackColor {...props} colorPickerProps={{  title: 'BackColorTitle', view: 'palette', icon: 'background', paletteSettings: paletteSettings, defaultValue: "blue" }} />
);


/**
 * Array containing tools for the task sidebar editor.
 * These tools are used for text formatting, alignment, lists, undo/redo, links, images, viewing HTML, and cleaning formatting.
 */
export const tools = [
  Bold, Italic, Underline, Strikethrough,
  EditorScriptTool ,
  EditorAlignmentTool , 
  Indent, Outdent,
  NumberedList,BulletedList,
  Undo, Redo,
  EditorHyperLinkTool, GroupedInsertMedia ,  GroupedTools , 
  EditorTableTools ,  TableProperties, TableCellProperties,
  MergeCells, SplitCell, CustomForeColor, CustomBackColor, EditorPrintPdfTool
];

/**
 * Array containing tools for the task sidebar editor.
 * These tools are used for text formatting, alignment, lists, undo/redo, links, images, viewing HTML, and cleaning formatting.
 */
export const taskSidebartools = [
  [CustomForeColor, CustomBackColor, CleanFormatting, AlignLeft, AlignCenter, AlignRight, AlignJustify, OrderedList, UnorderedList, Indent, Outdent, Undo, Redo]
];

/**
* provides toolbar for Editor
* @params {String}
* @author Shivam Mishra
*/
export const toolbarList = (tool) => {
  if (tool === editorToolType.TASK_SIDEBAR_TOOL) { return [...taskSidebartools] }
  else if (tool === editorToolType.TASK_POPUP_TOOL) { return [...tools] }
  else return tools
}



/**
 * Function to generate a unique ID based on provided parameters.
 * @param {number} userID - The user ID.
 * @param {string} smartChipType - The type of smart chip.
 * @param {string} selectedDetails - The selected details related to the smart chip.
 * @returns {string} - Returns a unique ID.
 * @author Shivam Mishra
 */
export const generateUniqueId = (userID, smartChipType, selectedDetails) => {
  const timestamp = Date.now();
  const uniqueId = `ss_${userID}_${timestamp}_${smartChipType}_${selectedDetails}`;
  return uniqueId;
};

/**
 * Function to generate an array representing time slots in 15-minute intervals.
 * @returns {string[]} - Returns an array of time slots formatted as HH:mm.
 * @author Shivam Mishra
 */
export function generateTimeArray() {
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0); // Set the start time to midnight

  return Array.from({ length: 96 }, (_, index) => {
    const time = addMinutes(startTime, index * 15);
    const formattedTime = format(time, 'hh:mm a');
    return formattedTime;
  });
}

/**
 * Function to format a date.
 * @param {Date} date - The date to format.
 * @returns {string} - Returns the formatted date.
 * @author Shivam Mishra
 */

export const formatDate = (date) => {
  if (date === null) return "No due date" ; 
  const parsedDate = new Date(date);

  // Check if the parsed date is valid
  if (!isValid(parsedDate)) {
    return ;
  }
  const formattedDate = format(new Date(date), 'd MMM yy');
  return formattedDate;
};

/**
 * Function to format a time string.
 * @param {string} time - The time string to format (in HH:mm format).
 * @returns {string} - Returns the formatted time string (in hh:mm AM/PM format).
 * @author Shivam Mishra
 */
export const formatTime = (time) => {
  const [hours, minutes] = time.split(':');
  const formattedTime = format(new Date().setHours(hours, minutes), 'hh:mm aa');
  return formattedTime;
};

/**
 * Function to format a date and time.
 * @param {Date} date - The date to format.
 * @param {string} time - The time string to format (in HH:mm format).
 * @returns {string} - Returns the formatted date and time string.
 * @author Shivam Mishra
 */
export const formatDateTime = (date, time) => {
  const formattedDate = formatDate(date);
  const formattedTime = formatTime(time);
  return `${formattedDate} ${formattedTime}`;
};

// created for front end implementation going to remove in future 
export const arrayOfObjects = [
  { id: 1, value: ['Tokyo', 'Rio', 'Denver', 'StockHolm', 'Delhi'] },
  { id: 2, value: ['India', 'UAE', 'RSA', 'Italy', 'Japan'] },
  { id: 3, value: ['Asia', 'Africa', 'Australia', 'Europe', 'North & South America'] }
];

/**
 * Function to format a date and time into 24 hour format .
 * @param {string} time - The time string to format (in HH:mm format).
 * @returns {string} - Returns the formatted date and time string.
 * @author Shivam Mishra
 */
export function convertTo24HourFormat(time) {
  const date = parse(time, 'h:mm a', new Date());
  
  if (!isValid(date)) {
    return format(new Date(), 'HH:mm');
  }
  
  return format(date, 'HH:mm');
}

/**
 * Function to check if an event listener is attached to an element.
 * @param {HTMLElement} element - The element to check for event listener.
 * @param {string} eventName - The name of the event.
 * @param {Function} handler - The event handler function.
 * @returns {boolean} - Returns true if the element has the specified event listener, false otherwise.
 * @author Shivam Mishra
 */
export const hasEventListener = (element, eventName, handler) => {
  return (
    element &&
    element._eventListeners &&
    element._eventListeners[eventName] &&
    element._eventListeners[eventName].some((listener) => listener === handler)
  );
};

/**
 * Function to parse the inputDateString into a Date object and format it into a time string.
 * @param {string} inputDateString - The string representing the date and time in the format 'MMM dd, yyyy hh:mm a'.
 * @returns {[Date, string]} - Returns an array containing the parsed Date object and the formatted time string.
 * @author Shivam Mishra
 */
export function getDateAndTime(inputDateString) {
  const parsedDate = parse(inputDateString, "dd MMM yy", new Date());
  const dateObject = new Date(parsedDate);
  return dateObject;
}

export const editorIcon = [
  {
    id: 1,
    class: "description-close-button",
    icon: icon.CLOSE_EDITOR
  },
  {
    id: 2,
    class: "description-edit-button",
    icon: icon.EDIT_DESCRIPTION
  },
  {
    id: 3,
    class: "description-tick-button",
    icon: icon.SAVE_MARK
  },
  {
    id: 4,
    class: "d-none",
    icon: icon.SAVE_MARK
  },
]

/**
* Function to search users based on a keyword.
* @param {string} keyword - The keyword to search for.
* @returns {Array<Object>} - The array of users matching the keyword.
* @author Shivam Mishra
*/
export function searchUsers(keyword, defaultDetails , assigneeList) {
  keyword = keyword.toLowerCase();
  if (!keyword.length) return assigneeList

  return defaultDetails?.assigneeList?.filter(assignee =>
    assignee.label.toLowerCase().includes(keyword) ||
    assignee.Email.toLowerCase().includes(keyword)
  );
}