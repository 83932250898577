import { format, addDays, intervalToDuration, differenceInWeeks, getDay } from 'date-fns'
import { number } from '../../../config';
import { isDateValid } from '../../../shared/validators/validator';
import store from '../../../store';
import { accessBasedOnProjectMembers } from '../../../helper/common';

export const getRecurrTitle = (recur, duration, RemainingRecurrence, EndDate, FrequencyDayDate) => {
  let validateEndDate = EndDate && isDateValid(EndDate) && new Date(EndDate)

  switch (true) {
      //Gets Title for daily recurrence
      case ((recur === number.ONE) && (duration === number.ONE)): return (`This Task recurs daily`); 
      case ((recur === number.ONE) && (duration === number.TWO)): return validateEndDate && (`This Task recurs daily until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.ONE) && (duration === number.THREE)): return (`This Task recurs daily for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "day" : "days"}`); 

      //Gets Title for weekly recurrence
      case ((recur === number.TWO) && (duration === number.ONE)): return (`This Task recurs weekly on ${format(FrequencyDayDate, 'EEEE')}`); 
      case ((recur === number.TWO) && (duration === number.TWO)): return validateEndDate && (`This Task recurs weekly until ${format(validateEndDate, 'do MMMM, yyyy')}`);
      case ((recur === number.TWO) && (duration === number.THREE)): return (`This Task recurs weekly on  ${format(FrequencyDayDate, 'EEEE')} for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "week" : "weeks"}`); 

      //Gets Title for monthly recurrence 
      case (recur === number.THREE && (duration === number.ONE)): return (`This Task recurs monthly on ${format(FrequencyDayDate, 'do')} of the month`); 
      case (recur === number.THREE && (duration === number.TWO)): return validateEndDate && (`This Task recurs monthly until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case (recur === number.THREE && (duration === number.THREE)): return (`This Task recurs monthly for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "month" : "months"}`); 

      //Gets Title for yearly recurrence
      case (recur === number.FOUR && (duration === number.ONE)): return (`This Task recurs yearly on ${format(FrequencyDayDate, 'do MMM')}`); 
      case (recur === number.FOUR && (duration === number.TWO)): return validateEndDate && (`This Task recurs yearly until ${format(validateEndDate, 'yyyy')}`); 
      case (recur === number.FOUR && (duration === number.THREE)): return (`This Task recurs yearly for next ${RemainingRecurrence} ${RemainingRecurrence==number.ONE ? "year" : "years"}`); 
     
  }
}

export const getRecurrTitleCompletionBased = (recur, duration, skipCountValue, count, EndDate) => {
  let validateEndDate = EndDate && isDateValid(EndDate) && new Date(EndDate)
  switch (true) {
      //Gets Title for daily recurrence
      case ((recur === number.ONE) && (duration === number.ONE)): return (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "day" : "days"}`); 
      case ((recur === number.ONE) && (duration === number.TWO)): return validateEndDate && (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "day" : "days"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.ONE) && (duration === number.THREE)): return (`This task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "day" : "days"} for next ${count} times`); 

      //Gets Title for weekly recurrence
      case ((recur === number.TWO) && (duration === number.ONE)): return (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "week" : "weeks"}`); 
      case ((recur === number.TWO) && (duration === number.TWO)): return validateEndDate && (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "week" : "weeks"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.TWO) && (duration === number.THREE)): return (`This task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "week" : "weeks"} for next ${count} times`); 


      //Gets Title for monthly recurrence 
      case ((recur === number.THREE) && (duration === number.ONE)): return (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "month" : "months"}`); 
      case ((recur === number.THREE) && (duration === number.TWO)): return validateEndDate && (`This Task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "month" : "months"} until ${format(validateEndDate, 'do MMMM, yyyy')} `); 
      case ((recur === number.THREE) && (duration === number.THREE)): return (`This task recurs every ${skipCountValue} ${skipCountValue==number.ONE ? "month" : "months"} for next ${count} times`);  
  }
}

//counts the number of saturdays and sundays between end and start date 
 const countSatSun=(start, end)=>{
  if(end < start)return; 
      let count
      for(count = {sun:number.ZERO, sat:number.ZERO}; start <= end; start.setDate(start.getDate() + number.ONE)){
      if(start.getDay() == number.ZERO)count.sun++;
      else if(start.getDay() == 6)count.sat++;
      }
  return count;
  }

/**
 * updates endByCountValue according to the day chosen (saturday/sunday)
 */
export const endByCountValueSatSun = (endDate, skipDays, calenderValue,endByDate) => {
  let saturdaySundayCount=endByDate ? countSatSun(new Date(calenderValue),endDate) : countSatSun(new Date(calenderValue),new Date(addDays(calenderValue, endDate)))
  switch (true) {
    case (skipDays?.length == number.ZERO): return number.ZERO
    case (skipDays?.length == number.TWO): return saturdaySundayCount?.sat + saturdaySundayCount?.sun
    case (skipDays?.length == number.ONE && skipDays[number.ZERO] == number.SEVEN): return saturdaySundayCount?.sat
    case (skipDays?.length == number.ONE && skipDays[number.ZERO] == number.ONE): return  saturdaySundayCount?.sun
  }
}

/**
 * gets the number of dates between two dates excluding specified weekends 
 */
export const getDatesBetweenDatesDaily = (startDate, endDate, skipDays, calenderValue) => {
  let dates = []
  const theDate = new Date(startDate)
  let saturdaySundayCount=endByCountValueSatSun(endDate, skipDays, calenderValue,true)
  
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + number.ONE)
  }
  return dates.length - saturdaySundayCount
}

/**
 * returns dates based on the recurrence type and date range provided.
 */
export const getDatesBetweenDates = (recur,startDate, endDate, skipDays, calenderValue) => {
  let intervalToDurationCount
  if (startDate && endDate) {
    intervalToDurationCount = intervalToDuration({
      start: startDate,
      end: endDate
    })
  }
  switch (true) {
    case (recur === number.ONE): return getDatesBetweenDatesDaily(startDate, endDate, skipDays, calenderValue)
    case (recur === number.TWO): return differenceInWeeks(endDate,startDate)
    case (recur === number.THREE): return intervalToDurationCount?.months
    case (recur === number.FOUR): return intervalToDurationCount?.years
    default: return number.ZERO
  }
}

/**
 * checks if the user has access for creating recurrence or not 
 */
export const recurrenceAccess = (task) =>{
    const state = store.getState();
    const { id, myProjectId } = state.auth.user
    const { defaultDetails } =state.tasks
    let project = defaultDetails?.allProjectsList?.find((project) => project.ProjectId == task.baseProjectId)
    
    return accessBasedOnProjectMembers(project, id) 
    || ((task.ProjectId ==  myProjectId) && id == task.CreatedBy)
  }

/**
  * creates a payload for task recurrence details based on existing settings
  * exsiting reccurrence setting made by the user 
  * @author Muskan Thakur
  */
export const getExistingRecurrencePayload = (recurrenceDetails) => {
    let existingRecurrencePayload = {
      frequencyType: recurrenceDetails?.FrequencyType,
      frequencyDay: recurrenceDetails?.FrequencyDay,
      recurrenceMode: recurrenceDetails?.RecurrenceMode,
      recurrencePattern: recurrenceDetails?.RecurrencePattern,
      skipPastRecurrence: Boolean(recurrenceDetails?.SkipPastRecurrence),
      endCriteriaType: recurrenceDetails?.EndCriteriaType,
      endDate: recurrenceDetails?.EndDate ? format(new Date(recurrenceDetails?.EndDate), 'yyyy-MM-dd') : null,
      endRepeatCount: recurrenceDetails?.EndRepeatCount,
      skipDays: recurrenceDetails?.SkipDays,
      frequencyDayDate: format(new Date(recurrenceDetails?.FrequencyDayDate), 'yyyy-MM-dd'),
      reminder: recurrenceDetails?.Reminder,
      reminderType: recurrenceDetails?.ReminderType,
      remainingRecurrence: recurrenceDetails?.RemainingRecurrence,
      completionFrequencyType: recurrenceDetails?.CompletionFrequencyType ,
      completionFrequencyDays: recurrenceDetails?.CompletionFrequencyDays,
      taskAssignee: recurrenceDetails?.TaskAssignee,
      projectId: recurrenceDetails?.ProjectId
    };
    return existingRecurrencePayload;
}

/**
  * creates a payload for task recurrence details based on current state variables and settings
  * current reccurrence setting made by the user 
  * @author Muskan Thakur
  */
export const createRecurrencePayload = (recur, recurrencePattern, calenderValue, recurrenceMode, skipDays, duration, endBydateValue, endByCountValue, skipWeekends, reminderValue, remainingRecurrence, taskAssignmentType, task, id, skipCountValue, assignedId) => {
  return {
      taskId: task.taskId,
      frequencyType: recurrencePattern === number.ONE ? recur?.value : null,
      frequencyDay: getDay(new Date(calenderValue)),
      recurrenceMode: recurrenceMode,
      recurrencePattern: recurrencePattern,
      skipPastRecurrence: skipDays.includes(true),
      endCriteriaType: duration?.value,
      endDate: endBydateValue ? format(endBydateValue, 'yyyy-MM-dd') : null,
      endRepeatCount: endByCountValue ? endByCountValue : null,
      skipDays: skipWeekends?.toString(),
      createdBy: id,
      frequencyDayDate: format(calenderValue, 'yyyy-MM-dd'),
      reminder: reminderValue?.length ? number.ONE : number.ZERO,
      reminderType: reminderValue.toString(),
      remainingRecurrence: remainingRecurrence,
      completionFrequencyType: recurrencePattern === number.TWO ? recur?.value : null,
      completionFrequencyDays: recurrencePattern === number.TWO ? skipCountValue : null,
      taskAssignee: taskAssignmentType?.value === number.TWO ? assignedId?.value : null,
      projectId: task?.CurrentProject,
      taskAssignmentType: taskAssignmentType?.value,
      workflowId: taskAssignmentType?.value === number.FIVE ? assignedId?.value : null
  };
};