import { icon } from "../config"

export const editorToolType = {
    TASK_SIDEBAR_TOOL : "taskSidebartools" , 
    TASK_POPUP_TOOL : "taskPopuptools"
}

export const editorEntity = {
    TASK_DESC : "Task Desc"
}

export const MODE_ARRAY = [
{ modeId: 2, mode: "Edit" , icon :icon.KEYBOARD_HIDE, class: "green"},
{ modeId: 3, mode: "Edit Smart Chips" , icon :icon.KEYBOARD_LOCK, class: "yellow"} ,
{ modeId: 1, mode: "Read-only" , icon : icon.KEYBOARD_LOCK_OFF, class: "red"},
]

export const MEDIA_ARRAY = [
    {icon : icon.INSERT_IMAGE_EDITOR , media : "Image"} , 
    {icon : icon.IFRAME , media :"Iframes"} , 
    {icon : icon.PICTURE_AS_PDF , media : "Add PDF"}
]

export const defaultUserChip = (acknowledgeChip) => ({
    label: acknowledgeChip ? "Sender Name" : "Select User",
    Email: null
});


export const editorToolsDimension = {
    rowHeight : 25 , 
    colWidth : 25
}

export const editorToolsGap = {
    rows: 5,
    cols: 5,
}

export const cleanFormattingLabel = "Clean Formatting"