import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icon, label, number, tooltip } from '../../../config'
import { GridContext } from '../../../utils/kendo'
import { getAssigneeData, handleGetProjectWorkflows } from '../../TaskSidebar/sidebar.helper'
import { FlowElementsContext } from './FlowElementsContextProvider'
import { useFlowElementHandlers } from './flowElementscommonHook'
import { setFlowElements } from '../../../actions/taskSidebar'
import { deleteItem } from '../workflowHelper'
import { FlowElementCommandCell } from '../../../shared/components/FlowElement/FlowElementCommandCell'
import '../WorflowDesigner.scss'

/**
* Parent Component : FLowElementDetails
* This is the simplified view for flow elements ( for task only )
* Childen : FlowElementCommandCell
* @author Sarthak Arora
*/
const SimplifiedFlowElementDetails = (props) => {
  const { task , flowElements  } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks)
  const dispatch = useDispatch()
  const { handleApprovalFlow, handleFlowLock , showTooltip, hideTooltip } = useFlowElementHandlers();

  const { flowElementData , deleteFlowElement , entity} = useContext(GridContext);

  const { flowAssignmentType, setFlowAssignmentType,flowWorkflowAssignmentType, setFlowWorkflowAssignmentType,flowTaskAssignmentId, setFlowTaskAssignmentId,flowWorkflowAssignmentId, setFlowWorkflowAssignmentId,  isApproval,isFlowLocked , wrapper , taskAssignmentTypes , workflowAssignmentTypes , setIsApproval} = useContext(FlowElementsContext)

 
  useEffect(() => {
    const requiredAssignType = taskAssignmentTypes?.find((c) => c.value === props?.dataItem.taskAssignmentType)
    setFlowAssignmentType(requiredAssignType ? requiredAssignType : null);
  }, [flowAssignmentType, taskAssignmentTypes, flowElementData])


  /**
  * sets flow assignment Id for new and existing flow element
  * flowAssignment type:-
  * two for user assignment
  * three for project assignment
  */
  useEffect(() => {
    switch (flowAssignmentType?.value) { 
      case number.TWO:
        const requiredUserAssignId = defaultDetails?.assigneeList?.find((c) => c.value === props?.dataItem?.taskAssignmentId)
        setFlowTaskAssignmentId(requiredUserAssignId ? requiredUserAssignId : null);
        break;
      case number.THREE:
        const allProjectList = _.orderBy([...defaultDetails?.allProjectsList], [project => project.label?.toLowerCase()?.trim()], ['asc']);
        let requiredProjectAssignId = allProjectList?.find((c) => c.value === props?.dataItem?.taskAssignmentId)
        setFlowTaskAssignmentId(requiredProjectAssignId ? requiredProjectAssignId : null);
        break;
      default:
        break;
      }

  }, [props?.dataItem?.taskAssignmentId, flowAssignmentType, defaultDetails]);


  /**
  * sets workflow assignment type for new and existing flow element
  */
  useEffect(() => {
    const requiredWorkflowAssignType = workflowAssignmentTypes?.find((c) => c.value === props?.dataItem?.workflowAssignmentType);
    setFlowWorkflowAssignmentType(requiredWorkflowAssignType);
  }, [props?.dataItem.workflowAssignmentType, workflowAssignmentTypes])


  useEffect( () => {
    (async()=>{
    const project = defaultDetails?.allProjectsList?.find((project) => project.value === (flowAssignmentType?.value === number.TWO ? flowTaskAssignmentId?.myProjectId : flowTaskAssignmentId?.value));
    let assigneeList;
    switch (flowWorkflowAssignmentType?.value) {
      case number.TWO:
        assigneeList = project && getAssigneeData(project, defaultDetails);
        break;
      case number.FIVE:
        let workflows = await handleGetProjectWorkflows(parseInt(project?.value))
        if (project) {
          assigneeList = workflows?.map(({ WorkflowId, WorkflowName, Icon, WorkflowPrivacy }) => ({ value: WorkflowId, label: WorkflowName, privacy: WorkflowPrivacy, icon: Icon }));
        }
        break;
      default:
        break;  
    }
    const requiredWorkflow = assigneeList?.find((c) => c.value === props?.dataItem?.workflowAssignmentId);
    setFlowWorkflowAssignmentId(requiredWorkflow ? requiredWorkflow : { label: label.UNASSIGNED });
  })();
  }, [props?.dataItem?.workflowAssignmentId , defaultDetails, flowWorkflowAssignmentType , flowAssignmentType , flowTaskAssignmentId])

  /**
   * sets isApproval to true if worflow element is in approval
   */
  useEffect(()=> {
    setIsApproval(props?.dataItem?.isApproval)
  },[props?.dataItem?.isApproval])

    /**handles deletion of existing flow element*/
    const handleDeleteFlowElement = async (dataItem) => {
      let payload = {
        flowElementId: dataItem?.id
      }
      payload["taskId"] = entity;
      dispatch(setFlowElements([...deleteItem(dataItem, [...flowElements])]))
      entity && await deleteFlowElement(payload);
    }



  return (
    <div>
      <div className={`workflow-grid-border position-relative `} >
        <div className={`${isApproval ? "border border-primary p-2" : "border border-secondary p-2"}`}>
          <div className={` form-row custom-form-row `}>
            <div className={`${(task?.taskId) ? "form-group col-md-1" : "form-group col-md-2"}`}>
              { <span>{icon[flowAssignmentType?.icon]}</span>}
            </div>
            <div className='form-group col-md-4'>
              { <div className='text-truncate'>{flowTaskAssignmentId?.label}</div>}
            </div>

            {(flowAssignmentType && flowTaskAssignmentId) && <><div className={`${(task?.taskId) ? "form-group col-md-1 d-flex align-item-end" : "form-group col-md-2"}`}>
              { <span>{icon[flowWorkflowAssignmentType?.icon]}</span>}
            </div>
              <div className='form-group col-md-4'>
                <span title={flowWorkflowAssignmentId?.label}></span>
                { <div className="text-truncate">{flowWorkflowAssignmentId?.label}</div>}
              </div></>}
          </div>
          <div className={`form-row custom-form-row`}>
            <div className={`form-group col-md-1 workflow-approval-column `}>
              <button
                id="approval-btn"
                className={`approval-icon mt-2 ml-3 d-block ${isApproval ? "text-blue" : "icon-black"} border-none`}
                title={"approval"}
                onClick={handleApprovalFlow}
                disabled={true}
              >
                {(!isApproval ) ? "" : icon?.APPROVAL_ICON}
              </button>
            </div>
            <div className={`form-group col-md-6 workflow-lock-task-column`}>
              <button
                id="simplified-flow-element-lock-button"
                ref={wrapper}
                className={`flow-lock-icon mt-2 ml-3 d-block ${isFlowLocked ? "text-blue" : "icon-black"} border-none `}
                title={isFlowLocked ? tooltip.UNLOCK_TASK : tooltip.LOCK_TASK}
                onClick={handleFlowLock}
                disabled={true}
                onMouseOver={showTooltip}
                onMouseLeave={hideTooltip}
              >
                {isFlowLocked ? icon.APPROVAL_LOCK : ""}
              </button>
            </div>
            <div className={`form-group col-md-3`}></div>
            <div className='form-group col-md-2 d-flex align-items-center '>
              <span className={` mt-2 col-md-3 flow-element-grid-action`}>
                <FlowElementCommandCell {...props}
                handleDeleteFlowElement={handleDeleteFlowElement}/>
              </span>
            </div>
          </div>
          {(!task?.taskId) && isApproval && <span className='approval-border-icon' title={tooltip.APPROVAL}>
            {icon.APPROVAL_ICON}
          </span>}
        </div>
      </div>
    </div>
  )
}

export default SimplifiedFlowElementDetails