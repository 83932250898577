import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { dateFormat, icon, label } from "../../../../config";
import { getFutureDate } from "../../../../utils/common";
import { setDisabledForSidebar, setDisabledForSidebarUnlocked } from "../../../TaskSidebar/sidebar.helper";
import { dateDiff } from "../../../Tasks/tasks.constants";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import './loader.scss';

const TaskDueDate = () => {
    const [value, setValue] = useState(null);
    const [isOnChange, setIsOnChange] = useState(false)
    const [isDueDateExceeded, setIsDueDateExceeded] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);
    
    const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
    const { task, isNewTask } = useSelector((state) => state.taskSidebar)
    const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
    const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.dueDate);
    const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails)

    /** 
     * initial value for due date in case of new task
     * @author Himanshu Negi
     */
    useEffect(() => {
        if (workflowBasicDetails && !isCloneTask && isNewTask) {
            const days = workflowBasicDetails?.TaskDueDateAfter;
            const date = getFutureDate(days);
            days ? setValue(date) : setValue(null)
            handleTaskKeyUpdate(isNewTask, "dueDate", days ? new Date(date) : label.NO_DUE_DATE)
        }
    }, [workflowBasicDetails, isCloneTask, isNewTask])

    /** 
     * initial value for due date in case of clone task
     * @author Himanshu Negi
     */
    useEffect(() => {
        if(isNewTask && isCloneTask){
            setValue(new Date(cloneTask.dueDate));
            handleTaskKeyUpdate(isNewTask, "dueDate", cloneTask.dueDate ? new Date(cloneTask.dueDate) : label.NO_DUE_DATE)
        }
    },[isCloneTask, isNewTask])

    /** 
     * initial value for due date in case of existing task
     * @author Himanshu Negi
     */
    useEffect(() => {
        if (task.taskId) {
            (task.DueDate && task.DueDate != label.NO_DUE_DATE) ? setValue(new Date(task.DueDate)) : setValue(null)
            setDisabledForSidebar(task, setIsDisabled)
        }
    }, [task?.taskId])

    useEffect(() => {
        if (!isNewTask) {
            const result = dateDiff(task?.DueDate) && !task.IsTaskComplete
            setIsDueDateExceeded(result);
        } else {
            setIsDueDateExceeded(false);
        }
    }, [task?.taskId, isDueDateExceeded, value])

    /**
     * works when duedate is changed
     * @param {*} event
     */
    const changeDueDate = (event) => {
        if (new Date(value).valueOf() != new Date(event.value).valueOf()) {
            setIsOnChange(true)
            setValue(new Date(event.value))
            handleTaskKeyUpdate(isNewTask, "dueDate", event.value ? new Date(event.value) : label.NO_DUE_DATE)
        }
    }

    /**
     * works when cross icon is clicked to remove duedate
     * @author {Prachi Jain}
     */

    const removeDueDate = () => {
        setIsOnChange(true)
        setValue(null)
        handleTaskKeyUpdate(isNewTask, "dueDate", label.NO_DUE_DATE)
    }

    return (
        <React.Fragment>
            <div className={showTaskDetailPanel && !isNewTask ? "form-group col-lg-4 col-md-4 col-sm-4" : "w-100 position-relative"}>
                <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
                    {showTaskDetailPanel && !isNewTask ? "" : <label>{label.DUE_DATE}</label>}
                    {!!(value && setDisabledForSidebarUnlocked()) && <span title={label.REMOVE_DUE_DATE} className={`cursor-pointer position-absolute due-date-clear-icon ${showTaskDetailPanel && !isNewTask ? 'due-date-clear-icon2' : ''}`} onClick={removeDueDate}>{icon.CLOSE_TASK_DUE_DATE}</span>}
                    <div className="d-flex due-date-field">
                        <DatePicker
                            id="task-due-date"
                            disabled={loader || isDisabled}
                            value={value}
                            defaultShow={false}
                            onChange={changeDueDate}
                            format={dateFormat.DEFAULT_DATE_FORMAT}
                            className={`${isDueDateExceeded ? "red" : ""} ${showTaskDetailPanel && !isNewTask ? "" : ""}`}
                            placeholder={label.NO_DUE_DATE} title={label.ADD_DUE_DATE} />

                    </div>
                    {loader && <div className="preloader loader-wrap"></div>}
                </Tooltip>
            </div>
        </React.Fragment>
    );
}



export default TaskDueDate;
