import { ProseMirror } from "@progress/kendo-react-editor";
import { setLoading } from "../../actions/comments";
import { resetLinkTaskForNewTask } from "../../actions/linkedTasks";
import { setNavigatedNotification } from "../../actions/notifications";
import { resetPlanMyday } from "../../actions/planMyDay";
import { resetSidebarReducer, setShowTaskSidebar, resetRecurrence } from "../../actions/taskSidebar";
import { AUTH_TOKENS, label, number } from "../../config";
import { loadState } from "../../shared/services/session.service";
import store from "../../store/index";
import { convertToString, isTrueBit, splitString } from "../../utils";
import { getBookmarks } from "../Bookmarks/bookmarks.service";
import { addReactionComment, addReactionReply } from "../TaskSidebar/TaskSidebarContent/Comments/comments.service";
import { addUpdateDescLog, deleteTask, getTasks, updateTaskSidebar } from "./tasks.service";

const { Plugin, PluginKey } = ProseMirror;



const DUE_DATE_FILTER = {
    DUE_TODAY: "Tasks Due Today",
    DUE_TOMORROW: "Tasks Due Tomorrow",
    ALL_TASKS_DUE: "All Tasks",
    DUE_TASKS: "View Tasks Due in",
    WITHOUT_DUE_DATE: "Tasks without DueDate"
}

export const checkAssignedProjects = (project, task) => {
    let projectIds = splitString(task.projectTrailIds, ',');
    if (projectIds?.includes(convertToString(project?.value))) {
        return ""
    }
    else {
        return project
    }
}

export const filterUser = (user, defaultDetails, task) => {
    let projectIds = splitString(task.projectTrailIds, ',');
    let projects = defaultDetails.allProjectsList?.find(project =>
        (projectIds?.includes(project.ProjectId.toString()) && project.isDefault === number.ONE && !project.QueueId && project.CreatedBy == user.value) ||
        (task.ProjectId == project.ProjectId && project.isDefault === number.ONE && !project.QueueId && project.CreatedBy == user.value))
    if (projects) { return "" }
    else { return user }
}

/**
* handle emoji reactions
* @param {emojiReactionPayload}
* @returns {void}
*/

export const emojiReactionClick = async (emojiReactionPayload) => {
    const { type, dispatch, userId, taskId, emoji, commentId, secEntityId } = emojiReactionPayload;
    dispatch(setLoading(true))
    if (type === 'comment') {
        const payload = { emoji, userId, priEntityId: taskId, secEntityId }
        await dispatch(addReactionComment(payload));
    } else {
        const payload = { emoji, userId, priEntityId: commentId, secEntityId }
        await dispatch(addReactionReply(payload))
    }
    dispatch(setLoading(false))
}

export const updateGrid = async (value, dataItem, projectId, field) => {
    var user = loadState(AUTH_TOKENS.USER);
    const { Id, taskHistoryId, CurrentProject, isReturnable, AssignedId } = dataItem;
    const state = store.getState();
    const { positionId } = state.userPosition;
    let details = {
        taskId: Id,
        taskHistoryId: taskHistoryId,
        userId: user.id,
        positionId
    }
    details[field] = value
    if (field == "assignedId") {
        details['updatedCurrentProject'] = CurrentProject
        details['lastCurrentProject'] = CurrentProject
        details['isReturnable'] = isReturnable
        details['assignedType'] = number.THREE
        details['assignedId'] = CurrentProject
        details['assignedBy'] = user.id
        details['projectId'] = projectId
        details["assignee"] = value
        details['relatedAssignedType'] = number.TWO
        details['relatedAssignedId'] = value
    }
    store.dispatch(updateTaskSidebar(details));
}

export const filterAssignees = (assigneeList, task, projectId, projectList) => {
    var included = [{ label: label.UNASSIGNED }], collaborator = [], owner = [];
    assigneeList?.map((assignees) => {
        let defaultProject = projectId ? projectId : task.CurrentProject
        let project = projectList.find(p => p.value && p.value.toString() === defaultProject?.toString());
        if (project && project.collaborators) { collaborator = splitString(project.collaborators, ",") }
        if (project && project.owners) { owner = splitString(project.owners, ",") }
        if (collaborator.includes(assignees.value?.toString()) || owner.includes(assignees.value?.toString())) { included.push(assignees) }
        return null;
    })
    return included;
}

/**
 * Delete Task and close sidebar and getBookmarks
 * @param {Integet} taskId
 */
export const deleteExistingTask = async (taskId) => {
    const state = store.getState(),
        { auth } = state;
    let response = await deleteTask(taskId, auth.user.id)
    if (response) {
        getTasks()
        store.dispatch(resetRecurrence())
        store.dispatch(setShowTaskSidebar({ showTaskSidebar: false }))
        store.dispatch(getBookmarks(auth.user.id))
    }
}

/**
 * returns Task Page Header for due date filter
 * @returns Task Page Header for due date filter
 */
export const getDueDateHeader = (auth) => {
    if (!auth.user.AllTask && !auth.user.IsFiltered) return DUE_DATE_FILTER.ALL_TASKS_DUE
    if (auth.user.AllTask) return DUE_DATE_FILTER.ALL_TASKS_DUE
    if (auth.user.DueDays == number.ZERO && auth.user.IsFiltered) return DUE_DATE_FILTER.DUE_TODAY
    if (auth.user.DueDays == number.ONE && auth.user.IsFiltered) return DUE_DATE_FILTER.DUE_TOMORROW;
    if (auth.user.DueDays > number.ONE && auth.user.IsFiltered) return `${DUE_DATE_FILTER.DUE_TASKS} ${auth.user.DueDays} Days`
}

/**
 * returns filter fields count
 * @param {String, Array} count
 * @returns {Number}
 * @author Himanshu Negi
 */
export const getFieldCount = (count, countList) => {
    const fieldCount = countList[count]
    return fieldCount > number.NINTY_NINE ? number.NINTY_NINE + "+" : fieldCount;
}

/**
* returns task is in approval or not
* @param {String, Array}
* @returns {Boolean}
* @author Himanshu Negi
*/
export const getIsTaskInApproval = (taskId, taskList) => {
    const task = taskList?.find((item) => item.Id === taskId);
    return isTrueBit(task, 'InApproval') || isTrueBit(task, 'IsApproved');

}


/**
 * returns pinnedTasks
 * @param {Object} allTasks
 * @returns {Object}
 */
export const pinnedTasksPayload = (allTasks) => {
    if (allTasks?.superset?.length) {
        let pinnedTasks = {
            superset: allTasks?.superset?.filter(t => t.pinned),
            myTasks: allTasks?.myTasks?.filter(t => t.pinned).sort((a, b) => a.pinnedSortId > b.pinnedSortId ? 1 : -1),
            projectTasks: allTasks?.projectTasks?.filter(t => t.pinned).sort((a, b) => a.pinnedSortId > b.pinnedSortId ? 1 : -1)
        };
        return pinnedTasks;
    }
}

/**
 * returns unPinnedTasks
 * @param {Object} allTasks
 * @returns {Object}
 */
export const unPinnedTasksPayload = (allTasks) => {
    if (allTasks?.superset?.length) {
        let unPinnedTasks = {
            superset: allTasks?.superset?.filter(t => !t.pinned),
            myTasks: allTasks?.myTasks?.filter(t => !t.pinned),
            projectTasks: allTasks?.projectTasks?.filter(t => !t.pinned)
        };
        return unPinnedTasks;
    }
}

/**
*create Plugins for onMount function in LinkedTaskDetailsPopup , ParentTaskDetailPanel
* author @Shivam
*/
export const createEditorPlugins = (state) => {
    return [
        ...state.plugins,
        new Plugin({
            key: new PluginKey('readonly'),
            props: { editable: () => false },
        })
    ];
};

/**
 * return value in onMount function in LinkedTaskDetailsPopup , ParentTaskDetailPanel
 * author @Shivam
 */
export const returnEditorView = (event, plugins, onPictureOpen, doc) => {
    return new ProseMirror.EditorView(
        { mount: event.dom },
        {
            ...event.viewProps,
            handleDOMEvents: {
                ...(event.viewProps.handleDOMEvents || {}),
                click: onPictureOpen,
            },
            state: ProseMirror.EditorState.create({ doc: doc, plugins }),
        }
    );
};


/**
* Decides according to workflow settings whether a section is visible or not
* @param {String} field
* @returns {Boolean}
* @author Muskan Thakur
*/
export const getRenderCondition = (field) => {
    const state = store.getState();
    const { showDescription, showAttachments, showComments, showLinkedTasksSubTasks } = state?.taskSidebar;
    if (!field) {
        return true;
    }
    switch (field) {
        case "ShowDescription":
            return !!showDescription;
        case "ShowAttachments":
            return !!showAttachments;
        case "ShowLinkedTasks":
            return !!showLinkedTasksSubTasks;
        case "ShowComments":
            return !!showComments;
        case "ShowWorkflow":
        case "ShowActivity":
            return true;
        default:
            return false;
    }
};


/**
 * Handles closing the sidebar and performs necessary cleanup actions.
 * @param {Function} dispatch - The dispatch function to send actions to the store.
 * @param {Object} taskSidebarData - The data related to the task sidebar.
 */
export const handleCloseSidebar = async (dispatch, taskSidebarData) => {
    const { isNewTask, isNewTaskSaved, task, user, isExistingTaskDescChanged, taskDescChangeLogSent } = taskSidebarData;
    dispatch(resetSidebarReducer())
    dispatch(resetRecurrence())
    dispatch(setNavigatedNotification(null))
    isNewTask && dispatch(resetLinkTaskForNewTask())
    dispatch(resetPlanMyday())

    if (isExistingTaskDescChanged && !taskDescChangeLogSent) {
        await dispatch(addUpdateDescLog({
            taskId: task?.taskId,
            userId: user.id
        }))
    }
}

/**
 * Returns the project name for the given project ID.
 * @author Bhavana
 */
export const getProjectName = (projectId) => {
  const state = store.getState();
  const { defaultDetails } = state.tasks;
  if (!projectId) return;
  return defaultDetails?.allProjectsList?.find((project) => project.ProjectId.toString() === projectId.toString())?.ProjectName;
};
