import _ from "lodash";
import { setNewReplyCount } from "../../actions/comments";
import { cloneSubtaskLinkedTask, resetLinkTaskForNewTask } from "../../actions/linkedTasks";
import { setNavigatedNotification } from "../../actions/notifications";
import { setNavigatedReminder } from "../../actions/reminders";
import { allAttachmentData, cloneOrNewTaskAttachmentData, cloneTask, createTaskViaSharedFlow, resetSidebarReducer, setApprovalLogs, setDependencyToggle, setExpanded, setFlowElements, setNewCommentCount, setShowTaskSidebar, setTask, resetRecurrence, toggleDescPopup } from "../../actions/taskSidebar";
import { kendo, label, notifyIcon, number, quote, route } from "../../config";
import { getProjectWorkflows } from "../../shared/services/projects.service";
import { getProjectWorkflowsDropdown, getWorkflow } from "../../shared/services/workflow.services";
import store from "../../store/";
import { isTrueBit, splitString } from "../../utils";
import { getNotification } from "../../utils/common";
import { navbarOptions } from "../Tasks/tasks.constants";
import { getAttachments, getNewCount, getNewTaskId, getTaskDetails, getTaskWorkflowDetails, handleTaskKeyUpdate, openSidebar } from "../Tasks/tasks.service";
import { getUpdatePayload } from "./sidebar.common";
import { DESC_TYPE } from "./sidebar.constants";
import { getComments, getReplies } from "./TaskSidebarContent/Comments/comments.service";

const ANCHOR_TAG_REGEX = /<a\s+[^>]*href\s*=\s*(['"])(https?:\/\/[^\/]+)\/?(.*?)\1[^>]*>(.*?)<\/a>/g;

export const getCurrentProject = (assignmentState) => {
    const state = store.getState(),
        { newTaskData, isNewTask } = state.taskSidebar,
        defaultDetails = state.tasks.defaultDetails;
    let requiredCurrentProject;
    const assignedProject = isNewTask ? newTaskData.assignedId : assignmentState?.assignedId?.value;
    const assignedProjectType = isNewTask ? newTaskData.assignedType : assignmentState?.assignmentType?.key

    if (isNewTask && !newTaskData.assignedId) {
        return newTaskData.project
    }
    switch (assignedProjectType) {
        case number.THREE:
            requiredCurrentProject = assignedProject
            break;
        case number.TWO:
            requiredCurrentProject = defaultDetails?.allProjectsList?.find(p => p.Id.toString() === assignedProject?.toString() && p.isDefault === number.ONE && !p.QueueId)?.ProjectId
            break;
        default:
    }
    return requiredCurrentProject;
}


const getProjectExternalUsers = (isNewTask, task, defaultDetails) => {
    let collaborator = [], owner = []
    let assignedProject = isNewTask ? task.project?.toString() : task.CurrentProject
    let requiredProject = defaultDetails?.allProjectsList.find(project => project.value == assignedProject)
    if (requiredProject && requiredProject.collaborators) { collaborator = splitString(requiredProject.collaborators, ",") }
    if (requiredProject && requiredProject.owners) { owner = splitString(requiredProject.owners, ",") }
    let assignedUser = isNewTask ? task.assignedId : task.AssignedId;
    return (collaborator.includes(assignedUser?.toString()) || owner.includes(assignedUser?.toString()))
}

export const getAssignedIdElem = (defaultDetails, assignedType, assignedId) => {
    switch (assignedType) {
        case number.TWO:
            return defaultDetails?.assigneeList?.find(a => a.value == assignedId)
        case number.THREE:
            return defaultDetails?.allProjectsList?.find(project => project.value == assignedId)
    }

}

export const getAssigneeDefaults = (assignmentState) => {
    const state = store.getState(),
        defaultDetails = state.tasks.defaultDetails,
        { isNewTask, newTaskData } = state.taskSidebar;
    if (isNewTask ? newTaskData.assignedId : assignmentState?.assignedId?.value) {
        let assignedTypeList = {};
        switch (isNewTask ? newTaskData.assignedType : assignmentState?.assignmentType?.key) {
            case 3:
                assignedTypeList = findSelectedAssigned(defaultDetails?.allProjectsList, isNewTask ? newTaskData.assignedId : assignmentState?.assignedId?.value)
                return assignedTypeList.defaultUserId;
            case 2:
                return assignmentState?.assignedId?.value;
            default:
                return;
        }
    }
}

export const findSelectedAssigned = (assignedTypeList, value) => {
    return assignedTypeList?.find(item => item.value === value)
}

/**
 * Sets the disabled state for the sidebar.
 * @param {Object} task - The task object.
 * @param {Function} setIsDisabled - The function to set the disabled state.
 * @param {string} queueId - The ID of the queue.
 * @author {Himanshi Chawla}
 */

export const setDisabledForSidebar = (task, setIsDisabled) => {
    let state = store.getState();
    let { isNewTask } = state.taskSidebar;
    if (task && !isNewTask && !isTrueBit(task, "InApproval") && (task.isFollowed || task.IsTaskComplete)) {

        setIsDisabled(true)
    }
    else setIsDisabled(false)
}

/**
 * Sets the disabled state for the stage.
 * @param {Object} task - The task object.
 * @param {Function} setIsDisabled - The function to set the disabled state.
 * @param {string} queueId - The ID of the queue.
 * @author {Himanshi Chawla}
 */
export const setDisabledForStage = (task, setIsDisabled, queueTaskParams) => {
    let state = store.getState();
    let { isNewTask } = state.taskSidebar;
    if (task && !isNewTask && ((isTrueBit(task, 'IsLocked') || task.isFollowed || isTrueBit(task, "InApproval")) || !queueTasksPageActiveAccess(task, queueTaskParams) || !task.hasAccess || task.IsTaskComplete)) {
        setIsDisabled(true)
    }
    else setIsDisabled(false)
}

/**
 * evaluates the conditions and determines whether the field should be 'disabled' or not for the fields that are not locked.
 * @author Muskan Thakur
 */
export const setDisabledForSidebarUnlocked = () => {
    const state = store.getState();
    const { isNewTask, task } = state.taskSidebar;
    return isNewTask || !task?.isFollowed && (!task.IsTaskComplete || isTrueBit(task, "InApproval"))
}

/**
 * Sets the disabled state for the assignment.
 * @param {Object} params - The parameters object.
 * @param {Object} params.task - The task object.
 * @param {Function} params.setIsDisabled - The function to set the disabled state.
 * @param {string} params.delegationType - The type of delegation.
 * @param {string} params.queueId - The ID of the queue.
 * @author {Himanshi Chawla}
 */
export const setDisabledForAssignment = ({
    task,
    setIsDisabled,
    delegationType,
    queueTaskParams
}) => {
    let state = store.getState();
    let { isNewTask } = state.taskSidebar;
    if (task && !isNewTask && ((isTrueBit(task, 'IsLocked') || task.isFollowed || isTrueBit(task, "InApproval")) || !task.hasAccess || task.IsTaskComplete || !queueTasksPageActiveAccess(task, queueTaskParams))) {
        setIsDisabled(true)
    }
    else setIsDisabled(false)
}

/**
 * used to get assignees of a project
 * @param {*} assigned
 * @param {*} defaultDetails
 * @returns dropdown data
 * @author Prachi Jain
 */
export const getAssigneeData = (assigned, defaultDetails) => {
    let owners = splitString(assigned?.owners, ",")
    let collaborators = splitString(assigned?.collaborators, ",")
    let users = owners?.concat(collaborators);
    let data = [];
    users?.map((userId) => {
        if (userId) {
            let user = defaultDetails?.assigneeList?.find((u) => u.value == userId)
            data.push(user)
        }
    })
    data = _.orderBy(data, [user => user.label.toLowerCase()], ['asc']);
    data = [{ label: label.UNASSIGNED }, ...data]
    return data;
}

/**
 * to check whether a user is a part of the assigned project or not
 * @returns {Object} Project Details
 * @author Prachi Jain
 */

export const userPartOfAssignedProject = (assignmentState, isBaseProjectChange) => {
    const state = store.getState(),
        { allProjectsList } = state.tasks.defaultDetails,
        { user } = state.auth,
        { isNewTask, newTaskData } = state.taskSidebar;
    let assignedType = (isNewTask && !isBaseProjectChange) ? newTaskData.assignedType : assignmentState?.assignmentType?.key;
    let projectId;
    if (assignedType == number.THREE) projectId = (isNewTask && !isBaseProjectChange) ? newTaskData.assignedId : assignmentState?.assignedId?.ProjectId;
    const userProjectsList = allProjectsList?.filter((project) => { return (project.RoleId || (project.IsPersonal && project.user === user.id)) })

    return userProjectsList?.find((p) => p.value == projectId && p.QueueId)
}

/**
 * to update the taskName while cloning
 * @param {*} taskName eg .taskName
 * @returns {String} taskName eg. taskName §
 * @author Shivam Mishra
 */
export const updatedClonedTaskName = (taskName) => {
    taskName = taskName?.trim()
    if (taskName.length <= kendo.TASK_INPUT_LENGTH - number.TWO) taskName += " §"
    return taskName
}

/**
 * updates workflows for cloning if required
 * called in getCloneTaskData
 * @returns {None}
 */
export const getWorkflowsForCloning = async () => {
    let state = store.getState();
    const url = new URL(window.location.href);
    const projectId = splitString(url.pathname, "/")[number.TWO] || state.auth.user.myProjectId;

    let workflows = state.projectWorkflows[parseInt(projectId)];

    // Check if workflows are falsy and retry the dispatch
    if (!workflows) {
        workflows = await store.dispatch(getProjectWorkflowsDropdown({ projectId: parseInt(projectId), userId: state.auth.user.id }));
    }
    const defaultWorkflowId = workflows?.find(workflow => workflow.IsDefault === number.ONE).WorkflowId
    await getWorkflow({ workflowId: defaultWorkflowId })
};

/**
 * returns data that is copied to newTaskData reducer while cloning tasks
 * called in cloneTaskAction
 * @returns {Object}
 */
const getCloneTaskData = () => {
    let state = store.getState();
    getWorkflowsForCloning()
    return {
        'name': updatedClonedTaskName(state.taskSidebar.task.Name),
        "description": state.taskSidebar.task.Description ? state.taskSidebar.task.Description : '',
        "tags": state.taskSidebar.task.tagIds,
        "attachmentCount": state.taskSidebar.task.attachmentCount,
        'priority': state.taskSidebar.task.Priority,
        'followers': state.taskSidebar.task.followerIds,
        'dueDate': state.taskSidebar.task.DueDate
    }
}

/**
 * clone the attachments of a task
 * updates the reducer for clone task attachments
 * called in cloneTaskAction
 */
const setCloneAttachments = async () => {
    let state = store.getState();
    let data = await getAttachments(route.PRIVATE_ROUTE.TASKS.ENTITY_NAME, state.taskSidebar.task.taskId)
    let cloneAttachments = []
    for (let i in data) {
        let newAttachmentData = attachmentPayload(splitString(data[i].AttachmentLink, "/")[number.FOUR], data[i].AttachmentName, data[i].FileSize, data[i].AttachmentLink, true, "")
        cloneAttachments.push(newAttachmentData)
    }
    store.dispatch(allAttachmentData(cloneAttachments))
    store.dispatch(cloneOrNewTaskAttachmentData(data))
}

/**
  * modifies the subtasks for setCloneSubTasksAndLinkedTasks
  * @param {Array}of object
  * @returns {Array}of object
  */
const cloneSubTask = (subtask) => {
    let clonedSubTasks = []
    for (let i in subtask) {
        let ithSubTask = {
            Name: subtask[i].Name,
            DueDate: subtask[i].DueDate,
            IsCompleted: subtask[i].IsCompleted,
            id: Number(i)
        }
        clonedSubTasks.push(ithSubTask)
    }
    return clonedSubTasks
}

/**
  * rename keys of objects
  * @param {object}
  * @returns {object}
  */
const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: obj[key] }
        }),
        {}
    );

/**
  * modifies the linkedtask for setCloneSubTasksAndLinkedTasks
  * @param {Array}of object
  * @returns {Array}of object
  */
const cloneLinkedTask = (linkedtask) => {
    for (let i in linkedtask) {
        linkedtask[i] = renameKeys({ LinkTaskId: 'Id', RelationId: 'relationId' }, linkedtask[i])
        delete linkedtask[i]["TaskId"]
        delete linkedtask[i]["linkId"]
    }
    return linkedtask
}
const setCloneSubTasksAndLinkedTasks = async () => {
    let state = store.getState();
    const { subTask, linkTask } = state.linkedTasks,
        task = state.taskSidebar.task;
    const payload = {
        clonedSubTasks: cloneSubTask(subTask),
        clonedChildLink: cloneLinkedTask(linkTask.childTask),
        clonedParentLinkTask: cloneLinkedTask(linkTask.parentTask),
        clonedRelatedLinkTask: cloneLinkedTask(linkTask.relatedTo)
    }
    store.dispatch(cloneSubtaskLinkedTask(payload))
    store.dispatch(setDependencyToggle(task.IsDependent ? true : false))
}
/**
 * clones the task
 */
export const cloneTaskAction = async () => {
    let state = store.getState();
    setCloneSubTasksAndLinkedTasks()
    store.dispatch(cloneTask(state.taskSidebar.task.taskId))
    store.dispatch(getNewTaskId())
    let cloneTaskData = getCloneTaskData()
    for (let key of Object.keys(cloneTaskData)) {
        handleTaskKeyUpdate(true, key, cloneTaskData[key])
    }
    setCloneAttachments()
    store.dispatch(setExpanded([]))
}
/** returns if the user has access to the project or not
 * @param {*} projectId
 * @returns
 */
export const getProjectAccess = (projectId) => {
    const state = store.getState();
    const { tasks, auth, taskSidebar } = state
    let trail = splitString(taskSidebar.task.projectTrailIds, ',')
    const userProjectsList = tasks?.defaultDetails?.allProjectsList?.filter((project) => { return (project.RoleId || (project.IsPersonal && project.user === auth.user.id)) })

    if (trail?.includes(projectId?.toString())) {
        let project = userProjectsList?.find(p => p.value == projectId)
        let projectOwner = project?.owners;
        let projectCollaborator = project?.collaborators;
        let members = splitString(projectOwner, ",")
        let member = splitString(projectCollaborator, ",")
        members = members?.concat(member)
        return members?.includes(auth.user.id.toString())
    }
    else return false
}

/**
 * creates attachment payload for clone and new task
 */
export const attachmentPayload = (attachmentKey, attachmentName, fileSize, attachmentLink, cloneTask, fileContent) => {
    let attachment = {
        AttachmentKey: attachmentKey,
        AttachmentName: attachmentName,
        FileSize: fileSize,
        AttachmentLink: attachmentLink,
        CloneTask: cloneTask,
        FileContent: fileContent
    };
    return attachment;
}

/**
 * calculates  the height of editor box
 * author @Shivam
 */
export const getHeight = (editor) => {
    if (editor?.current) {
        const view = editor?.current?.view;
        const content = view?.dom;
        if (content?.scrollHeight) {
            return content.scrollHeight;
        }
        return content?.offsetHeight;
    }
};

/**
 * adds target="_top" inside anchor tag
 * if target="_blank" is not present
 * author @Shivam
 */
export const urlifyText = (text, currentDomain) => {

    const updatedText = text?.replace(ANCHOR_TAG_REGEX, function (match, quote, href, path, content) {
        try {
            // Create a URL object from the href
            const urlObject = new URL(href);

            // Extract the hostname from the URL
            const hrefHostname = urlObject.hostname;

            if (!/target\s*=\s*(['"])_blank\1/.test(match)) {
                if (hrefHostname === currentDomain) {
                    return `<span  id="${path}" style="color: blue; cursor: pointer;">${content}</span>`;
                }
                // Add target="_top" if target="_blank" is not present
                return match.replace(/<a/, '<a target="_top"');
            } else {
                // Leave the anchor tag as it is if target="_blank" is already present
                return match;
            }
        } catch (error) {
            // Handle the error gracefully (e.g., by logging it and returning the original match)
            return match;
        }
    });

    return `<div id="container">${updatedText}</div>`;
};




/**
 * closes the description popup if we clicks on description or approval description section
 * @param {Array} items
 * @returns Array
 * @author Himanshu Negi
 */
export const handleDescSection = (items) => {
    const state = store.getState();
    const { showDescPopup } = state.taskSidebar;
    const isTaskDescriptionOpen = items?.find((item) => item === 'task-description') && showDescPopup.type !== DESC_TYPE.APPROVAL_DESCRIPTION;
    const isApprovalDescriptionOpen = items?.find((item) => item === 'task-approval-description') && showDescPopup.type !== DESC_TYPE.DESCRIPTION;
    if (isTaskDescriptionOpen || isApprovalDescriptionOpen) {
        return store.dispatch(toggleDescPopup({ status: false, type: "" }))
    }
    return;
}

/**
 * returns list of project members
 * @param {Array} {queueProjects, defaultDetails, defaultProject}
 * @returns Array
 * @author Himanshu Negi
 */
const getProjectMembers = (project) => {
    let collab = splitString(project.collaborators, ",")?.length ? splitString(project.collaborators, ",") : [];
    let own = splitString(project.owners, ",")?.length ? splitString(project.owners, ",") : [];
    return [...own, ...collab]
}

/**
 * returns list of users that are only present in restricted projects
 * @param {Array} {defaultDetails}
 * @returns Array
 * @author Himanshu Negi
 */
const getRestrictedProjectUsers = (defaultDetails) => {
    let allUsers = [], restrictedProjectUsers = [];
    defaultDetails?.allProjectsList?.forEach((project) => {
        let members = getProjectMembers(project);
        allUsers = [...allUsers, ...members]
    })
    allUsers = _.uniq(allUsers);
    defaultDetails?.allProjectsList?.forEach((p) => {
        if (p.Privacy !== number.THREE) {
            let members = getProjectMembers(p)
            restrictedProjectUsers = [...restrictedProjectUsers, ...members]
        }
    })
    restrictedProjectUsers = _.uniq(restrictedProjectUsers);
    return allUsers.filter((id) => !(restrictedProjectUsers?.includes(id)));
}

/**
 * returns assignee list for task based on its current project privacy.
 * @param {Object, Object} defaultDetails, defaultProject
 * @returns Array
 * @author Himanshu Negi
 */
export const getProjectAssigneeList = (defaultDetails, defaultProject) => {
    let userList = []
    const project = defaultDetails?.allProjectsList?.find(p => p.ProjectId && p.ProjectId.toString() === defaultProject?.toString());
    const queueProjects = defaultDetails?.allProjectsList?.filter((p) => p.QueueId === project?.QueueId);
    switch (project?.Privacy) {
        case number.THREE:
            queueProjects?.forEach((q) => {
                if (q.Privacy !== number.THREE || q.ProjectId.toString() === defaultProject.toString()) {
                    let members = getProjectMembers(q);
                    userList = [...userList, ...members]
                }
            })
            userList = _.uniq(userList);
            userList = defaultDetails?.assigneeList?.filter((a) => userList?.includes(a?.value.toString()))
            return userList;
        case number.TWO:
        case number.ONE:
            const restrictedProjectUsers = getRestrictedProjectUsers(defaultDetails);
            userList = defaultDetails?.assigneeList?.filter((a) => !(restrictedProjectUsers?.includes(a?.value.toString())))
            return userList
    }
}


/**
 * used to get assigneelist of a project with external and internalusers on the basis project Privacy.
 * @param {Array} items
 * @returns Array
 * @author Himanshu Negi
 */
export const getFilterAssigneeList = (currentViewdProject, projectId, workflow) => {
    const state = store.getState(),
        defaultDetails = state.tasks.defaultDetails,
        { isNewTask, task, newTaskData } = state.taskSidebar
    let defaultProject = workflow ? projectId : (currentViewdProject ? currentViewdProject : (isNewTask ? newTaskData.project : task.CurrentProject));

    // remove logged in user from assignee list if project is user's project
    let userList = getProjectAssigneeList(defaultDetails, defaultProject);
    // if (userList && defaultProject == state.auth.user.myProjectId && !workflow) { -- might need this later
    //     userList = userList?.filter(a => a.value != state.auth.user.id)
    // }
    userList = _.orderBy(userList, [user => user.label.toLowerCase()], ['asc']);

    return userList;
}

/**
 * checks if logged in user is a part of project
 * @param {Object, Object} (defaultDetails, project)
 * @returns {Boolean}
 * @author Himanshu Negi
 */
export const isUserPartOfProject = (defaultDetails, project) => {
    const { auth } = store.getState()
    const userProjectsList = defaultDetails?.allProjectsList?.filter((project) => { return (project.RoleId || (project.IsPersonal && project.user === auth.user.id)) })

    return userProjectsList?.some((p) => p.value == project?.value);
}

/**
 * checks if the user is part of any of the projects in the completed flow of the task
 * @param {Array} flowElements
 * @param {Array} defaultDetails
 * @returns {Object} the flow where user is a part of
 * @author {Prachi Jain}
 */
export const isUserPartOfTaskFlow = (flowElements, defaultDetails) => {
    return flowElements?.find(f => f.isCompleted && isUserPartOfProject(defaultDetails, { value: f.assignmentProjectId }))
}
/**
 * Returns projects list for restricted & non restricted user
 * @param {Object, Boolean} (payload, isRestrictedUser)
 * @returns {Array}
 * @author Himanshu Negi
 */

export const getProjects = (payload, isRestrictedUser) => {
    const { defaultDetails } = payload;
    const projectList = defaultDetails?.allProjectsList?.filter((project) => {
        return (project.Privacy === number.ONE || project.Privacy === number.TWO || (project.Privacy === number.THREE && isUserPartOfProject(defaultDetails, project))) &&
            (project.QueueRoleId || (!isRestrictedUser && project.Privacy === number.ONE)) &&
            // checkAssignedProjects(project, task) && // need this later
            // (project.value != (workflow ? projectId : currentProject?.value)) && // might need this later
            (project.IsPersonal !== number.ONE && project.QueueId);
    })
    return projectList && [...projectList]
}

/**
 * used to get the project listfor assignment on the basis of user.
 * @returns Array
 * @author Himanshu Negi
 */
export const getProjectList = (projectId, workflow) => {
    const state = store.getState(),
        { defaultDetails } = state.tasks,
        { isNewTask, task, newTaskData } = state.taskSidebar,
        { user } = state.auth;
    const currentProject = defaultDetails?.allProjectsList?.find((project) => project.value === (workflow ? projectId : (isNewTask ? newTaskData?.project : task?.CurrentProject)));
    const restrictedUserPayload = { defaultDetails, workflow, projectId, currentProject, task };
    let projectList = getProjects(restrictedUserPayload, user?.isRestrictedUser);
    projectList = _.orderBy(projectList, [project => project.label.toLowerCase()?.trim()], ['asc']);
    projectList = projectList && [...projectList];
    if (+(currentProject?.value) !== user?.myProjectId || workflow) {
        return projectList?.filter((project) => project?.value !== user?.myProjectId)
    } else {
        return [...projectList]
    }
}

/**
 * sorts user list in comments mention
 * prority rule => task assignee > project owners > project collaborators > task followers > others
 * @param {Array} companyUsers
 * @returns Array
 * @author Himanshu Negi
 */
export const getMentionUserList = () => {

    const state = store.getState();
    const { task } = state.taskSidebar;
    const { defaultDetails } = state.tasks;
    const currentProject = defaultDetails?.allProjectsList?.find((project) => +(project.value) === +(task.EntityProjectId));
    let users = getFilterAssigneeList(task.EntityProjectId);

    let owners = currentProject?.IsPersonal ? [currentProject?.user.toString()] : splitString(currentProject?.owners, ",");
    let collaborators = currentProject?.IsPersonal ? [currentProject?.user.toString()] : splitString(currentProject?.collaborators, ",");
    let followers = task.followerIds ? splitString(task.followerIds, ",") : [];
    let currentAssignee = defaultDetails?.assigneeList?.find((user) => +(user.value) === +(task?.CurrentAssignee));

    owners = defaultDetails?.assigneeList?.filter((user) => owners?.includes(user.value.toString()));
    collaborators = defaultDetails?.assigneeList?.filter((user) => collaborators?.includes(user.value.toString()));
    followers = defaultDetails?.assigneeList?.filter((user) => followers?.includes(user.value.toString()));
    currentAssignee = currentAssignee ? [currentAssignee] : [];
    let sortedUsers = [...currentAssignee, ...owners, ...collaborators, ...followers, ...users];
    sortedUsers = _.uniqBy(sortedUsers, (e) => e?.value);
    return sortedUsers;

}
/**
 * It returns http request payload for update task & approval initiation
 * @param {Object} taskState
 * @param {Object} assignmentState
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getAssignmentAndApprovalPayload = (taskState, assignmentState) => {
    if (!taskState || !assignmentState) return;
    const { task, isApproval, taskUrl, user } = taskState;
    const { assignmentType, assignedId, projectDefaultAssignee, delegationType, currentProject, workflowAssignmentType, relatedAssignmentId } = assignmentState;
    let defaultPayload = getUpdatePayload();
    return {
        ...defaultPayload,
        ... {
            assignedId: assignedId?.value,
            assignedType: assignmentType?.key,
            assignee: projectDefaultAssignee?.value ? projectDefaultAssignee?.value : null,
            isReturnable: delegationType?.delegate ? number.ONE : number.ZERO,
            projectId: task.ProjectId,
            lastCurrentProject: task.CurrentProject,
            updatedCurrentProject: currentProject ? currentProject : task.CurrentProject,
            assignedBy: user.id,
            taskUrl,
            relatedAssignedType: workflowAssignmentType?.value,
            relatedAssignedId: relatedAssignmentId?.value ? relatedAssignmentId?.value : null,
            isApproval
        }
    };
}

export const handleGetProjectWorkflows = async (projectId, isWorkflowPanel) => {
    const state = store.getState();
    let { projectWorkflows, auth } = state;
    if (!projectWorkflows[projectId]?.length && projectId) {
        projectWorkflows = isWorkflowPanel ? await store.dispatch(getProjectWorkflows({ projectId })) : await store.dispatch(getProjectWorkflowsDropdown({ projectId: projectId, userId: auth.user.id }));
        return projectWorkflows
    }
    return projectWorkflows[projectId];
}

/**
 * used to add flow elements from current active project from workflow panel in ts.
 * @param {*} newDataItem
 * @param {*} requiredFields
 * @param {*} id
 */
export const handleTaskState = (newDataItem, requiredFields, id, existingFlowElements) => {
    if (!requiredFields) {
        getNotification(quote.ENTER_ALL_FIELDS, notifyIcon.WARNING_ICON)
        return;
    }
    const state = store.getState();
    const { flowElements } = state.taskSidebar;
    if (checkFlowInsertionFromFlows(flowElements, flowElements?.length - number.ONE, flowElements?.length, newDataItem)) {
        return getNotification(quote.CAN_NOT_COEXIST, notifyIcon.WARNING_ICON)
    } else {
        newDataItem.inEdit = true;
        const newData = insertGridItem(newDataItem, existingFlowElements, id);
        store.dispatch(setFlowElements([...newData]));
        return;
    }
}

/**
 * checks insertion of flows from 4 dds
 * @param {Array} flowElementData
 * @param {Integer} insertionIndex
 * @param {Object} newFlow
 * @param {Boolean} isLastFlow
 * @returns {Boolean}
 * @author Himanshu Negi
 */
export const checkFlowInsertion = (flowElementData, insertionIndex, newFlow, isLastFlow) => {
    const prevFlow = flowElementData?.find((item, i) => i === +(insertionIndex - number.ONE))
    const nextFlow = flowElementData?.find((item, i) => i === +(insertionIndex));
    if (prevFlow?.assignmentProjectId === newFlow?.assignmentProjectId && prevFlow?.workflowAssignmentType === newFlow?.relatedAssignedType && prevFlow?.workflowAssignmentId === newFlow?.relatedAssignedId ||
        (nextFlow?.assignmentProjectId === newFlow?.assignmentProjectId && nextFlow?.relatedAssignedType === newFlow?.workflowAssignmentType && nextFlow?.workflowAssignmentId === newFlow?.relatedAssignedId && !isLastFlow)) {
        return true;
    }
    return false;
}

/**
 * checks insertion of flows from flow elements
 * @param {Array} flowElementData
 * @param {Integer} insertionIndex
 * @param {Object} newFlow
 * @param {Boolean} isLastFlow
 * @returns {Boolean}
 * @author Himanshu Negi
 */
export const checkFlowInsertionFromFlows = (flowElementData, prevIndex, nextIndex, newFlow, isLastFlow) => {
    const prevFlow = flowElementData?.find((item, i) => i === +(prevIndex))
    const nextFlow = flowElementData?.find((item, i) => i === +(nextIndex));
    if (prevFlow?.assignmentProjectId === newFlow?.assignmentProjectId && prevFlow?.workflowAssignmentType === newFlow?.workflowAssignmentType && prevFlow?.workflowAssignmentId == newFlow?.workflowAssignmentId ||
        (nextFlow?.assignmentProjectId === newFlow?.assignmentProjectId && nextFlow?.workflowAssignmentType === newFlow?.workflowAssignmentType && nextFlow?.workflowAssignmentId == newFlow?.workflowAssignmentId && !isLastFlow)) {
        return true;
    }
    return false;
}

/**
 * checks deletion of flows from flow elements
 * @param {Array} flowElementData
 * @param {Integer} insertionIndex
 * @param {Object} newFlow
 * @returns {Boolean}
 * @author Prachi Jain
 */
export const checkDeleteFlowElement = (flowElementData, prevIndex, nextIndex) => {
    const prevFlow = flowElementData?.find((item, i) => i === +(prevIndex))
    const nextFlow = flowElementData?.find((item, i) => i === +(nextIndex));
    if (flowElementData?.length > number.ONE && prevFlow?.assignmentProjectId === nextFlow?.assignmentProjectId && prevFlow?.workflowAssignmentType === nextFlow?.workflowAssignmentType && prevFlow?.workflowAssignmentId == nextFlow?.workflowAssignmentId) {
        return true;
    }
    return false;
}

/**
 * used to check the project is personal project or not
 * @param {*} projectId
 * @returns {Object} project details
 */
export const checkIsPersonalProject = (projectId) => {
    const state = store.getState();
    const { defaultDetails } = state.tasks;
    return defaultDetails?.allProjectsList?.find((project) => project.ProjectId === projectId && !project.QueueId && project?.IsPersonal === number.ONE);
}

/**
 * used to get the value of DD3 in case of exisiting task
 * @returns {Interger} 2 for user and 5 for workflow
 */
export const getRelatedAssignedType = (payload) => {
    const { AssignedType, AssignedId, RelatedAssignedType } = payload;
    const state = store.getState();
    const { defaultDetails } = state.tasks;
    const projectId = AssignedType === number.THREE ? AssignedId : (defaultDetails?.allProjectsList?.find((project) => project.CreatedBy == AssignedId && project.IsPersonal)?.ProjectId);
    const isPersonalProject = checkIsPersonalProject(projectId);
    const relatedAssignedType = (RelatedAssignedType === number.FIVE && !isPersonalProject) ? number.TWO : RelatedAssignedType;
    return relatedAssignedType;
}

/**

Determines the active access to the queue tasks page for a given task.
@param {Object} task - The task object.
@param {string} queueId - The ID of the queue.
@returns {boolean} - Returns true if the task has active access to the queue tasks page, otherwise false.
@author {Himanshi Chawla}
*/
export const queueTasksPageActiveAccess = (task, queueTaskParams) => {
    const isQueuePage = queueTaskParams?.isExact;
    const isProjectPartOfQueue = task?.entityQueueId == queueTaskParams?.params?.queueId;

    if (isQueuePage) {
        return isProjectPartOfQueue
    } else return true
};


/**
 *Returns breadcrumb list for breadcrumbs
 * @param {Array} flowElements
 * @returns {Array}
 * @author Himanshu Negi
 */
export const getBreadCrumbList = (flowElements, currentActiveProject, isCurrent) => {
    const breadcrumbsList = flowElements?.filter((flow, index) => {
        const isLastElementCurrent = (isCurrent === flowElements?.length - number.ONE);
        const isFirstElementCurrent = (isCurrent === number.ZERO)
        const isCurrentActiveElement = (currentActiveProject?.id === flow.id)
        const previousElement = (isCurrent - number.ONE)
        const nextElement = (isCurrent + number.ONE)

        if (isLastElementCurrent)
            return isCurrentActiveElement || index === previousElement || index === isCurrent - number.TWO;
        if (isFirstElementCurrent)
            return isCurrentActiveElement || index === nextElement || index === isCurrent + number.TWO;

        return isCurrentActiveElement || index === previousElement || index === nextElement;
    });
    return [...breadcrumbsList];
}

/**
 * returns payload for getFlowsAndDetails function
 * @param {Object} task
 * @param {Object} user
 * @param {Object} updated
 * @returns {Object}
 * @author Himanshu Negi
 */
const getDetailsPayload = (task, user, updated) => {
    if (updated?.isDetailsUpdated) return { taskId: task.taskId, userId: user.id, taskHistoryId: task.taskHistoryId, entityProjectId: task.EntityProjectId };
    if (updated?.isTaskMoved) return { taskId: task.taskId, userId: user.id };
}

/**
 * Api call for task flows and task details
 * @param {Object} task
 * @param {Object} user
 * @param {Object} updated
 * @author Himanshu Negi
 */
const getFlowsAndDetails = async (task, user, updated) => {
    store.dispatch(getTaskWorkflowDetails({ taskId: task.taskId }));
    const detailsPayload = getDetailsPayload(task, user, updated);
    const taskData = await store.dispatch(getTaskDetails(detailsPayload));

    if (taskData?.taskDetails && !taskData.access && !taskData?.taskDetails?.taskId) {
        store.dispatch(setShowTaskSidebar({ showTaskSidebar: false }));
        getNotification(quote.TASK_ACCESS_DENIED, notifyIcon.WARNING_ICON);
    }
    if (taskData) {
        store.dispatch(setTask(taskData?.taskDetails));
        store.dispatch(setApprovalLogs(taskData?.approvalLogs))
    }
}

/**
 * Api calls based on updated flags
 * @param {Object} payload
 * @returns {void}
 * @author Himanshu Negi
 */
export const getUpdatedData = async (payload) => {
    const { task, user, comments, replies, updated } = payload;
    const { expanded } = store.getState()?.taskSidebar;
    if (!updated) return;

    if (updated?.isDetailsUpdated || updated?.isTaskMoved) {
        await getFlowsAndDetails(task, user, updated)
    }

    if (updated?.isFlowUpdated) {
        store.dispatch(getTaskWorkflowDetails({ taskId: task.taskId }));
    }

    if (updated?.isCommentModified) {
        if(expanded?.includes(navbarOptions[number.SEVEN]?.id)){
            const commentPayload = { taskId: task.taskId, offset: number.ZERO, limit: comments?.length > number.FIVE ? comments?.length : number.FIVE, userId: user.id, companyId: user.companyId, isRealTimeUpdate: true }
            store.dispatch(getComments(commentPayload, true));
        }else{
            const counts = await getNewCount({ taskId: task.taskId });
            store.dispatch(setNewCommentCount(counts?.newCommentCount));
        }
    }

    if (updated?.isReplyModified) {
        if (replies?.length > number.ZERO) {
            store.dispatch(getReplies(replies[number.ZERO]?.commentId, user?.id, true));
            const counts = await getNewCount({ taskId: task.taskId, commentId: replies[number.ZERO]?.commentId });
            store.dispatch(setNewReplyCount({ Id: replies[number.ZERO]?.commentId, totalReplyCount: counts.totalReplyCount, newReplyCount: counts.newReplyCount }))
        } else {
            const payload = { taskId: task.taskId, offset: number.ZERO, limit: comments?.length > number.FIVE ? comments?.length : number.FIVE, userId: user.id, companyId: user.companyId }
            store.dispatch(getComments(payload, true));
        }
    }
}

/**
 * set updated flag state
 * @param {Function} setUpdated
 * @param {Object} updatedFlags
 * @returns {Void}
 * @author Himanshu Negi
 */
export const updateSections = (setUpdated, updatedFlags) => {
    if (!updatedFlags) return;
    if (updatedFlags?.IsDetailsUpdated) setUpdated(prev => ({ ...prev, isDetailsUpdated: updatedFlags?.IsDetailsUpdated }));
    if (updatedFlags?.IsTaskMoved) setUpdated(prev => ({ ...prev, isTaskMoved: updatedFlags?.IsTaskMoved }));
    if (updatedFlags?.IsFlowUpdated) setUpdated(prev => ({ ...prev, isFlowUpdated: updatedFlags?.IsFlowUpdated }));
    if (updatedFlags?.IsCommentModified) setUpdated(prev => ({ ...prev, isCommentModified: updatedFlags?.IsCommentModified }));
    if (updatedFlags?.IsReplyModified) setUpdated(prev => ({ ...prev, isReplyModified: updatedFlags?.IsReplyModified }))
}

/**
* disables fields of task sidebar if task is locked
* @param {Function} setUpdated
* @param {Object} updatedFlags
* @returns {Void}
* @author Himanshu Negi
*/
export const setSidebarFieldsDisabled = () => {
    const state = store.getState();
    const { isNewTask, task } = state.taskSidebar;
    if (task?.IsTaskLocked) return true;
    if (isNewTask) return false;
    if (task?.isFollowed || (task.IsTaskComplete && !isTrueBit(task, "InApproval"))) return true;
    return false;
}

/**
 * reset tasksidebar data and generates new task id
 * @param {Void}
 * @returns {Void}
 * @author Shivam Mishra
 */
const handleAddTask = async () => {
    await store.dispatch(resetSidebarReducer())
    await store.dispatch(resetRecurrence())
    await store.dispatch(resetLinkTaskForNewTask())
    await store.dispatch(getNewTaskId())
}

/**
 * set updated flag state
 * @param {String} workflowId
 * @param {String} ProjectId
 * @returns {Void}
 * @author Shivam Mishra
 */
export const createWorkflowTask = async (flowId, projectId) => {
    const state = store.getState();
    const { auth } = state;

    const projectWorkFlows = await store.dispatch(getProjectWorkflowsDropdown({ projectId: parseInt(projectId), userId: auth?.user?.id }))
    const isWorkflowAccessible = projectWorkFlows?.find((i) => i.WorkflowId === parseInt(flowId))
    if (auth.user.myProjectId === parseInt(projectId)) { handleAddTask() }
    else { isWorkflowAccessible ? handleAddTask() : getNotification(quote.UNACCESSED_WORKFLOW, notifyIcon.WARNING_ICON); }
    await store.dispatch(createTaskViaSharedFlow({ createTask: true, sharedFlowId: flowId, sharedProjectId: projectId }))
}

/**
 * Used to navigate to previous task
 * @author Sarthak Arora
 */
export const navigateToPreviousTask = async () => {
    const state = store.getState();
    let previousTask, openedIndex;
    const { navigationTasks, taskSidebar, auth, notifications, planMyDay } = state;
    if (notifications?.navigatedNotification ) {
        const { currentIndex, uniqueNotificationAbove } = isUniqueNotificationAbove()
        openedIndex = currentIndex
        previousTask = navigationTasks[uniqueNotificationAbove]
    }
    else {
        openedIndex = navigationTasks?.findIndex(item => item.Id === taskSidebar?.task.taskId)
        previousTask = navigationTasks[openedIndex - number.ONE]
    }
    if (openedIndex > number.ZERO && previousTask) await openSidebar({ id: previousTask?.Id, userId: auth.user.id }, planMyDay?.isPlanMyDayActive);
    if (previousTask) store.dispatch(setNavigatedNotification(previousTask?.notifId))

}


/**
 * Used to navigate reminders to previous task
 * @author Sarthak Arora
 */
export const navigateRemindersToPreviousTask = async () => {
    const state = store.getState();
    let previousTask, openedIndex;
    const { navigationTasks, taskSidebar, auth, planMyDay , reminders} = state;
    if ( reminders?.navigatedReminder) {
        const { currentIndex, uniqueNotificationAbove } = isUniqueNotificationAbove()
        openedIndex = currentIndex
        previousTask = navigationTasks[uniqueNotificationAbove]
    }
    if (openedIndex > number.ZERO && previousTask) await openSidebar({ id: previousTask?.taskId , userId: auth?.user?.id }, planMyDay?.isPlanMyDayActive);
    if (previousTask) {
        store.dispatch(setNavigatedReminder(previousTask?.Id))
    }
}

/**
 * Used to navigate to next task
 * @author Sarthak Arora
 */
export const navigateToNextTask = async () => {
    const state = store.getState();
    let nextTask, openedIndex;
    const { navigationTasks, taskSidebar, auth, notifications, planMyDay  } = state;
    if (notifications?.navigatedNotification) {
        openedIndex = navigationTasks?.findIndex(item => item.notifId === notifications?.navigatedNotification)
        nextTask = navigationTasks?.find((taskData, i) => i > openedIndex && taskData.Id !== taskSidebar?.task.taskId)
    }
    else {
        openedIndex = navigationTasks?.findIndex(item => item?.Id === taskSidebar?.task.taskId)
        nextTask = navigationTasks[openedIndex + number.ONE]
    }
    if (openedIndex < navigationTasks?.length - number.ONE && nextTask) await openSidebar({ id: nextTask?.Id, userId: auth.user.id }, planMyDay?.isPlanMyDayActive);
    if (nextTask) store.dispatch(setNavigatedNotification(nextTask?.notifId))

}

/**
 * Used to navigate Reminders to next task
 * @author Sarthak Arora
 */
export const navigateRemindersToNextTask = async () => {
    const state = store.getState();
    let nextTask, openedIndex;
    const { navigationTasks, taskSidebar, auth, planMyDay , reminders } = state;
     if(reminders?.navigatedReminder) {
        openedIndex = navigationTasks?.findIndex(item => item?.Id === reminders?.navigatedReminder)
        nextTask = navigationTasks?.find((taskData, i) => i > openedIndex && taskData?.taskId !== taskSidebar?.task?.taskId)
            }

    if (openedIndex < navigationTasks?.length - number.ONE && nextTask){
        await openSidebar({ id: nextTask?.taskId , userId: auth?.user?.id }, planMyDay?.isPlanMyDayActive);
}
    if (nextTask){
       store.dispatch(setNavigatedReminder(nextTask?.Id))
    }
}
/**
 * used to get if there any unique task above the current task
 * @returns unique task above the current task
 *  @author Sarthak Arora
 */
export const isUniqueNotificationAbove = () => {
    const state = store.getState();

    const { navigationTasks, taskSidebar, notifications,reminders } = state;
    let currentIndex,uniqueNotificationAbove;
    if(notifications?.navigatedNotification){
     currentIndex = navigationTasks?.findLastIndex((data) => data?.notifId === notifications?.navigatedNotification);
     uniqueNotificationAbove = navigationTasks?.findLastIndex((taskData, i) => i < currentIndex && taskData?.Id !== taskSidebar?.task?.taskId)
    }
    else if(reminders?.navigatedReminder) {
     currentIndex = navigationTasks?.findLastIndex((data) => data?.Id === reminders?.navigatedReminder);
     uniqueNotificationAbove = navigationTasks?.findLastIndex((taskData, i) => i < currentIndex && taskData?.taskId !== taskSidebar?.task?.taskId)
    }
    return { currentIndex, uniqueNotificationAbove }
}


/**
   * Determine the appropriate icon for the editor based on conditions
   * @param None
   * @return IconNumber {integer}
   * @author Shivam
   */
export const determineEditorIconId = (saveCloseCondition, tickCondition, editCondition, isNewTask) => {
    let iconId;
    if (saveCloseCondition) {
        iconId = number.ONE;
    }
    if (tickCondition) {
        iconId = number.THREE;
    }
    if (editCondition) {
        iconId = number.TWO;
    }
    if (isNewTask) {
        iconId = number.FOUR;
    }
    return iconId ? iconId : number.FOUR;
};

export const skipPlanTask = (isPlanMyDay) => {
    const state = store.getState();
    const { navigationTasks, taskSidebar, auth } = state;
    let openedIndex = navigationTasks?.findIndex(item => item.Id === taskSidebar?.task.taskId)
    const previousTask = navigationTasks[openedIndex + number.ONE]
    if (openedIndex < navigationTasks?.length - number.ONE) openSidebar({ id: previousTask.Id, userId: auth.user.id }, isPlanMyDay);
}

/**
 * @returns id for newly added element in the grid
 */
export const generateId = (data) =>
    data.reduce((acc, current) => Math.max(acc, current.id), 0) + 1;

/**
 * inserts newly added element to the grid
 */
export const insertGridItem = (item, data, id) => {
    item.id = id;
    item.inEdit = false;
    data.push(item);
    return data;
};

/**
* process Parent Description
* @param desc {String}
* @return desc {String}
* @author Shivam
*/
export const processParentDescription = (desc) => {
    if (!desc  || !desc.trim() || (desc &&  desc.trim() === '<p></p>')) return false;
    else return true;
}
