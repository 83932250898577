import { Popup } from '@progress/kendo-react-popup'
import React, { useContext, useState } from 'react'
import { popupAlign } from '../../helper/common'
import { icon, label, number } from '../../config';
import { ReminderContext } from '../Tasks/Reminders/ReminderContextProvider';
import { usePopup } from '../../helper/commonHooks';
import { Button } from '@progress/kendo-react-buttons';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { editWorkflowReminder } from '../../shared/services/workflow.services';
import { WorkflowContext } from './WorkflowContextProvider';
import '../Tasks/Reminders/reminder.scss'
import { useTaskDynamicReminderPopup } from '../Tasks/Reminders/taskRemindersHook';

const WorkflowReminderEditPopup = ({ popupAnchor, show }) => {

    const { contentRef: deleteRef, blurTimeoutRef, onOpen, onFocus, onBlur } = usePopup();
    const anchor = React.useRef();
    const { setIsReminderClicked, setReminderMessage, daysBefore, setDaysBefore, editNoOfDays, editName, editRem, reminderMessage } = useContext(ReminderContext);
    const { workflowReminders, setWorkflowReminders } = useContext(WorkflowContext)
    const [noOfDays, setNoOfDays] = useState(editNoOfDays)
    const { workflowId } = useParams()
    const dispatch = useDispatch()
    const {handleKeyDown} = useTaskDynamicReminderPopup();


    /**
    * closes the popup
    * @param {void}  
    * @return {void}  
    */
    const hideOnBlur = () => {
        setIsReminderClicked(false)
    };

    /**
     *  sets the reminderMessage
     *  @author: Sarthak Arora
     */
    const inputEvent = (event) => {
        setReminderMessage(event.target.value)
    }

    /**
     * handle the up and down arrow keys for noOfDays
     * @author {Sarthak Arora}
     * @param {*} increment 
     */
    const handleDaysBeforeChange = (increment) => {
        increment ? setNoOfDays(noOfDays + number.ONE) : setNoOfDays(noOfDays - number.ONE)
    }

    /**
     * handler save the edited changes
     * @author {Sarthak Arora}
     */
    const handleSubmit = () => {
        const payload = {
            workflowId: Number(workflowId),
            workflowReminderId: editRem,
            reminderMessage: reminderMessage,
            noOfDays: noOfDays
        }
        dispatch(editWorkflowReminder(payload));
        const updatedReminder = workflowReminders.find((rem) => rem.Id === editRem);
        updatedReminder.NoOfDays = noOfDays;
        updatedReminder.ReminderMessage = reminderMessage;
        const updatedWorkflowReminders = workflowReminders.map((reminder) => {
            return reminder.Id === editRem ? updatedReminder : reminder
        })
        setWorkflowReminders(updatedWorkflowReminders);
        setIsReminderClicked(false)
    }


    /**
     * allows nummeric typing in numeric text box
     * @param {*} e 
     */
    const handleNumericTyping = (e) => {
        setNoOfDays(e.target.value)
    }

    return (
        <Popup show={show} anchor={popupAnchor?.current} popupAlign={popupAlign("right", "middle")} popupClass={'reminder-popup new-reminder'} onOpen={() => onOpen(deleteRef)}>
            <div
                ref={deleteRef}
                tabIndex={number.ZERO}
                onFocus={() => onFocus(blurTimeoutRef)}
                onBlur={() => onBlur(blurTimeoutRef, hideOnBlur)}
            >
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-row m-0' >
                                <div className='form-group w-100' ref={anchor}>
                                    <div className='form-group mt-2'>
                                        <label>{label.TITLE}</label>
                                        <input
                                            id='form-components-reminder-title'
                                            className='w-100 form-control'
                                            type="text"
                                            onChange={inputEvent}
                                            placeholder={label.TITLE}
                                            defaultValue={editName}
                                        />
                                    </div>

                                    <div className='d-flex '>
                                        <span className='d-flex align-items-center'>
                                            <input className='days-from-today mr-2'
                                                type="numeric"
                                                value={noOfDays}
                                                maxLength={number.TWO}
                                                onKeyDown={handleKeyDown}
                                                onChange={handleNumericTyping}

                                            />
                                            <div className='dynamic-reminder-popup-inc-dec-btn-group d-flex flex-column'>
                                                <button id='dynamic-reminder-popup-inc-days-before' className="mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none bg-transparent" onClick={() => handleDaysBeforeChange(true)}>{icon.ARROW_UP}</button>
                                                <button id="dynamic-reminder-popup-dec-days-before" className="mr-2 dynamic-reminder-popup-inc-dec-btn d-flex align-items-center justify-content-center border-0 outline-none bg-transparent" onClick={() => handleDaysBeforeChange(false)}>{icon.ARROW_DOWN}</button>
                                            </div>
                                            {label.DAYS_BEFORE_DUE_DATE}

                                        </span>

                                    </div>
                                    <Button id="form-components-reminder-set-btn" className='btn btn-primary mt-3' onClick={handleSubmit}>{label.SET}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Popup>
    )
}

export default WorkflowReminderEditPopup