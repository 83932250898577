import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox, NumericTextBox, Switch } from '@progress/kendo-react-inputs';
import { addDays, differenceInWeeks, startOfDay } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { label, number } from '../../../config';
import { RecurringTaskContext } from './RecurringTaskContext';
import { skipPastDates } from './constants';

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurrenceModesAndDurations = () => {
    const { setCalenderValue, recurrencePattern, setRecurrencePattern, recur, setRecur, setDuration, skipCountValue, setSkipCountValue, recurrenceMode, setRecurrenceMode, edit, setProject, patternBasedRecur, skipDays, setSkipDays  } = useContext(RecurringTaskContext);
    const { task } = useSelector((state) => state.taskSidebar);
    const { recurrenceDetails, recurrenceDefaults } = useSelector((state) => state.recurrence);
    const { defaultDetails } = useSelector((state) => state.tasks);
    const skipWeekends = skipDays.filter(function (e) { return e !== true });


    /**
     * sets the initial values for duration and frequency
     * @author Muskan Thakur
     */
    useEffect(() => {
        setDuration(recurrenceDefaults?.endCriteriaType.find(i => i.value === number.ONE))
        setRecur(recurrenceDefaults?.frequency.find(i => i.value === number.TWO))
    }, [recurrenceDefaults])

    /**
     * sets calendar value based on due date, weekends, and skip days.
     */
    const setCalenderValueDueDate = () => {
        let updatedCalendarValue = new Date(task?.DueDate) < startOfDay(new Date()) && skipDays.includes(true) ? (addDays(new Date(task?.DueDate), number.SEVEN * (number.ONE + (differenceInWeeks(startOfDay(new Date()), new Date(task?.DueDate)))))) : (new Date(task?.DueDate))
        const dayOfWeek = updatedCalendarValue?.getDay();
        const skipPastDueDates = skipWeekends[number.ONE]
        if (dayOfWeek === number.SIX && skipWeekends?.length === number.TWO) {
            setCalenderValue(addDays(updatedCalendarValue, number.TWO));
            return;
        }
        if ((dayOfWeek === number.ZERO && skipWeekends?.length === number.TWO)) {
            setCalenderValue(addDays(updatedCalendarValue, number.ONE));
            return;
        }
        if ((dayOfWeek === number.SIX && skipWeekends?.length === number.ONE && skipPastDueDates === number.SEVEN) || (dayOfWeek === number.ZERO && skipWeekends?.length === number.ONE && skipPastDueDates === number.ONE)) {
            setCalenderValue(addDays(updatedCalendarValue, number.ONE));
            return;
        }
        setCalenderValue(updatedCalendarValue)
    }

    /**
     * sets recurrence values and project details based on recurrence details, adjusting settings appropriately for new or existing tasks
     * @author Muskan Thakur
     */
    useEffect(() => {
        if (recurrenceDetails?.Id) {
            if (edit) {
                recurrencePattern === number.ONE ? setRecur(recurrenceDefaults?.frequency.find(i => i.value === patternBasedRecur)) : setRecur(recurrenceDefaults?.completionFrequency?.find(i => i.value === (patternBasedRecur === number.FOUR ? number.ONE : patternBasedRecur)))
                setCalenderValueDueDate()
            }
        }
        else {
            setCalenderValueDueDate()
            setProject(defaultDetails?.allProjectsList?.find((project) => project.ProjectId === task?.CurrentProject)?.ProjectName)
            recurrencePattern === number.ONE ? setRecur(recurrenceDefaults?.frequency.find(i => i.value === number.TWO)) : setRecur(recurrenceDefaults?.completionFrequency?.find(i => i.value === number.ONE))
        }
    }, [task?.taskId, skipDays.includes(true), recurrencePattern, edit])

    /**
     * handles recurrence pattern for the respective recurrence
     */
    const handleRecurrencePattern = () => {
        if (recurrencePattern === number.ONE) setRecurrencePattern(number.TWO)
        else setRecurrencePattern(number.ONE)
    }

    /**
     * handles recurrence mode for the respective recurrence
     */
    const handleRecurrenceMode = () => {
        if (recurrenceMode === number.ONE) setRecurrenceMode(number.TWO)
        else setRecurrenceMode(number.ONE)
    }
     /**
     * handles skip days options checkbox 
     */
     const handleCheckBox = (value) => {
        let checkedskipDays = [...skipDays, value];
        if (skipDays?.includes(value)) {
            checkedskipDays = checkedskipDays.filter(item => item !== value);
        }
        setSkipDays(checkedskipDays);
    }

    /**
     * checkbox list for skip options(weekends, past due days)
     */
    const checkboxList = skipPastDates.map(({ label, value }, index) => {
        return (label ? <div className='recurring-skip-list' key={index}>
            <Checkbox className='mr-3' checked={skipDays.includes(true)} value={label} label={label} onChange={() => handleCheckBox(value, label)} />
        </div> : "")
    });

    return (
        <React.Fragment>
            <div className='form-row pt-0 recurring-switch'>
                <div className='form-group col-md-12'>
                    <label className='form-section-heading'>{label.RECURRENCE_PATTERN}</label>
                    <div className='row'>
                        <div className='col-md-6 d-flex justify-content-between'>
                            <span className='small'>{recurrencePattern === number.ONE ? recurrenceDefaults?.recurrancePattern.find(i => i.value === number.ONE).field : recurrenceDefaults?.recurrancePattern.find(i => i.value === number.TWO).field}</span>
                            <Switch
                                onChange={handleRecurrencePattern}
                                checked={recurrencePattern === number.ONE}
                                id='recurring-task-content-pattern-toggle'
                            />
                        </div>
                        {recurrencePattern === number.ONE && <div className='col-md-6'>
                            {checkboxList}
                        </div>}
                    </div>
                </div>
            </div>
            <div className='form-row pt-0 recurring-switch'>
                <div className='form-group col-md-12'>
                    <label className='form-section-heading'>{label.MODE}</label>
                    <div className='row'>
                        <div className='col-md-6 d-flex justify-content-between'>
                            <span className='small'>{recurrenceMode === number.ONE ? recurrenceDefaults?.resetMode.find(i => i.value === number.ONE).field : recurrenceDefaults?.resetMode.find(i => i.value === number.TWO).field}</span>
                            <Switch
                                onChange={handleRecurrenceMode}
                                checked={recurrenceMode === number.ONE}
                                id='recurring-task-content-mode-toggle'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {recurrencePattern === number.ONE ? <div className='form-row'><div className='form-group col-md-6'><label className='form-section-heading'>{label.FREQUENCY}</label></div><div className='form-group col-md-6'>
                <DropDownList data={recurrenceDefaults?.frequency} value={recur} onChange={(e) => setRecur(e.target.value)} dataItemKey="value" textField="field" /></div></div> :
                <div className='form-row'>
                    <div className='form-group col-md-3'>
                        <label className='form-section-heading'>{label.FREQUENCY}</label>
                    </div>
                    <div className='form-group col-md-9 completion-frequency'>
                        <div className='d-flex justify-content-end align-items-center'>
                            <div className='small mr-2'>Every</div>
                            <div className='mr-2'>
                                <NumericTextBox
                                    value={skipCountValue}
                                    min={number.ONE}
                                    id='recurring-task-content-frequency'
                                    onChange={(e) => setSkipCountValue(e.target.value)}
                                />
                            </div>
                            <div className='recurring-dropdown'>
                                <DropDownList data={recurrenceDefaults?.completionFrequency} id='recurring-task-content-frequency-dropdown' value={recur} onChange={(e) => setRecur(e.target.value)} dataItemKey="value" textField="field" />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>

    )
}

export default RecurrenceModesAndDurations;