import React, { useState } from "react";
import { connect } from "react-redux";
import { Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { navBreadcrumb } from "../../../utils";
import config from '../../../env.config';
import { icon, label , number } from "../../../../src/config/";
import { openSidebarProject } from "../../../components/Projects/project.common";
import { useSelector } from "react-redux";
import { subStrings } from "../../../utils/common";
import { Tooltip } from "@progress/kendo-react-tooltip";

const Breadcrumbs = (props) => {
  const { projectId, projectName , queueName , queueId } = useParams();
  const [defaultProjectName, setDefaultProjectName] = React.useState(projectName);
  const { app } = useSelector((state) => state.app);
  const [projectTaskUrl, setProjectTaskUrl] = useState('')
  const [taskURL, setTaskUrl] = useState('');
  const { route } = props;
  const { data } = useSelector((state) => state.allProjects);
  const { user } = useSelector((state) => state.auth);
  const { allProjectsList } = useSelector((state) => state.tasks.defaultDetails);
  const [originalProjectName, setOriginalProjectName] = React.useState('');

  /**
    * sets the defaultProjectName in defaultProjectName state 
    * author @Shivam Mishra
  */
  React.useEffect(() => {
      let projectList = data ? data :  allProjectsList
      let projectName = projectList?.find((i) => i.ProjectId == projectId)?.ProjectName
      if (queueName){setDefaultProjectName(queueName) ;  return  }
      setOriginalProjectName(projectName);
      projectName = projectName?.length > number.TWENTY_FIVE ? subStrings(projectName , number.TWENTY_FIVE)  + "..." : projectName
      setDefaultProjectName(projectName)
  }, [data, projectId, allProjectsList])

    /**
    * intializes the project Name while rendering the first time
    * author @Shivam Mishra
  */
  React.useEffect(() => {
    !queueName && setDefaultProjectName(projectName?.length > number.TWENTY_FIVE ? subStrings(projectName , number.TWENTY_FIVE)  + "..." : projectName)
  }, [])

  React.useEffect(() => {
    setTaskUrl(`${app.tenantAppUrl ? app.tenantAppUrl : config.BASE_URL.APP_URL}/tasks`)
    setProjectTaskUrl(`${app.tenantAppUrl ? app.tenantAppUrl : config.BASE_URL.APP_URL}/tasks/${projectId}/${defaultProjectName}`)
  }, [app])

  /**
   * Show Breadcrumbs
   * @return {JSX}
   */
  const showBreadcrumbs = () => {
    const projectTask = defaultProjectName ? defaultProjectName + " " + label.BREADCRUMB_TASKS : user.myProjectName;
    const isArchived = props.archivedView ? " / " + label.ARCHIVED_TASKS : '';
    return (
      <span className="text-truncate">
        <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
          <span className="cursor-pointer active-user" onClick={() => openSidebarProject(projectId , queueId)} > 
            {originalProjectName?.length > number.TWENTY_FIVE ? 
              <span title={originalProjectName}>{defaultProjectName}</span> 
              :
              <span>{defaultProjectName}</span>
            }
          </span>
          <span >
          {defaultProjectName && " / "}
          {isArchived ? (<a href={defaultProjectName ? projectTaskUrl : taskURL}>{projectTask}</a>) : projectTask}
          {isArchived}
          </span>
        </Tooltip>
      </span >
    )
  }

  return (
    <div className="p-0">
      {route && (<Breadcrumb>
        {route.PARENT_ROUTES && route.PARENT_ROUTES.map((r, index) => (
          <Breadcrumb.Item
            key={index}
            onClick={() => {
              navBreadcrumb(props.history, r.path);
            }}
          >
            {r.header}
          </Breadcrumb.Item>
        ))}
        <Breadcrumb.Item active>
        {route.PROPS.HEADER == label.BREADCRUMB_TASKS ? showBreadcrumbs() : route.PROPS.HEADER}
        </Breadcrumb.Item>
      </Breadcrumb>)}
    </div>
  );
};

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
  return {
    archivedView: state.tasks.archivedView,
  };
}

export default connect(mapStateToProps, null)(Breadcrumbs);