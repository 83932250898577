import { createContext, useState } from "react";
import { number } from "../../config";

export const WorkflowContext = createContext("Unknown");

/**
 * Wrapper component for workflow context provider
 */
export const WorkflowContextProvider = ({ children }) => {
  const [workflowName, setWorkflowName] = useState("");
  const [workflowDesc, setWorkflowDesc] = useState("");
  const [active, setActive] = useState(true);
  const [workflowIcon, setWorkflowIcon] = useState(null);
  const [workflowPrivacy, setWorkflowPrivacy] = useState(null);
  const [showDescription, setShowDescription] = useState(true);
  const [showAttachment, setShowAttachment] = useState(true);
  const [showSubChildTask, setShowSubChildTask] = useState(true);
  const [showComment, setShowComment] = useState(true);
  const [showTaskDescPopup, setShowTaskDescPopup] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [follower, setFollower] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [priority, setPriority] = useState(null);
  const [effort, setEffort] = useState(number.ZERO);
  const [effortUnit, setEffortUnit] = useState(number.ZERO);
  const [dueDateDays, setDueDateDays] = useState(number.THREE);
  const [archiveDays, setArchiveDays] = useState(number.ONE);
  const [selectFirstStage, setSelectFirstStage] = useState(true);
  const [incomingStage, setIncomingStage] = useState(null);
  const [reminder, setReminder] = useState([]);
  const [startFlowAfter, setStartFlowAfter] = useState(number.ZERO);
  const [workflow, setWorkflow] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [returnToOriginal, setReturnToOriginal] = useState(true);
  const [dependency, setDependency] = useState(false);
  const [desc, setDesc] = useState("");
  const [subtaskData, setSubtaskData] = useState([]);
  const [childtaskData, setChildTaskData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [flowElementData, setFlowElementData] = useState([]);
  const [workflowDefaults, setWorkflowDefaults] = useState([]);
  const [existingFlowElements, setExistingFlowElements] = useState([]);
  const [existingSubtask, setExistingSubtask] = useState([]);
  const [existingChildtask, setExistingChildtask] = useState([]);
  const [workflowDetails, setWorkflowDetails] = useState(null);
  const [stages, setStages] = useState([]);
  const [workflowDefaultAssignee, setWorkflowDefaultAssignee] = useState(null);
  const [showDescPopup, setShowDescPopup] = useState(false);
  const [simplifiedTaskDetail, setSimplifiedTaskDetail] = useState(true);
  const [workflowUsageCounts, setWorkflowUsageCounts] = useState(null)
  const [showWorkFlowUsagePopup, setShowWorkFlowUsagePopup] = useState(false);
  const [createdBy, setCreatedBy] = useState(null);
  const [enableWorkflowEmail, setEnableWorkflowEmail] = useState(false);
  const [workflowEmails, setWorkflowEmails] = useState([]);
  const [workflowEmailDomain, setWorkflowEmailDomain] = useState("");
  const [workflowAckEnabled, setWorkflowAckEnabled] = useState(false)
  const [workflowAckId, setWorkflowAckId] = useState(null)
  const [workflowAcknowledgementDesc, setWorkflowAcknowledgementDesc] = useState("")
  const [workflowReminders, setWorkflowReminders] = useState([]);



  const value = {
    workflowName, setWorkflowName, active, setActive, workflowIcon, setWorkflowIcon, workflowDesc, setWorkflowDesc, workflowPrivacy, setWorkflowPrivacy, showDescription, setShowDescription, showAttachment, setShowAttachment, showSubChildTask, showComment, setShowComment, setShowSubChildTask, tags, setTags, follower, setFollower, taskName, setTaskName, priority, setPriority, dueDateDays, setDueDateDays, archiveDays, setArchiveDays, selectFirstStage, setSelectFirstStage, incomingStage, setIncomingStage, reminder, setReminder, workflow, setWorkflow, setStartFlowAfter, startFlowAfter,
    isDefault, setIsDefault, desc, setDesc, returnToOriginal, setReturnToOriginal, tagIds, setTagIds, subtaskData, setSubtaskData, childtaskData, setChildTaskData, attachments, setAttachments, flowElementData, setFlowElementData, workflowDefaults, setWorkflowDefaults, existingFlowElements, setExistingFlowElements, existingSubtask, setExistingSubtask, existingChildtask, setExistingChildtask, stages, setStages,
    workflowDetails, setWorkflowDetails, workflowDefaultAssignee, setWorkflowDefaultAssignee, dependency, setDependency, showDescPopup, setShowDescPopup, simplifiedTaskDetail, setSimplifiedTaskDetail, showWorkFlowUsagePopup, setShowWorkFlowUsagePopup, workflowUsageCounts, setWorkflowUsageCounts, createdBy, setCreatedBy, enableWorkflowEmail, setEnableWorkflowEmail, workflowEmails, setWorkflowEmails, workflowEmailDomain, setWorkflowEmailDomain, effort, setEffort, effortUnit, setEffortUnit, showTaskDescPopup, setShowTaskDescPopup,
    workflowAckEnabled, setWorkflowAckEnabled, workflowAcknowledgementDesc, setWorkflowAcknowledgementDesc, workflowAckId, setWorkflowAckId, workflowReminders, setWorkflowReminders
  }
  return (
    <WorkflowContext.Provider value={value}>{children}</WorkflowContext.Provider>
  )

}