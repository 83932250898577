import { createContext, useContext, useState } from "react";

export const taskOwnerContext = createContext({
  assignmentType  : null , 
  setOwnerAssignmentType : () => {}  , 
  assignedId : null , 
  setAssignedId : () => {} 
});

export function TaskOwnerContextProvider({ children }) {
  const [assignmentType , setOwnerAssignmentType] = useState()
  const [assignedId, setAssignedId] = useState() 
  const [relatedAssignmentList, setRelatedAssignmentList] = useState([]);
  const [relatedAssignmentId, setRelatedAssignmentId] = useState(null);
  const [workflowAssignmentType, setWorkflowAssignmentType] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [externalUser, setExternalUser] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  return (
    <taskOwnerContext.Provider value={{ 
      assignmentType ,
      setOwnerAssignmentType , 
      assignedId, 
      setAssignedId , 
      relatedAssignmentList, 
      setRelatedAssignmentList , 
      relatedAssignmentId, 
      setRelatedAssignmentId , 
      workflowAssignmentType, 
      setWorkflowAssignmentType , 
      currentProject, 
      externalUser,
      setExternalUser , 
      setCurrentProject , 
      isDisabled, setIsDisabled}}>
      {children}
    </taskOwnerContext.Provider>
  );
}

export function useTaskOwnerContext() {
  const {  
    assignmentType ,
    setOwnerAssignmentType , 
    assignedId, 
    setAssignedId , 
    relatedAssignmentList, 
    setRelatedAssignmentList , 
    relatedAssignmentId, 
    setRelatedAssignmentId , 
    workflowAssignmentType, 
    setWorkflowAssignmentType , 
    currentProject, 
    setCurrentProject , 
    externalUser,
    setExternalUser , 
    isDisabled, 
    setIsDisabled
  } = useContext(taskOwnerContext);

  return { 
    assignmentType ,
    setOwnerAssignmentType , 
    assignedId, 
    setAssignedId , 
    relatedAssignmentList, 
    setRelatedAssignmentList , 
    relatedAssignmentId, 
    setRelatedAssignmentId , 
    workflowAssignmentType, 
    setWorkflowAssignmentType , 
    currentProject, 
    setCurrentProject , 
    externalUser,
    setExternalUser ,
    isDisabled, 
    setIsDisabled
  };
}