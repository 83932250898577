import _ from "lodash"
import { setTaskWorkflowDetails } from "../../../../actions/taskSidebar"
import { label, number } from "../../../../config"
import { getWorkflow } from "../../../../shared/services/workflow.services"
import store from "../../../../store"
import { isTrueBit, splitString } from "../../../../utils"
import { assignedTypes } from "../../../Tasks/tasks.constants"
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service"
import { isUserPersonalProject } from "../../TaskSidebarHeader/TaskSidebarHeader.helper"
import { getAssigneeData, getRelatedAssignedType, handleGetProjectWorkflows, userPartOfAssignedProject } from "../../sidebar.helper"

/**
 * checks if the popup should open or not for task reminders and recurring tasks
 * @param {*} task 
 * @returns 
 */
export const showPopupCondition = (task) => {
  return !task?.isFollowed
}

/**
 * Return payload for getting existing related assignedId list(DD4)
 * @param {*} defaultDetails 
 * @param {*} task 
 * @author Himanshu Negi
 * @returns Object
 */

export const existingRelatedAssignedIdListPayload = async (defaultDetails, task) => {
  const projectList = task?.CurrentAssignedType === number.THREE ? [...defaultDetails?.allProjectsList] : [...defaultDetails?.assigneeList];
  const assignedTypePayload = { AssignedType: task?.CurrentAssignedType, AssignedId: task?.CurrentAssignedId, RelatedAssignedType: task?.CurrentRelatedAssignedType }
  const currentRelatedAssignedType = getRelatedAssignedType(assignedTypePayload);
  const workflowAssignmentType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value == currentRelatedAssignedType);
  const assignedId = projectList?.find((project) => project?.value == task?.CurrentAssignedId);
  const assignmentType = assignedTypes?.find(t => t.key == task.CurrentAssignedType);
  return {
    workflowAssignmentType,
    assignedId,
    assignmentType,
    defaultDetails,
  };
}

/**
 * sets dd4 based on dd2 change & returns list
 * case => 2 => when user selects user option in dd2
 * case => 5 => when user selects workflow option in dd2
 * @param {Object} Payload 
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getRelatedAssignmentList = async (payload) => {
  const { workflowAssignmentType, assignedId, assignmentType, defaultDetails } = payload;
  switch (workflowAssignmentType?.value) {
    case number.TWO:
      let assigneeList = assignedId && getAssigneeData(assignedId, defaultDetails);
      return assigneeList;
    case number.FIVE:
      if (assignedId) {
        const project = defaultDetails?.allProjectsList?.find((project) => project.value == (assignmentType?.key == number.THREE ? assignedId?.value : assignedId?.myProjectId));
        let workflows = await handleGetProjectWorkflows(project?.value);
        workflows = project && workflows?.map(({ WorkflowId, WorkflowName, Icon, WorkflowPrivacy, IsDefault }) => ({ value: WorkflowId, label: WorkflowName, privacy: WorkflowPrivacy, icon: Icon, isDefault: IsDefault }));
        workflows = _.orderBy(workflows, [workflow => workflow.label?.toLowerCase()?.trim()], ['asc']);
        return workflows;
      }
    default:
      return [];
  }
}

/**
 * sets dd4 initial state
 * case => 2 => when user selects user option in dd2
 * case => 5 => when user selects workflow option in dd2
 * @param {Object} Payload 
 * @returns {Object}
 * @author Himanshu Negi
 */
export const getInitialRelatedAssignId = async (payload) => {
  const { task, defaultDetails } = payload;
  const assignedTypePayload = { AssignedType: task?.CurrentAssignedType, AssignedId: task?.CurrentAssignedId, RelatedAssignedType: task?.CurrentRelatedAssignedType }
  const currentRelatedAssignedType = getRelatedAssignedType(assignedTypePayload);
  switch (currentRelatedAssignedType) {
    case number.TWO:
      const requiredAssignee = defaultDetails?.assigneeList?.find((assignee) => assignee.value == task?.Assignee);
      return requiredAssignee ? requiredAssignee : { value: null, label: label.UNASSIGNED };
    case number.FIVE:
      if (task?.CurrentAssignedId) {
        const projectList = task?.CurrentAssignedType === number.THREE ? [...defaultDetails?.allProjectsList] : [...defaultDetails?.assigneeList];
        const project = projectList?.find((project) => project.value == task?.CurrentAssignedId);
        const projectId = task?.CurrentAssignedType === number.THREE ? project?.value : project?.myProjectId;
        let workflows = project && await handleGetProjectWorkflows(parseInt(projectId));
        workflows = project && workflows?.map(({ WorkflowId, WorkflowName, Icon, WorkflowPrivacy, IsDefault }) => ({ value: WorkflowId, label: WorkflowName, privacy: WorkflowPrivacy, icon: Icon, isDefault: IsDefault }));
        const requiredWorkflow = workflows?.find((workflow) => workflow?.value == task?.CurrentRelatedAssignedId)
        return requiredWorkflow ? requiredWorkflow : null;
      }
  }
}


/**
 * used to get only specific fields of default workflow
 * @param {*} workflowData 
 * @returns {Object} required data of default workflow
 * @author Prachi Jain
 */
export const getDefaultWorkflowData = (workflowData) => {
  return {
    ...workflowData,
    workflowBasicDetails: { ...workflowData.workflowBasicDetails, Tags: "", Followers: "", TaskName: "", TaskReminders: "" },
    workflowAttachments: [],
    workflowChildTasks: [],
    workflowDescription: "",
    workflowElements: [],
    workflowSubTasks: []
  }
}

/**
 * Returns required relatedAssignId based on relatedAssignType
 * @param {*} payload
 * @author Himanshu Negi 
 * @returns 
 */
export const getRelatedAssignId = async (payload) => {
  const { workflowAssignedType, selectedAssignId, defaultDetails, isNewTask, user, requiredRelatedAssignmentList, task } = payload;
  switch (workflowAssignedType?.value) {
    case number.TWO:
      let assigneeList = selectedAssignId && getAssigneeData(selectedAssignId, defaultDetails);
      const requiredAssignee = assigneeList?.find((assignee) => (isNewTask || task?.CurrentAssignedId !== selectedAssignId?.value) ? assignee?.value == user.id : assignee?.value == task?.CurrentRelatedAssignedId);
      return requiredAssignee;
    case number.FIVE:
      let requiredWorkflow = requiredRelatedAssignmentList?.find((workflow) => (isNewTask || task?.CurrentAssignedId !== selectedAssignId?.value) ? workflow?.isDefault == number.ONE : workflow.value == task?.CurrentRelatedAssignedId);
      requiredWorkflow = requiredWorkflow ? requiredWorkflow : requiredRelatedAssignmentList ? requiredRelatedAssignmentList[number.ZERO] : {};
      return requiredWorkflow;
    default:
      return null;
  }
}


/**
 * sets dd3 & dd4 sates when assignedId(dd2) changes
 * @param {*} requiredPayload 
 * @author Himanshu Negi
 */
export const handleRelatedAssignment = async (taskPayload) => {
  const { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task } = taskPayload;
  const { assignmentType, setRelatedAssignmentList, setWorkflowAssignmentType, setRelatedAssignmentId } = assignmentState;
  const workflowAssignedType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value == (userPartOfAssignedProject({ ...assignmentState, assignedId: selectedAssignId }) ? number.TWO : number.FIVE));
  setWorkflowAssignmentType(workflowAssignedType ? workflowAssignedType : null);
  const payload = { workflowAssignmentType: workflowAssignedType, assignedId: selectedAssignId, assignmentType, defaultDetails }
  const requiredRelatedAssignmentList = await getRelatedAssignmentList(payload);
  setRelatedAssignmentList(requiredRelatedAssignmentList);
  const relatedAssignIdPayload = { workflowAssignedType, selectedAssignId, defaultDetails, isNewTask, user, requiredRelatedAssignmentList, task }
  const relatedAssinedId = await getRelatedAssignId(relatedAssignIdPayload);
  setRelatedAssignmentId(relatedAssinedId ? relatedAssinedId : null);
}

/**
 * sets dd4 sates when relatedAssignType(dd3) changes
 * @param {*} requiredPayload 
 * @author Himanshu Negi
 */
export const relatedAssignedTypeDropDownEffect = async (taskPayload) => {
  const { defaultDetails, isNewTask, user, assignmentState, selectedValue, task } = taskPayload;
  const { assignedId, assignmentType, setRelatedAssignmentList, setRelatedAssignmentId } = assignmentState;
  const payload = { workflowAssignmentType: selectedValue, assignedId, assignmentType, defaultDetails }
  const relatedAssignedIdList = await getRelatedAssignmentList(payload);
  setRelatedAssignmentList(relatedAssignedIdList);
  const relatedAssignIdPayload = { workflowAssignedType: selectedValue, selectedAssignId: assignedId, defaultDetails, isNewTask, user, requiredRelatedAssignmentList: relatedAssignedIdList, task }
  const relatedAssinedId = await getRelatedAssignId(relatedAssignIdPayload);
  setRelatedAssignmentId(relatedAssinedId ? relatedAssinedId : null);
  if (selectedValue?.value == number.FIVE && isNewTask) getSelectedWorkflowDetails(null, null, relatedAssinedId?.value)
  if (selectedValue?.value == number.TWO && isNewTask) {
    const projectWorkflows = await handleGetProjectWorkflows(parseInt(assignedId?.ProjectId))
    getSelectedWorkflowDetails(projectWorkflows, assignedId?.ProjectId, null)
  }
}

/**
 * sets dd1, dd2, dd3, dd3, dd4 sates when relatedAssignType(dd3) changes
 * @param {*} requiredPayload 
 * @author Himanshu Negi
 */
export const initialProjectChangeEffect = async (taskPayload) => {
  const { defaultDetails, assignmentState, isNewTask, task, newTaskData, user } = taskPayload;
  const { setAssignmentType, setAssignedId, setWorkflowAssignmentType, setRelatedAssignmentList, setRelatedAssignmentId } = assignmentState;
  const project = defaultDetails?.allProjectsList?.find((project) => project.value === newTaskData?.project);
  const isPersonalProject = isUserPersonalProject(project, user);
  const requiredAssignType = assignedTypes.find(type => type.key == (isPersonalProject ? number.TWO : number.THREE))
  let workflows = newTaskData?.project && await handleGetProjectWorkflows(parseInt(newTaskData?.project))
  getSelectedWorkflowDetails(workflows, newTaskData?.project)
  setAssignmentType(requiredAssignType ? { ...requiredAssignType } : null);
  const assignedIdList = requiredAssignType?.key === number.TWO ? [...defaultDetails?.assigneeList] : [...defaultDetails?.allProjectsList];
  const requiredProject = assignedIdList?.find((project) => newTaskData?.project === (requiredAssignType?.key === number.TWO ? project.myProjectId : project.value));
  setAssignedId(requiredProject ? requiredProject : null);
  const workflowAssignedType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value == (userPartOfAssignedProject(({ ...assignmentState, assignedId: requiredProject, assignmentType: requiredAssignType }), true) ? number.TWO : number.FIVE));
  setWorkflowAssignmentType(workflowAssignedType ? workflowAssignedType : null);
  const payload = { workflowAssignmentType: workflowAssignedType, assignedId: requiredProject, assignmentType: requiredAssignType, defaultDetails }
  const requiredRelatedAssignmentList = await getRelatedAssignmentList(payload);
  setRelatedAssignmentList(requiredRelatedAssignmentList);
  const relatedAssignIdPayload = { workflowAssignedType, selectedAssignId: requiredProject, defaultDetails, isNewTask, user, requiredRelatedAssignmentList, task }
  const relatedAssignId = await getRelatedAssignId(relatedAssignIdPayload);
  setRelatedAssignmentId(relatedAssignId ? relatedAssignId : null);
}

/**
 * provides the userlevel / projectlevel assignedId  associated with sharedProjectId from shared workflow url  
* @param {object} defaultDetails
 * @param {Int} sharedProjectId
 * @returns {Object} requiredProject
 * @author Shivam Mishra
 */
export const sharedWorkflow = async (defaultDetails, sharedProjectId) => {
  const checkIsPersonal = defaultDetails?.allProjectsList?.some(obj => obj.ProjectId === parseInt(sharedProjectId) && obj.IsPersonal);
  const assignedIdList = checkIsPersonal ? [...defaultDetails?.assigneeList] : [...defaultDetails?.allProjectsList];
  const requiredProject = assignedIdList?.find((project) => checkIsPersonal ? project?.myProjectId == sharedProjectId : project?.value == sharedProjectId);
  return requiredProject
}

/**
 * sets dd1, dd2, dd3, dd3, dd4 states for shared workflow url 
 * @param {object} taskPayload
 * @author Shivam Mishra
 */
export const shareWorkflowEffect = async (taskPayload) => {
  const { defaultDetails, assignmentState, sharedProjectId, sharedWorkflowId } = taskPayload;
  const { setAssignmentType, setAssignedId, setWorkflowAssignmentType, setRelatedAssignmentList, setRelatedAssignmentId } = assignmentState;
  const checkIsPersonal = defaultDetails.allProjectsList.some(obj => obj.ProjectId === parseInt(sharedProjectId) && obj.IsPersonal);
  const reqAssignId = checkIsPersonal ? number.TWO : number.THREE;
  const requiredAssignType = assignedTypes?.find(obj => obj.key === reqAssignId)
  setAssignmentType(requiredAssignType)
  let workflows = parseInt(sharedProjectId) && await handleGetProjectWorkflows(parseInt(sharedProjectId))
  getSelectedWorkflowDetails(workflows, null, sharedWorkflowId)
  const requiredAssignedId = await sharedWorkflow(defaultDetails, sharedProjectId)
  setAssignedId(requiredAssignedId ? requiredAssignedId : null);
  const workflowAssignedType = defaultDetails?.relatedAssignmentTypeList?.find((type) => type.value === number.FIVE);
  setWorkflowAssignmentType(workflowAssignedType ? workflowAssignedType : null);
  const payload = { workflowAssignmentType: workflowAssignedType, assignedId: requiredAssignedId, assignmentType: requiredAssignType, defaultDetails }
  const requiredRelatedAssignmentList = await getRelatedAssignmentList(payload);
  setRelatedAssignmentList(requiredRelatedAssignmentList);
  const relatedAssignId = requiredRelatedAssignmentList?.find((obj) => obj.value === parseInt(sharedWorkflowId))
  setRelatedAssignmentId(relatedAssignId ? relatedAssignId : null)
  return;
}


/**
 * sets selected workflow data in the reducer
 * @param {Array} assignedProjectWorkflows 
 * @param {Integer} projectId 
 * @param {Integer} selectedWorkflowId 
 */
export const getSelectedWorkflowDetails = async (assignedProjectWorkflows, projectId, selectedWorkflowId) => {
  const state = store.getState();
  const { tasks, taskSidebar, cloneTaskDetails } = state;
  const { user } = state.auth
  let workflowId;
  if (assignedProjectWorkflows) {
    workflowId = assignedProjectWorkflows?.find(p => p.IsDefault == number.ONE)?.WorkflowId
    workflowId = workflowId ? workflowId : assignedProjectWorkflows[0]?.WorkflowId
  }
  let workflowData = await getWorkflow({ workflowId: selectedWorkflowId ? selectedWorkflowId : workflowId })
  const userProjectList = tasks?.defaultDetails?.allProjectsList?.filter((project) => { return (project.RoleId || (project.IsPersonal && project.user === user.id)) })
  if (workflowId && userProjectList?.find(p => p.value == projectId && !p.IsPersonal)?.value) {
    workflowData = getDefaultWorkflowData(workflowData)
  }
  store.dispatch(setTaskWorkflowDetails(workflowData))
  await handleTaskKeyUpdate(true, "attachmentCount", cloneTaskDetails?.isCloneTask ? cloneTaskDetails?.cloneTask?.attachmentCount : workflowData?.workflowAttachments?.length)
  await handleTaskKeyUpdate(true, "childOrSubtaskCount", cloneTaskDetails?.isCloneTask ? cloneTaskDetails?.cloneTask?.subtaskCount : (workflowData?.workflowChildTasks?.length + workflowData?.workflowSubTasks?.length))
  const updateDesc = cloneTaskDetails?.isCloneTask ? false : taskSidebar.newTaskData.isDescChanged ? false  : true ;  
  taskSidebar.isNewTask && (updateDesc || cloneTaskDetails?.isCloneTask) &&  handleTaskKeyUpdate(true, 'description', cloneTaskDetails?.isCloneTask ? (cloneTaskDetails?.cloneTask?.description ?? ""): (workflowData?.workflowDescription ?? ""));
}

/**
 * used to add logged in users in workflow follower list if workflow followers doesn't already have it
 * @param {String} followers 
 * @param {Int} user 
 * @returns {String} followerIds with loggedin user's id if required
 * @author Prachi Jain
 */
const addAuthUserToFollowers = (followers, user) => {
  const workflowFollowers = splitString(followers, ",")
  let uniqueWorkflowFollowers;
  if (!workflowFollowers?.includes(user?.toString())) {
    uniqueWorkflowFollowers = followers?.concat(`,${user}`);
  } else uniqueWorkflowFollowers = followers;
  return uniqueWorkflowFollowers;
}

/**
 * create a list of followerIds of the task based on task creation method
 * @param {Object} followersData 
 * @returns {String} followerIds of the task
 * @author Prachi Jain
 */
export const getFollowerList = (followersData) => {
  const { followers, user, isNewTask, defaultDetails } = followersData;
  let selectedFollowers = splitString(followers,",") ? splitString(followers,",") : [];
  selectedFollowers = [...new Set([...selectedFollowers])];
  if(isNewTask) selectedFollowers.push(user)
  let taskFollowers = [] 
  selectedFollowers?.forEach(userId => {
      let follower = defaultDetails?.assigneeList?.find(f => Number(f.value) === Number(userId));
      if (follower) return taskFollowers.push(follower);
  }) 
  return taskFollowers ? taskFollowers : [];
}

/**
 * used to check of only internal user changed or not.
 * @param {object} task 
 * @param {Object} assignmentState 
 * @returns {Boolean} 
 * @author {Prachi Jain}
 */
export const isInternalUserChanged = (task, assignmentState, isApproval) => {
  const { CurrentAssignedId, CurrentAssignedType, Assignee } = task;
  const { assignedId, workflowAssignmentType, assignmentType, relatedAssignmentId } = assignmentState
  if ((CurrentAssignedId == assignedId?.value) && (CurrentAssignedType == assignmentType?.key)
    && (((workflowAssignmentType?.value == number.TWO) && (Assignee != relatedAssignmentId?.value)) || isApproval)
  ) return true;
  else return false
}

/**
 * used to check of external assigment.
 * @param {object} task 
 * @param {Object} assignmentState 
 * @returns {Boolean} 
 * @author {Himanshu Negi}
 */
export const isExternalAssignment = (task, assignmentState) => {
  const { CurrentAssignedId, CurrentAssignedType } = task;
  const { assignedId, assignmentType } = assignmentState
  return ((CurrentAssignedId == assignedId?.value) && (CurrentAssignedType == assignmentType?.key))
}

/**
 * used to get notification verbiage on task assignment update
 * @param {object} task 
 * @param {Object} assignmentState 
 * @param {Boolean} isApproval 
 * @returns {String} Notification Verbiage
 * @author {Prachi Jain}
 */
export const getAssignmentNotification = (task, assignmentState, isApproval) => {
  const { CurrentAssignedId, CurrentAssignedType } = task;
  const { assignedId, workflowAssignmentType, assignmentType } = assignmentState
  if ((CurrentAssignedId != assignedId.value) || (CurrentAssignedType != assignmentType.key)) {
    return label.TASK_ROUTE_AMENDED
  }
  else if (workflowAssignmentType.value == number.FIVE || isApproval) {
    return label.TASK_AMENDED;
  }
}
