import React from 'react';
import { label } from '../../../config';
import { useSelector } from 'react-redux';

/**
 * adds "Show more" label in the "Detail" panel bar item 
 * @author Muskan Thakur
 */
const CustomPanelBarItemContent = ({ title, showTaskDetailPanel }) => {
    const { task } = useSelector((state) => state.taskSidebar)
  return (
    <div className='panel-bar-item'>
      <span>{title}</span>
      {title === label.DETAILS && showTaskDetailPanel && task?.taskId ? <span className='show-more'>{label.SHOW_MORE}</span> : <></>}
    </div>
  );
};

export default CustomPanelBarItemContent;