import { applyMiddleware, compose, createStore } from "redux";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import thunk from "redux-thunk";
import reducer from "../reducers/index";
import { config } from "./config";

const initialState = {};

const middleware = [thunk, createStateSyncMiddleware(config)];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, initialState, applyMiddleware(...middleware));

// redux devtools without persist
// const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(...middleware)));

// redux dev tools
// const store = createStore(persistedReducer, initialState, composeEnhancer(applyMiddleware(...middleware)));

initMessageListener(store);

export default store;
