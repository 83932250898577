import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { label, number } from "../../../config";

export const ReminderContext = createContext("Unknown");

export const ReminderContextProvider = ({ children }) => {
    const { task } = useSelector((state) => state.taskSidebar)
    const [onDueDate, setOnDueDate] = useState(true);
    const [daysBeforeChecked, setDaysBeforeChecked] = useState(false);
    const [daysAfterChecked, setDaysAfterChecked] = useState(false)
    const [daysBefore, setDaysBefore] = useState(number.ONE);
    const [daysAfter, setDaysAfter] = useState(number.TWO);
    const [showCustomPopup, setShowCustomPopup] = useState(false);
    const [reminderMessage, setReminderMessage] = useState(task.Name);
    const [onTomorrow, setOnTomorrow] = useState(true)
    const [displayWarning, setDisplayWarning] = useState(false);
    const [isAddClicked, setIsAddClicked] = useState(false)
    const [editAddRem, setEditAddRem] = useState(false)
    const [isDueDatePassed, setIsDueDatePassed] = useState((!task.DueDate || task.DueDate === label.NO_DUE_DATE) ? true : new Date(task.DueDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0));
    const [isReminderMessageEmpty, setIsReminderMessageEmpty] = useState(false)

    // States for reminder list 

    const [editRem, setEditRem] = useState()
    const [editName, setEditName] = useState()
    const [editDate, setEditDate] = useState()
    const [editTime, setEditTime] = useState()
    const [isDynamic, setIsDynamic] = useState(false)
    const [delId, setDelId] = useState()
    const [isupdatingReminder, setIsUpdatingReminder] = useState(false)
    const [ isReminderClicked ,setIsReminderClicked] = useState(false);
    const [editNoOfDays, setEditNoOfDays] = useState()

    const value = {
        onDueDate, setOnDueDate, daysBeforeChecked, setDaysBeforeChecked, daysAfterChecked, setDaysAfterChecked, daysBefore, setDaysBefore, daysAfter, setDaysAfter, showCustomPopup, setShowCustomPopup, reminderMessage, setReminderMessage,
        onTomorrow, setOnTomorrow, displayWarning, setDisplayWarning, isAddClicked, setIsAddClicked, editAddRem, setEditAddRem,
        isDueDatePassed, setIsDueDatePassed , editRem , setEditRem , editName , setEditName , editDate , setEditDate , editTime , setEditTime , isDynamic , setIsDynamic , delId , setDelId , isupdatingReminder , setIsUpdatingReminder , isReminderClicked , setIsReminderClicked,editNoOfDays , setEditNoOfDays,isReminderMessageEmpty , setIsReminderMessageEmpty
    }
    return (<ReminderContext.Provider value={value}>{children}</ReminderContext.Provider>)

}

