import { PopupPropsContext } from "@progress/kendo-react-popup";
import React, { Suspense, lazy, useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Link,
  BrowserRouter as Router,
  useHistory,
} from "react-router-dom";
import { alertNotification } from "../../actions/alertNotification";
import Notify from "../../shared/components/Kendo/Notify";
import { openSidebar } from "../Tasks/tasks.service";
import AppComponents from "./AppComponents";
import "../../assets/styles/main.scss";
import "@progress/kendo-theme-bootstrap/dist/all.scss";
import TourGuide from "../../TourGuide/TourGuide";
import { ErrorBoundary } from "react-error-boundary";
import './fallback.scss';
import FallBack from "./FallBack";

const Home = lazy(() => import('../Home/Home'));
const Tasks = lazy(() => import('../Tasks/Tasks'));
const Login = lazy(() => import('../Login/Login'));
const Signup = lazy(() => import('../SignUp/Signup'));
const ForgotPassword = lazy(() => import('../ForgotPassword/ForgotPassword'));
const Project = lazy(() => import('../Projects/Project/Project'));
const ResetPassword = lazy(() => import('../ResetPassword/ResetPassword'));
const Queue = lazy(() => import('../Queues/Queue/Queue'));
const ActivityDigest = lazy(() => import('../ActivityDigest/ActivityDigest'));
const Inbox = lazy(() => import('../Inbox/Inbox'));
const Calendar = lazy(() => import('../Calendar/Calendar'));
const OrganisationDashboard = lazy(() => import('../OrganisationDashboard/OrganisationDashboard'));
const HelpSupport = lazy(() => import('../HelpSupport/HelpSupport'));
const ManageUsers = lazy(() => import('../Admin/ManageUsers/ManageUsers'));
const RolePermissions = lazy(() => import('../Admin/RolePermissions/RolePermissions'));
const ManageOrganization = lazy(() => import('../Admin/ManageOrganization/ManageOrganization'));
const ManageUtilization = lazy(() => import('../Admin/ManageOrganization/ManageUtilization'));
const ActivityLog = lazy(() => import('../Admin/ActivityLog/ActivityLog'));
const ViewOrganisation = lazy(() => import('../SuperAdmin/ViewOrganisation'));
const SuperAdminSystemStream = lazy(() => import('../SuperAdmin/SuperAdminSystemStream'));
const QueueTasks = lazy(() => import('../Queues/QueueTasks/QueuePage'));
const Workflow = lazy(() => import('../Workflow/Workflow'));
const VerifyCompanyEmail = lazy(() => import('../Admin/ManageOrganization/VerifyCompanyEmail'));
const RecentHistoryPage = lazy(() => import('../RecentHistory/RecentHistoryPage'));
const NotificationPage = lazy(() => import('../Notifications/NotificationPage'));
const CommentPage = lazy(() => import('../Comments/CommentPage'));
const ReminderPage = lazy(() => import('../Tasks/Reminders/ReminderPage'));
const UsageSummary = lazy(() => import('../Admin/ManageOrganization/UsageSummary/UsageSummary'));
const TagsCommonPage = lazy(() => import('../Admin/ManageOrganization/TagsCommonPage'));
const UserOnboarding = lazy(() => import('../UserOnboarding/UserOnboarding'));
const ProfileSetup = lazy(() => import('../ProfileSetup/ProfileSetup'));
const SpamList = lazy(() => import('../ManageEmails/SpamList'));
const ManageEmails = lazy(() => import('../ManageEmails/ManageEmails'));
const TenantLogin = lazy(() => import('../TenantLogin/TenantLogin'))

const App = (props) => {
  const { auth } = props;
  const { showAlert, alertMsg, alertIcon, taskPayload } = useSelector((state) => state.alertNotification);
  const { currentTheme, status } = useThemeSwitcher();
  const dispatch = useDispatch();
  const components = {
    "Home": Home,
    "Tasks": Tasks,
    "Queues": Queue,
    'ActivityDigest': ActivityDigest,
    'Inbox': Inbox,
    'Calendar': Calendar,
    'HelpSupport': HelpSupport,
    'Projects': Project,
    'Login': Login,
    'Signup': Signup,
    'ForgotPassword': ForgotPassword,
    'ResetPassword': ResetPassword,
    'ManageUsers': ManageUsers,
    'ManageOrganization': ManageOrganization,
    'RolePermissions': RolePermissions,
    'ActivityLog': ActivityLog,
    'ViewOrganisation': ViewOrganisation,
    'SuperAdminSystemStream': SuperAdminSystemStream,
    'QueueTasks': QueueTasks,
    'Workflow': Workflow,
    'OrganisationDashboard': OrganisationDashboard,
    'VerifyCompanyEmail': VerifyCompanyEmail,
    "RecentHistoryPage": RecentHistoryPage,
    "NotificationPage": NotificationPage,
    "ReminderPage": ReminderPage,
    "UsageSummary": UsageSummary,
    "TagsCommonPage": TagsCommonPage,
    "ProfileSetup": ProfileSetup,
    "UserOnboarding": UserOnboarding,
    "ManageUtilization": ManageUtilization,
    "CommentPage": CommentPage,
    "SpamList": SpamList,
    "ManageEmails": ManageEmails,
    "TenantLogin": TenantLogin
  };


  const onClickNotification = () => {
    taskPayload && openSidebar({ id: taskPayload.taskId, userId: auth.user.id, taskHistoryId: taskPayload.taskHistory })
  }
  useEffect(() => {
    setTimeout(() => {
      if (showAlert) {
        dispatch(alertNotification(false, null, null));
      }
    }, [3000])
  }, [showAlert])
  const renderLoader = () => <p>Loading...</p>;
  const wrapper = React.useRef(null);
  function getTheme(status, theme) {
    if (status === 'loading' || status === 'idle') {
      return 'd-none';
    } else {
      return theme;
    }
  }

  return (
    <Suspense fallback={renderLoader}>
      <div id="layout-wrapper" ref={wrapper} className={getTheme(status, currentTheme)}>
        <PopupPropsContext.Provider value={props => ({
          ...props,
          appendTo: wrapper.current
        })}>
          <div className="sidenav-overlay visible position-fixed d-none"></div>
          <Router>
            <TourGuide />
            <ErrorBoundary FallbackComponent={FallBack}>
              <AppComponents components={components} />
            </ErrorBoundary>
          </Router>
          {showAlert &&
            <Notify message={alertMsg} icon={alertIcon} onClicknotif={onClickNotification} />}
        </PopupPropsContext.Provider>
      </div>
    </Suspense>
  )
}
/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */
function mapStateToProps(state) {
  return {
    auth: state.auth,
    loading: state.loader.loading
  };
}
export default connect(mapStateToProps)(App);
