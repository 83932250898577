import * as ACTION_TYPES from "../actions/action-types";
import { number } from "../config";

const initialState = {
  allTasks: {},
  taskLogs: [],
  cardCounts: {},
  archivedView: false,
  defaultDetails: { isAdded: false },
  apiCallCompleted: false,
  filterSaved: true,
  selectedView: "List View",
  filterGroup: "",
};

/**
 * used to set attachments to the tasks
 * @param {*} requiredTasks
 * @param {*} action
 * @returns {requiredTasks}
 */
const setAttachments = (requiredTasks, action) => {
  let task = requiredTasks?.find((t) => t.Id == action.payload.taskId);
  if (task) {
    task.attachments = action.payload.attachments;
  }
  return requiredTasks;
};

/**
 * used to set pinned and pinnedSortId of the tasks
 * @param {*} requiredTasks
 * @param {*} action
 * @returns {requiredTasks}
 */
const setPinTasks = (requiredTasks, action) => {
  let task = requiredTasks?.find((t) => t?.Id == action?.payload?.task?.Id || t?.Id == action.payload.task?.taskId);
  if (task) {
    task.pinnedSortId = action.payload.pinnedSortId;
    task.pinned = !action.payload.task.pinned;
  }
  return requiredTasks;
};

/**
 * used to set Description to the tasks
 * @param {*} requiredTasks
 * @param {*} action
 * @returns {requiredTasks}
 */
const setDescription = (requiredTasks, action) => {
  let task = requiredTasks?.find((t) => t.Id == action.payload.taskId);
  if (task) {
    task.Description = action.payload.description;
  }
  return requiredTasks;
};

/**
 * Updates the recurrenceId of a specific task within an array of tasks.
 * @param {*} requiredTasks 
 * @param {*} action 
 * @returns tasks 
 * @author Muskan Thakur
 */
const setTaskRecurrence = (requiredTasks, action) => {
  return requiredTasks.map(task => {
    if (task.Id === action.payload.task) {
      return { 
        ...task, 
        recurrenceId: action.payload.recurrenceId,
        recurrenceMode: action.payload.recurrenceMode,
      };
    }
    return task;
  });
};

/**
 * Updates object keys within an array
 * @param {Array} arrayList
 * @param {Number/String} selector
 * @param {Object} mergeObj
 * @returns
 */
const updateObjectInArray = (arrayList, selector, mergeObj) => {
  let index = arrayList?.findIndex((element) => element.Id === selector);
  if (index >= number.ZERO) arrayList[index] = { ...arrayList[index], ...mergeObj };
  return arrayList;
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_TASKS:
      return {
        ...state,
        allTasks: action.payload,
      };
    case ACTION_TYPES.SET_PINNED:
      if (state.allTasks.superset?.length) {
        let allTask = state.allTasks;
        let { task } = action.payload;
        return {
          ...state,
          allTasks: {
            superset: allTask.superset,
            myTasks: setPinTasks(allTask.myTasks, action),
            projectTasks: setPinTasks(allTask.projectTasks, action),
          },
        };
      }
      break;
    case ACTION_TYPES.ARCHIVED_COUNT:
      return {
        ...state,
        cardCounts: action.payload,
      };
    case ACTION_TYPES.FILTER_SAVED:
      return {
        ...state,
        filterSaved: action.payload,
      };
    case ACTION_TYPES.FILTER_GROUP:
      return {
        ...state,
        filterGroup: action.payload,
      };
    case ACTION_TYPES.ARCHIVED_VIEW:
      return {
        ...state,
        archivedView: action.payload,
      };
    case ACTION_TYPES.DEFAULT_TASK_VALUES:
      return { ...state, defaultDetails: action.payload };
    case ACTION_TYPES.SET_ASSIGNEE_LIST:
      return { ...state, defaultDetails: { ...state.defaultDetails, assigneeList: action.payload } };
    case ACTION_TYPES.GET_TASK_ATTACHMENTS:
      if (state.allTasks.superset?.length) {
        let allTask = state.allTasks;
        return {
          ...state,
          allTasks: {
            superset: setAttachments(allTask.superset, action),
            myTasks: setAttachments(allTask.myTasks, action),
            projectTasks: setAttachments(allTask.projectTasks, action),
          },
        };
      }
      break;
    case ACTION_TYPES.UPDATE_TASK_DESCRIPTIONS:
      if (state.allTasks.superset?.length) {
        let allTask = state.allTasks;
        return {
          ...state,
          allTasks: {
            superset: setDescription(allTask.superset, action),
            myTasks: setDescription(allTask.myTasks, action),
            projectTasks: setDescription(allTask.projectTasks, action),
          },
        };
      }
      break;
    case ACTION_TYPES.UPDATE_TASK_RECURRENCE:
      if (state.allTasks.superset?.length) {
        let allTask = state.allTasks;
        return {
          ...state,
          allTasks: {
            superset: setTaskRecurrence([...allTask.superset], action),
            myTasks: setTaskRecurrence([...allTask.myTasks], action),
            projectTasks: setTaskRecurrence([...allTask.projectTasks], action),
          },
        };
      }
      return state;
    case ACTION_TYPES.UPDATE_TASK_DETAIL:
      if (state.allTasks) {
        let allTask = state.allTasks;
        let task = action.payload;
        if (task) {
          return {
            ...state,
            allTasks: {
              ...state?.allTasks,
              superset: updateObjectInArray(allTask.superset, task.taskId, task.detail),
              myTasks: updateObjectInArray(allTask.myTasks, task.taskId, task.detail),
              projectTasks: updateObjectInArray(allTask.projectTasks, task.taskId, task.detail),
            },
          };
        } else return null;
      }
      break;
    case ACTION_TYPES.API_COMPLETED:
      return {
        ...state,
        apiCallCompleted: action.payload,
      };
    case ACTION_TYPES.SELECTED_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };
    case ACTION_TYPES.SHOW_TASKS_WITHOUT_DUEDATE:
      if (state.allTasks) {
        let allTask = state.allTasks;
        return {
          ...state,
          allTasks: {
            superset: allTask.superset?.filter((task) => !task.DueDate),
            myTasks: allTask.myTasks?.filter((task) => !task.DueDate),
            projectTasks: allTask.projectTasks?.filter((task) => !task.DueDate),
          },
        };
      } else return state.allTasks;
    case ACTION_TYPES.SET_MY_TASKS:
      return { ...state, allTasks: { ...state.allTasks, myTasks: action.payload.newMyTasks, projectTasks: action.payload.newProjectTasks } };

    case ACTION_TYPES.SET_PROJECT_OWNERS:
      return { ...state, allTasks: { ...state.allTasks, projectOwners: action.payload?.filter((user) => user.RoleId === number.FOUR) } };

    case ACTION_TYPES.UPDATE_TASK: {
      const isMyTasksEmpty = state.allTasks.myTasks?.length === 0;
      return { ...state, allTasks: isMyTasksEmpty ? { ...state.allTasks, projectTasks: action.payload } : { ...state.allTasks, myTasks: action.payload } };
    }

    case ACTION_TYPES.UPDATE_PROJECTTASK:
      return { ...state, allTasks: { ...state.allTasks, projectTasks: action.payload } };

    default:
      return state;
  }
};

export default tasks;
