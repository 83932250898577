import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Switch } from '@progress/kendo-react-inputs';
import { Input } from '@progress/kendo-react-inputs';
import { label, number } from '../../../../config';
import { userDropdownData } from '../../layout.constants';
import { isEmpty } from 'lodash';
import { getDateFromTime } from "../../../../helper/common";
import { getIcon } from "../../../../utils";
import { configurePlanMyDay, getUserEmailControl, handleUserEmailControl } from '../../../services/user.service';
import { updateCurrUser } from '../../../../actions/auth';
import { PROJECT_TYPE } from '../../../../components/ProjectSidebar/projectSidebar.constants';


/**
  /**
 * PreferencesSettings component is responsible for rendering user preference settings.
 * It allows users to customize their notification preferences, such as daily digest updates,
 * task update notifications, and follower update notifications. Users can toggle these preferences
 * on or off using switches. Additionally, users can set a "Do Not Disturb" (DND) time range during
 * which they do not wish to receive notifications.
  * @author Dimple Sahota
  */

const PreferencesSettings = () => {
    const [showTime, setShowTime] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [isDailyDigestEnabled, setIsDailyDigestEnabled] = useState(false);
    const [isTaskUpdateEnabled, setIsTaskUpdateEnabled] = useState(false);
    const [isFollowerUpdateEnabled, setIsFollowerUpdateEnabled] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [isDndValid, setIsDndValid] = useState(true);
    const [showError, setShowError] = useState(false);
    const [profileData, setProfileData] = useState(userDropdownData.PROFILE_MODAL);
    const [planMydayActive, setPlanMyDatActive] = useState(false);
    const [dueTaskIn, setDueTaskIn] = useState(null);
    const [includeNoDueDate, setIncludeNoDueDateTasks] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    /**
     * Reacts to changes in the user state and updates accordingly.
     */
    useEffect(() => {
        setUserInfo(user);
    }, [user]);

    /**
     * Matches user information to the state based on the profile data.
     */
    useEffect(() => {
        matchStateToUserInfo();
    }, [profileData]);

    /**
     * Fetches user email control settings.
     */
    useEffect(() => {
        (async () => {
            const useremailPrefs = await getUserEmailControl(user.id);
            setIsTaskUpdateEnabled(useremailPrefs.IsTaskUpdateEnabled)
            setIsDailyDigestEnabled(useremailPrefs.IsDailyDigestEnabled)
            setIsFollowerUpdateEnabled(useremailPrefs.IsFollowerUpdateEnabled)
        })();
    }, [user.id]);

    /**
     * Updates user preferences based on user information.
     */
    useEffect(() => {
        const isEnabled = [isTaskUpdateEnabled, isDailyDigestEnabled, isFollowerUpdateEnabled].some(enabled => enabled);
        setShowNotification(isEnabled);

        profileData?.optionsData?.forEach((field) => {
            if (field.key === 'EmailNotification') {
                field.value = isEnabled;
            }
        });
    }, [isTaskUpdateEnabled, isDailyDigestEnabled, isFollowerUpdateEnabled]);

    /**
     *  sets initial state for plan my day
     *  @author Himanshu Negi
     */
    useEffect(() => {
        setPlanMyDatActive(user?.PlanMyDay ? true : false);
        setDueTaskIn(user?.PlannedTaskDueDays);
        setIncludeNoDueDateTasks(user?.IncludeNoDueDate ? true : false);
    }, [user?.PlanMyDay, user?.PlannedTaskDueDays, user?.IncludeNoDueDate])


    /**
     * Matches user information to the state based on the profile data.
     */
    const matchStateToUserInfo = () => {
        let profileDataCopy = { ...profileData };
        Object.entries(profileDataCopy).forEach(([key, subObj]) => {
            subObj.forEach((field, index) => {
                assignValueToState(field, index, subObj, key);
            });
        });
    };

    /**
     * Assigns a value to the state based on the field key.
     * 
     * @param {Object} field - The field object.
     * @param {number} index - The index of the field.
     * @param {Array} stateField - The state field array.
     * @param {string} jsonKey - The key of the JSON object.
     */
    const assignValueToState = (field, index, stateField, jsonKey) => {
        let itemValue = userInfo[field.key];

        if (Object.keys(userInfo).indexOf(field.key) > number.MINUS_ONE) {
            if (itemValue && itemValue.data) {
                itemValue = itemValue.data[number.ZERO] === number.ZERO ? false : true;
            }
            handleChange(itemValue, index, stateField);
            if (field.key === profileData.optionsData[number.ZERO].key) {
                showHideTime(itemValue);
            }
        } else {
            handleChange(profileData[jsonKey][index].defaultValue, index, stateField);
        }
    };

    /**
     * Shows or hides the DND (Do Not Disturb) time range.
     * 
     * @param {boolean} show - Whether to show the DND time range.
     */
    const showHideTime = (show) => {
        setShowTime(show ? true : false);
        resetTime();
    };

    /**
     * Resets the DND (Do Not Disturb) time range.
     */
    const resetTime = () => {
        userDropdownData.PROFILE_MODAL.dndData.forEach((field, index) => {
            handleChange(field.defaultValue, index, profileData.dndData);
        });
    };

  


    /**
     * Handles the change event of a switch component.
     * 
     * @param {boolean} value - The new value of the switch.
     * @param {Object} field - The field object associated with the switch.
     * @param {number} index - The index of the switch field.
     */
    const handleSwitchChange = (value, field, index) => {
        if (field.key === userDropdownData.PROFILE_MODAL["optionsData"][number.ZERO].key) {
            showHideTime(value);
        }
        if (field.key === 'EmailNotification') {
            updateEmailToggleKeys(value, index, profileData?.optionsData);
        }


        if (field.key === userDropdownData.PROFILE_MODAL.optionsData[number.TWO].key) {
            togglePlanMyDay(value);
        }


    };

    /**
 * Handles the change event of an input component.
 *
 * @param {Event} e - The event object.
 * @param {number} index - The index of the input component.
 */
    const handleInputChange = (e, index) => {
        handleChange(e.target.value, index, profileData?.optionsData);
    };

    /**
     * Handles changes in the input value.
     * 
     * @param {string} value - The new value.
     * @param {number} index - The index of the data.
     * @param {Array} data - The data array.
     */
    const handleChange = (value, index, data) => {
        data[index].value = value;
    };


    /**
     * Updates email toggle keys and dispatches actions accordingly.
     * 
     * @param {boolean} value - The new value.
     * @param {number} index - The index of the data.
     * @param {Array} data - The data array.
     */
    const updateEmailToggleKeys = (value, index, data) => {
        const field = data[index];
        if (!field) return;
        if (field.key === 'EmailNotification') {
            field.value = value;
            if (field.children && Array.isArray(field.children)) {
                field.children.forEach((child, childIndex) => {
                    child.value = value;
                });
            }
            setShowNotification(value);
            setIsDailyDigestEnabled(value);
            setIsTaskUpdateEnabled(value);
            setIsFollowerUpdateEnabled(value);

            dispatch(handleUserEmailControl({
                userId: user.id,
                isTaskUpdateEnabled: value,
                isDailyDigestEnabled: value,
                isFollowerUpdateEnabled: value
            }));
        }
        checkForValidation(index, data);

    }

    /**
     * Updates child toggle keys and dispatches actions accordingly.
     * 
     * @param {Event} event - The event object.
     * @param {Object} field - The field object.
     * @param {number} childIndex - The index of the child.
     */
    const updateChildToggleKeys = (event, field, childIndex) => {
        const child = field.children[childIndex];
        child.value = event.target.value;

        if (field.children[childIndex].key === 'FollowedTasks') {
            commonFunc('FollowedTasks', event.target.value, setIsFollowerUpdateEnabled);
        }
        else if (field.children[childIndex].key === 'DailyDigestUpdates') {
            commonFunc('DailyDigestUpdates', event.target.value, setIsDailyDigestEnabled);
        } else {
            commonFunc('AssignedTasks', event.target.value, setIsTaskUpdateEnabled);
        }
    };

    /**
     * Updates the user preferences based on the toggle key and child value, and dispatches corresponding actions.
     * 
     * @param {string} toggleKey - The key indicating which preference to update.
     * @param {boolean} childValue - The new value for the preference.
     * @param {function} setUpdateEnabled - The setter function to update the enabled state.
     */
    const commonFunc = (toggleKey, childValue, setUpdateEnabled) => {

        setUpdateEnabled(childValue);

        dispatch(handleUserEmailControl({
            userId: user.id,
            isTaskUpdateEnabled: toggleKey === 'AssignedTasks' ? childValue : isTaskUpdateEnabled,
            isDailyDigestEnabled: toggleKey === 'DailyDigestUpdates' ? childValue : isDailyDigestEnabled,
            isFollowerUpdateEnabled: toggleKey === 'FollowedTasks' ? childValue : isFollowerUpdateEnabled
        }
        ));
    };

    /**
     * Handles state updates after validation checks.
     * 
     * @param {string} stateName - The name of the state.
     * @param {boolean} bitVal - The value of the bit.
     * @param {Array} data - The data array.
     */
    const setStateAfterValidation = (stateName, bitVal, data) => {
        if (stateName && bitVal !== null) {
            if (stateName === 'isDndValid') {
                setIsDndValid(bitVal);
            }
        }
    };

    /**
     * Checks if a specific toggle key is enabled.
     * 
     * @param {string} key - The toggle key.
     * @return {boolean} The state of the toggle key.
     */
    const checkFunction = (key) => {
        if (key === 'FollowedTasks') return isFollowerUpdateEnabled
        if (key === 'DailyDigestUpdates') return isDailyDigestEnabled
        if (key === 'AssignedTasks') return isTaskUpdateEnabled
        if (key === 'DnD') return isDndValid
        if (key === 'PlanMyDay') return planMydayActive
        return showNotification
    }

    /**
     * Checks for validation based on the data key.
     * 
     * @param {number} index - The index of the data.
     * @param {Array} data - The data array.
     */
    const checkForValidation = (index, data) => {
        const key = data[index].key;

        if (key === 'DndStartTime' || key === 'DndEndTime') {
            validateTime(data);
        } else {
            setStateAfterValidation(null, null, data);
        }
    };

    /**
     * Validates the DND (Do Not Disturb) time range.
     * 
     * @param {Array} data - The data array.
     */
    const validateTime = (data) => {
        let startTime = '';
        let endTime = '';
        data.forEach((field) => {
            if (!isEmpty(field.value)) {
                switch (field.key) {
                    case 'DndStartTime':
                        startTime = getDateFromTime(field.value);
                        break;
                    case 'DndEndTime':
                        endTime = getDateFromTime(field.value);
                        break;
                    default:
                        return null;
                }
            }
        });
        if (startTime > endTime) {
            setShowError(true);
            setStateAfterValidation('isDndValid', false, data);
        } else {
            setShowError(false);
            setStateAfterValidation('isDndValid', true, data);
        }
    };
    /**
     * plan my switch input handler
     * @param {*} value 
     * @author Himanshu Negi
     */
    const togglePlanMyDay = (value) => {
        setPlanMyDatActive(value);
        const payload = { isPlanMyDayActive: value ? number.ONE : number.ZERO, plannedTaskDueDays: number.THREE, includeDueDate: number.ZERO, userId: userInfo.id }
        const updatedUser = { PlanMyDay: value ? number.ONE : number.ZERO, IncludeNoDueDate: number.ZERO, PlannedTaskDueDays: number.THREE }
        dispatch(updateCurrUser(updatedUser))
        configurePlanMyDay(payload);
    }

    /**
     * Planned task due days input handler
     * @param {Object} e 
     * @author Himanshu Negi
     */
    const plannedTaskChangeHandler = (e) => {
        if (e.target.value < number.ZERO) return;
        setDueTaskIn(e.target.value);
        const payload = { isPlanMyDayActive: planMydayActive, plannedTaskDueDays: e.target.value, includeDueDate: includeNoDueDate, userId: userInfo.id }
        const updatedUser = { PlannedTaskDueDays: e.target.value }
        dispatch(updateCurrUser(updatedUser))
        configurePlanMyDay(payload);
    }

    /**
     * include due date checkbox handler
     * @param {Object} e 
     * @author Himanshu Negi
     */
    const includeNoDueDateHandler = (e) => {
        setIncludeNoDueDateTasks(e.value);
        const payload = { isPlanMyDayActive: planMydayActive, plannedTaskDueDays: dueTaskIn, includeDueDate: e.value, userId: userInfo.id }
        const updatedUser = { IncludeNoDueDate: e.value ? number.ONE : number.ZERO }
        dispatch(updateCurrUser(updatedUser))
        configurePlanMyDay(payload);
    }

    return (
        <div className="preferences-settings-container">
            <div className="row mt-1" key={number.ONE}>
                <div className="col-md-6">
                    {profileData?.optionsData?.map((field, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="row mb-3 mt-2">
                                    <div className="col-md-12 option-icon d-flex justify-content-between">
                                        <div className='d-flex items-center'>
                                          {getIcon(field.icon)} {field.label}
                                        </div>
                                        <div id="option-switch">
                                            {field.children || (field.key === "PlanMyDay") ? (
                                                  <Switch
                                                    size="small"
                                                    checked={checkFunction(field.key)}
                                                    onChange={({ value }) => { handleSwitchChange(value, field, index) }
                                                    }
                                                />
                                            ) : (
                                               <Switch
                                                    size="small"
                                                    onChange={({ value }) => { handleSwitchChange(value, field, index) }
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* Render children */}
                                {showNotification && field.children && field.value && field.children.map((child, childIndex) => (
                                    <div className="row mb-3 mt-2 ml-4" key={childIndex} >
                                        <div className="col-md-12 option-icon d-flex justify-content-between">
                                            <div>
                                                {child.label}
                                            </div>
                                            <div id="option-switch">
                                                <Switch
                                                    size="small"
                                                    key={field.key}
                                                    checked={checkFunction(field.children[childIndex].key)}
                                                    onChange={(event) => { updateChildToggleKeys(event, field, childIndex) }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="col-md-6">
                    <div className="row">
                        {showTime && profileData?.dndData?.map((field, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div className="col-md-6" key={index} id="dnd-option-switch">
                                        <Input
                                            label={field.label}
                                            defaultValue={field.value}
                                            type="time"
                                            onChange={(e) => {
                                                handleInputChange(e, index)
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        {showError && showTime && (
                            <small className="text-danger">
                                {label.TIME_ERROR}
                            </small>
                        )}
                    </div>
                </div>
            </div>

            {planMydayActive && <>
                <div className='user-plan-my-day-preferences'>
                    <div className='user-plan-my-day-preferences-date d-flex align-items-center'>
                        <label htmlFor='user-plan-my-day-date'>Show Tasks due in</label>
                        <Input id='user-plan-my-day-date' className='user-plan-my-day-date-input d-inline width-100' type='number' value={dueTaskIn} onChange={plannedTaskChangeHandler} />
                        <span>days</span>
                    </div>
                    <div className='user-plan-my-day-preferences-no-date d-flex align-items-center'>
                        <label htmlFor='user-plan-my-day-no-due-date'>Include tasks without due date</label>
                        <Checkbox id='user-plan-my-day-no-due-date' checked={includeNoDueDate} onChange={includeNoDueDateHandler} />
                    </div>
                </div>
            </>}
        </div>
    );
};

export default PreferencesSettings;