import { number } from "../../config";


/**
 * common function for updating grid values
 * @param { Object } allTasks 
 * @param { String } updatedKey 
 * @param  { Integer } taskId 
 * @returns 
*/
export const updateGridWithKey = (allTasks, updatedKey, taskId) => {
    const updateTask = (task) => task.Id === taskId ? { ...task, [updatedKey]: number.ZERO } : task;
    const myTasks = allTasks?.myTasks?.map(updateTask);
    const projectTasks = allTasks?.projectTasks?.map(updateTask);
    const superset = allTasks?.superset?.map(updateTask);
    return { ...allTasks, myTasks, projectTasks, superset }
}

/**
 * common function for updating grid values
 * @param { Object } payload
 * @returns 
*/
export const updateTaskField = (payload, myTasks ,stages , assigneeList) => {
    const variableFieldKey = Object.keys(payload).filter(key =>
        !['taskId', 'taskHistoryId', 'userId', 'positionId'].includes(key)
    );
    const updatedMytasks = myTasks?.map(task => {
        if (task.Id == payload?.taskId) {
            let updatedTask = { ...task };
            variableFieldKey?.forEach(variableFieldKey => {
                const fieldName = Object.keys(task)?.find(
                  key => key?.toLowerCase() === variableFieldKey?.toLowerCase()
              );
              if (variableFieldKey === "stageId") {
                  updatedTask = {
                    ...updatedTask,
                    StageName:stages?.find(stage => stage.value == payload.stageId)?.label,
                    [fieldName]: payload[variableFieldKey],
                  };
                             
              } 
              else if (variableFieldKey === "relatedAssignedType" && payload?.relatedAssignedType  &&  payload?.relatedAssignedType === number.TWO && task?.CurrentAssignedId === payload.assignedId && task?.AssignedType === payload.assignedType) {
                updatedTask = {
                  ...updatedTask,
                  AssignedByName: assigneeList?.find(name => name.value === payload.assignedBy)?.label,
                  AssigneeName: assigneeList?.find(name => name.value === payload.relatedAssignedId)?.label,
                  AssigneePhoto:assigneeList?.find(name => name.value === payload.relatedAssignedId)?.PhotoLink,
                  AssignedByPhoto:assigneeList?.find(name => name.value === payload.assignedBy)?.PhotoLink,
                  [fieldName]: payload[variableFieldKey],
                };
              } 
              else if (fieldName) {
                updatedTask = {
                  ...updatedTask,
                  [fieldName]: payload[variableFieldKey],
                };
              }
            });
            return updatedTask;
        }
        return task;
      });
    
      return updatedMytasks;
    };
           