import { Calendar } from '@progress/kendo-react-dateinputs';
import React, { useContext } from 'react';
import "./recurringTask.scss";
import { RecurringTaskContext } from './RecurringTaskContext'
import RecurrenceCustomCalendarCell from './RecurrenceCustomCalendarCell';
import { number } from '../../../config';
import { startOfDay } from 'date-fns';

/**
 * kendo calander cell custom component 
 */
const CalendarCustomCell = ({ props, realProps, recur, duration, calenderValue, endBydateValue, endByCountValue, skipDays, recurrencePattern, skipCountValue, disablePastDueDate }) => <RecurrenceCustomCalendarCell {...props} realProps={realProps} recur={recur} duration={duration} calenderValue={calenderValue} endBydateValue={endBydateValue} endByCountValue={endByCountValue} skipDays={skipDays} recurrencePattern={recurrencePattern} skipCountValue={skipCountValue} disablePastDueDate={disablePastDueDate}
/>

/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurrenceCalendar = () => {
    const { calenderValue, setCalenderValue, recurrencePattern, recur, duration, endBydateValue, endByCountValue, skipCountValue, skipDays } = useContext(RecurringTaskContext);
    const skipWeekends = skipDays.filter(function (e) { return e !== true })

    /**
     * handles calendar date changes by checking weekend skip conditions and ensuring the  selected date is valid. Adjusts the calendar value accordingly.
     * @author Muskan Thakur
     */
    const handleCalenderChange = (e) => {
        const dayOfWeek = e.value.getDay();
        const isWeekend = (dayOfWeek === number.ZERO || dayOfWeek === number.SIX);

        const shouldSkip = (
            (skipWeekends?.length === number.ZERO) ||
            (skipWeekends?.length === number.TWO && !isWeekend) ||
            (skipWeekends?.length === number.ONE &&
                ((skipWeekends[number.ZERO] === number.SEVEN && dayOfWeek !== number.SIX) ||
                    (skipWeekends[number.ZERO] === number.ONE && dayOfWeek !== number.ZERO))
            )
        );

        if (shouldSkip) {
            if (e.value < startOfDay(new Date()) && skipDays.includes(true)) {
                setCalenderValue(startOfDay(new Date()));
            } else {
                setCalenderValue(e.value);
            }
        }
    };

  
    return (

        <Calendar value={calenderValue}
            onChange={handleCalenderChange}
            navigation={false}
            cell={((props) => <CalendarCustomCell realProps={props} {...props} disablePastDueDate={skipDays.includes(true)} recur={recur} duration={duration} calenderValue={calenderValue} endBydateValue={endBydateValue} endByCountValue={endByCountValue} skipDays={skipDays} recurrencePattern={recurrencePattern} skipCountValue={skipCountValue} />)}
        />
    )
}

export default RecurrenceCalendar;