import { setSavedFilter } from "../../../../actions/queueTaskPage";
import { label } from "../../../../config";
import store from "../../../../store";

/**
 *used to get Queue Filter Components List
 * @returns {Array} Filter Components List
 * @author {Prachi Jain}
 */
export const getQueueFilterComponents = () => {
  return [
    { value: 1, label: label.TEAMS },
    { value: 2, label: label.ASSIGNEE },
    { value: 3, label: label.TAGS },
    { value: 4, label: label.PRIORITY },
    { value: 5, label: label.WORKFLOW },
    { value: 6, label: label.APPROVALS },
    { value: 7, label: label.OWNERSHIP },
  ];
};

/**
 * used to update the updated values of filter to the selection dd of workspace filter
 * @param {*} filterId
 * @param {*} filterGroup
 * @author {Prachi Jain}
 */
export const setQueueUpdatedFilterList = (filterId, filterGroup) => {
  const state = store.getState();
  const { queueSavedFilters } = state.queueTaskPage;
  const newFilterPayload = addSavedQueueFilterPayload(filterId, filterGroup);
  const newFilters = queueSavedFilters?.map((filter) => (filter.value === filterId ? newFilterPayload : filter));
  store.dispatch(setSavedFilter([...newFilters]));
};

/**
 * used to get payload for selection dd values, to update the dd
 * @param {*} filterId
 * @param {*} filterGroup
 * @returns {Object} Payload
 */
export const addSavedQueueFilterPayload = (filterId, filterGroup) => {
  const state = store.getState();
  const { filterName, visibleTo } = state.queueTaskPage.queueFilterData;
  const filterPaylaod = {
    value: filterId,
    label: filterName,
    visibleTo: visibleTo,
    filterGroup: filterGroup,
  };
  return filterPaylaod;
};

/**
 * used to add an object to selection dd on creation of new workspace filter
 * @param {*} newFilterId
 * @author {Prachi Jain}
 */
export const addNewQueueFilterToFilterList = (newFilterId) => {
  const state = store.getState();
  const { queueSavedFilters } = state.queueTaskPage;
  const filterPayload = addSavedQueueFilterPayload(newFilterId);
  store.dispatch(setSavedFilter([...queueSavedFilters, filterPayload]));
};
