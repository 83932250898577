import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    Redirect,
    Route,
    Switch,
    useLocation
} from "react-router-dom";
import { alertNotification } from "../../actions/alertNotification";
import { route } from "../../config/routes";
import Breadcrumbs from "../../shared/layouts/Breadcrumb/Breadcrumb";
import SideBar from "../../shared/layouts/Sidebar/Sidebar";
import { MainHeader } from "../../shared/layouts/index";
import { checkAdmin } from "../../utils";
import { checkSuperAdmin } from "../../utils/common";
import "../../assets/styles/main.scss";
import config from '../../env.config'
import { messaging } from "../../firebase";
import { getToken, onMessage } from "firebase/messaging";

/**
 * creating private routes
 * @param {*} props
 * @returns {PrivateRoute}
 */
const PrivateRoute = (privateProps) => {
    const { isAuthenticated, path, renderer } = privateProps;
    return (
        <Route
            path={path}
            render={(props) => {
                return isAuthenticated ? (
                    renderer(props)
                )
                    : (
                        <Redirect to={route.ROUTE.LOGIN.PATH} />
                    );
            }}
        />
    );
};
/**
 * creating admin routes
 * @param {*} props
 * @returns {PrivateRoute}
 */
const AdminRoute = (privateProps) => {
    const { isAuthenticated, path, renderer, isAdmin } = privateProps;
    return (
        <Route
            exact
            path={path}
            render={(props) => {
                return isAuthenticated && isAdmin ? (
                    renderer(props)
                ) : (
                    <Redirect to={route.PRIVATE_ROUTE.TASKS.PATH} />
                );
            }}
        />
    );
};
/**
 * creating super admin routes
 * @param {*} props
 * @returns {PrivateRoute}
 */
const SuperAdminRoute = (privateProps) => {
    const { isAuthenticated, path, renderer, isSuperAdmin } = privateProps;
    return (
        <Route
            path={path}
            render={(props) => {
                return isAuthenticated && isSuperAdmin ? (
                    renderer(props)
                ) : (
                    <Redirect to={route.PRIVATE_ROUTE.TASKS.PATH} />
                );
            }}
        />
    );
};
const AppComponents = (props) => {
    const sideCollapse = useState(false)
    const { auth } = props;
    const { showAlert } = useSelector((state) => state.alertNotification);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (showAlert) {
                dispatch(alertNotification(false, null, null));
            }
        }, [3000])
    }, [showAlert])

    const requestPermission = async (userId) => {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_VAPID_KEY,
            });
            await dispatch(storeFcmToken({ userId, token }));
        }
    }
    useEffect(() => {
        if (auth?.user?.id && config.ENABLE_FIREBASE_NOTIFICATIONS) requestPermission(auth?.user?.id);
    }, [auth.user.id])

    if (config.ENABLE_FIREBASE_NOTIFICATIONS) {
        onMessage(messaging, (payload) => {
            dispatch(getNotificationsCount(auth?.user?.id, getTimeFromDate(new Date()), dateConvert(new Date())))
            getNotification(label.NEW_NOTIFICATION, notifyIcon.SUCCESS_ICON);;
            if (location?.pathname === route.PRIVATE_ROUTE.NOTIFICATION.PATH) {
                dispatch(setUserInNotificationPage(true));
            }
        })
    }

    /**
     * Returns Component to be rendered
     * @param {String} name
     * @returns
     */
    const getComponent = (name) => {
        return props.components[name]
    }
    const getCollapse = (collapse) => {
        let wrapperElementClass = document.getElementById('layout-wrapper')?.classList
        if (collapse && wrapperElementClass) {
            wrapperElementClass.add("vertical-collpsed");
            wrapperElementClass.remove("vertical-expand");
        }
        else if (wrapperElementClass) {
            wrapperElementClass.remove("vertical-collpsed");
            wrapperElementClass.add("vertical-expand");
        }
    };
    return (
        <>
            {auth.isAuthenticated && !Object.values(route.ROUTE).some(route => location?.pathname.startsWith(route.PATH)) ? (
                <React.Fragment>
                    <SideBar sendCollapse={getCollapse} />
                    <MainHeader
                        getCollapse={getCollapse}
                        sideCollapse={sideCollapse} />
                </React.Fragment>
            ) : (
                ""
            )}
            <Switch>
                {Object.values(route.PRIVATE_ROUTE).map((route, index) => {
                    const PrivateComponent = getComponent(route.COMPONENT)
                    return (<PrivateRoute
                        exact
                        path={route.ROUTER_PATH}
                        key={index}
                        isAuthenticated={auth.isAuthenticated}
                        renderer={(props) => {
                            return (
                                <div className="main-content overflow-y-scroll overflow-x-hidden position-relative vh-100">
                                    {route.SHOW_BREADCRUMBS && <Breadcrumbs
                                        {...props}
                                        route={route}
                                    />}
                                    <PrivateComponent
                                        {...props}
                                    />
                                </div>
                            );
                        }}
                    />)
                })}
                {Object.values(route.ADMIN_ROUTE).map((route, index) => {
                    const AdminComponent = getComponent(route.COMPONENT)
                    return (<AdminRoute
                        exact
                        path={route.ROUTER_PATH}
                        key={index}
                        isAuthenticated={auth.isAuthenticated}
                        isAdmin={checkAdmin(auth.user.isAdmin)}
                        renderer={(props) => {
                            return (
                                <div className="main-content overflow-y-scroll overflow-x-hidden position-relative vh-100">
                                    {route.SHOW_BREADCRUMBS && <Breadcrumbs
                                        {...props}
                                        route={route}
                                    />}
                                    <AdminComponent
                                        {...props}
                                    />
                                </div>
                            );
                        }}
                    />)
                })}
                {Object.values(route.SUPER_ADMIN_ROUTE).map((route, index) => {
                    const SuperAdminComponent = getComponent(route.COMPONENT)
                    return (<SuperAdminRoute
                        exact
                        path={route.ROUTER_PATH}
                        key={index}
                        isAuthenticated={auth.isAuthenticated}
                        isSuperAdmin={checkSuperAdmin(auth.user.IsSuperAdmin)}
                        renderer={(props) => {
                            return (
                                <div className="main-content overflow-y-scroll overflow-x-hidden position-relative vh-100">
                                    {route.SHOW_BREADCRUMBS && <Breadcrumbs
                                        {...props}
                                        route={route}
                                    />}
                                    <SuperAdminComponent
                                        {...props}
                                    />
                                </div>
                            );
                        }}
                    />)
                })}
                {Object.values(route.ROUTE).map((currRoute, index) => {
                    const RouteComponent = getComponent(currRoute.COMPONENT);
                    return (
                        <Route key={index} path={currRoute.ROUTER_PATH} component={RouteComponent} />
                    )
                })}
            </Switch>
        </>
    )
}
/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */
function mapStateToProps(state) {
    return {
        auth: state.auth,
        loading: state.loader.loading
    };
}
export default connect(mapStateToProps)(AppComponents);
