import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { icon, label } from "../../../../config";
import { setDisabledForSidebar } from "../../../TaskSidebar/sidebar.helper";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import './loader.scss';

/**
 * Handles the priority dropdown of sidebar
 * @props {defaultDetails}
 * @author Prachi Jain
 */

const TaskPriority = ({ defaultDetails}) => {
    const {showTaskDetailPanel} = useSelector((state) => state.sidebarContent);
    const { task, isNewTask } = useSelector((state) => state.taskSidebar)
    const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.priority),
        [priority, setPriority] = useState({}),
        { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
        const [isDisabled, setIsDisabled] = useState();
        const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails);
        const contentRef = useRef();

    /** 
     * Initial value for priority incase of new task
     * @author Himanshu Negi
    */
    useEffect(() => {
        if (isNewTask && !isCloneTask && workflowBasicDetails) {
            const pId = workflowBasicDetails?.TaskPriority
            setPriority(defaultDetails?.priorityList?.find((c) => c.value == pId))
            handleTaskKeyUpdate(isNewTask, "priority", pId)
        }
    }, [isNewTask, isCloneTask, workflowBasicDetails]);

    
    /** 
     * Initial value for priority incase of clone task
     * @author Himanshu Negi
    */
    useEffect(() => {
        if(isNewTask && isCloneTask){
            setPriority(defaultDetails?.priorityList?.find((c) => Number(c.value) === Number(cloneTask?.priority)))
            handleTaskKeyUpdate(isNewTask, "priority", cloneTask?.priority)
        }
    },[isNewTask, isCloneTask]);

    /** 
     * Initial value for priority incase of existing task
     * @author Himanshu Negi
    */
    useEffect(() => {
        if (task.taskId && defaultDetails) {
            setPriority(defaultDetails?.priorityList?.find((c) => c.value == task.Priority))
            setDisabledForSidebar(task, setIsDisabled)
        }
    }, [task?.taskId, defaultDetails])

    /**
     * update priority in db (api call) for existing task
     * @param {Event} event 
     * @author Himanshi
     */
    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
        if (event.target.value
            && event.target.value?.value !== task.Priority
            && !isDisabled
            ) {
            handleTaskKeyUpdate(isNewTask, "priority", event.target.value?.value)
        }
    }

    const itemRender = (li, itemProps) => {
        if (showTaskDetailPanel) {
            const itemChildren = (<span key={itemProps?.index}>  {icon[itemProps.dataItem.icon]}  </span>);
            return React.cloneElement(li, li.props, itemChildren);
        }
        const itemChildren = (<span key={itemProps?.index}>{icon[itemProps.dataItem.icon]} {itemProps.dataItem.label} </span>);
        return React.cloneElement(li, li.props, itemChildren);
    }

    const valueRender = (element, value) => {
        if (!value) {
            return element;
        }
        if (showTaskDetailPanel) {
            const children = [<span key={value}> {icon[value.icon]}  </span>];
            return React.cloneElement(element, { ...element.props }, children);
        }
        const children = [<span key={value}>{icon[value.icon]} {value.label}</span>];
        return React.cloneElement(element, { ...element.props }, children);
    };


    return (
        <React.Fragment>
            <div ref={contentRef} className={`form-group ${!showTaskDetailPanel ? 'col-lg-3 col-md-3 col-sm-3' : 'col-lg-2 col-md-2 col-sm-2'}`}>
                <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
                    {showTaskDetailPanel && !isNewTask ? "" : <label htmlFor="" >{label.CHOOSE_PRIORITY}</label>}
                    <DropDownList
                        onChange={handlePriorityChange}
                        itemRender={itemRender}
                        value={priority}
                        data={defaultDetails?.priorityList}
                        textField="label"
                        valueRender={valueRender}
                        disabled={loader || isDisabled}
                        className="priority-field"
                        id="dropdown-task-priority"
                        popupSettings={{ appendTo: contentRef.current }}
                    />
                </Tooltip>
                {loader && <div className="preloader loader-wrap"></div>}
            </div>

        </React.Fragment>
    );
}

/**
 * merges ReduxStore with props
 * @param {*} state
 * @returns {state as props}
 */

function mapStateToProps(state) {
    return {
        defaultDetails: state.tasks.defaultDetails,
        task: state.taskSidebar.task
    };
}

export default connect(mapStateToProps, null)(TaskPriority);
