import { icon } from "../../../config";

/**
 * Views for queue grids for Tab switch on Queue page
 */

export const views = [
    {
        label: 'List View',
        selected: true,
        component: 'QueueTasks'
    },
    {
        label: 'DashBoard View',
        selected: true,
        component: 'DashBoard'
    }
]

export const kendoViewList = [
    {
        text: "List View",
        selected: true,
        id: 0,
        icon: icon.LIST_VIEW,
        class: "list-view",
    },
    {
        text: "Dashboard View",
        selected: false,
        id: 1,
        icon: icon.DASHBOARD_VIEW,
        class: "dashboard-view"
    },
];

export const DUE_DATE_FILTER = {
    DUE_TODAY: "Tasks Due Today",
    DUE_TOMORROW: "Tasks Due Tomorrow",
    ALL_TASKS_DUE: "All Tasks",
    DUE_TASKS: "Tasks Due in ",
}

export const selectedtask = ['Task Id', 'Due Date', 'Priority', 'Teams', 'Tags','Assignee']
