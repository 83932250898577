import { orderBy } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { button, icon, label, number, tooltip } from "../../../config";
import { storeProjects } from "../../../shared/services/projects.service";
import { storeFilteredQueueProjects, updateProjectQueue } from "../../../shared/services/queues.service";
import { splitString } from "../../../utils";
import { appendString, prependString } from "../../../utils/common";
import { GridContext } from "../../../utils/kendo";
import CreateProjectPopup from "../../Projects/CreateProject/CreateProjectPopup";
import { openSidebarProject } from "../../Projects/project.common";
import MoveProjectPopup from "../../Queues/MoveProjectPopup/MoveProjectPopup";
import "./MoveExistingProject.scss";
import { PrivacyColumnCell, TeamHeaderCell, TeamName } from "./MoveExistingProjectGrid";

const MoveExistingProject = (props) => {
  const [projects, setProjects] = useState([]);
  const [queue, setQueue] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const [selectAllProjects, setSelectAllProjects] = React.useState(false);
  const [showAddTeamPopup, setShowAddTeamPopup] = useState(false);
  const [showMoveProjectPopup, setShowMoveProjectPopup] = useState(false);
  const [sort, setSort] = React.useState([]);

  const anchorMoveProjectPopup = useRef(null);
  const anchorAddTeam = useRef(null);
  const textSearch = useRef("");

  const { operationalTeamName } = useSelector((state) => state.auth.user);
  const { allProjectsList } = useSelector((state) => state.tasks?.defaultDetails);
  const auth = useSelector((state) => state.auth);
  const queueProjects = useSelector((state) => state.queueProjects);
  const allQueues = useSelector((state) => state.allQueues);
  const queueDetails = useSelector((state) => state.teamSidebar.queueDetails);
  const { user } = auth;
  const { QueueId, RoleId } = queueDetails;
  const filteredItems = useMemo(() => {
    return queueProjects?.projects.filter((project) => project.ProjectName.toLowerCase().includes(queueProjects.searchText.toLowerCase()));
  }, [queueProjects?.projects, queueProjects.searchText]);
  const [gridData, setGridData] = useState(filteredItems);
  const [checkedState, setCheckedState] = useState(new Array(filteredItems?.length).fill(false));
  const dispatch = useDispatch();

  /**
   * used to prefill the form for updation
   */

  useEffect(() => {
    filterQueueProjects();
  }, [queueDetails]);

  useEffect(() => {
    filterQueueProjects();
  }, [queueProjects.projects]);

  //@author: "Sarthak Arora"
  // to update the grid data when a new team is added
  useEffect(() => {
    setGridData(filteredItems);
  }, [filteredItems, queueProjects.projects]);

  const handleCheckBox = (event, id, position) => {
    if (!selectAllProjects) {
      const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

      setCheckedState(updatedCheckedState);
      if (event.target.checked) {
        setProjects([...projects, id]);
        setIsDisabled(false);
      } else {
        const temp = projects;
        const index = temp.indexOf(id);
        if (index > -number.ONE) {
          temp.splice(index, number.ONE);
        }
        setProjects(temp);
        setIsDisabled(projects.length <= number.ZERO);
      }
    }
  };

  const filterQueueProjects = () => {
    const searchName = textSearch.current.value;
    dispatch(storeFilteredQueueProjects(searchName));
  };

  /**
   * change queue of Projects
   * @param {void}
   * @returns {void}
   * @author Prachi Jain
   */
  const changeProjectQueue = () => {
    const payload = {
      data: {
        queueId: queue.QueueId,
        previousQueue: QueueId,
      },
      projects: projects,
    };
    dispatch(updateProjectQueue(payload));
    setCheckedState(checkedState.map(() => false));
    const allProjects = [...allProjectsList];
    projects?.map((project) => {
      const requiredProject = allProjects?.find((p) => p.ProjectId === project);
      requiredProject.QueueId = queue.QueueId;
    });
    dispatch(storeProjects({ data: allProjects }));
    setProjects([]);
    setShowMoveProjectPopup(false);
  };

  /**
   * check if the user is valid
   * @returns {isValid}
   */
  const validateUser = () => {
    const isValidProjectLength = queueProjects.projects.length === number.ONE;
    return !((RoleId === number.TWO || user.isAdmin) && !isValidProjectLength);
  };

  /**
   * returns hyperlinked project
   * @param {Object} project
   * @author Himanshu Negi
   */

  const handleProjectNameClick = (project) => {
    const ownerIds = splitString(queueDetails?.userId, ",");
    ownerIds?.includes(user.id.toString()) && openSidebarProject(project?.ProjectId);
  };

  const handleSelectAll = (e) => {
    setSelectAllProjects(e.target.checked);
    if (e.target.checked) {
      const allSelectedProjectState = checkedState?.map((item) => true);
      setCheckedState(allSelectedProjectState);
      const allProjectIds = filteredItems?.map((project) => project.ProjectId);
      setProjects(allProjectIds);
      setIsDisabled(false);
    } else {
      const allSelectedProjectState = checkedState?.map((item) => false);
      setCheckedState(allSelectedProjectState);
      setProjects([]);
      setIsDisabled(true);
    }
  };

  return (
    <React.Fragment>
      <div className='mt-3 mb-3'>
        <div className='row mt-1 mb-1'>
          <div className='col-md-6'>
            <button type='button' className='btn btn-primary btn-width mr-2' disabled={isDisabled} onClick={() => setShowMoveProjectPopup(true)} ref={anchorMoveProjectPopup}>
              <span title={isDisabled ? tooltip.SELECT_TEAM : tooltip.MOVE_TEAM}>{button.MOVE_PROJECTS}</span>
            </button>
            <button type='button' className='btn btn-primary btn-width' onClick={() => setShowAddTeamPopup(true)} ref={anchorAddTeam}>
              {appendString(prependString(user.operationalTeamName ? user.operationalTeamName : label.TEAM, button.ADD + " "), label.SMALL_S)}
            </button>
          </div>
          <div className='col-md-6'>
            <div className='input-group'>
              <Input
                type='text'
                className='form-control'
                id='search-project'
                placeholder={appendString(prependString(user.operationalTeamName ? user.operationalTeamName : label.TEAM, label.SEARCH), label.SMALL_S)}
                ref={textSearch}
                onChange={filterQueueProjects}
              />
              <div className='input-group-btn'>
                <span className='btn btn-primary'>{icon.SEARCH}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-12'>
            <GridContext.Provider
              value={{
                handleCheckBox,
                checkedState,
                validateUser,
                handleProjectNameClick,
                splitString,
                queueDetails,
                user,
                selectedProject,
                setSelectedProject,
                hoveredRow,
                setHoveredRow,
                selectAllProjects,
                handleSelectAll,
                operationalTeamName,
              }}>
              <Grid
                className='project-move-grid overflow-auto'
                data={orderBy(gridData, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}>
                <GridColumn field='ProjectName' cell={TeamName} headerCell={TeamHeaderCell} />
                <GridColumn field='Privacy' title={label.PRIVACY} width={125} cell={PrivacyColumnCell} />
              </Grid>
            </GridContext.Provider>
          </div>
        </div>
      </div>
      <div>
        {showAddTeamPopup && <CreateProjectPopup anchor={anchorAddTeam} show={showAddTeamPopup} setShow={setShowAddTeamPopup} queueId={QueueId} />}
        {showMoveProjectPopup && (
          <MoveProjectPopup
            anchor={anchorMoveProjectPopup}
            show={showMoveProjectPopup}
            teamName={user.operationalTeamName}
            setShow={setShowMoveProjectPopup}
            queue={queue}
            allQueues={allQueues?.filter((item) => item.QueueId !== QueueId)}
            setQueue={setQueue}
            changeProjectQueue={changeProjectQueue}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default MoveExistingProject;
