import React, { useState, useEffect } from 'react';
import { label, number } from '../../../../../config';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SubTaskDueDate, Verified, AttachIcon, SubTaskName } from './subtaskGrid';
import { CellRender, RowRender } from "./subtask.helper"
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { reordering, GridContext, DragRowWithDragEnd } from '../../../../../utils/kendo';
import { joinString } from '../../../../../utils';
import { changeSubTaskOrder } from '../../../../Tasks/tasks.service';
import { getFutureDate } from '../../../../../utils/common';
import { storeSubTask } from '../../../../../actions/linkedTasks';

const DragIcon = (props) => {
  return (
    <td>
      <span className='drag-icon'><DragRowWithDragEnd {...props} /></span>
    </td>
  );
};

const OptionSubTask = (props) => <AttachIcon {...props} />
const VerifiedIcon = (props) => <Verified {...props} />
const DueDate = (props) => <SubTaskDueDate {...props} />

const SubTasks = () => {
  const dispatch = useDispatch()
  const { subTask } = useSelector((state) => state.linkedTasks);
  const { isNewTask } = useSelector((state) => state.taskSidebar);
  let subTaskData = subTask;
  const [editField, setEditField] = React.useState("");
  const [data, setData] = React.useState(subTaskData ? subTaskData : null);
  const { showLinkedTasksSubTasks } = useSelector((state) => state.taskSidebar);
  const [activeItem, setActiveItem] = useState(null);
  const { workflowSubTasks, workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
  const { isCloneTask, cloneTask } = useSelector((state) => state.cloneTaskDetails);

  // add subtasks of workflows while creation of tasks
  useEffect(() => {
    if (isNewTask && !isCloneTask) {
      if (workflowSubTasks?.length > subTask?.length && showLinkedTasksSubTasks) {
        const subtaskData = workflowSubTasks?.map((subtask, i) => ({ subTaskId: i, Name: subtask?.SubtaskName, DueDate: getFutureDate(subtask?.SubtaskDueDays), IsCompleted: { data: [number.ZERO] } }));
        setData(subtaskData)
        dispatch(storeSubTask(subtaskData))
      } else if (workflowSubTasks?.length <= subTask?.length && showLinkedTasksSubTasks) {
        dispatch(storeSubTask(subTask))
      }
      else {
        setData(null)
        dispatch(storeSubTask([]))
      }
    }
  }, [workflowSubTasks, workflowBasicDetails, showLinkedTasksSubTasks])

  /**
   * initial subtask incase of clone task
   */
  useEffect(() => {
    if(isNewTask && isCloneTask){
      dispatch(storeSubTask([...cloneTask?.subTask]))
    }
  },[isCloneTask])

  // used to set data to be shown in grid
  useEffect(() => {
    if (subTaskData) {
      setData(subTaskData)
    } else {
      setData(null)
    }
  }, [subTaskData])

  /**
  * to get the order of sub task
  * @param {*} reorderedData
  * @returns order of subtask
  * @author {Bhavana}
  */
  const getEntities = (reorderedData) => {
    let entity = {
      subTaskId: []
    };
    reorderedData.map((data) => {
      entity?.subTaskId?.push(data?.subTaskId)
    })
    return entity;
  }


  /**
   * Reorders the data according to the drag and drop
   * @param {*} dataItem
   * @author {Bhavana}
   */
  const reorder = (dataItem) => {
    let reorderedData = reordering(dataItem, activeItem, data);
    if (reorderedData) setData(reorderedData)
  }

  /**
  * api call for changeSubTaskOrder when the subtask is dropped
  * @author {Bhavana}
  */
  const onDrop = () => {
    let entity = getEntities(data)
    let payload = {
      order: joinString(entity.subTaskId, ","),
    }
    isNewTask ? dispatch(storeSubTask(data)) : dispatch(changeSubTaskOrder(payload))
  }

  const dragStart = (dataItem) => {
    setActiveItem(dataItem);
  };

  /**
   * adds an inEdit field to make the grid cell editable
   * @param {*} dataItem
   * @param {*} field
   * @author {Bhavana}
   */
  const enterEdit = (dataItem, field) => {
    if (field != dataItem.inEdit) {
      const newData = data.map((item) => ({
        ...item,
        inEdit: item?.Id === dataItem?.Id ? field : null,
      }));
      setData(newData);
      setEditField(field);
    }
  };

  /**
   * used to exit the editable mode of grid
   * @author {Bhavana}
   */
  const exitEdit = () => {
    const newData = data.map((item) => ({ ...item, inEdit: null }));
    setData(newData);
    setEditField(null);
  };

  /**
   * renders custom cell of grid
   * @param {*} td
   * @param {*} props
   * @author {Bhavana}
   */
  const customCellRender = (td, props) => (
    <CellRender
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      editField={editField}
    />
  );

  /**
   * returns custom row of grid
   * @param {*} tr
   * @param {*} props
   * @author {Bhavana}
   */
  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      exitEdit={exitEdit}
      editField={editField}
    />
  );

  return (
    <React.Fragment>
      {subTaskData && subTaskData?.length !== number.ZERO &&
        <>
          <Tooltip position='bottom' anchorElement="target" parentTitle={true}>
            {subTaskData.length && <div className="blue mt-2 mb-1 heading">{label.SUBTASKS}</div>}
            <GridContext.Provider value={{
              reorder: reorder,
              dragStart: dragStart,
              onDrop: (e) => onDrop(e),
              activeItem,
              setActiveItem
            }}>
              <Grid
                id= "subtask-grid"
                data={data?.length ? data : []}
                cellRender={customCellRender}
                rowRender={customRowRender}
                editField="inEdit"
              >
                <Column cell={DragIcon} width="30px" />
                <Column cell={VerifiedIcon} width="30px" />
                <Column cell={SubTaskName} field="Name" width="250px" />
                <Column cell={DueDate} field="DueDate" width="100px" />
                <Column cell={OptionSubTask} width="30px" />
              </Grid>
            </GridContext.Provider>
          </Tooltip>
        </>}
    </React.Fragment>
  );
}


export default SubTasks;
