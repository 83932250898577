import { combineReducers } from "redux";
import { withReduxStateSync } from 'redux-state-sync';
import auth from "./auth";
import company from "./company";
import loader from "./loader";
import allQueues from "./queues";
import companyUsers from "./companyUsers";
import allProjects from "./projects";
import projectForm from "./ProjectForm";
import team from "./team";
import queueProjects from "./queueProjects";
import storeActivityLog from "./activityLog";
import tasks from "./task";
import myBodyConfig from "./myTaskBodyConfig";
import comments from './comments';
import projectSettings from "./projectSettings";
import selectedProject from './selectedProject'
import notes from "./notes";
import bookmarks from './bookmarks';
import notifications from "./notifications";
import superAdmin from './superAdmin';
import reminders from "./reminders";
import alertNotification from "./alertNotification";
import taskSidebar from "./taskSidebar";
import { RESET_STORE } from "../actions/action-types";
import projectSidebar from "./projectSidebar";
import latestSidebar from './latestSidebar';
import history from './history';
import admin from "./admin";
import common from "./common";
import recurrence from "./recurrence";
import teamSidebar from "./teamSidebar";
import queueTaskPage from './queueTaskPage'
import projectWorkflows from "./projectWorkflows";
import taskWorkflowDetails from "./taskWorkflowDetails";
import linkedTasks from "./linkedTasks";
import teamFilter from "./teamFilter";
import navigationTasks from "./navigationTasks";
import sidebarContent from "./sidebarContent";
import userPosition from "./userPosition";
import planMyDay from './planMyday';
import adminOnboard from "./adminUserOnboard"
import initializeTour from "./tour";
import app from './app';
import attachments from "./attachments";
import cloneTaskDetails from "./cloneTaskDetails";

const allReducers = combineReducers({
  auth,
  company,
  loader,
  allQueues,
  companyUsers,
  allProjects,
  projectForm,
  team,
  teamFilter,
  queueProjects,
  storeActivityLog,
  tasks,
  myBodyConfig,
  comments,
  projectSettings,
  selectedProject,
  notes,
  bookmarks,
  notifications,
  superAdmin,
  reminders,
  taskSidebar,
  alertNotification,
  projectSidebar,
  latestSidebar,
  history,
  admin,
  common,
  recurrence,
  teamSidebar,
  queueTaskPage,
  projectWorkflows,
  taskWorkflowDetails,
  linkedTasks,
  navigationTasks,
  sidebarContent,
  userPosition,
  planMyDay,
  adminOnboard,
  initializeTour,
  app,
  attachments,
  cloneTaskDetails
});

export default withReduxStateSync((state, action) => {
  if (action.type === RESET_STORE) {
    state = {};
  }

  return allReducers(state, action);
})
