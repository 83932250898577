import { Loader } from '@progress/kendo-react-indicators';
import React, { useEffect, useRef, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setShowRecentHistory } from '../../actions/history';
import { resetSidebarReducer } from '../../actions/taskSidebar';
import { label, number, route, icon, dateFormat } from '../../config';
import { useArrowKeyNavigation } from '../../helper/commonHooks';
import { getUserRecentHistory } from './recentHistory.service';
import store from '../../store';
import _ from "lodash";
import { Link } from "react-router-dom";
import { setSelectedView } from '../../actions/task';
import { format } from 'date-fns';
import { openSidebar } from '../Tasks/tasks.service';

const RecentHistoryList = ({ limit }) => {
  const [loading, setLoading] = useState(true);
  const [recentTaskList, setRecentTaskList] = useState([]);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { show, recentHistoryList } = useSelector((state) => state.history);
  const { selected, downPress, upPress, cursor } = useArrowKeyNavigation(recentTaskList);


  const recentHistoryRefs = useRef([]);
  let element;

  /**
  * sets recent task list for recent history list in the header else sets it for the dashboard
  * @author Muskan Thakur
  */
  useEffect(() => {
    setRecentTaskList(limit && recentHistoryList)
  }, [recentHistoryList])

  useEffect(() => {
    element = recentHistoryRefs?.current?.find((node) => node?.className?.includes('active-recent-history'));
    element && element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  }, [downPress, upPress])

  useEffect(() => {
    if (show || !limit) {
      getRecentHistory();
    }
  }, [show])


  const getRecentHistory = async () => {
    setLoading(limit ? true : false);
    if (limit) await dispatch(getUserRecentHistory(user.id, limit, number.ZERO, false));
    setLoading(false);
  }

  useEffect(() => {
    if (selected)
      recentHistoryClickAction(selected)
  }, [selected]);

  /**
   * handles show more click, sets selcted view to none
   * @author Muskan Thakur 
   */
  const handleShowMore = () => {
    dispatch(setSelectedView(""))
    store.dispatch(setShowRecentHistory(false));
  }

  return (
    <div className={limit ? 'recent-history-container position-relative' : ""} id='recent-history-list-content'>
      <div className='recent-history'>
        {limit && <div className={'recent-history-header position-relative'}>
          <div className='d-flex justify-content-between align-items-center'>
            <span className='header-title font-weight-semi-bold mr-3'>{label.RECENTLY_VIEWED}</span>
          </div>
        </div>}
        {loading ?
          <div className='text-center'><Loader size="medium" type={"infinite-spinner"} /></div> :
          <div className={limit ? 'recent-history-list-container' : ""}>
            <div className='recent-history-list-item overflow-y-auto'>
              {limit && recentTaskList.map((item, index) => {
                return (
                  <div className={`recent-history-content cursor-pointer ${index === cursor && 'active-recent-history'}`} id={`recent-task-click ${item.Id}`} key={item.Id} ref={el => recentHistoryRefs.current[index] = el} onClick={() => recentHistoryClickAction(item)}>
                    <div className='recent-history-item d-flex'>
                      <div className='recent-history-type rounded-circle recent-history-task d-flex justify-content-center align-items-center mr-3'>
                        {icon[item.FlowIcon]}
                      </div>
                      <div className='w-100'>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <span className='recent-history-code font-weight-semi-bold mr-3'>{item.Code}</span>
                          </div>
                          <div>
                            <span className='recent-history-time'>{format(new Date(item.CreatedOn), dateFormat.DATE_FORMAT)}</span>
                          </div>
                        </div>
                        <p className='recent-history-name mb-0'>{item.Name}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='recent-history-footer d-flex justify-content-center align-items-center'>
              {limit && <Link to={`${route.PRIVATE_ROUTE.TASK_HISTORY.PATH}`} onClick={handleShowMore} id="show-more-recent-history-click">
                {label.VIEW_ALL_RECENTLY_VIEWED}
              </Link>}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default RecentHistoryList;

/**
* click action for recent history item 
* @param { Object } item
* @returns { Void }
*/
export const recentHistoryClickAction = (item) => {
  const state = store.getState(),
    { user } = state.auth
  store.dispatch(resetSidebarReducer())
  openSidebar({ id: item.EntityId, userId: user.id })
  store.dispatch(setShowRecentHistory(false));
}