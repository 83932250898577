import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { setShowTaskSidebar } from "../../../../actions/taskSidebar";
import { label, notifyIcon, number, quote } from "../../../../config";
import { usePopup } from "../../../../helper/commonHooks";
import store from "../../../../store";
import { splitString } from "../../../../utils";
import { getNotification } from "../../../../utils/common";
import { setDisabledForSidebar } from "../../../TaskSidebar/sidebar.helper";
import { handleTaskKeyUpdate } from "../../../Tasks/tasks.service";
import TaskFollowers from './TaskFollowers';
import { getFollowerList } from "./taskDetail.helper";

const TaskFollower = () => {
    const [isFollowed, setIsFollowed] = useState(false);
    const [followerCount, setFollowerCount] = useState(0);
    const [followers, setFollowers] = useState(null);
    const { workflowBasicDetails } = useSelector((state) => state.taskWorkflowDetails);
    const [isDisabled, setIsDisabled] = useState();
    const { task, isNewTask } = useSelector((state) => state.taskSidebar)
    const auth = useSelector((state) => state.auth)
    const { defaultDetails } = useSelector((state) => state.tasks)
    const {
        show: showSelectFollowers,
        setShow: setShowSelectFollowers,
        anchor,
        contentRef: followerPopupRef,
        blurTimeoutRef,
        onOpen,
        onFocus,
        onBlur } = usePopup();

    useEffect(() => {
        if(isNewTask){
            const selectedFollowers = getFollowerList({ followers: workflowBasicDetails?.Followers, user: auth.user.id, isNewTask, defaultDetails })
            setFollowers(selectedFollowers)
            setFollowerCount(selectedFollowers?.length)
            const isLoggedInUserFollowed = selectedFollowers?.find(f => Number(f.value) === Number(auth.user.id)) ? true : false; 
            setIsFollowed(isLoggedInUserFollowed)            
            handleTaskKeyUpdate(isNewTask, "followers", selectedFollowers.map((f) => f.value).join(","))
        }
    }, [isNewTask, workflowBasicDetails?.Followers])

    useEffect(() => {
        if(task?.taskId){
            const selectedFollowers = getFollowerList({ followers: task?.followerIds ? task?.followerIds : "", user: auth.user.id, isNewTask, defaultDetails })
            setFollowers(selectedFollowers)
            setFollowerCount(selectedFollowers.length)
            const isLoggedInUserFollowed = selectedFollowers?.find(f => Number(f.value) === Number(auth.user.id)) ? true : false; 
            setIsFollowed(isLoggedInUserFollowed)            
        }
        setDisabledForSidebar(task, setIsDisabled)
    }, [task?.taskId])

    const startFollowing = () => {
        const taskFollowers = [...followers];
        const follower = defaultDetails?.assigneeList?.find(f => f.value == auth.user.id);
        if(!taskFollowers.find(f => f.value === auth.user.id)){
            taskFollowers.push(follower);
        }
        setFollowers(taskFollowers);
        setFollowerCount(taskFollowers?.length);
        handleTaskKeyUpdate(isNewTask, "followers", taskFollowers.map((f) => f.value).join(","))
    }

    const removeFollowing = () => {
        const taskFollowers = followers?.filter(f => Number(f.value) !== Number(auth.user.id))
        setFollowers(taskFollowers);
        setFollowerCount(taskFollowers?.length);
        handleTaskKeyUpdate(isNewTask, "followers", taskFollowers.map((f) => f.value).join(","))
    }

    const toggleFollow = () => {
        setIsFollowed(!isFollowed);
        isFollowed ? removeFollowing() : startFollowing()
    }

    const showFollowerPopup = () => {
        setShowSelectFollowers(!showSelectFollowers)
    }

    return (
    <div className="form-group col-lg-2 col-md-2 col-sm-2 task-followers">
        <label>{label.FOLLOWERS}</label>
        <div className="follower-container custom-control zindex-zero pl-0">
            <small className="start-follow float-none m-0 d-flex">
                <span onClick={toggleFollow} className="blue cursor-pointer">{isFollowed ? label.UNFOLLOW : label.FOLLOW}</span>
                <div className="normal-badge rounded-circle text-center font-weight-bold cursor-pointer" onClick={showFollowerPopup} ref={anchor}>{followerCount}</div>
            </small>
            {showSelectFollowers && <TaskFollowers
                show={showSelectFollowers}
                togglePopup={setShowSelectFollowers}
                anchor={anchor}
                setIsFollowed={setIsFollowed}
                followerPopupRef={followerPopupRef}
                blurTimeoutRef={blurTimeoutRef}
                onOpen={onOpen}
                onFocus={onFocus}
                onBlur={onBlur}
                followers={followers}
                setFollowers={setFollowers}
                setFollowerCount={setFollowerCount}
            />}
        </div>
    </div>
    )
}

export default TaskFollower;
