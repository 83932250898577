import { DropDownList } from '@progress/kendo-react-dropdowns'
import React from 'react'
import { useSelector } from 'react-redux';

import { useTaskOwnerContext } from './taskOwner.Context';
import { relatedAssignedTypeDropDownEffect } from '../Details/taskDetail.helper';
import { userPartOfAssignedProject } from '../../sidebar.helper';
import { number , icon, label } from '../../../../config';

/**
 * WorkflowAssignmentType type drowpdown component
 * Parent component => OwnerShipContainer
 * @returns JSX
 * @author Shivam
 */
const WorkflowAssignmentType = () => {
  const assignmentState = useTaskOwnerContext()
  const { assignmentType , workflowAssignmentType, setWorkflowAssignmentType, isDisabled } = assignmentState;

  const { user } = useSelector(state => state.auth);
  const { isNewTask, task } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector(state => state.tasks);


  const itemRender = (li, itemProps) => {
    const itemChildren = (<span key={itemProps?.index}>{icon[itemProps.dataItem.icon]}</span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [<span key={value?.value}>{icon[value.icon]}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

  /**
   * Handles workflowAssignmentType state change
   * @param {*} event 
   */
  const workflowAssignmentTypeChange = async (event) => {
    const selectedValue = event.target.value;
    setWorkflowAssignmentType(selectedValue);
    const payload = {defaultDetails, isNewTask, user, assignmentState, selectedValue, task}
    relatedAssignedTypeDropDownEffect(payload);
  }

   /**
   * Disables dd3 dropdown
   * @param {*} event 
   */
  const getDisable = () => {
    if ( assignmentType?.key === number.TWO) return true;
    if ( assignmentType?.key === number.THREE && !userPartOfAssignedProject(assignmentState)) return true;
    return false;
  }

  return (
    <React.Fragment>
      { <div className="form-group col-lg-2 col-md-2 col-sm-2 ">
        <label htmlFor=""> {workflowAssignmentType?.value === number.FIVE ? label.WORKFLOW : label.USER}</label>
        <DropDownList
          data={defaultDetails?.relatedAssignmentTypeList}
          value={workflowAssignmentType}
          onChange={workflowAssignmentTypeChange}
          id="value"
          textField="label"
          itemRender={itemRender}
          valueRender={valueRender}
          disabled={getDisable() || isDisabled}
        />
      </div>}

    </React.Fragment>
  )
}

export default WorkflowAssignmentType