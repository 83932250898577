import React, { useContext, useState } from 'react'
import DynamicReminderPopup from '../Tasks/Reminders/DynamicReminderPopup'
import ReminderList from '../Tasks/Reminders/ReminderList'
import { icon, label, number, quote } from '../../config';
import '../Tasks/Reminders/reminder.scss'
import { useWorkflowDynamicReminderPopup, useWorkflowReminderList } from './workflowRemindersHook';
import { ReminderContext } from '../Tasks/Reminders/ReminderContextProvider';
import { WorkflowContext } from './WorkflowContextProvider';
import WorkflowReminderEditPopup from './WorkflowReminderEditPopup';

const HandleWorkflowReminder = ({ anchor, show, toggleShowPopup, setShowPopup }) => {
    const [showCustomPopup, setShowCustomPopup] = useState(false);
    const { isAddClicked, isReminderClicked, setIsReminderClicked, displayWarning } = useContext(ReminderContext)
    const { workflowReminders, editName, editRem, editNoOfDays } = useContext(WorkflowContext);

    const onClick = () => {
        setShowPopup(false);
    };
    /**
     *  sets the reminderMessage
     *  @author: Sarthak Arora
     */


    const handleCancel = () => {
        setShowPopup(false)
    }


    const closeCustomPopup = () => {
        setShowCustomPopup(false);
    };

    /**
     * renders the reminder details in Reminder List
     * @param {*} reminder 
     */
    const reminderDetails = (reminder) => {
        return <div className="small text-secondary">{`${reminder.NoOfDays} days before due date`}</div>
    }

    const { popupBodyContent, handleSaveReminder } = useWorkflowDynamicReminderPopup(setShowPopup);
    const { handleClick, delReminder, setDelReminder, handleAddRem } = useWorkflowReminderList(onClick)



    return (
        <div>
            {
                (isAddClicked || workflowReminders.length === 0) ?
                    <DynamicReminderPopup
                        popupAnchor={anchor}
                        show={show}
                        onClick={toggleShowPopup}
                        setShowReminder={setShowPopup}
                        popupBodyContent={popupBodyContent}
                        handleCancel={handleCancel}
                        handleSaveReminder={handleSaveReminder}
                        showCustomPopup={showCustomPopup}
                        closeCustomPopup={closeCustomPopup}
                    /> :
                    <ReminderList onClick={onClick}
                        show={show}
                        reminders={workflowReminders}
                        anchor={anchor}
                        reminderDetails={reminderDetails}
                        handleAddRem={handleAddRem}
                        setDelReminder={setDelReminder}
                        handleClick={handleClick}
                        delReminder={delReminder}
                    />

            }
            {
                isReminderClicked && <WorkflowReminderEditPopup
                    onClick={onClick}
                    ReminderId={editRem}
                    reminderMessage={editName}
                    noOfDays={editNoOfDays}
                    handleAddRem={handleAddRem}
                    popupAnchor={anchor}
                    show={show}
                    setIsReminderClicked={setIsReminderClicked}
                />
            }
        </div>
    )
}

export default HandleWorkflowReminder

