import * as ACTION_TYPES from "./action-types";


/**
* updates Project Description 
* @param {project Description}
*/


export const updateProjectDesc = (description) => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_DESC,
    payload: description
  };
};

/**
 * used to a new project in all projects
 * @param {*} payload 
 * @returns 
 */
export const setNewProject = (payload) => {
  return {
    type: ACTION_TYPES.SET_NEW_PROJECT,
    payload: payload
  }
}

/**
* sets project streams  
* @param {data Array}
*/

export const storeProjectStreams = (stream) => {
  return {
    type: ACTION_TYPES.STORE_PROJECT_STREAMS,
    payload: stream
  }
}

/**
* sets Emoji Icons 
* @param {emojiIcons }
*/

export const storeEmojiIcons = (emoji) => {
  return {
    type: ACTION_TYPES.STORE_EMOJI,
    payload: emoji
  }
}


/**
* updates Project stream Emoji reaction  
*/

export const updateStreamEmoji = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_STREAM_EMOJI,
    payload: payload
  }
}


/**
* add Project  
*/
export const addProjectAction = (payload) => {
  return {
    type: ACTION_TYPES.ADD_PROJECT,
    payload: payload
  }
}

/**
* update Projects in grid 
*/
export const updateProjectAction = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT,
    payload: payload
  }
}

/**
* add Project Member 
*/
export const addProjectMemberAction = (payload) => {
  return {
    type: ACTION_TYPES.ADD_PROJECT_MEMBER,
    payload: payload
  }
}

/**
* delete Project Member 
*/
export const deleteProjectMemberAction = (payload) => {
  return {
    type: ACTION_TYPES.DELETE_PROJECT_MEMBER,
    payload: payload
  }
}

/**
* Update Project Member 
*/
export const updateProjectMemberAction = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_PROJECT_MEMBER,
    payload: payload
  }
}


/**
* Update Project Member 
*/
export const updateProjectGrid = (payload) => {
  return {
    type: ACTION_TYPES.FILTER_DATA_BY_PROJECT_ID,
    payload: payload
  }
}


/**
* updates Project stream  
*/
export const addNewStream = (payload) => {
  return {
    type: ACTION_TYPES.ADD_NEW_STREAM,
    payload: payload
  }
}

export const setIsLastStream = (data) => {
  return {
    type: ACTION_TYPES.IS_LAST_STREAM,
    payload: data
  }
}

export const clearStreams = () => {
  return {
    type: ACTION_TYPES.CLEAR_STREAMS
  }
}

export const streamMarkAsRead = (streamId) => {
  return {
    type: ACTION_TYPES.STREAMS_MARK_AS_READ,
    payload: streamId
  }

}

export const storeTeamFilterUtilities = (payload) => {
  return {
    type: ACTION_TYPES.SET_TEAM_FILTER_UTILITIES,
    payload
  }
}

export const setTeamFilterDetails = (payload) => {
  return {
    type: ACTION_TYPES.SET_TEAM_FILTER_DETAILS,
    payload
  }
}

export const emptyTeamFilterDetails = () => {
  return {
    type: ACTION_TYPES.EMPTY_TEAM_FILTER_DETAILS,
  }
}

export const setTeamSavedFilter = (payload) => {
  return {
    type: ACTION_TYPES.SET_TEAM_SAVED_FILTERS,
    payload
  }
}

export const setLastFilterId = (payload) => {
  return {
    type: ACTION_TYPES.SET_LAST_FILTER,
    payload
  }
}

export const setIsApprovalFilterSelected = (payload) => {
  return {
    type: ACTION_TYPES.IS_APPROVAL_FILTER_SELECTED,
    payload
  }
}