import { addDays } from 'date-fns';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {number } from '../../../config';
import { isDateValid } from '../../../shared/validators/validator';
import "./recurringTask.scss";
import { getRecurrTitle, getRecurrTitleCompletionBased } from './recurringTaskHelper';
import { RecurringTaskContext } from './RecurringTaskContext';
import SavedRecurrence from './SavedRecurrence';
import SaveOrEditRecurrence from './SaveOrEditRecurrence';


/**
 * Contains recurring task related details 
 * @author Muskan Thakur 
 */
const RecurringTaskContentWrapper = (props) => {
    const { show, togglePopup, anchor, recurrPopupRef, blurTimeoutRef, onOpen, onFocus, onBlur } = props
    const { calenderValue, setCalenderValue, setRecurrencePattern, setRecur, setReminder, setTitle, setDuration, setEndBydateValue, setEndByCountValue, setSkipCountValue, setRecurrenceMode, edit, setProject, setRecurrenceCreator,  setPatternBasedRecur, skipDays, setSkipDays, setAssignedId, workflows, setTaskAssignmentType } = useContext(RecurringTaskContext);
    const { recurrenceDetails, recurrenceDefaults } = useSelector((state) => state.recurrence);
    const { defaultDetails } = useSelector((state) => state.tasks);
    const skipWeekends = skipDays.filter(function (e) { return e !== true });

    /**
     * sets up the recurrence details for a task based on the provided recurrence settings.
     * @author Muskan Thakur
     */
    const setEnabledRecurrence = () => {
        const { RecurrencePattern, CompletionFrequencyType, FrequencyType, FrequencyDayDate, EndDate, RemainingRecurrence, RecurrenceMode, EndCriteriaType, SkipDays, CompletionFrequencyDays, ReminderType, TaskAssignee, CreatedBy, ProjectId, SkipPastRecurrence, TaskAssignmentType, WorkflowId } = recurrenceDetails
        const { endCriteriaType, frequency, reminderType, completionFrequency } = recurrenceDefaults

        let patternBasedRecur = RecurrencePattern === number.TWO ? CompletionFrequencyType : FrequencyType
        let patternBasedRecurConstants = RecurrencePattern === number.TWO ? completionFrequency : frequency
        let recurrTitle = RecurrencePattern === number.TWO ? getRecurrTitleCompletionBased(patternBasedRecur, EndCriteriaType, CompletionFrequencyDays, RemainingRecurrence, EndDate) : getRecurrTitle(patternBasedRecur, EndCriteriaType, RemainingRecurrence, EndDate, new Date(FrequencyDayDate))

        setPatternBasedRecur(patternBasedRecur)
        setCalenderValue(new Date(recurrenceDetails?.FrequencyDayDate))
        EndDate && isDateValid(EndDate) && setEndBydateValue(new Date(EndDate))
        RemainingRecurrence && setEndByCountValue(RemainingRecurrence)
        setRecurrencePattern(RecurrencePattern)
        setRecurrenceMode(RecurrenceMode)
        setDuration(endCriteriaType.filter(row => row.value === EndCriteriaType)[number.ZERO])
        setRecur(patternBasedRecurConstants.filter(row => row.value === patternBasedRecur)[number.ZERO])
        SkipDays && setSkipDays(SkipDays?.split(","))
        setTitle(recurrTitle)
        setSkipCountValue(CompletionFrequencyDays ? CompletionFrequencyDays : number.ONE)
        setReminder(ReminderType?.split(",").map((val) => {
            return reminderType.find(obj => obj.value === val)?.field;
        }))
        setAssignedId(TaskAssignmentType === number.TWO ? defaultDetails?.assigneeList?.find(a => a.value === (TaskAssignee)) : workflows?.find(workflow => workflow.value === WorkflowId))
        setRecurrenceCreator(defaultDetails?.assigneeList?.find(a => a.value === (CreatedBy))?.label)
        setProject(defaultDetails?.allProjectsList?.find((project) => project.ProjectId === ProjectId)?.ProjectName
        )
        Boolean(SkipPastRecurrence) ? setSkipDays([Boolean(SkipPastRecurrence)]) : setSkipDays([])
        setTaskAssignmentType(defaultDetails?.relatedAssignmentTypeList?.find(i => i.value === TaskAssignmentType))
    }

    /**
     * updates the calendar for task where recurrence has been applied
     */
    useEffect(() => {
        if (recurrenceDetails?.Id && isDateValid(recurrenceDetails?.FrequencyDayDate)) setEnabledRecurrence()
    }, [recurrenceDetails?.Id, workflows])

    /**
     * sets calendar value to skip weekends based on user selection of skip days and current day of the week
     */
    useEffect(() => {
        const dayOfWeek = calenderValue?.getDay();
        if (dayOfWeek === number.SIX && skipWeekends?.length === number.TWO) {
            setCalenderValue(addDays(calenderValue, number.TWO));
            return;
        }
        if ((dayOfWeek === number.ZERO && skipWeekends?.length === number.TWO)) {
            setCalenderValue(addDays(calenderValue, number.ONE));
            return;
        }
        if ((dayOfWeek === number.SIX && skipWeekends?.length === number.ONE && skipWeekends[number.ZERO] === number.SEVEN) || (dayOfWeek === number.ZERO && skipWeekends?.length === number.ONE && skipWeekends[number.ZERO] === number.ONE)) {
            setCalenderValue(addDays(calenderValue, number.ONE));
            return;
        }
    }, [skipWeekends?.length])
   
    return (
        <React.Fragment>
            {(edit ? !edit : recurrenceDetails?.Id) ? <SavedRecurrence show={show} togglePopup={togglePopup} anchor={anchor} recurrPopupRef={recurrPopupRef} blurTimeoutRef={blurTimeoutRef} onOpen={onOpen} onFocus={onFocus} onBlur={onBlur}/> : <SaveOrEditRecurrence show={show} togglePopup={togglePopup} anchor={anchor} recurrPopupRef={recurrPopupRef} blurTimeoutRef={blurTimeoutRef} onOpen={onOpen} onFocus={onFocus} onBlur={onBlur}/>}</React.Fragment>
    )
}

export default RecurringTaskContentWrapper;