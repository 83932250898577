import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { BadgeContainer } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { setUpdateLayoutDesc } from "../../../../actions/common";
import { clearAllNotes, setShowNotesList } from "../../../../actions/notes";
import { setNotificationCount, setShowNotifications } from "../../../../actions/notifications";
import { setIsApprovalFilterSelected } from "../../../../actions/projects";
import BackForwardNavigation from "../../../../components/BackForwardNavigation/BackForwardNavigation";
import Loader from "../../../../components/Loader/Loader";
import Notes from "../../../../components/Notes/Notes";
import { getNotes } from "../../../../components/Notes/notes.service";
import Notifications from "../../../../components/Notifications/Notifications";
import { getNotificationsCount } from "../../../../components/Notifications/notifications.service";
import OverallSearch from "../../../../components/OverallSearch/OverallSearch";
import { icon, label, number, route, tooltip } from "../../../../config";
import { getTimeFromDate } from "../../../../helper/common";
import { splitString } from "../../../../utils";
import { appendString, dateConvert } from "../../../../utils/common";
import { isDescriptionUpdated } from "../../../services/common.service";
import CollabCentralLayout from "../../CollabCentralLayout";
import { showDescriptionIcon } from "../../layout.constants";
import UserDropdown from "../UserDropdown/UserDropdown";
import PlanMyDay from "./PlanMyDay";
import ToggleTheme from "./ToggleTheme";
import "./header.scss";

const MainHeader = () => {
  const homeRef = useRef();
  const profileRef = useRef();
  const buttonRef = useRef();
  const overallSearchRef = useRef();
  const layoutDescriptionRef = useRef();
  const [openProfile, setOpenProfile] = useState(false);
  const [pageParamState, setPageParamState] = useState({});
  const [currentRoute, setCurrentRoute] = useState({});
  const [notesLoading, setNotesLoading] = useState(false);
  const [projectNameState, setProjectNameState] = useState("");
  const [project, setProject] = useState("");
  const [queueName, setQueueName] = useState("");
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);
  const location = useLocation();
  const newDate = new Date();
  const auth = useSelector((state) => state.auth);
  const notes = useSelector((state) => state.notes);
  const notifications = useSelector((state) => state.notifications);
  const allProjectsList = useSelector((state) => state.tasks.defaultDetails.allProjectsList);
  const allQueueList = useSelector((state) => state.allQueues);
  const loading = useSelector((state) => state.loader.loading);
  const isCollabCentralOpened = useSelector((state) => state.common.isCollabCentralOpened);
  const dispatch = useDispatch();
  const user = auth.user;

  const header = currentRoute?.PROPS?.HEADER === label.PROJECTpS ? appendString(auth.user.operationalTeamName, label.SMALL_S) || label.TEAM : currentRoute?.PROPS?.HEADER;
  const taskRoute = route.PRIVATE_ROUTE.TASKS.PATH;
  let { projectName } = pageParamState;

  /* Used to get notification count for the main header */
  useEffect(() => {
    dispatch(getNotificationsCount(user.id, getTimeFromDate(newDate), dateConvert(newDate)));
    setCurrentRouteFunction();
  }, []);

  useEffect(() => {
    if (!isCollabCentralOpened) {
      setShowDescription(false);
    }
  }, [isCollabCentralOpened]);

  useEffect(() => {
    let { projectId } = pageParamState;
    let projectList;
    projectList = allProjectsList;
    setProjectNameState(projectList?.find((i) => i.ProjectId == projectId)?.ProjectName);
  }, [pageParamState.projectId, allProjectsList]);

  useEffect(() => {
    setQueueName(allQueueList?.find((i) => i.QueueId == pageParamState.queueId)?.QueueName);
  }, [pageParamState.queueId, allQueueList]);

  useEffect(() => {
    let currentProject = projectNameState ? projectNameState : (queueName ? queueName : auth.user.myProjectName);
    setProject(currentProject);
  }, [projectNameState, queueName, auth.user]);

  useEffect(() => {
    (async () => {
      let { queueId } = pageParamState;
      if (queueId) {
        const result = await isDescriptionUpdated(queueId, label.QUEUE_ENTITY, auth.user.id)();
        setDescriptionUpdated(result.IsUpdated);
      }
    })();
  }, [pageParamState.queueId]);

  useEffect(() => {
    (async () => {
      let { projectId } = pageParamState;
      if (projectId) {
        const result = await isDescriptionUpdated(projectId, label.PROJECT, auth.user.id)();
        setDescriptionUpdated(result.IsUpdated);
      }
    })();
  }, [pageParamState.projectId]);

  useEffect(() => {
    (async () => {
      if (location) {
        setCurrentRouteFunction();
        dispatch(setUpdateLayoutDesc(false));
      }
    })();
  }, [location]);

  /**
   * handling the open/close of dropdown menu
   * @returns {boolean to open/close dropdown}
   */
  const handleDropdownClick = (e) => {
    setOpenProfile(!openProfile);
  };

  /**
   * Sets router object from routes.js based on current route for header and icon
   */
  const setCurrentRouteFunction = () => {
    let pathname = location.pathname;
    setPageParams(pathname);
    let currentRoute = Object.values({ ...route.PRIVATE_ROUTE, ...route.ADMIN_ROUTE, ...route.SUPER_ADMIN_ROUTE }).find((r) => {
      return pathname.includes("superAdmin") ? r.PATH.includes(pathname) : r.PATH.includes(splitString(pathname, "/")[1]);
    });
    setCurrentRoute(currentRoute);
  };

  /**
   *
   * Sets state variable as page params task page
   * @param {String} pathname
   * @author Himanshi Chawla
   */
  const setPageParams = (pathname) => {
    if (matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH })) {
      const { params } = matchPath(pathname, { path: route.PRIVATE_ROUTE.TASKS.ROUTER_PATH });
      setPageParamState(params);
    } else if (matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH })) {
      const { params } = matchPath(pathname, { path: route.PRIVATE_ROUTE.QUEUE_TASKS.ROUTER_PATH });
      setPageParamState(params);
    }
  };

  const fetchNotes = async () => {
    await dispatch(clearAllNotes());
    const payload = { userId: user.id, limit: number.FIFTEEN, offset: number.ZERO };
    await dispatch(getNotes(payload));
  };
  /**
   * handling the open/close of layout description
   * @params {void}
   * @returns {void}
   * @author Shivam
   */
  const handleShowDescription = () => {
    if (showDescriptionIcon[currentRoute.ENTITY_NAME]) {
      setShowDescription(!showDescription);
      setDescriptionUpdated(false);
    }
  };
  /**
   * handling the open/close of notification dropdown and clears notification count
   * @returns {void}
   */
  const handleNotificationButtonClick = () => {
    if (!notifications.showNotification) dispatch(setNotificationCount(number.ZERO));
    dispatch(setShowNotifications(!notifications.showNotification));
    dispatch(setIsApprovalFilterSelected(false));
  };

  /**
   * handling the open/close of NOotes dropdown and getNotes api call
   * @returns {void}
   *
   */
  const handleNotesButtonClick = async () => {
    setNotesLoading(true);
    await dispatch(setShowNotesList(!notes.showNotesList));
    await fetchNotes();
    setNotesLoading(false);
  };

  return (
    <React.Fragment>
      <Tooltip anchorElement='target' position='bottom' parentTitle='true'>
        <header id='page-topbar' className='position-fixed top-0 right-0'>
          <div className='navbar-header d-flex justify-content-between align-items-center'>
            <div className='d-flex navbar-left align-items-center'>
              {header !== label.HOME && (
                <Link to={taskRoute} className={`btn btn-sm px-3 header-item project-icon ${header === label.WORKFLOW ? "workflow-icon position-relative" : ""}`}>
                  {icon.HOME}
                </Link>
              )}
              <div className='header-main-heading' ref={layoutDescriptionRef} onClick={handleShowDescription}>
                <span className='position-relative mr-1'>
                  {header === label.BREADCRUMB_TASKS ? (
                    <>
                      {currentRoute?.SHOW_BREADCRUMBS ? (
                        <>{project}</>
                      ) : (
                        <>
                          {" "}
                          <span className='cursor-pointer'>{project}</span>
                          {(projectName || queueName) && ` / ${label.BREADCRUMB_TASKS}`}
                        </>
                      )}
                    </>
                  ) : (
                    header
                  )}
                  {(pageParamState.projectId || pageParamState.queueId) && descriptionUpdated ? <span className='newstream-indicator rounded-circle position-absolute'></span> : <></>}
                </span>
                {/* Condition for showing description icon */}
                {showDescriptionIcon[currentRoute?.ENTITY_NAME] && (
                  <span className='task-down-circle-icon cursor-pointer' title={tooltip.OPEN_DESCRIPTION}>
                    {icon.EXPAND}
                  </span>
                )}
              </div>
            </div>
            {/* Condition for CollabCentralLayout */}
            {showDescription && <CollabCentralLayout anchor={layoutDescriptionRef} setShowPopup={handleShowDescription} classicMode={true} />}
            <div className='d-flex align-items-center'>
              {/* OverallSearch component */}
              <div className='app-search' ref={overallSearchRef}>
                {" "}
                <OverallSearch anchor={overallSearchRef} popupClass='header-search' />
              </div>
              {/* PlanMyDay component */}
              {!!auth.user.PlanMyDay && (
                <div className={`dropdown plan-my-day-header-icon`}>
                  {" "}
                  <PlanMyDay />
                </div>
              )}
              {/* BackForwardNavigation component */}
              <div className={`dropdown`}>
                {" "}
                <BackForwardNavigation />{" "}
              </div>
              {/* Notes button */}
              <div className='dropdown'>
                <button type='button' className='btn header-item header-icon header-hover-btn' title={tooltip.NOTES} disabled={notesLoading} onClick={handleNotesButtonClick}>
                  {icon.EDIT_NOTES}
                </button>
              </div>
              {/* ToggleTheme component */}
              <div className='dropdown'>
                {" "}
                <ToggleTheme />
              </div>
              {/* BadgeContainer for Notifications */}
              <div className='dropdown'>
                <BadgeContainer>
                  <div className='cursor-pointer' id='header-notification-box' onClick={handleNotificationButtonClick}>
                    <button type='button' id='button-header-notify-icon' className='btn header-item header-icon header-hover-btn' title={tooltip.NOTIFICATIONS}>
                      {icon.NOTIFICATION}
                    </button>
                    {/* Conditionally showing notification indicator */}
                    {notifications.notificationCount > number.ZERO && !notifications.showNotification && (
                      <span className='notification-newstream-indicator rounded-circle position-absolute notification-indicator '></span>
                    )}
                  </div>
                </BadgeContainer>
                {/* Conditionally showing Notifications component */}
                {notifications.showNotification && <Notifications />}
              </div>
              {/* UserDropdown */}
              <div className='d-inline-block dropdown'>
                <button type='button' id='button-header-profile-icon' className='btn header-item header-icon header-hover-btn' ref={buttonRef} onClick={() => handleDropdownClick()}>
                  {/* Conditionally rendering user profile picture or default icon */}
                  {auth.user.PhotoLink ? <img alt='Profile Pic' className='profile-picture rounded-circle object-fit-cover' src={auth.user.PhotoLink} /> : icon.ACCOUNT_CIRCLE}
                </button>
                {/* Conditionally showing UserDropdown */}
                <div ref={profileRef}>{openProfile && <UserDropdown openProfile={openProfile} buttonRef={buttonRef} toggleDropdown={handleDropdownClick} />}</div>
              </div>
            </div>
          </div>
          {/* Loader component */}
          {loading && <Loader />}
        </header>
      </Tooltip>
      {/* Notes component */}
      <Notes />
    </React.Fragment>
  );
};

export default MainHeader;
