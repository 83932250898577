import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleApproval } from '../../../../../actions/taskSidebar';
import { icon, label, notifyIcon, number, quote, tooltip } from '../../../../../config';
import { editWorkflowElement } from '../../../../../shared/services/workflow.services';
import { getNotification } from '../../../../../utils/common';
import { getTaskWorkflowDetails, handleTaskKeyUpdate, markTaskAsPlanned, updateTaskSidebar } from '../../../../Tasks/tasks.service';
import { planMyDayEffects } from '../../../TaskSidebarHeader/TaskSidebarHeader.helper';
import { getCurrentActiveProject } from '../../../sidebar.common';
import { checkFlowInsertion, getAssignmentAndApprovalPayload, getRelatedAssignedType } from '../../../sidebar.helper';
import { existingRelatedAssignedIdListPayload, getAssignmentNotification, getInitialRelatedAssignId, getRelatedAssignmentList, isExternalAssignment, isInternalUserChanged } from '../taskDetail.helper';
import { TaskAssignmentContext } from './taskAssignment.context';

/**
 * Task submit component
 * Parent component => TaskAssignment
 * @returns JSX
*/
const TaskSubmit = () => {
  const [tickDisabled, setTickDisabled] = useState(false);
  const [initialRelatedAssignmentType, setInitialRelatedAssignmentType] = useState(null);
  const [initialRelatedAssignmentId, setInitialRelatedAssignmentId] = useState(null);

  const assignmentState = useContext(TaskAssignmentContext);

  const { assignedId, relatedAssignmentId, setAssignmentType, setAssignedId, setRelatedAssignmentId, setDelegationType, setExternalUser, isDisabled, workflowAssignmentType, setWorkflowAssignmentType, setRelatedAssignmentList, assignmentType: assignedType, matchingFlowElement } = assignmentState;

  const { isPlanMyDayActive } = useSelector((state) => state.planMyDay);
  /**
   * disables tick icon if all any value is not found
   * @author {Prachi Jain}
   */
  useEffect(() => {
    if (!assignedId?.value || !assignedType?.key || !workflowAssignmentType?.value) {
      setTickDisabled(true)
    } else {
      setTickDisabled(false)
    }
  }, [assignedId, assignedType, relatedAssignmentId, workflowAssignmentType])

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { defaultDetails } = useSelector(state => state.tasks);
  const { task, isNewTask, taskUrl, isApproval, flowElements } = useSelector(task => task.taskSidebar);

  // set user icon in DD3 and assignee in DD4 for generic projects
  useEffect(() => {
    (async () => {
      if (isNewTask) return
      const assignedTypePayload = { AssignedType: task?.CurrentAssignedType, AssignedId: task?.CurrentAssignedId, RelatedAssignedType: task?.CurrentRelatedAssignedType }
      const currentRelatedAssignedType = getRelatedAssignedType(assignedTypePayload);
      setInitialRelatedAssignmentType(currentRelatedAssignedType);
      const requiredId = await getInitialRelatedAssignId({ task, defaultDetails });
      setInitialRelatedAssignmentId(requiredId);
    })();
  }, [task?.taskId, task?.Assignee])

  /**
   * re-collect the initial states of assignment dropdown and cancels the assignment
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */
  const clearAssigned = async () => {
    if (isNewTask) {
      setAssignedId(null);
      setRelatedAssignmentId(null);
      setDelegationType({ delegate: true, passOn: false, approval: false })
      handleTaskKeyUpdate(isNewTask, "assignedId", null);
      handleTaskKeyUpdate(isNewTask, "assignee", null);
      handleTaskKeyUpdate(isNewTask, "isReturnable", true)
      return;
    }
    const { workflowAssignmentType, assignedId, assignmentType } = await existingRelatedAssignedIdListPayload(defaultDetails, task);
    setAssignmentType(assignmentType);
    setAssignedId(assignedId);
    setWorkflowAssignmentType(workflowAssignmentType);
    const requiredId = await getInitialRelatedAssignId({ task, defaultDetails });
    setRelatedAssignmentId(requiredId);
    const payload = { workflowAssignmentType, assignedId, assignmentType, defaultDetails }
    const assignmentList = await getRelatedAssignmentList(payload);
    setRelatedAssignmentList(assignmentList ? assignmentList : []);
    setExternalUser(false);
    setDelegationType({ delegate: false, passOn: true, approval: false });
    dispatch(toggleApproval(false));
  }

  /**
 * update the task assignment
 * workflowAssignmentType = 5 => assigned to workflow (DD3)
 * @param {Void}
 * @returns {Void}
 * @author Himanshu Negi
 */
  const updateTaskAssignment = async () => {
    setTickDisabled(true);
    const taskState = { task, isApproval, taskUrl, user };
    if (!assignedId?.value) {
      getNotification(label.SELECT_USER, notifyIcon.WARNING_ICON);
      setTickDisabled(false);
      return;
    }
    const payload = getAssignmentAndApprovalPayload(taskState, assignmentState);
    const resetSidebar = isInternalUserChanged(task, assignmentState, isApproval);
    const externalAssignment = isExternalAssignment(task, assignmentState);
    const { currentProjectIndex } = getCurrentActiveProject(flowElements);
    const checkInsertionPayload = { ...payload, taskAssignmentId: payload.assignedId, assignmentProjectId: assignedType.key === number.TWO ? assignedId?.myProjectId : assignedId?.value };
    const isValidInsertion = await checkFlowInsertion(flowElements, currentProjectIndex + number.ONE, checkInsertionPayload);
    if (isValidInsertion && !externalAssignment) {
      getNotification(quote.CAN_NOT_COEXIST, notifyIcon.WARNING_ICON);
      setTickDisabled(false);
      return;
    }
    const response = await dispatch(updateTaskSidebar(payload, isPlanMyDayActive ? false : resetSidebar));
    
    if (isPlanMyDayActive) {
      planMyDayEffects()
      return;
    };

    if ((!resetSidebar || isApproval) && response) {
      dispatch(getTaskWorkflowDetails({ taskId: payload.taskId }));
      clearAssigned()
      const notificationVerbiage = getAssignmentNotification(task, assignmentState, isApproval)
      notificationVerbiage && getNotification(notificationVerbiage, notifyIcon.SUCCESS_ICON);
      setTickDisabled(false);
    }
    if (matchingFlowElement) {
      matchingFlowElement.workflowAssignmentId = relatedAssignmentId?.value;
      matchingFlowElement.flowElementId = matchingFlowElement.id
      await dispatch(editWorkflowElement(matchingFlowElement))
    }
    isApproval && dispatch(toggleApproval(false));

  }

  return (
    <React.Fragment>
      <div className='task-assignment-submit'>
        {(relatedAssignmentId?.value !== initialRelatedAssignmentId?.value || (assignedId?.value !== task.CurrentAssignedId) || workflowAssignmentType?.value !== initialRelatedAssignmentType || isApproval) &&
          <div className="d-flex align-items-center justify-content-center">
            {!isNewTask && !isDisabled && <button className="ml-1 task-check-icon stroke-thin icon cursor-pointer bg-transparent" disabled={tickDisabled} onClick={updateTaskAssignment} title={tooltip.CLICK_TO_ASSIGN}>{icon.CHECKBOX}</button>}
            {!isDisabled && !isNewTask && <span className="task-check-icon stroke-thin icon" ><span onClick={clearAssigned}>{icon.CLOSE}</span></span>}
          </div>}
      </div>
    </React.Fragment>
  )
}

export default TaskSubmit;