import React  from 'react';
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { label, number } from "../../../../config";
import { assignedTypes } from '../../../Tasks/tasks.constants';
import {  useTaskOwnerContext } from './taskOwner.Context';

/**
 * OwnerAssignmentTyp type drowpdown component
 * Parent component => OwnerShipContainer
 *  @author Shivam
 */
const OwnerAssignmentType = () => {
  const {  assignmentType , setOwnerAssignmentType} = useTaskOwnerContext()

 
  const itemRender = (li, itemProps) => {
    const itemChildren = (<span key={itemProps?.index}>{itemProps.dataItem.icon}</span>);
    return React.cloneElement(li, li.props, itemChildren);
  }

  const valueRender = (element, value) => {
    if (!value) {
      return element;
    }
    const children = [<span key={value?.key}>{value.icon}</span>];
    return React.cloneElement(element, { ...element.props }, children);
  };

  /**
   * handles assignment type change
   * @param {Object} event
   * @returns {Void}
   */
  const handleChange = async (event) => {
    setOwnerAssignmentType(event.target.value);
  }
  

  return (
    <React.Fragment>
      <div className="form-group col-lg-2 col-md-2 col-sm-2 ">
        <label htmlFor="">{label.ASSIGN_TO}</label>
        <DropDownList
          data={assignedTypes}
          value={assignmentType}
          onChange={handleChange}
          id="ownership-dd1"
          textField="label"
          itemRender={itemRender}
          valueRender={valueRender}
        />
      </div>
    </React.Fragment>

  )
}

export default OwnerAssignmentType;