import { Loader } from '@progress/kendo-react-indicators';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import React, { useContext, useEffect, useRef, useState } from "react";
import { icon, label, number, quote } from "../../../config/index";
import { popupAlign } from "../../../helper/common";
import { onBlur, onOpen } from "../../../utils/kendo";
import { ReminderContext } from './ReminderContextProvider';
import './reminder.scss';

/**
* reminder List
* @author Shivam Mishra
*/
const ReminderList = React.memo(({ show, anchor, onClick, reminders, handleClick, delReminder, setDelReminder, handleAddRem, handleOutSideClick, reminderDetails }) => {
    const [reminderList, setReminderList] = useState([])
    const contentRef = useRef();
    const blurTimeoutRef = useRef();
    const { delId, isAddClicked, isReminderClicked } = useContext(ReminderContext)

    useEffect(() => {
        setReminderList(reminders)
    }, [reminders])

    const reminderListPopup = () => {
        return (
            <Popup show={show} anchor={anchor.current} popupAlign={popupAlign("right", "top")} animate={false} popupClass={'dt-popup reminder-popup '} onOpen={() => onOpen(contentRef)}>
                <Tooltip anchorElement="target" parentTitle={true} position="bottom">
                    <div ref={contentRef} tabIndex={number.ZERO} onBlur={() => onBlur(blurTimeoutRef, onClick)}>
                        <div className="reminder-header d-flex justify-content-between align-items-center pt-2 pb-2 pl-3 pr-3" >
                            <div className="reminder-title">
                                {quote.EXISTING_REMINDERS}
                            </div>
                            <span className="dt-popup-cancel-btn cursor-pointer ml-2" title={label.CLOSE_POPUP} onClick={onClick}>
                                {icon.CLOSE}
                            </span>
                        </div>

                        <div className="dt-popup-body p-0 reminder-body overflow-y-auto overflow-x-hidden">
                            <div className='text-center mb-2'>{!reminderList && <Loader />}</div>
                            {
                                (reminderList?.length > number.ZERO) &&
                                reminderList?.map((reminder) => {

                                    return (
                                        <React.Fragment key={reminder.Id}>
                                            <div className="reminder-list-item" onClick={() => handleClick(reminder)}>
                                                <div className="w-100 d-flex justify-content-between align-items-center">
                                                    <span className="cursor-pointer text-truncate">{reminder.ReminderMessage}</span>
                                                    {reminder.ReminderType == 2 && <span className="cursor-pointer">{icon.BOLT}</span>}
                                                </div>
                                                <div className="w-100 d-flex justify-content-between align-items-center">
                                                    {reminderDetails(reminder)}
                                                    <div className={'position-relative'}>
                                                        <span onClick={(e) => setDelReminder(reminder.Id, e)} title={label.DEL_REM} className="delete-button cursor-pointer">{icon.DELETE_ICON}</span>
                                                        {
                                                            delId == reminder.Id &&
                                                            <div className={'floating-button cursor-pointer'} onClick={(e) => delReminder(reminder.Id, e)}>{label.REMOVE}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                                )
                            }
                        </div>
                        <div className="dt-popup-footer text-right">
                            <span onClick={() => handleAddRem(true)} title={label.ADD_REM} className="cursor-pointer">
                                {icon.ADD_CIRCLE_ICON}
                            </span>

                        </div>
                    </div>
                </Tooltip>
            </Popup>
        )
    }

    return <div>
        {

            !isAddClicked && !isReminderClicked && reminderListPopup()
        }
    </div>;
});
export default ReminderList;