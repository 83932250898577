import React, { useContext, useEffect, useState } from 'react'
import DetailedFlowElementDetails from '../../../shared/components/FlowElement/DetailedFlowElementDetails'
import { FlowElementsContext } from '../../Workflow/FlowElements/FlowElementsContextProvider'
import { checkFlowInsertionFromFlows, handleTaskState } from '../../TaskSidebar/sidebar.helper';
import { setFlowElements } from '../../../actions/taskSidebar';
import { getNotification, isNonNegativeOrNull } from '../../../utils/common';
import { notifyIcon, number, quote } from '../../../config';
import { deleteItem, update } from '../../Workflow/workflowHelper';
import { GridContext } from '../../../utils/kendo';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

/**
* Parent Component : FLowElementDetails
* This defines the logic particular to task used for DetailedFlowElements and FlowElementCommandCell
* @author Sarthak Arora
*/
const TaskDetailedFlowElementDetails = (props) => {

    const { defaultDetails } = useSelector((state) => state.tasks);
    const { isNewTask, flowElements } = useSelector((state) => state.taskSidebar)
    const { projectId, workflowId } = useParams();
    const dispatch = useDispatch()

    const { taskAssignmentTypes, workflowAssignmentTypes } = useContext(FlowElementsContext);
    const { flowElementData, setFlowElementData, existingFlowElements, entity, addFlowElement, editFlowElement, taskElement } = useContext(GridContext);

    const { flowAssignmentType, flowWorkflowAssignmentType, flowTaskAssignmentId, flowWorkflowAssignmentId, flowElementDueDate, flowElementDelay, isApproval, isFlowLocked } = useContext(FlowElementsContext)

    const projectWorkflowOrUser = flowAssignmentType?.value == number.THREE ? (flowWorkflowAssignmentType && flowWorkflowAssignmentId) : true;

    const requiredFields = flowAssignmentType && flowTaskAssignmentId && projectWorkflowOrUser && isNonNegativeOrNull(flowElementDueDate) && isNonNegativeOrNull(flowElementDelay)

    /**handles edit in case of existing flow element*/
    const handleFlowElementEdit = async (dataItem) => {
        let payload = {
            flowElementId: dataItem?.id,
            taskAssignmentType: flowAssignmentType?.value,
            taskAssignmentId: flowTaskAssignmentId?.value,
            workflowAssignmentType: flowWorkflowAssignmentType?.value,
            workflowAssignmentId: flowWorkflowAssignmentId?.value,
            dueDateAfter: flowElementDueDate,
            delayAfterCompletion: flowElementDelay,
            sortId: dataItem?.sortId,
            assignmentProjectId: flowAssignmentType?.value === number.THREE ? flowTaskAssignmentId?.value : (defaultDetails?.allProjectsList?.find((item) => item.IsPersonal === number.ONE && item.CreatedBy === flowTaskAssignmentId?.value)?.ProjectId),
            isApproval,
            isFlowLocked
        }
        let isNotValidInsertion, delgationBasedInsertion;
        payload["taskId"] = entity;
        const updatedFlows = flowElements?.map((flow) => flow?.id === dataItem?.id ? { ...flow, ...payload, id: dataItem?.id } : flow)
        const itemIndex = flowElements?.findIndex(flow => flow.id === dataItem?.id)
        isNotValidInsertion = checkFlowInsertionFromFlows(updatedFlows, itemIndex - number.ONE, itemIndex + number.ONE, payload, false)
        !isNotValidInsertion ? dispatch(setFlowElements([...updatedFlows])) : getNotification(quote.CAN_NOT_COEXIST, notifyIcon?.WARNING_ICON)

        if (requiredFields && !isNotValidInsertion && !delgationBasedInsertion) {
            update({ ...payload, id: dataItem?.id }, existingFlowElements, setFlowElementData, requiredFields);
            entity && await editFlowElement(payload);
        }
    }

    /**handles addition of new flow element*/

    const handleNewFlowElement = async (dataItem) => {
        const newDataItem = {
            flowElementId: dataItem?.id,
            workflowId: entity,
            taskAssignmentType: flowAssignmentType?.value,
            taskAssignmentId: flowTaskAssignmentId?.value,
            workflowAssignmentType: flowWorkflowAssignmentType?.value,
            workflowAssignmentId: flowWorkflowAssignmentId?.value,
            dueDateAfter: flowElementDueDate,
            delayAfterCompletion: flowElementDelay,
            sortId: flowElementData?.length,
            assignmentProjectId: flowAssignmentType?.value === number.THREE ? flowTaskAssignmentId?.value : (defaultDetails?.allProjectsList?.find((item) => item.IsPersonal === number.ONE && item.CreatedBy === flowTaskAssignmentId?.value)?.ProjectId),
            isApproval,
            isFlowLocked
        }
        let payload = {
            workflowElements: [newDataItem],
            isNewTask
        }
        payload["taskId"] = entity;
        const insertionIndex = flowElementData?.length - number.ONE;
        const response = entity && requiredFields &&
            !checkFlowInsertionFromFlows((taskElement ? flowElements : flowElementData), insertionIndex - number.ONE, insertionIndex, newDataItem, (taskElement ? false : true)) &&
            !(newDataItem.assignmentProjectId == projectId && newDataItem.workflowAssignmentType == number.FIVE && newDataItem.workflowAssignmentId == workflowId)
            && await addFlowElement(payload);
        const id = entity ? response?.flowId : null
        handleTaskState(newDataItem, requiredFields, id, [...flowElements]);
    }


    return (
        <div>
            <DetailedFlowElementDetails {...props}
                taskAssignmentTypes={taskAssignmentTypes}
                workflowAssignmentTypes={workflowAssignmentTypes}
                handleFlowElementEdit={handleFlowElementEdit}
                handleNewFlowElement={handleNewFlowElement}
            />
        </div>
    )
}

export default TaskDetailedFlowElementDetails