import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { createTaskViaSharedFlow } from "../../../../../actions/taskSidebar";
import { icon, notifyIcon, number, quote } from "../../../../../config";
import { getQueryParams } from "../../../../../helper/common";
import Notify from "../../../../../shared/components/Kendo/Notify";
import { createDynamicTeamName } from "../../../../../utils/common";
import { filterData } from "../../../../../utils/kendo";
import { handleTaskKeyUpdate } from "../../../../Tasks/tasks.service";
import { getAssignedIdElem, getCurrentProject, getFilterAssigneeList, getProjectList, handleGetProjectWorkflows, userPartOfAssignedProject } from "../../../sidebar.helper";
import { getSelectedWorkflowDetails, handleRelatedAssignment, initialProjectChangeEffect, shareWorkflowEffect } from "../taskDetail.helper";
import TaskIsReturnable from "./TaskIsReturnable";
import { TaskAssignmentContext } from "./taskAssignment.context";


/**
 * TaskAssignmentId component
 * Parent component => TaskAssignment
 * @returns JSX
 */
const TaskAssignmentId = () => {
  const { showTaskDetailPanel } = useSelector((state) => state.sidebarContent);
  const location = useLocation();
  const dispatch = useDispatch();
  const [assignedIdList, setAssignedIdList] = useState([]);
  const [notify, setNotify] = useState(false);
  const { currentTheme } = useThemeSwitcher();

  const assignmentState = useContext(TaskAssignmentContext);
  const { assignmentType, assignedId, setAssignedId, setExternalUser, setCurrentProject, isDisabled, setWorkflowAssignmentType } = assignmentState;

  const { defaultDetails } = useSelector(state => state.tasks);
  const { task, isNewTask, newTaskData, sharedWorkflowDetail, showTaskSidebar } = useSelector(state => state.taskSidebar);
  const loader = useSelector((state) => state.taskSidebar?.loaderComponent?.assignedId);
  const { user } = useSelector((state) => state.auth);

  const createTask = getQueryParams('createTask', location.search) || sharedWorkflowDetail.createTask;
  const sharedProjectId = getQueryParams('pId', location.search) || sharedWorkflowDetail.sharedProjectId;
  const sharedWorkflowId = getQueryParams('fId', location.search) || sharedWorkflowDetail.sharedFlowId;
  const ProjectName = assignmentType?.key === number.TWO ? assignedId?.label : assignedId?.ProjectName;
  const contentRef = useRef();

  // set dd2 list based on dd1
  useEffect(() => {
    if (assignmentType) {
      getAndFilterAssigneIdList();
    }
  }, [assignmentType]);

  //sets dd2 existing value in case of existing task
  useEffect(() => {
    (async () => {
      if (task?.taskId) {
        const assignee = await getAssignedIdElem(defaultDetails, task?.CurrentAssignedType, task.CurrentAssignedId);
        setAssignedId(assignee);
        await handleGetProjectWorkflows(assignmentType == number.TWO ? parseInt(assignee?.myProjectId) : parseInt(assignee?.value));
      }
    })();

  }, [task?.taskId, task?.CurrentAssignedId]);

  //sets assignId in redux in case of new task
  useEffect(() => {
    (async () => {
      if (isNewTask) {
        await handleTaskKeyUpdate(isNewTask, "assignedId", assignedId?.value);
      }
    })();
  }, [isNewTask, assignedId])

  //sets dd1, dd2, dd3, dd4 when base project changes
  useEffect(() => {
    (async () => {
      if (isNewTask && !createTask) {
        const payload = { defaultDetails, assignmentState, isNewTask, task, newTaskData, user };
        await initialProjectChangeEffect(payload);
      }
    })();
  }, [isNewTask, newTaskData?.project])


  /**
 * sets dd1, dd2, dd3, dd4 for shared workflow url
 * @author Shivam
 */
  useEffect(() => {
    (async () => {
      if (createTask && newTaskData?.project) {
        const payload = { defaultDetails, assignmentState, isNewTask, task, newTaskData, user, sharedProjectId, sharedWorkflowId };
        await shareWorkflowEffect(payload);
        dispatch(createTaskViaSharedFlow({ createTask: false, sharedFlowId: null, sharedProjectId: null }));
      }
    })();
  }, [createTask, newTaskData?.project])

  /**
   * handles assignId change incase of user assignment
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleUserAssignedId = async (selectedAssignId) => {
    setNotify(true);
    setExternalUser(true);
    const payload = { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task }
    await handleRelatedAssignment(payload);
  }

  /**
   * handles assignId change incase of project assignment
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleProjectAssignedId = async (selectedAssignId) => {
    const payload = { defaultDetails, selectedAssignId, assignmentState, isNewTask, user, task }
    await handleRelatedAssignment(payload)
  }

  /**
   * makes required changes when assignId is changed
   * @param {Object} selectedAssignId
   * @returns {Void}
   * @author Himanshu Negi
   */
  const assignIdStateChanges = async (selectedAssignId) => {
    setAssignedId(selectedAssignId);
    await setCurrentProject(getCurrentProject({ assignmentType, assignedId: selectedAssignId }));
    const requiredWorkflowAssignmentType = defaultDetails?.relatedAssignmentTypeList.find((type) => type.value == (userPartOfAssignedProject({ ...assignmentState, assignedId: selectedAssignId }) ? number.TWO : number.FIVE));
    setWorkflowAssignmentType(requiredWorkflowAssignmentType ? requiredWorkflowAssignmentType : null);
  }

  /**
   * handles assignedId change
   * @param {Object} event
   * @returns {Void}
   * @author Himanshu Negi
   */
  const handleChange = async (event) => {
    if (event) {
      const selectedAssignId = event.target.value;
      await assignIdStateChanges(selectedAssignId)
      switch (assignmentType?.key) {
        case number.TWO:
          const userProjectWorkflows = selectedAssignId?.myProjectId && await handleGetProjectWorkflows(parseInt(selectedAssignId?.myProjectId))
          isNewTask && await getSelectedWorkflowDetails(userProjectWorkflows, selectedAssignId?.myProjectId)
          handleUserAssignedId(selectedAssignId);
          return;
        case number.THREE:
          const selectedProjectWorkflows = selectedAssignId?.ProjectId && await handleGetProjectWorkflows(parseInt(selectedAssignId?.ProjectId))
          isNewTask && await getSelectedWorkflowDetails(selectedProjectWorkflows, selectedAssignId?.ProjectId)
          handleProjectAssignedId(selectedAssignId);
          return;
        default:
          break;
      }
    }
  }

  /**
   * sets assignedId list on the basis of assignmentType
   * @param {Void}
   * @returns {Void}
   * @author Himanshu Negi
   */

  const getAndFilterAssigneIdList = (event) => {
    switch (assignmentType?.key) {
      case number.TWO:
        let assigneeList = getFilterAssigneeList();
        assigneeList = event ? filterData(event.filter, assigneeList) : assigneeList;
        setAssignedIdList(assigneeList);
        break;
      case number.THREE:
        let projects = getProjectList();
        projects = event ? filterData(event.filter, projects) : projects;
        setAssignedIdList(projects);
      default:
        break;
    }
  }

  /**
   * custom dropdown Item render for DD2
   * @returns JSX
   * @author Prachi Jain
   */
  const itemRender = (li, itemProps) => {
    const { Privacy, label, Email } = itemProps.dataItem;
    let colorCode = currentTheme == 'dark' ? 'white' : "#000000"
    const itemChildren = (
      <span key={itemProps?.index} style={{ color: colorCode }} title={label} className={assignmentType?.key == number.THREE ? "d-flex text-center" : "custom-assignee-dropdown-item text-truncate d-flex flex-column"}>
        {assignmentType?.key == number.THREE && <div className="mr-2" style={{ color: Privacy == number.ONE ? "#56B2D9" : (Privacy == number.TWO ? colorCode : "maroon") }}>{icon["PROJECT_ICON"]}</div>}
        <span className='assignee-name text-truncate'>{label}</span>
        {Email && <span className='assignee-email text-truncate'>{Email}</span>}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  }

  /**
   * custom dropdown value render for DD2
   * @returns JSX
   * @author Prachi Jain
   */
  const valueRender = (element, value) => {
    let colorCode = currentTheme == 'dark' ? 'white' : "#000000"
    if (!value) {
      return element;
    }
    const children = [<div className="float-left d-flex text-center" key={value?.value}>
      {assignmentType?.key == number.THREE && <span className="mr-2" style={{ color: value.Privacy == number.ONE ? "#56B2D9" : (value.Privacy == number.TWO ? colorCode : "maroon") }}>{icon["PROJECT_ICON"]}</span>}
      <span key={value?.value}>{value.label}</span>
    </div>];
    return React.cloneElement(element, { ...element.props }, children);
  };
  return (
    <React.Fragment>
      <div className={`tour-task-sidebar-asignmentId form-group col-lg-4 col-md-4 col-sm-4 ${showTaskDetailPanel && !isNewTask ? 'text-truncate mr-3' : 'd-flex align-items-left flex-column task-returnable project-name-column'}`}>
        {showTaskDetailPanel && !isNewTask ? <Tooltip position='bottom' anchorElement="target" parentTitle={true}><div className='text-truncate' title={ProjectName}>{ProjectName}</div></Tooltip> :
          <div className="d-flex mt-auto">
            <div className="w-100">
              <TaskIsReturnable />
              <Tooltip position='bottom' anchorElement="target" parentTitle={true} >
                <div className="d-flex">
                  <div className="task-assignee w-100">
                    <div ref={contentRef}>
                      <DropDownList
                        disabled={Boolean(isDisabled || loader || (assignmentType?.key === number.TWO && user?.isRestrictedUser))}
                        id="task-select-project"
                        data={assignedIdList}
                        value={assignedId}
                        onChange={(obj) => { handleChange(obj); }}
                        itemRender={itemRender}
                        valueRender={valueRender}
                        textField="label"
                        filterable={true}
                        onFilterChange={getAndFilterAssigneIdList}
                        title={assignedId ? assignedId.label : ""}
                        className={!assignedId ? "form-control-invalid" : ""}
                        popupSettings={{ appendTo: contentRef.current }}
                      />
                    </div>
                    {notify && <Notify message={createDynamicTeamName(quote.EXTERNAL_TASK, user.operationalTeamName)} icon={notifyIcon.WARNING_ICON} />}
                    {loader && <div className="preloader loader-wrap"></div>}
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        }

      </div>
    </React.Fragment>
  )
}


export default TaskAssignmentId;
