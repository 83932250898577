import React, { useEffect, useState } from "react";
import './sidebar.scss'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { icon, label, route, link, quote, tooltip } from "../../../config/";
import "react-pro-sidebar/dist/css/styles.css";
import drutaslogoWhiteD from "../../../assets/images/drutas-logo-white-d.png";
import drutaslogoWhite from "../../../assets/images/drutas-logo-white.png";
import { RESPONSIVE_WIDTH, number } from "../../../config/constants";
import BookmarksList from "../../../components/Bookmarks/BookmarksList";
import { useSelector, useDispatch } from "react-redux";
import { getBookmarks } from "../../../components/Bookmarks/bookmarks.service";
import TaskSidebar from "../../../components/TaskSidebar/TaskSidebar";
import ProjectSidebar from "../../../components/ProjectSidebar/ProjectSidebar";
import { Tooltip } from "@progress/kendo-react-tooltip";
import TeamSideBar from "../../../components/TeamSideBar/TeamSideBar";
import { appendString } from "../../../utils/common";
import { loadState } from "../../services/session.service";
import { getVersionNo, isDescriptionUpdated } from "../../services/common.service";
import CollabCentralLayout from "../CollabCentralLayout";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { subStrWithTooltip } from "../../validators/validator";
import PlanMyDayActions from "../../../components/TaskSidebar/PlanMyDayActions/PlanMyDayActions";

const SideBar = ({ sendCollapse }) => {
  const location = useLocation();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleItems, setToggleItems] = useState({ home: false, tasks: false, projects: false, queues: false })
  const { id, operationalTeamName ,companyId  } = useSelector((state) => state.auth.user);
  const userId = id;
  const { taskBookmarks, projectBookmarks, queueBookmarks } = useSelector((state) => state.bookmarks)
  const { showTaskSidebar } = useSelector((state) => state.taskSidebar);
  const { showProjectSidebar } = useSelector((state) => state.projectSidebar);
  const latestSidebar = useSelector((state) => state.latestSidebar);
  const { showQueueSidebar } = useSelector((state) => state.teamSidebar);
  const { isCollabCentralOpened } = useSelector((state) => state.common);
  const dispatch = useDispatch()
  const [version, setVersion] = useState();
  const [showDescription, setShowDescription] = useState(false);
  const [isDescUpdated , setIsDescUpdated] = useState();
  const layoutDescriptionRef = React.useRef();
  const { isPlanMyDayActive } = useSelector((state) => state.planMyDay);
  useEffect(() => {
    const storedVersion = loadState('version').length ? loadState('version') : "";
    if (version !== storedVersion) {
      setVersion(storedVersion);
    }
  });

  /**
 * Checks if the current pathname matches the tasks route.
 * If it does, it updates the state to set the tasks item as active and others as inactive.
 * @param {object} location - The current location object containing the pathname
 * @param {function} setToggleItems - Function to set the state of toggle items
 * @param {object} route - Object containing route information
 */

  useEffect(() => {
    const path = route.PRIVATE_ROUTE.TASKS.PATH;
    if(location?.pathname === path){
      setToggleItems({home: false, tasks: true, projects: false, queues: false })
    }
  }, [location?.pathname])

  useEffect(() => {
    (async () => {
      dispatch(getVersionNo())
      dispatch(getBookmarks(userId))
      const result = await isDescriptionUpdated(companyId , label.COMPANY, userId)();
      setIsDescUpdated(result?.IsUpdated)
    })();
  }, [])

/**
* closes the collab central description once tasksidebar gets rendered
* @author Shivam Mishra
*/
  useEffect(() => {
    if (!isCollabCentralOpened) {setShowDescription(false)}
  } , [ isCollabCentralOpened ])

  window.onresize = function () {
    resizeActions()
  }
  window.onload = function () {
    resizeActions()
  }
  /**
   * Window Resize Actions for responsive sidebar
   */
  const resizeActions = () => {
    if (window.innerWidth <= RESPONSIVE_WIDTH) menuIconClick(true)
    else menuIconClick(false)
  }
  /**
   * handling the open/close of sidebar
   * @returns {boolean to open/close sidebar}
   */
  const menuIconClick = (menu) => {
    setMenuCollapse(menu);
    sendCollapse(menu);
  };
  const toggleMenu = (item) => {
    let items = toggleItems;
    items = Object.fromEntries(Object.keys(items).map((key) => [key, false]))
    items[item] = true;
    setToggleItems(items);
  }
  /**
 * handling the open/close of layout description
 * @params {void}
 * @returns {void}
 * @author Shivam
 */

  const handleShowDescription = () => {
    setShowDescription(!showDescription)
    setIsDescUpdated(false)
  }

  /**
 * returns html element with tooltip if operationalTeamName is long
 * else returns String
 * @params
 * @author Pragun Gandotra
 */
  const getTeamLabel=()=>{
    if(operationalTeamName)
       return subStrWithTooltip(appendString(operationalTeamName , label.SMALL_S), number.ZERO, number.TEN)
    else
       return label.TEAM
  }

  return (
    <>
      <ProSidebar collapsed={menuCollapse} className="vertical-menu position-fixed">
        <Tooltip anchorElement="target" position="bottom" parentTitle='true'>
          <SidebarHeader
            className={menuCollapse ? "menu-collapse" : "menu-expand"}
          >
            <span className="brand-logo position-relative overflow-hidden d-inline-block text-center w-100 cursor-pointer" id="sidebar-collapse-click" ref={layoutDescriptionRef} onClick={handleShowDescription} title={tooltip.OPEN_COLLAB_CENTRAL}>
              <img src={drutaslogoWhite} alt="logo" className="logo h-auto"></img>
              { isDescUpdated ? <span className='newstream-indicator rounded-circle position-absolute collab-central'></span> : <></>}
            </span>
            {
              showDescription && <CollabCentralLayout isOrganisationDesc={true} anchor={layoutDescriptionRef} setShowPopup={handleShowDescription} classicMode={false}hidePreview = {true} />
            }
            <button className="btn btn-sm px-3 collapse-menu-icon position-absolute" id = "button-sidebar-collapse" onClick={() => menuIconClick(!menuCollapse)}>
              <span className="menu-bar-icon">{icon.MENU}</span>
              <span className="menu-close-icon">{icon.CLOSE}</span>
            </button>
          </SidebarHeader>
        </Tooltip>
        <div className="h-100 overflow-auto d-flex flex-column sidebar-content overflow-y-auto sidebar-scrollbar">
          <SidebarContent>
            <Menu>
              {/* <MenuItem onClick={() => toggleMenu('home')} className={toggleItems.home ? 'active' : ''} >
                <Link to={route.PRIVATE_ROUTE.HOME.PATH}>{icon.HOME} {label.HOME}</Link>
              </MenuItem> */}
              <MenuItem onClick={() => toggleMenu('tasks')} className={toggleItems.tasks ? 'active' : ''} >
              <Link id='task-sidebar' to={route.PRIVATE_ROUTE.TASKS.PATH}><span className="tour-sidebar-my-board">{icon.BULLET_LIST} {label.MY_BOARD}</span></Link>
              </MenuItem>
              {taskBookmarks?.length && <BookmarksList type={route.PRIVATE_ROUTE.TASKS.ENTITY_NAME} menuCollapse={menuCollapse} />}
              <MenuItem onClick={() => toggleMenu('projects')} className={toggleItems.projects ? 'active' : ''} >
                <Link id='team-sidebar' to={route.PRIVATE_ROUTE.PROJECT.PATH}>{icon.DVR} {getTeamLabel()}</Link>
              </MenuItem>
              {projectBookmarks?.length && <BookmarksList type={route.PRIVATE_ROUTE.PROJECT.ENTITY_NAME} menuCollapse={menuCollapse} />}
              <MenuItem onClick={() => toggleMenu('queues')} className={toggleItems.queues ? 'active' : ''} >
                <Link id='queue-sidebar' to={route.PRIVATE_ROUTE.QUEUES.PATH}>{icon.GROUPS} {label.QUEUES}</Link>
              </MenuItem>
              {queueBookmarks?.length && <BookmarksList type={route.PRIVATE_ROUTE.QUEUES.ENTITY_NAME} menuCollapse={menuCollapse} />}
            </Menu>
          </SidebarContent>
        </div>
        <SidebarFooter>
          <div className="text-center mt-2 mb-3">
            <a className="help-text" href={link.HELP_SUPPORT} target="_blank" rel="noreferrer" >{icon.HEADSET} {label.HELP_SUPPORT}</a>
          </div>
        </SidebarFooter>
        <SidebarFooter>
          <div className="text-center mb-2">
            <a className="help-text" href={link.VERSION_URL} target="_blank" rel="noreferrer" >{quote.VERSION} {version}</a>
          </div>
        </SidebarFooter>
      </ProSidebar>
      <div className={`task-slide-left position-fixed p-0 right-0  ${(showTaskSidebar) ? "page-slide-show" : ""} ${latestSidebar.tasks ? "top-sidebar" : ""}`}>
        {showTaskSidebar && <TaskSidebar />}
      </div>
      <div className={`task-slide-left position-fixed p-0 right-0  ${showProjectSidebar ? "page-slide-show" : ""} ${latestSidebar.projects ? "top-sidebar" : ""}`}>
        {showProjectSidebar && <ProjectSidebar />}
      </div>
      <div className={`task-slide-left position-fixed p-0 right-0 pt-2  ${showQueueSidebar ? "page-slide-show" : ""} ${latestSidebar?.teams ? "top-sidebar" : ""}`}>
        {showQueueSidebar && <TeamSideBar />}
      </div>
      {isPlanMyDayActive && <PlanMyDayActions />}

    </>
  );
};
export default SideBar;
