import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number } from '../../../../../config';
import { getWorkflowChildTasksData } from './linkTask.helper';
import { storeChildTask } from '../../../../../actions/linkedTasks';
import LinkedTaskGrid from './LinkedTaskGrid';

/**
 * Component for managing and displaying linked tasks for new tasks, using the LinkedTaskGrid component.
* Parent component: LinkedTasks
* Child component: LinkedTaskGrid
* @author Bhavana
 */
const NewTaskLinkTask = ({ linkedTaskType, labelValue }) => {
  const [gridData, setGridData] = useState(null);
  const dispatch = useDispatch();
  const { childTask, parentTask, relatedTo } = useSelector((state) => state.linkedTasks.linkTask);
  const { workflowChildTasks, workflowBasicDetails, workflowStages } = useSelector((state) => state.taskWorkflowDetails);
  const { showLinkedTasksSubTasks } = useSelector((state) => state.taskSidebar);
  const { defaultDetails } = useSelector((state) => state.tasks);
  const { isCloneTask } = useSelector((state) => state.cloneTaskDetails);
  /**
   * Handles workflow child tasks updates on mount or dependencies change.
   * @author Bhavana
   */
  useEffect(() => {
    (async () => {
        if (!isCloneTask) {
        if (workflowChildTasks?.length > childTask.length && showLinkedTasksSubTasks || (childTask?.length)) {
          const childTaskData = workflowChildTasks?.map((childTask, i) => {
            const payload = { workflowBasicDetails, defaultDetails, workflowStages, i, childTask, workflowChildTasks };
            return getWorkflowChildTasksData(payload);
          });
          if (childTaskData) {
            const childTaskDataa = await Promise.all(childTaskData);
            childTask?.length === number.ZERO && dispatch(storeChildTask(childTaskDataa));
          }
        } else if (workflowChildTasks?.length <= childTask.length && showLinkedTasksSubTasks) {
          dispatch(storeChildTask(childTask));
        } else {
          setGridData(null);
          dispatch(storeChildTask([]));
        }
      }
      })();
  }, [workflowChildTasks, workflowBasicDetails, showLinkedTasksSubTasks]);

  /**
 * Updates grid data based on the current linked task type when childTask, parentTask, relatedTo, or linkedTaskType changes.
 * @author Bhavana
 */
  useEffect(() => {
    const getGridData = () => {
      switch (linkedTaskType) {
        case 'childTask':
          return childTask;
        case 'parentTask':
          return parentTask;
        case 'relatedTo':
          return relatedTo;
        default:
          return null;
      }
    };
    const gridData = getGridData();
    setGridData(gridData);
  }, [childTask, parentTask, relatedTo, linkedTaskType]);

  return (
    <LinkedTaskGrid linkedTaskType={linkedTaskType} labelValue={labelValue} gridData={gridData} setGridData={setGridData} />
  );
};

export default NewTaskLinkTask;
