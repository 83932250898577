import {
  EMPTY_QUEUE_FILTER_DETAILS, EMPTY_QUEUE_TASKS,
  EMPTY_USER_SAVED_FILTER,
  SET_DEFAULT_FILTER_VALUES,
  SET_QUEUE_FILTER_DETAILS,
  SET_QUEUE_TASKS,
  SET_SAVED_FILTERS,
  SET_USER_SAVED_FILTER,
  SET_LAST_FILTER_ID
} from "../actions/action-types";

const initialState = {
  defaultFilters: {},
  queueFilterData: {},
  queueSavedFilters: [],
  queueTasks: [],
  userSavedFilter: {},
  lastFilterId: null
};

/**
 * setting states according to the type of actions
 * @param {*} state
 * @param {*} action
 * @returns {state}
 */

const queueTaskPage = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_FILTER_VALUES:
      return {
        ...state,
        defaultFilters: action.payload
      };
    case SET_QUEUE_FILTER_DETAILS:
      return {
        ...state,
        queueFilterData: { ...state.queueFilterData, ...action.payload }
      }
    case EMPTY_QUEUE_FILTER_DETAILS:
      return {
        ...state,
        queueFilterData: {}
      }
    case EMPTY_QUEUE_TASKS:
      return {
        ...state,
        queueTasks: [],
      }
    case SET_SAVED_FILTERS:
      return {
        ...state,
        queueSavedFilters: action.payload
      }
    case SET_QUEUE_TASKS:
      return {
        ...state,
        queueTasks: action.payload
      }
    case SET_USER_SAVED_FILTER:
      return {
        ...state,
        userSavedFilter: action.payload
      }
    case EMPTY_USER_SAVED_FILTER:
      return {
        ...state,
        userSavedFilter: {}
      }
    case SET_LAST_FILTER_ID:
      return {
        ...state,
        lastFilterId: action.payload
      }
    default:
      return state;
  }
};

export default queueTaskPage;