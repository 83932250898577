import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from 'react-router';
import { Link } from "react-router-dom";
import { PROJECT_TYPE } from "../../../../components/ProjectSidebar/projectSidebar.constants";
import { button, icon, label, route } from "../../../../config";
import { number } from "../../../../config/constants";
import { logoutUser } from "../../../../shared/services/api.service";
import { subStr } from '../../../../shared/validators/validator';
import { checkAdmin } from "../../../../utils";
import { checkSuperAdmin } from "../../../../utils/common";
import { openProjectSidebar } from "../../../services/projects.service";
import { loadState } from "../../../services/session.service";
import ChangePassword from "../ChangePassword/ChangePassword";

/**
 * @author Himanshi Chawla
 */

const UserDropdown = (props) => {
  const { toggleDropdown, openProfile, buttonRef } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showCompanyName, setShowCompanyName] = useState(null);
  const [showUserName, setShowUserName] = useState(null);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const userDropdown = useRef(null);
  const changePassword = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const history = useHistory()
  const company = useSelector((state) => state.company);

  const user = auth.user;
  const organization = company.organization;

  useEffect(() => {
    setShowCompanyName(subStr(user.displayName, number.ZERO, number.NINETEEN));
    setShowUserName(subStr(user.Name, number.ZERO, number.NINETEEN));
    setIsGoogleUser(loadState(label.GOOGLE_USER));
  }, [user.id]);

  useEffect(() => {
    setShowCompanyName(subStr(user.displayName, number.ZERO, number.NINETEEN));
    setShowUserName(subStr(user.Name, number.ZERO, number.NINETEEN));
    setIsGoogleUser(loadState(label.GOOGLE_USER));
  }, [user.id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userDropdown.current && (!userDropdown.current.contains(event.target) && !buttonRef.current.contains(event.target) && !showPassword)) {
        toggleDropdown();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPassword]);




  /**
  * updates the breadcrumbs for manage organisation 
  * if user propagated through superadmin console 
  * @param {void}
  * @returns {void}
  * @author Pragun Gandotra
  */
  const handleClickAdminConsole = () => {
    const adminRoute = route.ADMIN_ROUTE.MANAGE_ORGANIZATION;
    if (adminRoute.PARENT_ROUTES.length > number.ONE) {
      adminRoute.PARENT_ROUTES.pop();
      adminRoute.PROPS.HEADER = "Manage Organization";
    }
    history.push(adminRoute.PATH);
    props.toggleDropdown();
  }

  /**
  * logout of app
  */
  const logOut = () => {
    dispatch(logoutUser())
  };


  /**
   * change state bool true to open modal popup
   */
  const openModal = () => {
    setShowPassword(true);
  };


  /**
   * change state bool false to close modal popup
   */
  const hideModal = () => {
    setShowPassword(false);
  };

  return (
    <div id="user-dropdown-on-click"
      ref={userDropdown}
      className={`${openProfile ? `d-block` : `d-none`} header-dropdown-menu position-absolute profile-dropdown-menu right-0`}>
      <ul className="project-setting-list mt-0 pl-0">
        <li className="d-inline-block w-100 cursor-pointer">
          <span id="user-dropdown-company-name">
            {user.companyPicture ? (<img src={`${user.companyPicture}`} alt="org" className="org-image rounded-circle"></img>) : (icon.BUSINESS)}
            {user.displayName && user.displayName.trim().length >= number.NINETEEN ? (
              <span className="text-truncate" data-toggle="tooltip" data-placement="bottom" title={user.displayName}>  {" "}{showCompanyName}
              </span>) : user.displayName ? (user.displayName) : (user.companyDisplayName)}
          </span>
        </li>
        <li className="d-inline-block w-100 cursor-pointer">
          <span
            id="user-dropdown-user-name"
            onClick={() => { openProjectSidebar(PROJECT_TYPE.PERSONAL_PROJECT, auth.user.myProjectId, number.ZERO); props.toggleDropdown(); }}>
            {icon.ACCOUNT_CIRCLE} {" "}
            {user.Name && user.Name.trim().length >= number.NINETEEN ? (
              <span data-toggle="tooltip" data-placement="bottom" title={user.Name}>
                {showUserName}
              </span>) : user.Name}
          </span>
        </li>
        {isGoogleUser && (
          <li className="d-inline-block w-100 cursor-pointer">
            <span
              onClick={() => { openModal("showPassword"); }}
              id="user-dropdown-change-password-on-click"  >
              {icon.LOCK} {button.CHANGE_PASSWORD}
            </span>
          </li>)}
        {checkAdmin(user.isAdmin) && (
          <li className="d-inline-block w-100 cursor-pointer">
            <span id="user-dropdown-admin-console" className='cursor-pointer' onClick={handleClickAdminConsole}>
              {icon.ADMIN_SETTINGS}{button.ADMIN_CONSOLE}
            </span>
          </li>
        )}
        {checkSuperAdmin(user.IsSuperAdmin) && (
          <li className="d-inline-block w-100 cursor-pointer">
            <Link to={route.SUPER_ADMIN_ROUTE.VIEW_ORGANISATION.PATH} className="text-decoration-none text-reset" onClick={() => props.toggleDropdown()}>
              <span id="user-dropdown-super-admin-console">
                {icon.ADMIN_SETTINGS}{button.SUPER_ADMIN_CONSOLE}
              </span>
            </Link>
          </li>)}
        <li className="d-inline-block w-100 cursor-pointer">
          <span onClick={logOut} id="log-out">
            {icon.LOGOUT} {button.LOGOUT}  </span>
        </li>
      </ul>
      <div>
        {showPassword && (<ChangePassword
          ref={changePassword}
          show={showPassword}
          hideModal={() => { hideModal("showPassword"); }}
          toggleDropdown={() => { toggleDropdown() }} />)}
      </div>
    </div>
  );
};


export default UserDropdown;


