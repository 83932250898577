
import { action } from '../../config/index';






// config/userDropdown.js
export const userDropdownData = {
  PROFILE_MODAL: {
    optionsData: [
      {
        label: 'Donot Disturb',
        value: false,
        defaultValue: false,
        key: 'DnD',
        icon: 'DND'
      },
      {
        label: 'Email Notifications',
        value: false,
        defaultValue: false,
        key: 'EmailNotification',
        icon: 'EMAIL_NOTIFICATION',
        children: [
          {
            label: 'Daily Digest Updates',
            value: false,
            defaultValue: false,
            key: 'DailyDigestUpdates',
            icon: ''
          },
          {
            label: 'Assigned Tasks',
            value: false,
            defaultValue: false,
            key: 'AssignedTasks',
            icon: ''
          },
          {
            label: 'Followed Tasks',
            value: false,
            defaultValue: false,
            key: 'FollowedTasks',
            icon: ''
          }
        ]
      },

      {
        label: 'Plan My Day',
        value: false,
        defaultValue: false,
        key: 'PlanMyDay',
        icon: 'PLAN_DO_LATER'
      },
    ],
    dndData: [
      {
        label: 'Start Time',
        value: '20:00',
        defaultValue: '20:00',
        key: 'DndStartTime'
      },
      {
        label: 'End Time',
        value: '23:59',
        defaultValue: '23:59',
        key: 'DndEndTime'
      }
    ]
  },
  CHANGEPASSWORD_MODAL: [
    {
      "label": "Current Password",
      "value": '',
      key: 'CurrentPassword'
    },
    {
      "label": "New Password",
      "value": '',
      key: 'NewPassword'
    },
    {
      "label": "Confirm Password",
      "value": '',
      key: 'ConfirmPassword'
    }
  ]
}


export const profileButtons = [
  {
    label: 'Cancel',
    variant: 'secondary',
    action: action.HIDE,
    disabled: false,
    class: 'btn-width mr-2 btn btn-secondary'
  },
  {
    label: 'Save',
    variant: 'primary',
    action: action.CONFIRM,
    disabled: false,
    class: 'btn-width btn btn-primary'
  }
]

export const exportButtons = [
  {
    label: 'Cancel',
    variant: 'secondary',
    action: action.HIDE,
    disabled: false,
    class: 'btn-width mr-2 btn btn-secondary'
  },
  {
    label: 'Export',
    variant: 'primary',
    action: action.CONFIRM,
    disabled: false,
    class: 'btn-width btn btn-primary'
  }
]

export const changePasswordButtons = [

  {
    label: 'Cancel',
    variant: 'secondary',
    action: action.HIDE,
    disabled: false
  },
  {
    label: 'Save',
    variant: 'primary',
    action: action.CONFIRM,
    disabled: true
  },
]

export const removeProjectUserButton = [
  {
    label: "Cancel",
    variant: "secondary",
    action: action.HIDE,
    disabled: false,
    class: 'mr-2'
  },
  {
    label: "Confirm",
    variant: "primary",
    action: action.CONFIRM,
    disabled: false,
  }
];

export const deleteTagButton = [
  {
    label: "Cancel",
    variant: "secondary",
    action: action.HIDE,
    disabled: false,
    class: 'mr-2'
  },
  {
    label: "Confirm",
    variant: "primary",
    action: action.CONFIRM,
    disabled: false,
  }
];

export const deleteSpaceUtilizationButton = [
  {
    label: "Cancel",
    variant: "secondary",
    action: action.HIDE,
    disabled: false,
    class: 'mr-2'
  },
  {
    label: "Confirm",
    variant: "primary",
    action: action.CONFIRM,
    disabled: false,
  }
];

// constants.js
export const showDescriptionIcon = {
  "Queue": false,
  "Tasks": true,
  "Project": false,
  "Queue Tasks": true,
  "Workflows": false
};
