import { label, number } from "../../config";
import store from "../../store";
import { getTagLabelAndColor } from "./tasks.constants";
import { pinTasks } from "./tasks.service";

/**
 * updates grid data
 * @param {Object} updatedData
 * @author Himanshu Negi
 */
export const updateGridTasks = (updatedData) => {
    const state = store.getState();
    const { allTasks } = state.tasks;
    const newMyTask = allTasks?.myTasks?.map((task) => {
        const data = updatedData?.find((data) => data.Id === task?.Id);
        if (data) {
            return { ...task, ...data };
        } else {
            return { ...task };
        }
    });

    const newProjectTask = allTasks?.projectTasks?.map((task) => {
        const data = updatedData?.find((data) => data.Id === task.Id)
        if (data) {
            return { ...task, ...data }
        } else {
            return { ...task }
        }
    });

    const newSuperSetTask = allTasks?.superset?.map((task) => {
        const data = updatedData?.find((data) => data.Id === task.Id)
        if (data) {
            return { ...task, ...data }
        } else {
            return { ...task }
        }
    })


    return { newMyTask, newProjectTask, newSuperSetTask };
}


/**
 * checks for assignee changed
 * @param {Object} updatedData
 * @author Himanshu Negi
 */
export const isAssigneeChanged = (updatedData) => {
    const state = store.getState();
    let isChanged = false;
    const { allTasks } = state.tasks;
    const { user } = state.auth;
    allTasks?.superset?.forEach((task) => {
        const data = updatedData?.find((data) => data.Id === task?.Id);
        if (data) {
            if (data.Assignee !== task?.Assignee) {
                isChanged = true;
            }
        } else if (updatedData?.find((d) => d.Assignee === user?.id)) {
            isChanged = true;
        }
    });
    return isChanged;
}


export const getUserPositionPayload = (projectId) => {
    const state = store.getState();
    if (projectId) {
        return {
            positionId: state?.userPosition?.positionId,
            userId: state.auth?.user?.id,
            routeId: state?.tasks?.defaultDetails?.routeTypeList?.find((route) => route.Id === number.TWO)?.Id,
            entityId: projectId
        };
    } else {
        return {
            positionId: state?.userPosition?.positionId,
            userId: state.auth?.user?.id,
            routeId: state?.tasks?.defaultDetails?.routeTypeList?.find((route) => route.Id === number.ONE)?.Id,
            entityId: state.auth?.user?.myProjectId
        };
    }
}



/**
 * on pinning/unpinning returns response and pinTaskCount for respective task
 */
export const pinTaskHelper = async (payload) => {
    let res = await pinTasks(payload);
    let pinTaskCount = Array.isArray(res) ? res[number.ZERO][number.ZERO].pinnedTaskCount : null
    return [res, pinTaskCount];
}

/**
 * Filters and maps the `gridAllTask` data based on the selected `filterTableColumn`.
 * @author Bhavana
 */
export const getFilteredGridData = (gridAllTask, filterTableColumn, projectId) => {
  return gridAllTask.map((task) => {
    const filteredData = {};

    if (filterTableColumn.includes(label?.TASK_ID)) {
      filteredData.ProjectTaskId = task?.ProjectTaskId;
    }

    filteredData.Name = task?.Name;

    if (filterTableColumn.includes(label?.STAGE)) {
      filteredData.StageName = task?.StageName;
    }

    if (filterTableColumn.includes(label?.DUE_DATE)) {
      filteredData.date = task?.date;
    }

    if (filterTableColumn.includes(label?.CHOOSE_PRIORITY)) {
      filteredData.PriorityName = task?.PriorityName;
    }

    if (projectId && filterTableColumn.includes(label?.CURRENT_USER)) {
      filteredData.AssigneeName = task?.AssigneeName;
    }

    if (filterTableColumn.includes(label?.TAGS)) {
      let { labels } = getTagLabelAndColor(task?.tagIds);
      filteredData.Tags = labels;
    } else if (filterTableColumn.includes(label?.ASSIGNED_BY)) {
      filteredData.AssignedByName = task?.AssignedByName;
    }

    return filteredData;
  });
};

/**
 * Returns the label of the filter matching the last filter ID.
 * @author Bhavana
 */
export const getFilterName = () => {
  const state = store.getState();
  const { lastFilterId, teamSavedFilters } = state.teamFilter;
  return teamSavedFilters?.find((filter) => filter.value === lastFilterId && lastFilterId)?.label;
};
