//labels and values of weekend
export const skipDay = [
    { label: "Skip Past Dates", value: true},
    { label: "Saturday", value: "7" },
    { label: "Sunday", value: "1" },
];

export const skipPastDates = [
    { label: "Skip Past Dates", value: true}
];
